import React, { useEffect, useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import privateApi from "../../../services/privateApi";
import ReactDatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import "../../../assets/global.css";
import Option2 from "../../extrapage/Page19/Options2";
import Option3 from "../../extrapage/Page19/Options3";
import { useHistory } from "react-router-dom";
import Nvh from "../../common/Nvh";
import ja from "date-fns/locale/ja";
import InputMask from "react-input-mask";
import ConvertTo from "../../utilis/ConvertKataka";
import { differenceInDays, differenceInYears, format, parse } from "date-fns";
import { getCurrentMonth } from "../../../services/auth/token";
import moment from "moment";

function CreateShortTermStudent() {
  const history = useHistory();
  const [errorList, setErrorList] = useState({});
  const [loading, setLoading] = useState("");
  const [startDate, setStartDate] = useState(new Date("2000-01-01"));
  const [joiningDate, setJoiningDate] = useState(new Date());
  const [leaveDate, setLeaveDate] = useState();
  const [guradianId, setGuardianId] = useState("");
  const [guardianOptions, setGuardianOptions] = useState();
  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [buses, setBuses] = useState([]);
  const [banks, setBanks] = useState([]);
  const [memberCode, setMemberCode] = useState();
  const [branchName, setBranchName] = useState("ブランチの選択");
  const [guradianFieldShow, setGuradianFieldShow] = useState(false);
  const [enableTabs, setEnableTabs] = useState(false);
  const [page, setPage] = useState(1);
  const [addressOptions, setAddressOptions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const addressListRef = useRef();
  const [areaLoading, setAreaLoading] = useState(false);
  const [showSugg, setShowSugg] = useState(false);
  const [age, setAge] = useState(null);
  const [classLevel, setClassLevel] = useState(null);
  
  const [userId, setUserId] = useState();
  const excludedUserIds = ["tondabayashi", "abiko"];
  const mailHide = ["abiko"];
  const genderHide = ["abiko"];
  

  let inputFields = {
    type: "Short Term",
    school_id: "",
    receipt_code: "",
    name: "",
    furigana: "",
    email: "",
    password: "123456",
    gender: "",
    birthday: startDate.toISOString().split("T")[0],
    district_code: "Default",
    zip_code: "",
    address_1: "",
    address_2: "",
    parent_name: "",
    phone_no_1: "",
    phone_note_1: "",
    phone_no_2: "",
    phone_note_2: "",
    fax_number: "",
    mail_address_1: "",
    mail_category_1: "",
    mail_note_1: "",
    mail_address_2: "",
    mail_category_2: "",
    mail_note_2: "",
    mail_address_3: "",
    mail_category_3: "",
    mail_note_3: "",
    charge_type_code: "",
    payment_type_code: "",
    deposit_item: "",
    account_no: "",
    account_holder: "",
    note: "",
    bus_course_number: "",
    outbound_bus: "",
    return_bus: "",
    created_by: "",
    status: "",
    password_confirmation: "123456",
    relation: "",
    joining_date: joiningDate.toISOString().split("T")[0],
    leave_date: "",
    bank_code: "",
    branch_code: "",
    em_first_name: "",
    em_last_name_furigana: "",
    em_first_name: "",
    em_last_name_furigana: "",
    em_phone_no: "",
  };

  const [newStudents, setNewStudents] = useState(inputFields);
  const [savedStudents, setSavedStudents] = useState([]);
  const [showValidation, setShowValidation] = useState(false);

  const handleInput = e => {
    e.persist();
    setErrorList({});
    setNewStudents({ ...newStudents, [e.target.name]: e.target.value });
    console.log(newStudents);
  };

  //Image Upload Handler
  const [file, setFile] = useState("");
  const [imagedata, setImagedata] = useState("");
  const handleChange = file => {
    setFile(URL.createObjectURL(file[0]));
    setImagedata(file[0]);
  };

  const setBlankField = () => {
    setGuardianId();
    setFile();
    setImagedata("");
    setNewStudents(inputFields);
    getGenerelRes();
    setGuardianId("");
  };

  const addNewStudent = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("member_code", memberCode);
    formData.append("guardian_photo", imagedata);
    formData.append("family_member_id", guradianId.value);

    for (const key in newStudents) {
      formData.append(key, newStudents[key]);
    }

    const response = await privateApi.post("admin/members", formData);

    if (
      newStudents.password !== newStudents.confirmPassword &&
      response.status === 200
    ) {
      setSavedStudents(response.data.data);
      setLoading(false);
      setBlankField();
      setEnableTabs(true);
      setShowValidation(false);
      swal(
        "作成しました！",
        "新しいスーパー管理者が作成されました！",
        "success"
      );
      // redirect to student list page
      history.push("/admin/stc/short-term-student-list");
    } else {
      setLoading(false);
      setShowValidation(true);
      setErrorList(response.data.errors);
      // swal(response.data.message, "", "error");
    }
  };

  const newGuardiansArray = [{ label: "家族を追加する", value: "" }];

  const createCustomGuardiansArray = old_array => {
    old_array.forEach(function (item) {
      newGuardiansArray.push({
        label: item.first_name + " " + item.last_name,
        value: item.id,
      });
    });
  };

  const getGuardians = async e => {
    const response = await privateApi.get("admin/guardians");
    if (response.status === 200) {
      createCustomGuardiansArray(response.data.data);
      setGuardianOptions(newGuardiansArray);
    } else {
      console.log("Server Error");
    }
  };

  const getClasses = async e => {
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const getGenerelRes = async e => {
    const month = getCurrentMonth();

    const response = await privateApi.get(
      `admin/general-resources?member_type=Short Term&type=bus,bank,member_code&processed_date=${moment(month).format("YYYY-MM")}`
    );
    if (response.status === 200) {
      setBuses(response.data.bus);
      setBanks(response.data.bank);
      setMemberCode(response.data.member_code);
    } else {
      console.log("Server Error");
    }
  };

  const bankHandler = (bank_code, branch_code, branch_name) => {
    setNewStudents({
      ...newStudents,
      bank_code: bank_code,
      branch_code: branch_code,
    });
    setBranchName(branch_name);
  };

  useEffect(() => {
    getGuardians();
    getClasses();
    getGenerelRes();
    handleDateChange(startDate);

    if (guradianId?.value == "") {
      setGuradianFieldShow(true);
    } else {
      setGuradianFieldShow(false);
    }
  }, [guradianId]);

  useEffect(() => {
    const userData = localStorage.getItem("school_slug");
    if (userData) {
      setUserId(userData);
    }
  }, []);

  // KAnji to Katakana

  const convertAndSetFurigana = async (target_field, given_field, type) => {
    try {
      const convertedText = await ConvertTo(newStudents[given_field], type);
      if (convertedText) {
        setNewStudents({
          ...newStudents,
          [`${target_field}`]: convertedText,
        });
      } else {
        setNewStudents({
          ...newStudents,
          [`${target_field}`]: "",
        });
      }
    } catch (error) {
      console.error(`Error converting ${given_field}`, error);
    }
  };
  const isFetching = useRef(false);
  const fetchAddressOptions = async (input, currentPage) => {
    if (currentPage > 0) {
      setAreaLoading(true);
    }

    if (isFetching.current) return;
    isFetching.current = true;

    try {
      const response = await privateApi.post(
        `admin/city-search?page=${currentPage + 1}`,
        input
      );
      if (response.status === 200) {
        const newOptions = await response.data.data;
        setAddressOptions(prevOptions => [...prevOptions, ...newOptions]);
        setPage(currentPage + 1);
        setAreaLoading(false);
        setShowSugg(true);
      }
    } catch (error) {
      console.error("Error fetching address options:", error);
    } finally {
      isFetching.current = false;
    }

    setAreaLoading(false);
  };
  const addressHandler = value => {
    setShowSugg(false);
    setNewStudents({
      ...newStudents,
      address_1: value.city + value.address,
      zip_code: value.postal_code,
    });
  };
  const handleKeyDown = e => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex(prevIndex =>
        prevIndex < addressOptions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      e.preventDefault();
      addressHandler(addressOptions[highlightedIndex]);
    }

    if (addressListRef.current) {
      const itemHeight = 50;
      const scrollTarget =
        highlightedIndex * itemHeight - addressListRef.current.clientHeight / 2;

      const animateScroll = time => {
        const currentTime = time || 0;
        const duration = 500;
        const progress = Math.min(1, currentTime / duration);
        if (addressListRef.current) {
          addressListRef.current.scrollTop =
            addressListRef.current.scrollTop +
            (scrollTarget - addressListRef.current.scrollTop) * progress;

          if (progress < 1) {
            window.requestAnimationFrame(animateScroll);
          }
        }
      };

      window.requestAnimationFrame(animateScroll);
    }
  };

  const zipCodeHandler = value => {
    setPage(1);
    setAddressOptions([]);
    const data = {
      postal_code: value,
      per_page: 15,
    };
    fetchAddressOptions(data, 0);
  };
  const handleDateChange = date => {
    if (date) {
      setStartDate(date);
      setNewStudents({ ...newStudents, birthday: date });
      calculateAge(date);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.name) {
        convertAndSetFurigana("furigana", "name", "katakana");
      } else {
        setNewStudents({
          ...newStudents,
          furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.name]);

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.em_first_name) {
        convertAndSetFurigana(
          "em_first_name_furigana",
          "em_first_name",
          "katakana"
        );
      } else {
        setNewStudents({
          ...newStudents,
          em_first_name_furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.em_first_name]);

  const calculateAge = birthdate => {
    console.log("birthdate");
    if (!birthdate) {
      setAge(null);
      return;
    }

    const today = new Date();
    const birthDate = parse(birthdate, "yyyy-MM-dd", new Date());
    console.log(birthDate);
    const diffInDays =
      differenceInDays(today, birthDate) -
      Math.floor(differenceInDays(today, birthDate) / 365 / 4);

    const years = Math.floor(diffInDays / 365);
    // const months = Math.floor((diffInDays % 365) / 30);
    // const days = diffInDays - years * 365 - months * 30;

    //calculate which class
    var year_now = today.getFullYear();
    var month_now = today.getMonth();
    if (month_now == "0" || month_now == "1" || month_now == "2") {
      year_now = Math.floor(year_now - 1);
    }
    const april_day = new Date(year_now + "-04-01");

    const years_for_class = differenceInYears(april_day, birthDate);

    var class_of_the_student = "";

    if (years_for_class == "6") {
      class_of_the_student = "小1";
    } else if (years_for_class == "7") {
      class_of_the_student = "小2";
    } else if (years_for_class == "8") {
      class_of_the_student = "小3";
    } else if (years_for_class == "9") {
      class_of_the_student = "小4";
    } else if (years_for_class == "10") {
      class_of_the_student = "小5";
    } else if (years_for_class == "11") {
      class_of_the_student = "小6";
    } else if (years_for_class == "12") {
      class_of_the_student = "中1";
    } else if (years_for_class == "13") {
      class_of_the_student = "中2";
    } else if (years_for_class == "14") {
      class_of_the_student = "中3";
    }

    setAge(`${years} 才`);
    setClassLevel(`${class_of_the_student}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (newStudents.em_last_name) {
        convertAndSetFurigana(
          "em_last_name_furigana",
          "em_last_name",
          "katakana"
        );
      } else {
        setNewStudents({
          ...newStudents,
          em_last_name_furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newStudents.em_last_name]);

  const formSection = (
    <span
      className={`${showValidation ? "was-validated" : "needs-validation"}`}
    >
      <div className="row">
        <div className="">
          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              短期ID: <span className="text-white">-</span>
            </label>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="text"
                placeholder="T000"
                name="member_code"
                onChange={e => setMemberCode(e.target.value)}
                value={memberCode}
                readOnly
              />
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              受付番号:
            </label>
            <div className="col-sm-4">
              <input
                className="form-control"
                type="text"
                placeholder="000"
                name="receipt_code"
                onChange={handleInput}
                value={newStudents.receipt_code}
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              氏名: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="名前"
                name="name"
                onChange={handleInput}
                value={newStudents.name}
                className={`form-control ${errorList.name ? "is-invalid" : ""}`}
                required
              />
              <div className="invalid-feedback">
                {errorList.name ? errorList.name : ""}
              </div>
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              フリガナ: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="フリガナ"
                name="furigana"
                onChange={handleInput}
                value={newStudents.furigana}
                className={`form-control ${
                  errorList.furigana ? "is-invalid" : ""
                }`}
                required
              />
              <div className="invalid-feedback">
                {errorList.furigana ? errorList.furigana : ""}
              </div>
            </div>
          </div>

          <div className="form-group mb-2 row">
          <label className="col-sm-2 col-form-label text-sm-end mt-2">
              性別: <span className="required">*</span>
            </label>
            <div className="col-sm-4 pt-2">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="invalidCheck"
                  name="gender"
                  onChange={handleInput}
                  value="男"
                  checked={newStudents.gender === "男"}
                  required
                />
                <label
                  className="form-check-label"
                  htmlFor="validationFormCheck2"
                >
                  男
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="validationFormCheck2"
                  name="gender"
                  onChange={handleInput}
                  value="女"
                  checked={newStudents.gender === "女"}
                  required
                />
                <label
                  className="form-check-label"
                  htmlFor="validationFormCheck2"
                >
                  女
                </label>
              </div>

              {!genderHide.includes(userId) && (
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="validationFormCheck2"
                    name="gender"
                    onChange={handleInput}
                    value="未"
                    checked={newStudents.gender === "未"}
                    required
                  />

                  <label
                    className="form-check-label"
                    htmlFor="validationFormCheck2"
                  >
                    未
                  </label>
                </div>
              )}

              <div className="invalid-feedback">
                {errorList?.gender ? errorList?.gender : ""}
              </div>
            </div>
            
            <label className="col-sm-2 col-form-label text-sm-end">
              生年月日:
            </label>
            <div className="col-sm-4">
              <ReactDatePicker
                className={`form-control ${
                  errorList.birthday ? "is-invalid" : ""
                }`}
                dateFormat="yyyy/MM/dd"
                selected={startDate}
                required
                locale={ja}
                onChange={date => {
                  if (date) {
                    setStartDate(date);
                    const formattedDate = date.toISOString().split("T")[0];
                    setNewStudents({ ...newStudents, birthday: formattedDate });
                    calculateAge(date.target.value);
                    //setStartDate(date.target.value);
                  }
                }}
                customInput={
                  <InputMask
                    mask="9999/99/99"
                    maskChar="_"
                    alwaysShowMask={true}
                  >
                    {inputProps => (
                      <input {...inputProps} className="form-control" />
                    )}
                  </InputMask>
                }
              />
              <div className="small text-danger">
                {errorList.birthday ? errorList.birthday : ""}
              </div>
            </div>
            
            {/*
            <label className="col-sm-2 col-form-label text-sm-end">
              生年月日: <span className="required">*</span>
            </label>
            <div className="col-sm-3 ">
              <div className="">
                <input
                  type="date"
                  className={`form-control w-100 ${
                    errorList?.birthday ? "is-invalid" : ""
                  }`}
                  value={startDate}
                  required
                  onChange={e => {
                    setStartDate(e.target.value);
                    calculateAge(e.target.value);
                    setNewStudents({
                      ...newStudents,
                      birthday: e.target.value,
                    });
                  }}
                  max="12-31-3090"
                />
                <div className="small text-danger">
                  {errorList?.birthday ? errorList?.birthday : ""}
                </div>
              </div>

              <div className="age-view">
                <div className="text-success">
                  {age} &nbsp;&nbsp; {classLevel}
                </div>
              </div>
            </div>
            */}
          </div>
          
          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              郵便番号: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <div className="select-wrapper">
                <input
                  type="text"
                  placeholder="郵便番号"
                  autoComplete="off"
                  name="zip_code"
                  onChange={e => {
                    setNewStudents({
                      ...newStudents,
                      zip_code: e.target.value,
                      address_1: "",
                    });
                    zipCodeHandler(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  value={newStudents.zip_code}
                  className={`form-control ${
                    errorList?.zip_code ? "is-invalid" : ""
                  }`}
                  required
                />

                {addressOptions.length > 0 && showSugg ? (
                  <ul
                    ref={addressListRef}
                    className="shadow-sm card list-box"
                    style={{
                      listStyle: "none",
                      maxHeight: "200px",
                      overflowY: "auto",
                      padding: "0px",
                    }}
                  >
                    {addressOptions.map((add, index) => (
                      <li
                        className={`area-list-item ${
                          highlightedIndex === index ? "highlighted" : ""
                        }`}
                        key={index}
                        onClick={() => addressHandler(add)}
                      >
                        {add.postal_code} ({add.city} {add.address})
                      </li>
                    ))}

                    {areaLoading && <li className="text-center">...</li>}
                  </ul>
                ) : (
                  ""
                )}
              </div>

              <div className="invalid-feedback">
                {errorList?.zip_code ? errorList?.zip_code : ""}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="">
          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              住所 1: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="住所 1"
                name="address_1"
                onChange={handleInput}
                value={newStudents.address_1}
                className={`form-control ${
                  errorList.address_1 ? "is-invalid" : ""
                }`}
                required
              />
              <div className="invalid-feedback">
                {errorList.address_1 ? errorList.address_1 : ""}
              </div>
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              住所 2:
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="住所 2"
                name="address_2"
                onChange={handleInput}
                value={newStudents.address_2}
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              電話1: <span className="required">*</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="電話番号1"
                name="phone_no_1"
                onChange={handleInput}
                value={newStudents.phone_no_1}
                className={`form-control ${
                  errorList.phone_no_1 ? "is-invalid" : ""
                }`}
                required
              />
              <div className="invalid-feedback">
                {errorList.phone_no_1 ? errorList.phone_no_1 : ""}
              </div>
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">備考:</label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="電話備考1"
                name="phone_note_1"
                onChange={handleInput}
                value={newStudents.phone_note_1}
              />
            </div>
          </div>

          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              電話2:<span className="text-white">-</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="電話番号2"
                name="phone_no_2"
                onChange={handleInput}
                value={newStudents.phone_no_2}
              />
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">備考:</label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="電話備考2"
                name="phone_note_2"
                onChange={handleInput}
                value={newStudents.phone_note_2}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="">
          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              メール1: 
            </label>
            <div className="col-sm-4">
              <input
                type="email"
                placeholder="メールアドレス_1"
                name="mail_address_1"
                onChange={handleInput}
                value={newStudents.mail_address_1}
                className={`form-control ${
                  errorList.mail_address_1 ? "is-invalid" : ""
                }`}
              />
              <div className="invalid-feedback">
                {errorList.mail_address_1 ? errorList.mail_address_1 : ""}
              </div>
            </div>
            <label className="col-sm-2 col-form-label text-sm-end">備考:</label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                placeholder="メール備考1"
                name="mail_note_1"
                onChange={handleInput}
                value={newStudents.mail_note_1}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="">
          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              バスコース番号: <span className="text-white">-</span>
            </label>

            <div className="col-sm-4">
              <select
                className="form-control"
                placeholder="バスコース番号"
                name="bus_course_number"
                onChange={handleInput}
                value={newStudents.bus_course_number}
              >
                <option value="">バスの選択</option>
                {buses.map((bus, i) => (
                  <option key={i} value={bus.id}>
                    {bus.bus_course_number} ({bus.stop_name})
                  </option>
                ))}
              </select>
            </div>

            <div className="col-5 row">
              <label className="col-2 col-form-label text-sm-end">往路:</label>
              <div className="col-2 pr-0">
                <input
                  type="number"
                  className="form-control"
                  name="outbound_bus"
                  onChange={handleInput}
                  value={Nvh({ value: newStudents.outbound_bus })}
                />
              </div>
              <div className="col-2">人</div>
              <label className="col-sm-2 col-form-label text-sm-end">
                復路:
              </label>
              <div className="col-sm-2 pr-0">
                <input
                  type="number"
                  className="form-control"
                  name="return_bus"
                  onChange={handleInput}
                  value={Nvh({ value: newStudents.return_bus })}
                />
              </div>
              <div className="col-2"> 人</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className=" row">
          <div className="col-md-2"></div>
          <div className="col-md-10">
            <h4 className="p-2">緊急連絡</h4>
          </div>
        </div>
        <div className=""></div>
      </div>

      <div className="row">
        <div className="">
          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              姓: <span className="text-white">-</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="緊急時の名"
                className="form-control"
                name="em_first_name"
                onChange={handleInput}
                value={Nvh({ value: newStudents.em_first_name })}
              />
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              フリガナ:
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="姓：フリガナ"
                className="form-control"
                name="em_first_name_furigana"
                onChange={handleInput}
                value={Nvh({ value: newStudents.em_first_name_furigana })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="">
          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              {" "}
              名: <span className="text-white">-</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="緊急時の姓"
                className="form-control"
                name="em_last_name"
                onChange={handleInput}
                value={Nvh({ value: newStudents.em_last_name })}
              />
            </div>

            <label className="col-sm-2 col-form-label text-sm-end">
              フリガナ:
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="名：フリガナ"
                className="form-control"
                name="em_last_name_furigana"
                onChange={handleInput}
                value={Nvh({ value: newStudents.em_last_name_furigana })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="">
          <div className="form-group mb-2 row">
            <label className="col-sm-2 col-form-label text-sm-end">
              電話番号: <span className="text-white">-</span>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                placeholder="緊急電話番号"
                className="form-control"
                name="em_phone_no"
                onChange={handleInput}
                value={Nvh({ value: newStudents.em_phone_no })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className=" d-flex">
          <div className="ml-auto">
            {loading ? (
              <ButtonLoader />
            ) : (
              <Button
                variant="primary"
                className="mx-auto btn-block mt-4"
                type="submit"
                onClick={() => addNewStudent()}
              >
                保存する
              </Button>
            )}
          </div>
        </div>
      </div>
    </span>
  );

  return (
    <>
      <div className="custom-scaling row">
        <div className="" style={{ width: "80%" }}>
          <div className="card">
            <div className="card-header">
              <div className="d-flex">
                <h5>新しい短期生徒を追加する:</h5>
                <div className="ml-auto">
                  <Link
                    className="btn btn-sm btn-primary px-3 ml-auto"
                    variant="primary"
                    to="/admin/stc/short-term-student-list"
                  >
                    {" "}
                    <i className="fas fa-angle-left fa-fw"></i> 戻る
                  </Link>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      <span>短期生徒</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      disabled={!enableTabs}
                    >
                      短期クラス
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active pt-4"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    {formSection}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    {enableTabs ? <Option2 studentInfo={savedStudents} /> : ""}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    {enableTabs ? <Option3 studentInfo={savedStudents} /> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateShortTermStudent;
