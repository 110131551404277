import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import swal from "sweetalert";
import { Table } from "react-bootstrap";
import privateApi from "../../services/privateApi";
import Pagination from "react-js-pagination";
import publicApi from "../../services/publicApi";
import { Skeleton } from "antd";

export default function LoginLogs() {
  const [schoolOptions, setSchoolOptions] = useState();
  const [schoolId, setSchoolId] = useState();
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState(false);
  const [paginationBtns, setPaginationBtns] = useState([]);

  const getLogs = async pageNumber => {
    const response = await privateApi.get(
      setLoading(true),
      `superadmin/search-login-log?page=${pageNumber}&per_page=10&school_id=${schoolId?.value}`
    );
    if (response.status === 200) {
      setLogs(response.data.data);
      setPaginationBtns(response?.data);
    } else {
      console.log("Server Error");
    }
    setLoading(false);
  };

  const getSchools = async () => {
    setLoading(true);
    const response = await publicApi.get("schools");
    if (response.status === 200) {
      setLoading(false);
      const newSchoolArray = [{ label: "学校を選択", value: "" }];
      response.data.data.forEach(function (item) {
        newSchoolArray.push({ label: item.school_name, value: item.id });
      });
      setSchoolOptions(newSchoolArray);
    } else {
      setLoading(false);
      swal("Schools not loaded", "", "warning");
    }
  };

  useEffect(() => {
    getSchools();
    getLogs();
  }, []);
  useEffect(() => {
    getLogs();
  }, [schoolId]);
  return (
    <div>
      <div className="row justify-content-left">
        <div className="col-md-6">
          <div className="card rounded">
            <div className="card-header text-center">
              <h4>学校を選択してください</h4>
            </div>
            <div className="card-body ">
              <div>
                <ReactSelect
                  className="shadow-sm"
                  options={schoolOptions}
                  onChange={setSchoolId}
                  value={schoolId}
                  placeholder="学校を選択"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2">
        {loading && <Skeleton paragraph={{ rows: 12 }} />}
        {logs && !loading && logs.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>IPアドレス</th>
                <th>メール</th>
                <th>ユーザーエージェント</th>
                <th>ログイン</th>
                <th>ログアウト</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>{log?.ip_address}</td>
                  <td>{log?.email}</td>
                  <td>{log?.user_agent}</td>
                  <td>{log?.login_at}</td>
                  <td>{log?.logout_at}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {logs.length > 0 && (
          <div className="paginations d-flex mt-3">
            <div className="d-flex mx-auto neomp">
              <Pagination
                activePage={
                  paginationBtns.current_page ? paginationBtns.current_page : 0
                }
                itemsCountPerPage={paginationBtns.per_page}
                totalItemsCount={paginationBtns.total}
                onChange={pageNumber => {
                  getLogs(pageNumber);
                }}
                pageRangeDisplayed={5}
                itemclassName="page-item"
                linkclassName="page-link"
                firstPageText="First"
                lastPageText="Last"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
