import TransferAttendance from '../components/admin/student/TransferAttendance';
import Dashboard from '../components/student/Dashboard';
import Classess from '../components/student/furikae/Classess';
import { Mypage } from '../components/student/my-page/MyPage';
import PaymentHistory from '../components/student/PaymentHistory';
import Payments from '../components/student/payments';
import Schedules from '../components/student/Schedules';

const routes = [
  { path: '/student', exact: true, name: 'student' },
  {
    path: '/student/dashboard',
    exact: true,
    name: 'Dashboard',
    component: Mypage,
    // component: Dashboard,
  },
  {
    path: '/student/re-schedule',
    exact: true,
    name: 'Schedules',
    component: Classess,
    // component: Schedules,
  },
  {
    path: '/student/attendance',
    exact: true,
    name: 'AttendanceList',
    // component: AttendanceList,
    component: TransferAttendance,
  },
  {
    path: '/student/payment',
    exact: true,
    name: 'PaymentHistory',
    // component: PaymentHistory,
    component: Payments
  },
];

export default routes;
