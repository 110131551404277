import React, { useState, useEffect } from "react";
import "../../components/admin/adminDashboard.css";
import ExcelFileUploader from "./ExcelFileUploader";

function PaymentProcessing() {
  return (
    <>
      <div>
        <p className="headline">入金処理</p>
      </div>
      <ExcelFileUploader />
    </>
  );
}

export default PaymentProcessing;
