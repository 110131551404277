import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import privateApi from "../../../services/privateApi";

export default function AttendanceHistory({
  info,
  callHistory,
  selectedStartTime,
  selectedEndTime,
  selectedDate,
  key2,
}) {
  const [history, setHistory] = useState([]);
  const [rankCounts, setRankCounts] = useState({});
  const [showComment, setShowComment] = useState({});

  const getAttendanceHistory = async () => {
    if (key2 === "attendanceSubmit") return;
    const params = {
      attendance_date: selectedDate,
      regular_class_id: info?.selectedClass,
      class_level_id: info?.selectedCourse,
      start_time: selectedStartTime,
      end_time: selectedEndTime,
    };
    setHistory([]);
    try {
      const response = await privateApi.post("coach/attendanceHistory", params);
      if (response.status === 200) {
        const classData = response.data.data;
        setHistory(classData);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  useEffect(() => {
    const initialShowCommentState = {};
    history.forEach(student => {
      initialShowCommentState[student.id] = false;
    });
    setShowComment(initialShowCommentState);
    setRankCounts(calculateRankCounts(history));
  }, [history]);

  const toggleShowComment = id => {
    setShowComment(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const calculateRankCounts = students => {
    const counts = {};
    students.forEach(student => {
      const rank = student?.member_class?.class;

      if (!counts[rank]) {
        counts[rank] = { total: 0, attended: 0 };
      }
      counts[rank].total += 1;
      if (student?.present) {
        counts[rank].attended += 1;
      }
    });
    return counts;
  };

  useEffect(() => {
    setRankCounts(calculateRankCounts(history));
  }, [history]);

  useEffect(() => {
    if (info?.selectedDate && info?.selectedClass) {
      getAttendanceHistory();
    }
  }, [callHistory, info]);

  useEffect(() => {
    setHistory([]);
  }, [selectedDate]);

  const sortedStudents = [...history].sort((a, b) => {
    const classA = a?.member_class?.class
      ? parseInt(a?.member_class?.class)
      : -Infinity;
    const classB = b?.member_class?.class
      ? parseInt(b?.member_class?.class)
      : -Infinity;

    return classB - classA;
  });

  const isLastOfRank = (student, index) => {
    const nextStudent = sortedStudents[index + 1];
    return (
      !nextStudent ||
      nextStudent?.member_class?.class !== student?.member_class?.class
    );
  };

  return (
    <div className="row mb-4 upper-container">
      {sortedStudents.length > 0 ? (
        <Table className="mt-1" striped bordered hover responsive="md">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>NO.</th>
              <th style={{ width: "15%" }}>名前</th>
              <th style={{ width: "15%" }}>フリガナ</th>
              <th style={{ width: "6%" }}>会員番号</th>
              <th style={{ width: "5%" }} className="text-center">
                級
              </th>
              <th style={{ width: "5%" }} className="text-center">
                小計
              </th>
              <th style={{ width: "5%" }} className="text-center">
                出席
              </th>
              <th>申し送り事項</th>
              {sortedStudents.some(student => student.result !== null) && (
                <>
                  <th style={{ width: "10%" }}>現在のクラス</th>
                  <th style={{ width: "10%" }}>試験コメント</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedStudents &&
              sortedStudents.length > 0 &&
              sortedStudents.map((student, index) => (
                <tr key={index}>
                  <td style={{ width: "5%" }}>{index + 1}</td>
                  <td style={{ width: "10%" }}>{student?.member.name}</td>
                  <td style={{ width: "10%" }}>{student?.member?.furigana}</td>
                  <td style={{ width: "10%" }}>
                    {student?.member?.member_code}
                  </td>
                  <td className="text-center" style={{ width: "5%" }}>
                    {student?.member_class?.class}
                  </td>
                  <td className="text-center" style={{ width: "7%" }}>
                    {isLastOfRank(student, index)
                      ? rankCounts[student?.member_class?.class]?.attended || 0
                      : ""}
                  </td>
                  <td style={{ width: "10%" }} className="text-center">
                    <p className="text-center">
                      {student.present ? "🟢" : "❌"}
                    </p>
                  </td>
                  <td className="d-flex">
                    <input
                      className="w-100"
                      type={showComment[student.id] ? "text" : "password"}
                      value={student?.member?.notice}
                      // onChange={e =>
                      //   commentHandler(student.id, e.target.value)
                      // }
                      // placeholder="コメントを入力"
                      disabled
                    />
                    <Button
                      variant="link"
                      className="float-end"
                      onClick={() => toggleShowComment(student.id)}
                    >
                      <FontAwesomeIcon
                        icon={showComment[student.id] ? faEye : faEyeSlash}
                      />
                    </Button>
                  </td>
                  {student.result !== null && (
                    <>
                      <td className="text-center" style={{ width: "10%" }}>
                        {student.result?.current_class || "--"}
                      </td>
                      <td style={{ width: "30%" }}>
                        {student.result?.comment || "--"}
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <div className="col-12">
          <p className="text-center text-info">出席履歴がありません。</p>
        </div>
      )}
    </div>
  );
}
