import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  //assigning location variable
  const location = useLocation();
  const { pathname } = location;
  const url = pathname.split("/coach/");
  const [promotionDropDown, setPromotionDropDown] = useState(false);
  const gradientStyle = {
    background: "linear-gradient(to right, #2193b0, #007bff80)",
  };

  const handleDropDown = value => {
    if (value === "" && pathname.includes("/coach/promotion")) {
      setPromotionDropDown(true);
      return;
    }

    if (value === "promotion") {
      setPromotionDropDown(!promotionDropDown);
    } else {
      setPromotionDropDown(false);
    }
  };

  useEffect(() => {
    handleDropDown("");
  }, [pathname]);

  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <div className="sb-sidenav-menu-heading"></div>
          <Link
            className={url[1] === "dashboard" ? "nav-link active2" : "nav-link"}
            to="/coach/dashboard"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-tachometer-alt"></i>
            </div>
            機能一覧{" "}
          </Link>

          <span
            onClick={() => handleDropDown("promotion")}
            className={
              url[1].startsWith("promotion/")
                ? "sb-nav-link-icon nav-link active2  hover-pointer dropdown-toggle"
                : "sb-nav-link-icon nav-link  hover-pointer dropdown-toggle"
            }
          >
            <i className="fas fa-star"></i>
            <span className="ps-2">進級処理</span>
          </span>

          <span
            className={`content dropdown ${promotionDropDown ? "active" : ""}`}
            style={gradientStyle}
          >
            {/* padding from left side */}
            <Link
              className={
                url[1] === "promotion" ||
                url[1] === "promotion/dashboard" ||
                url[1].startsWith("coach/promotion")
                  ? "nav-link active2"
                  : "nav-link"
              }
              to="/coach/promotion/dashboard"
            >
              <div className="ms-3 ps-2 sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              機能一覧
            </Link>
            <Link
              className={
                url[1] === "promotion/report" ? "nav-link active2" : "nav-link"
              }
              to="/coach/promotion/report"
            >
              <div className="ms-3 ps-2 sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              進級管理
            </Link>
            <Link
              className={
                url[1] === "promotion/grade" ? "nav-link active2" : "nav-link"
              }
              to="/coach/promotion/grade"
            >
              <div className="ms-3 ps-2 sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              学年ラベル
            </Link>
            <Link
              className={
                url[1] === "promotion/rank-management"
                  ? "nav-link active2"
                  : "nav-link"
              }
              to="/coach/promotion/rank-management"
            >
              <div className="ms-3 ps-2 sb-nav-link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              進級処理
            </Link>
          </span>

          {/* <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseProduct" aria-expanded="false" aria-controls="collapseProduct">
                        <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                        Students
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className="collapse" id="collapseProduct" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link className="nav-link" to="/coach/add-product">Add Students</Link>
                            <Link className="nav-link" to="/coach/view-product">View Students</Link>
                        </nav>
                    </div> */}

          {/* <Link className="nav-link" to="/coach/courses/list">
            <div className="sb-nav-link-icon">
              <i className="fas fa-tachometer-alt"></i>
            </div>
            Assigned Courses
          </Link> */}
          <Link
            className={
              url[1] === "attendance" ? "nav-link active2" : "nav-link"
            }
            to="/coach/attendance"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-list-alt"></i>
            </div>
            出席入力
          </Link>
          {/* <Link
            className={
              url[1] === "rank/rank-management"
                ? "nav-link active2"
                : "nav-link"
            }
            to="/coach/rank/rank-management"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-star"></i>
            </div>
            進級
          </Link>
          <Link
            className={
              url[1] === "rank/report" ? "nav-link active2" : "nav-link"
            }
            to="/coach/rank/report"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-list"></i>
            </div>
            進級管理
          </Link> */}
          {/*<Link
            className={
              url[1] === "re-schedule/class" ? "nav-link active2" : "nav-link"
            }
            to="/coach/re-schedule/class"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-random"></i>
            </div>
            振替処理
          </Link>
          <Link
            className={url[1] === "profile" ? "nav-link active2" : "nav-link"}
            to="/coach/profile"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-user"></i>
            </div>
            履歴確認
          </Link>
          <Link
            className={
              url[1] === "rank/report" ? "nav-link active2" : "nav-link"
            }
            to="/coach/rank/report"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-star"></i>
            </div>
            進級管理
          </Link>
          */}
        </div>
      </div>
      <div className="sb-sidenav-footer">
        <div className="small">としてログイン:</div>
        デモコーチ
      </div>
    </nav>
  );
};

export default Sidebar;
