import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Nvh from "../../common/Nvh";
import DateForm from "../../common/DateForm";
import { Badge } from "antd";

export const katakanaBtns = [
   {
      letter: "ア",
      values: "ア,イ,ウ,エ,オ"
   },
   {
      letter: "カ",
      values: "カ,キ,ク,ケ,コ"
   },
   {
      letter: "サ",
      values: "サ,シ,ス,セ,ソ"
   },
   {
      letter: "タ",
      values: "タ,チ,ツ,テ,ト"
   },
   {
      letter: "ナ",
      values: "ナ,ニ,ヌ,ネ,ノ"
   },
   {
      letter: "ハ",
      values: "ハ,ヒ,フ,ヘ,ホ"
   },
   {
      letter: "マ",
      values: "マ,ミ,ム,メ,モ"
   },
   {
      letter: "ヤ",
      values: "ヤ,ユ,ヨ"
   },
   {
      letter: "ラ",
      values: "ラ,リ,ル,レ,ロ"
   },
   {
      letter: "ワ",
      values: "ワ"
   },
];
const seasonMapping = {
   '1': '春',
   '2': '夏',
   '3': '秋'
};
export const shortTermTableColumns = [
   {
      title: '会員番号',
      dataIndex: 'member_code',
      key: 'member_code',
      width: 90,
      fixed: "left",
      sorter: (a, b) => a.member_code.localeCompare(b.member_code),
      render: (text, record) => (
         <Link to={`/admin/student/details/${record.id}?action=view&key=tab1`}>
            {text}
         </Link>
      ),
   },
   {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      width: 110,
      fixed: "left",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => <span>{Nvh({ value: text })}</span>,
   },
   {
      title: "フリガナ",
      dataIndex: "furigana",
      key: "furigana",
      width: 100,
      fixed: "left",
      sorter: (a, b) => a.furigana.localeCompare(b.furigana),
      render: (text, record) => <span>{Nvh({ value: record.furigana })}</span>,
   },
   {
      title: '開校年',
      dataIndex: 'short_term_class_schedule.class.opening_year',
      key: 'opening_year',
      width: 100,
      sorter: (a, b) => {
         const openingYearA = String(a.short_term_class_schedule?.class?.opening_year || '');
         const openingYearB = String(b.short_term_class_schedule?.class?.opening_year || '');
         return openingYearA.localeCompare(openingYearB);
      },
      render: (text, record) => {
         const classes = record.shortTermClassSchedules;
         if (!classes.length) {
            return <></>;
         }
         return <span>{classes[0].class?.opening_year}</span>;
      },
   },
   {
      title: '時期',
      dataIndex: 'short_term_class_schedule.class.opening_season',
      key: 'opening_season',
      width: 100,
      sorter: (a, b) => {
         const aTimes = String(a.opening_season || '');
         const bTimes = String(b.opening_season || '');
         return aTimes.localeCompare(bTimes);
      },
      render: (text, record) => {
         const classes = record.shortTermClassSchedules;
         if (!classes.length) {
            return <></>;
         }
         return <span>{seasonMapping[classes[0].class?.opening_season]}</span>;
      },
   },
   {
      title: 'クラス名',
      dataIndex: 'short_term_class_schedule.class.class_name',
      key: 'class_name',
      width: 160,
      className: 'text-start',
      sorter: (a, b) => {
         const aTimes = String(a.class_name || '');
         const bTimes = String(b.class_name || '');
         return aTimes.localeCompare(bTimes);
      },
      render: (text, record) => {
         const classes = record.shortTermClassSchedules;
         if (!classes.length) {
            return <></>;
         }
         const data = classes[0].class?.class_name;
         return <span>{!data ? "-------" : data.length > 6 ? data.slice(0, 6) + "..." : data}</span>;
      },
   },
   {
      title: 'コース名',
      dataIndex: 'short_term_class_schedule.course.course_name',
      key: 'course_name',
      width: 130,
      className: 'text-center',
      sorter: (a, b) => {
         const courseA = a.short_term_class_schedule?.course?.course_name || '';
         const courseB = b.short_term_class_schedule?.course?.course_name || '';
         return courseA.localeCompare(courseB);
      },
      render: (text, record) => {
         const classes = record.shortTermClassSchedules;
         if (!classes.length) {
            return <></>;
         }
         const data = classes[0].course?.course_name;
         return <div style={{ display: "flex", justifyContent: "space-between" }}><span>{!data ? "-------" : data.length > 4 ? data.slice(0, 4) + "..." : data}</span></div>;
      },
   },
   {
      title: '性別',
      dataIndex: 'gender',
      key: 'gender',
      width: 60,
      className: 'text-center',
      sorter: (a, b) => a.gender.localeCompare(b.gender),
      render: (text, record) => (
         <span>{Nvh({ value: record.gender })}</span>
      ),
   },
   {
      title: '生年月日',
      dataIndex: 'birthday',
      key: 'birthday',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => a.birthday.localeCompare(b.birthday),
      render: (text, record) => (
         <span>{DateForm({ value: record.birthday })}</span>
      ),
   },
   {
      title: '郵便番号',
      dataIndex: 'zip_code',
      key: 'zip_code',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => a.zip_code.localeCompare(b.zip_code),
      render: (text, record) => (
         <span>{Nvh({ value: record.zip_code })}</span>
      ),
   },
   {
      title: '住所1',
      dataIndex: 'address_1',
      key: 'address_1',
      width: 200,
      className: 'text-center',
      sorter: (a, b) => a.address_1.localeCompare(b.address_1),
      render: (text, record) => (
         <span>{Nvh({ value: record.address_1 })}</span>
      ),
   },
   {
      title: '住所2',
      dataIndex: 'address_2',
      key: 'address_2',
      width: 200,
      className: 'text-center',
      sorter: (a, b) => a.address_2.localeCompare(b.address_2),
      render: (text, record) => (
         <span>{Nvh({ value: record.address_2 })}</span>
      ),
   },
   {
      title: '電話番号1',
      dataIndex: 'phone_no_1',
      key: 'phone_no_1',
      width: 130,
      className: 'text-center',
      sorter: (a, b) => a.phone_no_1.localeCompare(b.phone_no_1),
      render: (text, record) => (
         <span>{Nvh({ value: record.phone_no_1 })}</span>
      ),
   },
   {
      title: '電話備考1',
      dataIndex: 'phone_note_1',
      key: 'phone_note_1',
      width: 150,
      className: 'text-center',
      sorter: (a, b) => a.phone_note_1.localeCompare(b.phone_note_1),
      render: (text, record) => (
         <span>{Nvh({ value: record.phone_note_1 })}</span>
      ),
   },
   {
      title: '電話番号2',
      dataIndex: 'phone_no_2',
      key: 'phone_no_2',
      width: 150,
      className: 'text-center',
      sorter: (a, b) => a.phone_no_2.localeCompare(b.phone_no_2),
      render: (text, record) => (
         <span>{Nvh({ value: record.phone_no_2 })}</span>
      ),
   },
   {
      title: '電話備考2',
      dataIndex: 'phone_note_2',
      key: 'phone_note_2',
      width: 150,
      className: 'text-center',
      sorter: (a, b) => a.phone_note_2.localeCompare(b.phone_note_2),
      render: (text, record) => (
         <span>{Nvh({ value: record.phone_note_2 })}</span>
      ),
   },
   {
      title: 'メールアドレス1',
      dataIndex: 'mail_address_1',
      key: 'mail_address_1',
      width: 150,
      className: 'text-center',
      sorter: (a, b) => a.mail_address_1.localeCompare(b.mail_address_1),
      render: (text, record) => (
         <span>{Nvh({ value: record.mail_address_1 })}</span>
      ),
   },
   {
      title: 'メール備考1',
      dataIndex: 'mail_note_1',
      key: 'mail_note_1',
      width: 300,
      className: 'text-center',
      sorter: (a, b) => a.mail_note_1.localeCompare(b.mail_note_1),
      render: (text, record) => (
         <span>{Nvh({ value: record.mail_note_1 })}</span>
      ),
   },
   {
      title: 'バスコース番号',
      dataIndex: 'bus.bus_course_number',
      key: 'bus_course_number',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => {
         const aTimes = String(a.bus_course_number || '');
         const bTimes = String(b.bus_course_number || '');
         return aTimes.localeCompare(bTimes);
      },
      render: (text, record) => (
         <span>{Nvh({ value: record.bus?.bus_course_number })}</span>
      ),
   },
   {
      title: 'バス往路',
      dataIndex: 'outbound_bus',
      key: 'outbound_bus',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => a.outbound_bus.localeCompare(b.outbound_bus),
      render: (text, record) => (
         <span>{Nvh({ value: record.outbound_bus })}</span>
      ),
   },
   {
      title: 'バス復路',
      dataIndex: 'return_bus',
      key: 'return_bus',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => a.return_bus.localeCompare(b.return_bus),
      render: (text, record) => (
         <span>{Nvh({ value: record.return_bus })}</span>
      ),
   },
   {
      title: '緊急姓',
      dataIndex: 'em_first_name',
      key: 'em_first_name',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => {
         const aTimes = String(a.em_first_name || '');
         const bTimes = String(b.em_first_name || '');
         return aTimes.localeCompare(bTimes);
      },
      render: (text, record) => (
         <span>{Nvh({ value: record.em_first_name })}</span>
      ),
   },
   {
      title: '緊急名',
      dataIndex: 'em_last_name',
      key: 'em_last_name',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => {
         const aTimes = String(a.em_last_name || '');
         const bTimes = String(b.em_last_name || '');
         return aTimes.localeCompare(bTimes);
      }, render: (text, record) => (
         <span>{Nvh({ value: record.em_last_name })}</span>
      ),
   },
   {
      title: '緊急姓フリガナ',
      dataIndex: 'em_first_name_furigana',
      key: 'em_first_name_furigana',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => {
         const aTimes = String(a.em_first_name_furigana || '');
         const bTimes = String(b.em_first_name_furigana || '');
         return aTimes.localeCompare(bTimes);
      },
      render: (text, record) => (
         <span>{Nvh({ value: record.em_first_name_furigana })}</span>
      ),
   },
   {
      title: '緊急名フリガナ',
      dataIndex: 'em_last_name_furigana',
      key: 'em_last_name_furigana',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => {
         const aTimes = String(a.em_last_name_furigana || '');
         const bTimes = String(b.em_last_name_furigana || '');
         return aTimes.localeCompare(bTimes);
      },
      render: (text, record) => (
         <span>{Nvh({ value: record.em_last_name_furigana })}</span>
      ),
   },
   {
      title: '緊急電話番号',
      dataIndex: 'em_phone_no',
      key: 'em_phone_no',
      width: 100,
      className: 'text-center',
      sorter: (a, b) => {
         const aTimes = String(a.em_phone_no || '');
         const bTimes = String(b.em_phone_no || '');
         return aTimes.localeCompare(bTimes);
      },
      render: (text, record) => (
         <span>{Nvh({ value: record.em_phone_no })}</span>
      ),
   },

];