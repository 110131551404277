const eventBus = new EventTarget();

export const dispatchEvent = (eventName, payload) => {
    const event = new CustomEvent(eventName, { detail: payload });
    eventBus.dispatchEvent(event);
};

export const addEventListener = (eventName, callback) => {
    eventBus.addEventListener(eventName, (event) => callback(event.detail));
};

export const removeEventListener = (eventName, callback) => {
    eventBus.removeEventListener(eventName, callback);
};