import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Badge, Button, Modal, Table } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import swal from "sweetalert";
import Select from "react-select";

import ReactDatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";
import privateApi from "../../services/privateApi";

import { getData } from "../../services/auth/token";
import ReactSelect from "react-select";

export default function NewAdvancedCertificate() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedApiDate, setSelectedApiDate] = useState(new Date());
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpdateModal2, setShowUpdateModal2] = useState(false);
  const [tab, setTab] = useState(1);
  const [selectedDateStr, setSelectedDateStr] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showPreviewModal2, setShowPreviewModal2] = useState(false);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  const [selectedWeekday, setSelectedWeekday] = useState("");
  const [showUpdateAllModal, setShowUpdateAllModal] = useState(false);
  const [selectedUpdateMember, setSelectedUpdateMember] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [classSchedule, setClassSchedule] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [fontSizeClassCode, setFontSizeClassCode] = useState("");
  const [reiwaFontSize, setReiwaFontSize] = useState("");
  const [reiwaLeft, setReiwaLeft] = useState("");
  const [reiwaTop, setReiwaTop] = useState("");
  const [fontSizeClassCode2, setFontSizeClassCode2] = useState("");
  const [reiwaFontSize2, setReiwaFontSize2] = useState("");
  const [reiwaLeft2, setReiwaLeft2] = useState("");
  const [reiwaTop2, setReiwaTop2] = useState("");
  const [singleStd, setSingleStd] = useState("");
  const [selectedSingleStdName, setSelectedSingleStdName] = useState("");
  const [noDataText, setNoDataText] = useState("");
  const [studentsList, setStudentslist] = useState([]);
  const uniqueScheduleSet = new Set();

  const rangeOptions = [1, 6, 16];
  const weekDays = ["日", "月", "火", "水", "木", "金", "土"];

  const getRankLevel = rank => {
    if (rank === "0") {
      return "上級";
    } else if (rank >= 16 && rank <= 99) {
      return "初級";
    } else if (rank >= 6 && rank <= 15) {
      return "中級";
    } else if (rank >= 1 && rank <= 5) {
      return "上級";
    } else {
      return "初級";
    }
  };

  const [certificatePosition, setCertificatePosition] = useState({
    left: "",
    top: "",
  });

  const [certificatePosition2, setCertificatePosition2] = useState({
    left: "",
    top: "",
  });

  const demoStudent = {
    class: "16",
    name: "松井　孝真",
  };

  const demoStudent2 = {
    class: "1",
    name: "松井　孝真",
  };

  const certificateRef = useRef(null);

  const handlePositionChange = (property, value) => {
    setCertificatePosition(prevPosition => ({
      ...prevPosition,
      [property]: value,
    }));
  };

  const handlePositionChange2 = (property, value) => {
    setCertificatePosition2(prevPosition => ({
      ...prevPosition,
      [property]: value,
    }));
  };

  const school_id = getData()?.school_id;
  console.log({ school_id });

  function convertToReiwaDate(junior) {
    const reiwaStartDate = new Date(2019, 4, 1);
    // Calculate the Reiwa year
    const reiwaYear =
      selectedApiDate.getFullYear() - reiwaStartDate.getFullYear() + 1;
    // Format the Reiwa date as a string
    const reiwaDate = `令和${reiwaYear}年${
      selectedApiDate.getMonth() + 1
    }月${selectedApiDate.getDate()}日`;
    const engDate = `${selectedApiDate.getFullYear()}${
      junior ? "年" : "    "
    }${String(selectedApiDate.getMonth() + 1).padStart(2, "0")}${
      junior ? "月" : "    "
    }${String(selectedApiDate.getDate()).padStart(2, "0")}${
      junior ? "日" : "    "
    }`;
    return engDate;
  }

  const reiwaDate = convertToReiwaDate();

  const saveCerInfo = async () => {
    setShowUpdateModal(false);
    const saveRes = await privateApi.post(`/admin/certificate-design?type=1`, {
      school_id: school_id,
      text1_size: fontSizeClassCode,
      text1_left_right: certificatePosition?.left,
      text1_top_bottom: certificatePosition?.top,
      text2_left_right: reiwaLeft,
      text2_top_bottom: reiwaTop,
      text2_size: reiwaFontSize,
      // certificate_date: "2024-03-2",
    });

    if (saveRes.status === 200) {
      setShowPreviewModal(false);
      swal("Success", "証明書デザインが正常に保存されました", "success");
      getCerInfo1();
    } else {
      swal("Error", saveRes?.message, "error");
    }
  };

  const saveCerInfo2 = async () => {
    setShowUpdateModal2(false);
    const saveRes = await privateApi.post(`/admin/certificate-design?type=2`, {
      school_id: school_id,
      text1_size: fontSizeClassCode2,
      text1_left_right: certificatePosition2?.left,
      text1_top_bottom: certificatePosition2?.top,
      text2_left_right: reiwaLeft2,
      text2_top_bottom: reiwaTop2,
      text2_size: reiwaFontSize2,
    });

    if (saveRes.status === 200) {
      setShowPreviewModal2(false);
      swal("Success", "証明書デザインが正常に保存されました", "success");
      getCerInfo2();
    } else {
      swal("Error", saveRes?.message, "error");
    }
  };

  const handleModalClose = () => {
    setShowPreviewModal(false);
    getCerInfo1();
  };

  const handleModalClose2 = () => {
    setShowPreviewModal2(false);
    getCerInfo2();
  };
  //for class 6 and 16
  const printCertificate = async studentInfo => {
    const certificateContent = (
      <div
        className="certificate-preview shadow border"
        style={{
          width: "447px",
          height: "631px",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: certificatePosition?.left,
            top: certificatePosition?.top,
            fontSize: `${parseInt(fontSizeClassCode, 10)}`,
          }}
        >
          <span>
            {`${getRankLevel(studentInfo?.class)}  `}
            合格
          </span>
          <br />
          <span>{studentInfo?.member?.name} 様</span>
        </div>
        <div
          style={{
            position: "absolute",
            left: reiwaLeft,
            top: reiwaTop,
          }}
        >
          <span
            style={{
              fontSize: `${reiwaFontSize}`,
              left: reiwaLeft,
              top: reiwaTop,
            }}
          >
            {convertToReiwaDate(true)}
          </span>
        </div>
      </div>
    );

    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Certificate Print</title>
      </head>
      <body>
        <div id="certificateContent">
          ${ReactDOMServer.renderToString(certificateContent)}
        </div>
        <script>
          setTimeout(function() {
            window.print();
            window.onafterprint = function() {
              window.close();
            };
          }, 0);
        </script>
      </body>
    </html>
  `);

    await new Promise(resolve => {
      printWindow.onload = resolve;
    });
  };
  //for class 1
  const printCertificate2 = async studentInfo => {
    const certificateContent = (
      <div
        className="certificate-preview shadow border"
        style={{
          width: "447px",
          height: "631px",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: certificatePosition2?.left,
            top: certificatePosition2?.top,
            fontSize: `${parseInt(fontSizeClassCode2, 10)}`,
          }}
        >
          <span>{studentInfo?.member?.name} 様</span>
        </div>
        <div
          style={{
            position: "absolute",
            left: reiwaLeft2,
            top: reiwaTop2,
          }}
        >
          <span
            style={{
              fontSize: `${reiwaFontSize2}`,
              left: reiwaLeft2,
              top: reiwaTop2,
            }}
          >
            {convertToReiwaDate(false)}
          </span>
        </div>
      </div>
    );

    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Certificate Print</title>
      </head>
      <body>
        <div id="certificateContent">
          ${ReactDOMServer.renderToString(certificateContent)}
        </div>
        <script>
          setTimeout(function() {
            window.print();
            window.onafterprint = function() {
              window.close();
            };
          }, 0);
        </script>
      </body>
    </html>
  `);

    await new Promise(resolve => {
      printWindow.onload = resolve;
    });
  };

  const printCertificateSingle = async (rank, last_result) => {
    const certificateContent = (
      <div
        className="certificate-preview shadow border"
        style={{
          width: "447px",
          height: "631px",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: certificatePosition?.left,
            top: certificatePosition?.top,
            fontSize: `${parseInt(fontSizeClassCode, 10)}`,
          }}
        >
          <span>
            {`${getRankLevel(rank)}  `}
            {rank === "0" ? "特習" : rank}級　合格
          </span>
          <br />
          <span>{selectedSingleStdName?.name}殿</span>
        </div>
        <div
          style={{
            position: "absolute",
            left: reiwaLeft,
            top: reiwaTop,
          }}
        >
          <span
            style={{
              fontSize: `${reiwaFontSize}`,
              left: reiwaLeft,
              top: reiwaTop,
            }}
          >
            {reiwaDate}
          </span>
        </div>
      </div>
    );

    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Certificate Print</title>
      </head>
      <body>
        <div id="certificateContent">
          ${ReactDOMServer.renderToString(certificateContent)}
        </div>
        <script>
          setTimeout(function() {
            window.print();
            window.onafterprint = function() {
              window.close();
            };
          }, 0);
        </script>
      </body>
    </html>
  `);

    await new Promise(resolve => {
      printWindow.onload = resolve;
    });
  };

  const handleDateChange = selectedOption => {
    setSelectedWeekday(selectedOption);
    setSelectedSchedule("");
  };

  useEffect(() => {
    if (certificateRef.current === null) {
      certificateRef.current = document.getElementById("certificatePreview");
    }
  }, [certificateRef.current]);

  const refactorSelectedDate = () => {
    if (selectedDate) {
      const selectedDateCopy = new Date(selectedDate);
      selectedDateCopy.setDate(selectedDateCopy.getDate() + 1);
      setSelectedDateStr(
        selectedDateCopy?.toISOString().split("T")[0].slice(0, 7)
      );
    }
  };

  // const refactorSelectedCerDate = () => {
  //   if (selectedCerDate) {
  //     // console.log({ selectedCerDate });
  //     const selectedDateCopy = new Date(selectedCerDate);
  //     selectedDateCopy.setDate(selectedDateCopy.getDate() + 1);
  //     console.log(selectedDateCopy?.toISOString().split("T")[0].slice(0, 9));
  //     setSelectedCerDateStr(
  //       selectedDateCopy?.toISOString().split("T")[0].slice(0, 7)
  //     );
  //   }
  // };

  const selectDateHandler = date => {
    if (date) {
      setSelectedDate(date);
      refactorSelectedDate();
    }
  };

  const updateCertificateDate = async date => {
    const selectedDateCopy = new Date(date);
    const formattedDate = selectedDateCopy.toISOString().split("T")[0];

    const saveRes = await privateApi.post(`/admin/certificate-design?type=1`, {
      school_id: school_id,
      certificate_date: formattedDate,
    });

    if (saveRes.status === 200) {
      getCerInfo1();
    } else {
      console.log("Server Error");
    }
  };

  const selectCerDateHandler = date => {
    if (date) {
      setSelectedApiDate(date);
      updateCertificateDate(date);
      // setSelectedCerDate(date);
      // refactorSelectedCerDate();
    }
  };

  const getStudentslist = async () => {
    const response = await privateApi.get(
      `admin/get-members-list?type=Regular`
    );
    if (response.status === 200) {
      setStudentslist(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const getStudentInfo = async id => {
    setIsLoading(true);
    const response = await privateApi.get(`admin/rankingResults/${id}`);
    if (response.status === 200) {
      if (response?.data?.data?.results.length === 0) {
        setNoDataText("クラスデータを挿入してください。");
      } else {
        setNoDataText("");
      }
      setSingleStd(response.data.data);

      setIsLoading(false);
    } else {
      console.log("Server Error");
      setIsLoading(false);
    }
  };

  const handleChange = selectedOption => {
    setSelectedSingleStdName(selectedOption?.value);

    getStudentInfo(selectedOption?.value?.id);
  };
  const getCerInfo1 = async e => {
    const response = await privateApi.get("admin/certificate-design?type=1");
    if (response.status === 200) {
      const dataArr = response.data.data.filter(item => item.type === "1")[0];
      setSelectedApiDate(
        dataArr?.certificate_date
          ? new Date(dataArr?.certificate_date)
          : new Date()
      );
      certificatePosition.left = dataArr?.text1_left_right || "30%";
      certificatePosition.top = dataArr?.text1_top_bottom || "40%";
      setFontSizeClassCode(dataArr?.text1_size || "14px");
      setReiwaFontSize(dataArr?.text2_size || "16px");
      setReiwaLeft(dataArr?.text2_left_right || "40%");
      setReiwaTop(dataArr?.text2_top_bottom || "70%");
    } else {
      console.log("Server Error");
    }
  };
  const getCerInfo2 = async e => {
    const response = await privateApi.get("admin/certificate-design?type=2");
    if (response.status === 200) {
      const dataArr = response.data.data.filter(item => item.type === "2")[0];
      console.log({ dataArr });
      certificatePosition2.left = dataArr?.text1_left_right || "30%";
      certificatePosition2.top = dataArr?.text1_top_bottom || "40%";
      setFontSizeClassCode2(dataArr?.text1_size || "14px");
      setReiwaFontSize2(dataArr?.text2_size || "16px");
      setReiwaLeft2(dataArr?.text2_left_right || "40%");
      setReiwaTop2(dataArr?.text2_top_bottom || "70%");
    } else {
      console.log("Server Error");
    }
  };

  const getClasses = async e => {
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const fetchStudents = async () => {
    try {
      setIsLoading(true);
      const response = await privateApi.post("/admin/membersByRank", {
        date: selectedDateStr,
        day_week: selectedWeekday.label,
        regular_class_id: selectedClass?.value,
        class: selectedRange?.value,
        start_time: selectedSchedule?.start_time,
        end_time: selectedSchedule?.end_time,
      });
      if (response.status === 200) {
        const studentData = response.data?.data;
        setStudents(studentData);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching student attendance data:", error);
    }
  };

  const getScheduleInfo = async id => {
    const response = await privateApi.get(
      `/admin/class-schedules/${selectedClass?.value}`
    );

    if (response.status === 200) {
      if (response.data.data && response.data.data.classSchedule) {
        setClassSchedule(response.data.data.classSchedule);
        // setSelectedClass(response.data.data.regular_class_id);
      } else {
        setClassSchedule([]);
      }
    } else {
      console.log("Server Error");
    }
  };

  const allOption = opt => {
    return { label: `すべて ${opt}`, value: " " };
  };

  const modifiedScheduleOptions = [
    allOption("時間"),
    ...classSchedule.flatMap(option =>
      option.classes.flatMap(schedule => {
        if (
          option.day === selectedWeekday.label &&
          schedule.start_time !== "" &&
          schedule.end_time !== ""
        ) {
          const scheduleKey = `${schedule.start_time}-${schedule.end_time}`;
          if (!uniqueScheduleSet.has(scheduleKey)) {
            uniqueScheduleSet.add(scheduleKey);
            return {
              start_time: schedule.start_time,
              end_time: schedule.end_time,
              value: schedule.id,
              label: `${schedule.start_time}-${schedule.end_time}`,
            };
          }
        }
        return [];
      })
    ),
  ];

  const uniqueScheduleOptions = Array.from(uniqueScheduleSet);

  const students_Id_Name = studentsList.map(student => ({
    value: student,
    label: student.member_code + "-" + student.name + "-" + student.furigana,
  }));

  // Modify the options array for the second Select dropdown
  const modifiedRangeOptions = rangeOptions.map(option => ({
    value: option === "特習" ? 0 : option,
    label: `${getRankLevel(option)}`,
  }));

  const modifiedWeekdayOptions = [
    weekDays.map((option, index) => ({
      value: index,
      label: `${option}`,
    })),
  ];

  useEffect(() => {
    getStudentslist();
  }, [tab]);

  useEffect(() => {
    getScheduleInfo();
  }, [selectedClass]);

  const UpperPart = () => {
    return (
      <div className="col-md-12">
        <div
          className="d-flex flex-wrap align-items-stretch py-3"
          style={{ gap: "4px" }}
        >
          <div style={{ width: "100px" }}>
            <Select
              defaultValue={selectedClass}
              onChange={setSelectedClass}
              options={classes.map(option => ({
                value: option.id,
                label: `${option.affiliation_name}`,
              }))}
              placeholder="授業"
            />
          </div>

          <div style={{ width: "140px" }}>
            <Select
              value={selectedWeekday}
              onChange={handleDateChange}
              options={modifiedWeekdayOptions[0]}
              placeholder="日"
              isDisabled={!selectedClass}
            />
          </div>
          <div style={{ width: "150px" }}>
            <Select
              defaultValue={selectedSchedule}
              onChange={setSelectedSchedule}
              options={modifiedScheduleOptions}
              placeholder="時間"
              isDisabled={!selectedClass || !selectedWeekday}
            />
          </div>
          <div style={{ width: "120px" }}>
            <Select
              defaultValue={selectedRange}
              onChange={setSelectedRange}
              options={[allOption("級"), ...modifiedRangeOptions]}
              placeholder="級"
              isDisabled={!selectedClass}
            />
          </div>
          <div>
            <ReactDatePicker
              className="form-control h-100"
              dateFormat="yyyy/MM"
              selected={selectedDate}
              onChange={selectDateHandler}
              todayButton={"Today"}
              locale={ja}
              placeholderText="日付を選択"
              readOnly={!selectedClass}
              showMonthYearPicker={true}
            />
          </div>
          <div>
            <button
              onClick={() => {
                setSelectedDate("");
                setSelectedRange("");
                setSelectedSchedule("");
                setSelectedWeekday("");
              }}
              className="btn btn-primary"
            >
              クリア
            </button>
          </div>
          <div className="">
            <Button
              onClick={() => {
                setShowPreviewModal(true);
              }}
              className="ml-3"
            >
              プレビュー(6級/16級)
            </Button>
          </div>
          <div className="">
            <Button
              onClick={() => {
                setShowPreviewModal2(true);
              }}
              className="ml-3"
            >
              プレビュー(1級)
            </Button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getClasses();
    getCerInfo1();
    getCerInfo2();
  }, []);

  useEffect(() => {
    refactorSelectedDate();
  }, [selectedDate]);

  useEffect(() => {
    if (selectedClass) {
      fetchStudents();
    }
  }, [
    selectedClass,
    selectedRange,
    selectedDate,
    selectedWeekday,
    selectedSchedule,
  ]);

  return (
    <div
      className="p-1"
      eventKey="student-list"
      title="会員一覧"
      style={{
        width: "100%",
      }}
    >
      <div className="card col-md-10">
        <div className="card-header">
          <div className="d-flex align-items-center gap-2">
            <div>
              <button
                onClick={() => setTab(1)}
                className={`btn px-3 mr-4 ${
                  tab === 1 ? "btn-primary" : "btn-outline-primary"
                }`}
              >
                会員一覧
              </button>
            </div>
            <div>
              <button
                onClick={() => setTab(2)}
                className={`btn px-3 mr-4 ${
                  tab === 2 ? "btn-primary" : "btn-outline-primary"
                }`}
              >
                生徒を選択
              </button>
            </div>
            <div>
              <div>
                <ReactDatePicker
                  className="w-100 shadow-sm custom_report_select text-center"
                  dateFormat="yyyy/MM/dd"
                  selected={selectedApiDate || new Date()}
                  onChange={selectCerDateHandler}
                  todayButton={"Today"}
                  locale={ja}
                  placeholderText="日付を選択"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {tab === 1 ? (
        <div className="card-body">
          <UpperPart />
          <div>
            {isLoading && (
              <p className="text-center text-bold">読み込み中...</p>
            )}
            {!isLoading && students?.length > 0 && (
              <div className="d-flex">
                <div
                  style={{ maxHeight: "500px" }}
                  className="col-md-10 overflow-scroll"
                >
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th style={{ width: "2%" }}>No</th>
                        <th style={{ width: "5%" }}>会員番号</th>
                        <th style={{ width: "10%" }}>氏名</th>
                        <th style={{ width: "2%" }}>級</th>
                        <th style={{ width: "5%" }} className="text-center">
                          進級者認定証
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {students.map(
                        (student, index) =>
                          student.class &&
                          (student.class === "1" ||
                            student.class === "6" ||
                            student.class === "16") && (
                            <tr
                              key={index}
                              onDoubleClick={() => {
                                setSelectedUpdateMember(student.member?.id);
                              }}
                            >
                              <td
                                style={{ width: "2%" }}

                                // onClick={() => toggleAttendance(student.id)}
                              >
                                {" "}
                                {index + 1}
                              </td>
                              <td
                                style={{ width: "5%" }}
                                // onClick={() => toggleAttendance(student.id)}
                              >
                                {student?.member?.member_code}
                              </td>
                              <td
                                style={{ width: "10%" }}
                                // onClick={() => toggleAttendance(student.id)}
                              >
                                {student?.member?.name}
                              </td>
                              <td
                                style={{ width: "2%" }}
                                // onClick={() => toggleAttendance(student.id)}
                              >
                                {getRankLevel(student?.class)}
                              </td>
                              <td
                                style={{ width: "5%" }}
                                className="text-center"
                              >
                                <Button
                                  className="btn-info"
                                  onClick={() => {
                                    if (
                                      student.class === "16" ||
                                      student.class === "6"
                                    ) {
                                      printCertificate(student);
                                    } else if (student.class === "1") {
                                      printCertificate2(student);
                                    }
                                  }}
                                >
                                  印刷
                                  <i className="fas fa-print ml-3"></i>
                                </Button>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
            {selectedStudents.length > 0 && (
              <div className="d-flex justify-content-end">
                <button
                  style={{ margin: "4px 0" }}
                  onClick={() => setShowUpdateAllModal(true)}
                  className="btn btn-primary"
                >
                  提出する
                </button>
              </div>
            )}
            {!isLoading && selectedClass && students?.length < 1 && (
              <p className="text-info text-center">
                何もデータが見つかりませんでした
              </p>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="card-body col-md-12 d-flex">
            <div className="col-md-5">
              <ReactSelect
                className="w-100 mr-4 shadow-sm custom_select"
                placeholder="会員番号／氏名で検索"
                options={students_Id_Name}
                onChange={handleChange}
                isDisabled={studentsList.length < 1}
              />
            </div>
            <div className="ml-2">
              <Button
                onClick={() => {
                  setShowPreviewModal(true);
                }}
                className="ml-3"
              >
                プレビュー(6級/16級)
              </Button>
            </div>
            <div className="">
              <Button
                onClick={() => {
                  setShowPreviewModal2(true);
                }}
                className="ml-3"
              >
                プレビュー(1級)
              </Button>
            </div>
          </div>
          <div>
            {isLoading && (
              <p className="text-center text-bold">読み込み中...</p>
            )}
            {!isLoading && singleStd && (
              <div className="d-flex">
                <div
                  style={{ maxHeight: "500px" }}
                  className="col-md-10 overflow-scroll"
                >
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}>会員番号</th>
                        <th style={{ width: "10%" }}>氏名</th>
                        <th style={{ width: "2%" }}>級</th>
                        <th style={{ width: "5%" }}>クラス</th>
                        <th style={{ width: "7%" }} className="text-center">
                          進級者認定証
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {singleStd?.all_classes.map((student, index) =>
                        singleStd?.results.length > 1 ||
                        singleStd?.all_classes[0]?.latest_rank ? (
                          <tr key={index}>
                            <td style={{ width: "2%" }}>
                              {selectedSingleStdName?.member_code}
                            </td>
                            <td style={{ width: "5%" }}>
                              {selectedSingleStdName?.name}
                            </td>
                            <td style={{ width: "10%" }}>
                              {student?.latest_rank === "0"
                                ? "特習"
                                : student?.latest_rank}
                            </td>
                            <td style={{ width: "2%" }}>
                              {student?.class_name}
                            </td>
                            <td style={{ width: "5%" }} className="text-center">
                              <Button
                                className="btn-info"
                                onClick={() => {
                                  printCertificateSingle(
                                    student?.latest_rank,
                                    singleStd?.results[0]
                                  );
                                }}
                              >
                                印刷
                                <i className="fas fa-print ml-3"></i>
                              </Button>
                            </td>
                          </tr>
                        ) : (
                          <p className="text-danger">
                            何もデータが見つかりませんでした！
                          </p>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
            {selectedStudents.length > 0 && (
              <div className="d-flex justify-content-end">
                <button
                  style={{ margin: "4px 0" }}
                  onClick={() => setShowUpdateAllModal(true)}
                  className="btn btn-primary"
                >
                  提出する
                </button>
              </div>
            )}
            {!isLoading && selectedClass && students?.length < 1 && (
              <p className="text-info text-center">
                何もデータが見つかりませんでした
              </p>
            )}
          </div>
        </>
      )}

      {/* modal for certificate preview and save for 6 and 16 */}
      <Modal
        size="xl"
        fullscreen
        centered
        show={showPreviewModal}
        onHide={() => handleModalClose()}
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-content-between w-100">
            <h3>
              <Badge bg="secondary"> 証明書をプレビューして保存する</Badge>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            id="certificatePreview"
            ref={certificateRef}
            className="certificate-preview shadow border"
            style={{
              width: "447px",
              height: "631px",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: certificatePosition.left,
                top: certificatePosition.top,
                fontSize: `${fontSizeClassCode}`,
              }}
            >
              <span>{`${getRankLevel(demoStudent?.class)}`} 　合格</span>
              <br />
              <span>{demoStudent?.name} 様</span>
            </div>
            <div
              style={{
                position: "absolute",
                left: reiwaLeft,
                top: reiwaTop,
              }}
            >
              <span
                style={{
                  fontSize: `${reiwaFontSize}`,
                  left: reiwaLeft,
                  top: reiwaTop,
                }}
              >
                {convertToReiwaDate(true)}
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12 d-flex justify-content-around">
            <div className="p-1 shadow">
              <h3>
                <Badge bg="secondary">日付の位置</Badge>
              </h3>
              <div>
                <label>フォントサイズ:</label>
                <input
                  type="range"
                  min="10"
                  max="30"
                  value={parseInt(reiwaFontSize, 10)}
                  onChange={e => setReiwaFontSize(`${e.target.value}px`)}
                />
              </div>
              <div>
                <label>左右の位置:</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parseInt(reiwaLeft)}
                  onChange={e => setReiwaLeft(`${e.target.value}%`)}
                />
              </div>
              <div>
                <label>上下の位置:</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parseInt(reiwaTop)}
                  onChange={e => setReiwaTop(`${e.target.value}%`)}
                />
              </div>
            </div>
            <div className="col-md-2 text-center align-items-center flex-column d-flex gap-3">
              <Button
                className=""
                variant="success"
                onClick={() => setShowUpdateModal(true)}
              >
                設定する
              </Button>
              <Button
                style={{ minWidth: "90px" }}
                className=""
                variant="primary"
                onClick={() => handleModalClose()}
              >
                戻る
              </Button>
            </div>
            <div className="p-1 shadow">
              <h3>
                <Badge bg="secondary">級と名前の位置</Badge>
              </h3>
              <div>
                <label>フォントサイズ:</label>
                <input
                  type="range"
                  min="10"
                  max="30"
                  value={parseInt(fontSizeClassCode, 10)}
                  onChange={e => setFontSizeClassCode(`${e.target.value}px`)}
                />
              </div>
              <div>
                <label>左右の位置:</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parseInt(certificatePosition.left)}
                  onChange={e =>
                    handlePositionChange("left", `${e.target.value}%`)
                  }
                />
              </div>
              <div>
                <label>上下の位置:</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parseInt(certificatePosition.top)}
                  onChange={e =>
                    handlePositionChange("top", `${e.target.value}%`)
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {/* modal for certificate preview and save for 1 */}
      <Modal
        size="xl"
        fullscreen
        centered
        show={showPreviewModal2}
        onHide={() => handleModalClose2()}
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-content-between w-100">
            <h3>
              <Badge bg="secondary"> 証明書をプレビューして保存する</Badge>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            id="certificatePreview"
            ref={certificateRef}
            className="certificate-preview shadow border"
            style={{
              width: "447px",
              height: "631px",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: certificatePosition2.left,
                top: certificatePosition2.top,
                fontSize: `${fontSizeClassCode2}`,
              }}
            >
              <br />
              <span>{demoStudent2?.name} 様</span>
            </div>
            <div
              style={{
                position: "absolute",
                left: reiwaLeft2,
                top: reiwaTop2,
              }}
            >
              <span
                style={{
                  fontSize: `${reiwaFontSize2}`,
                  left: reiwaLeft2,
                  top: reiwaTop2,
                }}
              >
                {convertToReiwaDate(false)}
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12 d-flex justify-content-around">
            <div className="p-1 shadow">
              <h3>
                <Badge bg="secondary">日付の位置</Badge>
              </h3>
              <div>
                <label>フォントサイズ:</label>
                <input
                  type="range"
                  min="10"
                  max="30"
                  value={parseInt(reiwaFontSize2, 10)}
                  onChange={e => setReiwaFontSize2(`${e.target.value}px`)}
                />
              </div>
              <div>
                <label>左右の位置:</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parseInt(reiwaLeft2)}
                  onChange={e => setReiwaLeft2(`${e.target.value}%`)}
                />
              </div>
              <div>
                <label>上下の位置:</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parseInt(reiwaTop2)}
                  onChange={e => setReiwaTop2(`${e.target.value}%`)}
                />
              </div>
            </div>
            <div className="col-md-2 text-center align-items-center flex-column d-flex gap-3">
              <Button
                className=""
                variant="success"
                onClick={() => setShowUpdateModal2(true)}
              >
                設定する
              </Button>
              <Button
                style={{ minWidth: "90px" }}
                className=""
                variant="primary"
                onClick={() => handleModalClose2()}
              >
                戻る
              </Button>
            </div>
            <div className="p-1 shadow">
              <h3>
                <Badge bg="secondary">級と名前の位置</Badge>
              </h3>
              <div>
                <label>フォントサイズ:</label>
                <input
                  type="range"
                  min="10"
                  max="30"
                  value={parseInt(fontSizeClassCode2, 10)}
                  onChange={e => setFontSizeClassCode2(`${e.target.value}px`)}
                />
              </div>
              <div>
                <label>左右の位置:</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parseInt(certificatePosition2.left)}
                  onChange={e =>
                    handlePositionChange2("left", `${e.target.value}%`)
                  }
                />
              </div>
              <div>
                <label>上下の位置:</label>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={parseInt(certificatePosition2.top)}
                  onChange={e =>
                    handlePositionChange2("top", `${e.target.value}%`)
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        show={showUpdateModal}
        onHide={() => setShowUpdateModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>更新してもよろしいですか?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => saveCerInfo()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        show={showUpdateModal2}
        onHide={() => setShowUpdateModal2(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>更新してもよろしいですか?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => saveCerInfo2()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
