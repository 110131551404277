import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import schoolLogo from "../../assets/school/logo/encircled-branches-and-book.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import privateApi from "../../services/privateApi";
import ConvertTo from "../utilis/ConvertKataka";
import "../../assets/common/css/modal.css";
import swal from "sweetalert";

const School = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState();
  // const [loading, setLoading] = useState("");

  const [errorList, setErrorList] = useState({});

  const [file, setFile] = useState("");
  const [imagedata, setImagedata] = useState("");

  const [schools, setSchools] = useState([]);
  const [schoolInfo, setSchoolInfo] = useState({
    school_name: "",
    slug: "",
    registration_no: "",
    school_code: "",
    school_level: "",
    contact_person_name: "",
    contact_person_email: "",
    contact_person_phone: "",
    address: "",
  });

  const [adminInfo, setAdminInfo] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
  });

  const handleInput = (e) => {
    e.persist();
    setSchoolInfo({ ...schoolInfo, [e.target.name]: e.target.value });
  };

  const handleInput2 = (e) => {
    e.persist();
    setAdminInfo({ ...adminInfo, [e.target.name]: e.target.value });
  };

  const handleChange = (file) => {
    setFile(URL.createObjectURL(file[0]));
    setImagedata(file[0]);
  };

  const updateModalShow = async (id) => {
    setSelectedSchool(id);
    setShow2(true);
    const response = await privateApi.get("superadmin/schools/" + id);
    if (response.status === 200) {
      setSchoolInfo(response.data.data);
      setFile(response.data.data.logo);
    } else {
      console.log("Server Error");
    }
  };

  const deleteHandler = async (id) => {
    const response = await privateApi.delete("superadmin/schools/" + id);
    if (response.status === 200) {
      swal("学校情報が削除されました", "", "success");
      getSchoolData();
    } else {
      swal("学校情報が削除されました", "", "error");
      console.log("Server Error");
    }
  };

  const getSchoolData = async (e) => {
    const response = await privateApi.get("superadmin/schools");

    if (response.status === 200) {
      setSchools(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const setBlankField = () => {
    setNextStep(false);
    setAdminInfo({});
    setSchoolInfo({});
    setErrorList({});
    setFile();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (schoolInfo.school_name) {
        try {
          const convertedText = await ConvertTo(schoolInfo.school_name, "romaji");
          if (convertedText) {
            setSchoolInfo({
              ...schoolInfo,
              slug: convertedText.replace(/\s+/g, "-").toLowerCase()
            });
          } else {
            console.log("Something wrong");
          }
        } catch (error) {
          console.error("Error converting", error);
        }
      } else {
        setSchoolInfo({
          ...schoolInfo,
          slug: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [schoolInfo.school_name]);

  useEffect(() => {
    getSchoolData();
  }, []);

  const formSubmit = async (e, id) => {
    e.preventDefault();
    // setLoading(true);

    const formData = new FormData();
    formData.append("school_name", schoolInfo.school_name);
    formData.append("slug", schoolInfo.slug);
    formData.append("registration_no", schoolInfo.registration_no);
    formData.append("school_code", schoolInfo.school_code);
    formData.append("school_level", schoolInfo.school_level);
    formData.append("contact_person_name", schoolInfo.contact_person_name);
    formData.append("contact_person_email", schoolInfo.contact_person_email);
    formData.append("contact_person_phone", schoolInfo.contact_person_phone);
    formData.append("address", schoolInfo.address);
    formData.append("logo", imagedata);

    formData.append("first_name", adminInfo.first_name);
    formData.append("last_name", adminInfo.last_name);
    formData.append("password", adminInfo.password);
    formData.append("password_confirmation", adminInfo.password_confirmation);
    formData.append("username", adminInfo.first_name);
    formData.append("email", adminInfo.email);
    formData.append("phone", adminInfo.phone);

    formData.append("status", 1);

    const response = await privateApi.post(`superadmin/schools`, formData);

    if (response.status === 200) {
      setShow(false);
      swal("学校情報を追加しました", "", "success");
      getSchoolData();
      setBlankField();
      // setLoading(false);
    } else {
      swal("内容に問題があります", response.data.message, "error");
      setErrorList(response.data.errors);
      // setLoading(false);
    }
  };

  const updateFormSubmit = async (id) => {
    // setLoading(true);

    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("school_name", schoolInfo.school_name);
    formData.append("slug", schoolInfo.slug);
    formData.append("registration_no", schoolInfo.registration_no);
    formData.append("school_code", schoolInfo.school_code);
    formData.append("school_level", schoolInfo.school_level);
    formData.append("contact_person_name", schoolInfo.contact_person_name);
    formData.append("contact_person_email", schoolInfo.contact_person_email);
    formData.append("contact_person_phone", schoolInfo.contact_person_phone);
    formData.append("address", schoolInfo.address);
    formData.append("logo", imagedata);

    const response = await privateApi.formUpdate(`superadmin/schools/${id}`, formData);

    if (response.status === 200) {
      setImagedata();
      setShow2(false);
      swal("学校情報を追加しました", "", "success");
      getSchoolData();
      setBlankField();
      // setLoading(false);
    } else {
      swal("内容に問題があります", response.data.message, "error");
      setErrorList(response.data.errors);
      // setLoading(false);
    }
  };

  const updateStatus = async (id) => {
    let updatedSchool = {};
    schools.map((school) => {
      if (school.id === id) {
        updatedSchool = { ...school, status: school.status === true ? 0 : 1 };
        return updatedSchool;
      }
      return school;
    });
    const response = await privateApi.update(`superadmin/schools/status/${id}`, updatedSchool);
    if (response.status === 200) {
      swal("更新しました！", "ステータスを更新しました！", "success");
      getSchoolData();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const schoolForm = (
    <div>
      <h5 className="mb-4">基本情報</h5>

      <div className="row">
        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicEmail"
        >
          <Form.Label>
            学校名 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 学校名"
            value={schoolInfo.school_name}
            name="school_name"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicEmail"
        >
          <Form.Label>
            ナメクジ <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="ナメクジ"
            value={schoolInfo.slug}
            name="slug"
            onChange={handleInput}
          />
          <Form.Text className="text-muted">
            <i className="fas fa-info-circle"></i> ログインURL : { window.location.origin }/{schoolInfo.slug}
          </Form.Text>
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            登録番号 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 登録番号"
            value={schoolInfo.registration_no}
            name="registration_no"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            学校コード <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 学校コード"
            value={schoolInfo.school_code}
            name="school_code"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            学校レベル <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 学校レベル"
            value={schoolInfo.school_level}
            name="school_level"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            address <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 学校レベル"
            value={schoolInfo.address}
            name="address"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>ロゴ</Form.Label>
          <div>
            {file ? (
              <img
                src={file}
                height={150}
                width={150}
                className="avater-img-src"
                alt="logo"
              />
            ) : (
              ""
            )}
          </div>

          <div className="img-box">
            <input
              onChange={(e) => handleChange(e.target.files)}
              multiple={false}
              type="file"
              className="form-control"
            />
          </div>
        </Form.Group>
      </div>

      <h6 className="my-4">一次連絡先</h6>

      <div className="row">
        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            連絡先 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 連絡先"
            value={schoolInfo.contact_person_name}
            name="contact_person_name"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            連絡先メールアドレス <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="
入力 連絡先メールアドレス"
            value={schoolInfo.contact_person_email}
            name="contact_person_email"
            onChange={handleInput}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            連絡先番号 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 連絡先番号"
            value={schoolInfo.contact_person_phone}
            name="contact_person_phone"
            onChange={handleInput}
          />
        </Form.Group>
      </div>
    </div>
  );

  const adminForm = (
    <div className="">
      <h5 className="mb-4">
        管理者情報 <span className="required">*</span>
      </h5>

      <div className="row">
        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            名前 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 名前"
            value={adminInfo.first_name}
            name="first_name"
            onChange={handleInput2}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            苗字 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 名前"
            value={adminInfo.last_name}
            name="last_name"
            onChange={handleInput2}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            利用者名 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 名前"
            value={adminInfo.username}
            name="username"
            onChange={handleInput2}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            メール <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="入力 メール"
            value={adminInfo.email}
            name="email"
            onChange={handleInput2}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            電話番号 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="入力 電話番号"
            value={adminInfo.phone}
            name="phone"
            onChange={handleInput2}
          />
        </Form.Group>

        <hr></hr>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            合言葉 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="password"
            value={adminInfo.password}
            name="password"
            onChange={handleInput2}
          />
        </Form.Group>

        <Form.Group
          className="col-md-6 mb-3"
          controlId="formBasicPassword"
        >
          <Form.Label>
            パスワードの確認 <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="confirm password"
            value={adminInfo.password_confirmation}
            name="password_confirmation"
            onChange={handleInput2}
          />
        </Form.Group>

        {/* <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
          <Form.Label>画像 (随意)</Form.Label>
          <Form.Control type="File" placeholder="入力 画像" />
        </Form.Group> */}
      </div>
    </div>
  );

  return (
    <>
      <div className="row">
        <div className="col-10">
          <p className="headline">学校一覧</p>
        </div>
        <div className="col-2 d-flex align-items-center">
          <button
            className="btn btn-sm btn-primary px-3 ml-auto"
            variant="primary"
            onClick={() => setShow(true)}
          >
            {" "}
            <i className="fas fa-plus fa-fw"></i>
            新しく追加する
          </button>
        </div>
      </div>

      <div>
        <Table
          striped
          bordered
          hover
        >
          <thead>
            <tr>
              <th>名前</th>
              <th>住所</th>
              <th className="text-center">ロゴ</th>
              <th>管理者</th>
              <th className="text-center">スターテス</th>
              <th className="text-center">アクション</th>
            </tr>
          </thead>
          <tbody>
            {schools.map((school, index) => (
              <tr key={index}>
                <td>{school.school_name}</td>
                <td>{school.address}</td>
                <td className="d-flex justify-content-center">
                  {school.logo ? (
                    <img
                      src={school.logo}
                      alt="logo"
                      height="50"
                      width="50"
                      className="d-flex align-items-center"
                    />
                  ) : (
                    <img
                      src={schoolLogo}
                      alt="logo"
                      height="50"
                      width="50"
                      className="d-flex align-items-center"
                    />
                  )}
                </td>
                <td>
                  {school.users?.map((user, index) => (
                    <div>
                      <b className="strong">名前:</b> {user.name}
                      <br></br>
                      <b className="strong">メール:</b> {user.email}
                      <br></br>
                    </div>
                  ))}
                </td>
                <td className="text-center">
                  {school.status ? (
                    <Form.Check
                      className="d-flex justify-content-center"
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={true}
                      onClick={() => updateStatus(school.id)}
                    />
                  ) : (
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={false}
                      onClick={() => updateStatus(school.id)}
                      className="d-flex justify-content-center"
                    />
                  )}
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-sm btn-primary mx-1"
                    onClick={() => updateModalShow(school.id)}
                  >
                    <FontAwesomeIcon icon="edit" />
                  </button>
                  <button
                    className="btn btn-sm btn-danger mx-1"
                    onClick={() => deleteHandler(school.id)}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">新しい学校を追加</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(errorList).length > 0 ? (
              <div
                className="alert alert-danger"
                role="alert"
              >
                <ul>
                  {Object.entries(errorList).map(([key, val], i) => (
                    <li key={i}>{val}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ""
            )}

            {nextStep ? adminForm : schoolForm}

            <div>
              {nextStep ? (
                <>
                  <Button
                    className="float-right mx-1 px-4"
                    variant="primary"
                    type="submit"
                    style={{ float: "right" }}
                    onClick={formSubmit}
                  >
                    保存
                  </Button>

                  <Button
                    className="btn btn-danger float-right mx-1 px-4"
                    variant="primary"
                    onClick={() => setNextStep(false)}
                    style={{ float: "right" }}
                  >
                    <FontAwesomeIcon icon="arrow-left" /> 前
                  </Button>
                </>
              ) : (
                <Button
                  className="float-right px-4"
                  variant="primary"
                  onClick={() => setNextStep(true)}
                  style={{ float: "right" }}
                >
                  次 <FontAwesomeIcon icon="arrow-right" />
                </Button>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show2}
          onHide={() => setShow2(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">学校情報を更新</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(errorList).length > 0 ? (
              <div
                className="alert alert-danger"
                role="alert"
              >
                <ul>
                  {Object.entries(errorList).map(([key, val], i) => (
                    <li key={i}>{val}</li>
                  ))}
                </ul>
              </div>
            ) : (
              ""
            )}

            {schoolForm}

            <div>
              <Button
                className="float-right mx-1 px-4"
                variant="primary"
                type="submit"
                style={{ float: "right" }}
                onClick={() => updateFormSubmit(selectedSchool)}
              >
                保存
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default School;
