import React, { useEffect, useState } from "react";
import moment from "moment";
import ja from "date-fns/locale/ja";
import { Table, Button, Modal } from "react-bootstrap";
import privateApi from "../../services/privateApi";
import { getCurrentMonth } from "../../services/auth/token";
import TimePicker from "react-time-picker";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import swal from "sweetalert";

const BusSchedule = () => {
  const [startDate, setStartDate] = useState(getCurrentMonth());
  const [endTo, setEndTo] = useState(getCurrentMonth());

  const [loading, setLoading] = useState(false);

  const [stoppages, setStoppages] = useState([]);
  const [classes, setClasses] = useState([]);
  const [stoppageTimes, setStoppageTimes] = useState([]);

  const [busStops, setBusStops] = useState([]);
  const [selectedBus, setSelectedBus] = useState();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  const handleConfirmCopy = () => {
    setShowConfirmationModal(true);
  };

  const getBuses = async () => {
    try {
      const response = await privateApi.get(
        `admin/buses?page=1&per_page=100&processed_date=${moment(startDate).format("YYYY-MM")}&bus=${selectedBus?.value}`
      );
      if (response.status === 200) {
        setStoppages(response.data.data);
      } else {
        console.log("Server error");
      }
    } catch (error) {
      console.error("Error fetching buses:", error);
    }
  };

  const getAllBuses = async () => {
    try {
      const response = await privateApi.get(
        `admin/buses?page=1&per_page=100&processed_date=${moment(startDate).format("YYYY-MM")}`
      );
      if (response.status === 200) {
        organizeStops(response.data.data);
      } else {
        console.log("Server error");
      }
    } catch (error) {
      console.error("Error fetching buses:", error);
    }
  };

  const organizeStops = org_buses => {
    const organizedStops = {};

    org_buses.forEach(bus => {
      const busCourseName = bus.bus_course_number.charAt(0);
      if (!organizedStops[busCourseName]) {
        organizedStops[busCourseName] = [];
      }
      organizedStops[busCourseName].push({
        id: bus.id,
        label: bus.stop_name,
      });
    });

    console.log("object new", organizedStops);

    setBusStops(organizedStops);
  };

  const handleBusSelect = selectedOption => {
    setSelectedBus(selectedOption);
  };

  const getBusRoutes = async () => {
    const params = {
      bus: selectedBus?.value,
      date: moment(startDate).format("YYYY-MM"),
    };

    const class_response = await privateApi.post(
      `/admin/get-class-name-by-bus`,
      {
        processed_date: moment(startDate).format("YYYY-MM"),
        bus_name: selectedBus?.value,
      }
    );

    if (class_response.status === 200) {
      setClasses(class_response.data.data);
    } else {
      console.log("Server Error");
    }

    const bus_response = await privateApi.get(
      "/admin/bus-routes?" + new URLSearchParams(params)
    );

    if (bus_response.status === 200) {
      const receivedStoppageTimes = bus_response?.data?.stoppage;

      // Find missing class IDs
      const existingClassIds = receivedStoppageTimes.map(
        stoppageTime => stoppageTime.class_id
      );
      const missingClassIds = class_response.data.data
        .map(cls => cls.id)
        .filter(id => !existingClassIds.includes(id));

      const set_time =
        receivedStoppageTimes.length > 1
          ? Object.fromEntries(
              Object.keys(receivedStoppageTimes[0].times).map(stopId => [
                stopId,
                { startTime: "", endTime: "" },
              ])
            )
          : {};

      // Add placeholder entries only for missing class IDs
      const updatedStoppageTimes = [
        ...receivedStoppageTimes,
        ...missingClassIds.map(id => ({
          class_id: id,
          times: set_time,
        })),
      ];

      setStoppageTimes(updatedStoppageTimes);
    } else {
      console.log("getBusRoutes:: Server Error");
    }
  };

  useEffect(() => {
    getBuses();
    getBusRoutes();
  }, [selectedBus, startDate]);

  useEffect(() => {
    getAllBuses();
  }, []);

  const updateStoppageTime = (classId, stoppageId, type, value) => {
    const stoppageIndex = stoppageTimes.findIndex(
      stoppageTime => stoppageTime.class_id === classId
    );

    if (stoppageIndex !== -1) {
      const updatedStoppageTimes = [...stoppageTimes];

      const updatedTimes = { ...updatedStoppageTimes[stoppageIndex].times };

      updatedTimes[stoppageId] = {
        ...updatedTimes[stoppageId],
        [type]: value,
      };

      updatedStoppageTimes[stoppageIndex] = {
        ...updatedStoppageTimes[stoppageIndex],
        times: updatedTimes,
      };

      setStoppageTimes(updatedStoppageTimes);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const data = {
        bus: selectedBus?.value,
        date: moment(startDate).format("YYYY-MM"),
        stoppage: stoppageTimes,
      };

      const response = await privateApi.post("/admin/bus-routes", data);

      if (response.status === 200) {
        setLoading(false);
        swal("成功!", "バスのスケジュールが正常に保存されました。", "success");
      } else {
        setLoading(false);
        swal("Error!", "There was an error saving the bus schedule", "error");
      }
    } catch (error) {
      swal("Error!", "There was an error saving the bus schedule", "error");
    }
  };

  const handleCancelCopy = () => {
    setShowConfirmationModal(false);
  };

  const handleCopy = async () => {
    setIsCopying(true);
    try {
      const fromMonth =
        startDate.getFullYear() +
        "-" +
        ("0" + (startDate.getMonth() + 1)).slice(-2);
      const toMonth =
        endTo.getFullYear() + "-" + ("0" + (endTo.getMonth() + 1)).slice(-2);

      const response = await privateApi.post("admin/bus-monthly-generate", {
        from_month: fromMonth,
        to_month: toMonth,
      });

      if (response.status === 200) {
        setShowConfirmationModal(false);
        swal("新しいバスが追加されました", "", "success");
      } else {
        swal("何かエラーが発生しました", "", "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsCopying(false);
    }
  };

  return (
    <div className="">
      <div className="button-div py-3 d-flex gap-3 flex-row w-100">
        <div style={{ minWidth: "140px" }}>
          <Select
            value={selectedBus}
            onChange={handleBusSelect}
            options={Object.keys(busStops).map(bus => ({
              value: bus,
              label: bus,
            }))}
            placeholder="バスを選択"
          />
        </div>
        <div style={{ width: "100px" }}>
          <ReactDatePicker
            className="form-control h-100"
            dateFormat="yyyy/MM"
            selected={startDate}
            onChange={date => {
              if (date) {
                setStartDate(date);
              }
            }}
            todayButton={"Today"}
            locale={ja}
            placeholderText="日付を選択"
            showMonthYearPicker={true}
          />
        </div>

        <div className="form-group row align-items-center">
          <div className="col-md-12 mx-0 d-flex align-items-center">
            <div className="btn-group">
              <button
                className="btn  btn-outline-secondary"
                onClick={() => {
                  setStartDate(prevDate => {
                    const newDate = new Date(prevDate);
                    newDate.setMonth(newDate.getMonth() - 1);
                    return newDate;
                  });
                }}
              >
                <i className="fas fa-arrow-left fa-fw"></i>
              </button>

              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  setStartDate(prevDate => {
                    const newDate = new Date(prevDate);
                    newDate.setMonth(newDate.getMonth() + 1);
                    return newDate;
                  });
                }}
              >
                <i className="fas fa-arrow-right fa-fw"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="highIndex" style={{ width: "100px" }}>
          <ReactDatePicker
            className="form-control h-100"
            selected={endTo}
            onChange={date => {
              if (date) {
                setEndTo(date);
              }
            }}
            dateFormat="yyyy/MM"
            showMonthYearPicker
            locale={ja}
            style={{ fontSize: "0.8rem", width: "50px" }}
          />
        </div>

        <div>
          <button
            className="btn btn-success mx-1"
            onClick={handleConfirmCopy}
            disabled={isCopying}
          >
            複製
          </button>
        </div>
        <div className="ml-auto">
          {loading ? (
            <Button variant="primary" disabled>
              保存
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              保存
            </Button>
          )}
        </div>
      </div>

      {selectedBus && (
        <Table
          responsive
          striped
          bordered
          hover
          className="mt-3 align-middle text-center w-100"
        >
          <thead>
            <tr>
              <th style={{ minWidth: "60px", maxWidth: "150px!important" }}>
                クラス：時間
              </th>
              <th style={{ maxWidth: "60px" }}>往復</th>
              {stoppages.map(stoppage => (
                <th
                  key={stoppage.id}
                  style={{
                    width: "20px",
                    writingMode: "tb",
                    padding: "5px 35px",
                  }}
                >
                  {stoppage.stop_name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {classes.map(cls => (
              <tr key={cls.id}>
                <th style={{ minWidth: "120px" }}>{cls.mixed_class_name}</th>
                <th style={{ minWidth: "60px" }}>
                  <div className="">
                    <span>迎え</span>
                    <br></br>
                    <span>送り</span>
                  </div>
                </th>
                {stoppages.map(stoppage => {
                  const time = stoppageTimes.find(
                    stoppageTime => stoppageTime.class_id === cls.id
                  )?.times[stoppage.id];
                  return (
                    <td key={stoppage.id}>
                      <div className="align-items-center d-flex flex-column d-row p-2 gap-1">
                        <TimePicker
                          className="bg-white text-center"
                          name="start_time"
                          clearIcon={null}
                          format="HH:mm"
                          disableClock
                          value={!time ? null : time.startTime || null}
                          onChange={newValue =>
                            updateStoppageTime(
                              cls.id,
                              stoppage.id,
                              "startTime",
                              newValue
                            )
                          }
                        />

                        <TimePicker
                          className="bg-white text-center"
                          name="end_time"
                          clearIcon={null}
                          format="HH:mm"
                          disableClock
                          value={!time ? null : time.endTime || null}
                          onChange={newValue =>
                            updateStoppageTime(
                              cls.id,
                              stoppage.id,
                              "endTime",
                              newValue
                            )
                          }
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showConfirmationModal} onHide={handleCancelCopy}>
        <Modal.Header closeButton>
          <Modal.Title>複製の確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>複製してもよろしいですか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelCopy}>
            キャンセル
          </Button>
          <Button variant="success" onClick={handleCopy} disabled={isCopying}>
            複製
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BusSchedule;
