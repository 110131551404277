import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import privateApi from "../../../services/privateApi";
import { useMediaQuery } from "react-responsive";

import "react-datepicker/dist/react-datepicker.css";
import BackButton from "../../common/Button/BackButton";
import RouteLeavingGuard from "../../Prompt/RouterLeavingGuard";

const TuitionMaster = ({ history }) => {
  const buttonPosition = {
    bottom: "-28%",
    right: "17%",
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1366 });

  const [classes, setClasses] = useState();
  const [currentClass, setCurrentClass] = useState("");
  const [tutionFees, setTutionFees] = useState([]);
  const classesOptions = [];
  const [TuitionMasters, setTuitionMasters] = useState([]);
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [whenState, updateWhenState] = useState(false);

  const getTuitionMasters = async pageNumber => {
    const response = await privateApi.get(
      `admin/tuition-fees?page=${pageNumber}&per_page=10`
    );
    if (response.status === 200) {
      setTuitionMasters(response.data.data);

      setPaginationBtns(response.data?.meta);
    } else {
      console.log("Server Error");
    }
  };
  useEffect(() => {
    getTuitionMasters();
  }, [currentClass]);

  const createClassOptions = old_array => {
    old_array.forEach(function (item) {
      classesOptions.push({
        label: item?.affiliation_name,
        value: item?.id,
      });
    });
  };

  const getClasses = async e => {
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      createClassOptions(response.data.data);
      setClasses(classesOptions);
    } else {
      console.log("Server Error");
    }
  };

  const getFees = async e => {
    const response = await privateApi.get(`admin/tuition-fees/${currentClass}`);
    if (response.status === 200) {
      setTutionFees(response.data.data);
    } else {
      console.log("Server Error");
    }
  };
  const getTuitionMasters_csv_export = async () => {
    try {
      const response = await privateApi.get("admin/tution-fees/csv/export");
      if (response.status === 200) {
        return response.data.data; // Return the data
      } else {
        console.log("Server Error");
        return []; // Return an empty array in case of an error
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      return []; // Return an empty array in case of an error
    }
  };
  async function download_table_as_csv(
    table_id,
    TuitionMasters_csv_export,
    separator = ","
  ) {
    var csv = [];
    var header = [];
    header.push('"' + "所属コード" + '"');
    header.push('"' + "所属名" + '"');
    header.push('"' + "回数" + '"');
    header.push('"' + "授業料" + '"');
    header.push('"' + "特別料1" + '"');
    header.push('"' + "特別料2" + '"');
    header.push('"' + "休校料" + '"');
    csv.push(header.join(separator));
    TuitionMasters_csv_export &&
      TuitionMasters_csv_export.forEach(tuition_fees => {
        var row = [];
        row.push('"' + tuition_fees.affiliation_code + '"');
        row.push('"' + tuition_fees.affiliation_name + '"');
        row.push('"' + tuition_fees.times + '"');
        row.push('"' + tuition_fees.tution_fee + '"');
        row.push('"' + tuition_fees.special_fee_1 + '"');
        row.push('"' + tuition_fees.special_fee_2 + '"');
        row.push('"' + tuition_fees.closure_fee + '"');
        csv.push(row.join(separator));
      });
    var csv_string = csv.join("\n");
    var filename =
      "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }
  const handleDownloadCSV = async table_id => {
    try {
      const TuitionMasters_csv_export = await getTuitionMasters_csv_export(); // Fetch the data
      await download_table_as_csv(table_id, TuitionMasters_csv_export); // Pass the data to the download function
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };

  const handleInputChange = (event, index, field) => {
    updateWhenState(true);
    const updatedFees = [...tutionFees];
    updatedFees[index] = {
      ...updatedFees[index],
      [field]: event.target.value,
    };
    setTutionFees(updatedFees);
  };

  const emptyRows = Array.from(
    { length: 7 - tutionFees.length },
    (_, index) => ({
      id: "",
      times: "",
      tution_fee: "",
      special_fee_1: "",
      special_fee_2: "",
      closure_fee: "",
    })
  );

  const allRows = [...tutionFees, ...emptyRows];

  const handleSubmit = async event => {
    event.preventDefault();
    // Prepare the request body
    const requestBody = {
      id: [],
      times: [],
      tution_fee: [],
      special_fee_1: [],
      special_fee_2: [],
      closure_fee: [],
    };

    tutionFees.forEach(fee => {
      requestBody.id.push(fee.id ? fee.id : null);
      requestBody.times.push(fee.times);
      requestBody.tution_fee.push(fee.tution_fee);
      requestBody.special_fee_1.push(fee.special_fee_1);
      requestBody.special_fee_2.push(fee.special_fee_2);
      requestBody.closure_fee.push(fee.closure_fee);
    });

    const response = await privateApi.post("/admin/tuition-fees", {
      ...requestBody,
      regular_class_id: currentClass,
    });

    if (response.status === 200) {
      updateWhenState(false);
      swal("このクラスの授業料を更新しました", "", "success");
    } else {
      swal(`${response?.data?.message}`, "", "error");
    }
  };

  const [showSecond, setShowSecond] = useState(false);

  useEffect(() => {
    getClasses();
  }, [currentClass]);

  useEffect(() => {
    getFees();
  }, [currentClass]);

  return (
    <div
      style={{
        width: isMobile ? "100%" : isTablet || isLaptop ? "60%" : "40%",
      }}
    >
      {showSecond ? (
        <div>
          <div className="d-flex justify-content-start mb-2">
            <div className="square border border-2 px-2 py-2 mt-2 row w-100">
              <div className="row mb-3">
                <div className="col-md-8">
                  <button
                    onClick={() => {
                      handleDownloadCSV("TuitionMasters");
                    }}
                    className="btn btn-sm btn-primary px-3 ml-auto"
                    type="button"
                  >
                    <i className="fas fa-download fa-fw"></i>
                    CSV
                  </button>
                </div>
              </div>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th className="text-center" style={{ width: "17%" }}>
                      所属コード
                    </th>
                    <th className="text-center">所属名</th>
                    <th className="text-center">回数</th>
                    <th className="text-center">授業料</th>
                    <th className="text-center">特別料1</th>
                    <th className="text-center">特別料2</th>
                    <th className="text-center">休校料</th>
                  </tr>
                </thead>
                <tbody>
                  {TuitionMasters.map((TuitionMaster, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        {TuitionMaster?.class?.affiliation_code}
                      </td>
                      <td className="text-center">
                        {TuitionMaster?.class?.affiliation_name}
                      </td>
                      <td className="text-center">{TuitionMaster?.times}</td>
                      <td className="text-center">
                        {TuitionMaster?.tution_fee}
                      </td>
                      <td className="text-center">
                        {TuitionMaster?.special_fee_1}
                      </td>
                      <td className="text-center">
                        {TuitionMaster?.special_fee_2}
                      </td>
                      <td className="text-center">
                        {TuitionMaster?.closure_fee}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="row ms-2">
                <div className="col-md-12 d-flex justify-content-end me-0">
                  <button
                    onClick={() => {
                      setShowSecond(false);
                    }}
                    className="btn btn-sm btn-primary me-0"
                    variant="primary"
                  >
                    戻る
                  </button>
                </div>
              </div>
              {TuitionMasters && (
                <div className="paginations d-flex mt-3">
                  <div className="d-flex mx-auto neomp">
                    <Pagination
                      activePage={
                        paginationBtns.current_page
                          ? paginationBtns.current_page
                          : 0
                      }
                      itemsCountPerPage={paginationBtns.per_page}
                      totalItemsCount={paginationBtns.total}
                      onChange={pageNumber => {
                        getTuitionMasters(pageNumber);
                      }}
                      pageRangeDisplayed={5}
                      itemclassName="page-item"
                      linkclassName="page-link"
                      firstPageText="First"
                      lastPageText="Last"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className=" d-flex justify-content-center mb-3">
            <div className="card md px-2 py-2 mt-4 row w-100 ">
              <div className="row my-1">
                <div className="d-inline-flex col-md-10 text-right py-1">
                  <div className="col-md-2 d-flex justify-content-center">
                    <label className="form-label text-bold d-flex justify-content-end align-items-end">
                      所属:
                    </label>
                  </div>
                  <div className="col-md-4">
                    <select
                      className="shadow-sm form-control form-control-sm"
                      value={currentClass}
                      onChange={e => setCurrentClass(e.target.value)}
                      style={{ width: "80%" }}
                    >
                      <option value="">選択する</option>
                      {classes?.map(classOption => (
                        <option
                          key={classOption.value}
                          value={classOption.value}
                        >
                          {classOption.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <Table
                style={{
                  padding: "0.1rem 0.1rem !important",
                }}
                bordered
                hover
                responsive="sm"
              >
                <thead>
                  <tr>
                    <th className="text-center" style={{ width: "7%" }}>
                      回数:
                    </th>
                    <th className="text-center">授購料:</th>
                    <th className="text-center">特別料1:</th>
                    <th className="text-center">特別料2:</th>
                    <th className="text-center">休校料:</th>
                  </tr>
                </thead>
                <tbody>
                  {allRows.map((fee, index) => (
                    <tr key={index}>
                      <td className="text-center" style={{ width: "5%" }}>
                        <input
                          style={{ width: "80%", textAlign: "right" }}
                          type="number"
                          value={fee?.times}
                          onChange={event =>
                            handleInputChange(event, index, "times")
                          }
                        />
                      </td>
                      <td className="text-center" style={{ width: "12%" }}>
                        <input
                          style={{ width: "100%", textAlign: "right" }}
                          type="text"
                          value={fee.tution_fee}
                          onChange={event =>
                            handleInputChange(event, index, "tution_fee")
                          }
                        />
                      </td>
                      <td className="text-center" style={{ width: "12%" }}>
                        <input
                          type="text"
                          style={{ width: "100%", textAlign: "right" }}
                          value={fee.special_fee_1}
                          onChange={event =>
                            handleInputChange(event, index, "special_fee_1")
                          }
                        />
                      </td>
                      <td className="text-center" style={{ width: "12%" }}>
                        <input
                          type="text"
                          style={{ width: "100%", textAlign: "right" }}
                          value={fee.special_fee_2}
                          onChange={event =>
                            handleInputChange(event, index, "special_fee_2")
                          }
                        />
                      </td>
                      <td className="text-center" style={{ width: "12%" }}>
                        {" "}
                        <input
                          type="text"
                          style={{ width: "100%", textAlign: "right" }}
                          value={fee.closure_fee}
                          onChange={event =>
                            handleInputChange(event, index, "closure_fee")
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="row mx-0">
                <div className="col-md-4">
                  <button
                    onClick={() => {
                      setShowSecond(true);
                    }}
                    className="btn btn-sm btn-primary ml-auto"
                    variant="primary"
                  >
                    一覧表示
                  </button>
                </div>
                <div
                  style={{ position: "relative" }}
                  className="col-md-8 d-flex justify-content-end me-0"
                >
                  <button
                    className="btn btn-sm btn-primary "
                    variant="primary"
                    onClick={e => handleSubmit(e)}
                  >
                    保存
                  </button>
                  <BackButton
                    redirectTo="/admin/master-maintenance/dashboard"
                    position={buttonPosition}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <RouteLeavingGuard
        when={whenState}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={location => {
          if (whenState) {
            return true;
          }
          return false;
        }}
        yes="yes"
        no="no"
        // content="Are you sure you want to leave?"
      />
    </div>
  );
};
export default TuitionMaster;
