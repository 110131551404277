import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth/AuthService";
import { getData } from "../../services/auth/token";
import "../../assets/common/css/navbar.css";

const Navbar = () => {
  const history = useHistory();
  const currentUser = getData()?.username;
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);

  const toggleSidebar = () => {
    document.body.classList.toggle("sb-sidenav-toggled");
    localStorage.setItem(
      "sb|sidebar-toggle",
      document.body.classList.contains("sb-sidenav-toggled")
    );
    setIsSidebarToggled(!isSidebarToggled);
  };
  const logoutController = async () => {
    const response = await AuthService.logout("superadmin");

    if (response.status === 200) {
      history.push("/login/super-admin");
    }
  };

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-primary">
      <Link className="navbar-brand ps-3" to="/super-admin/dashboard">
        スーパー管理者パネル
      </Link>

      <button
        className="btn btn-toggle btn-sm order-1 order-lg-0 me-4 me-lg-0 text-white"
        onClick={toggleSidebar}
      >
        {isSidebarToggled ? "開く" : "閉じる"}
      </button>

      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        {/* <div className="input-group">
          <input
            className="form-control"
            type="text"
            placeholder="検索する..."
            aria-label="Search for..."
            aria-describedby="btnNavbarSearch"
          />
          <button
            className="btn btn-primary"
            id="btnNavbarSearch"
            type="button"
          >
            <i className="fas fa-search"></i>
          </button>
        </div> */}
      </form>

      <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        {/* <li className="nav-item">
          <Link
            to="/"
            className="nav-link"
          >
            <i className="fas fa-home fa-fw"></i>
          </Link>
        </li> */}
        <li className="nav-item dropdown">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fas fa-user fa-fw"></i>
            {currentUser}
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown"
          >
            <li>
              <Link className="dropdown-item" to="#!">
                設定
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="#!">
                アクティビティ ログ
              </Link>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li onClick={logoutController}>
              <span className="dropdown-item link" to="/">
                ログアウト
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
