import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import "./Navbar.css";
import { getSchool } from "../../services/auth/token"
import { addEventListener, removeEventListener } from "../../services/event/eventBus";

function Navbar() {
  const history = useHistory();
  const location = useLocation();
  const [schoolSlug, setSchoolSlug] = useState(getSchool() ? '/' + getSchool() : '');

  const logoutSubmit = (e) => {
    e.preventDefault();

    axios.post(`/api/logout`).then((res) => {
      if (res.data.status === 200) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_name");
        swal("Success", res.data.message, "success");
        history.push("/");
      }
    });
  };

  useEffect(() => {
    if (!schoolSlug) {
      setSchoolSlug(getSchool() ? '/' + getSchool() : '');
    }

    const handleSchoolSlugChanged = (newSlug) => {
      setSchoolSlug(newSlug.startsWith('/') ? newSlug : '/' + newSlug);
    };

    addEventListener('schoolSlugChanged', handleSchoolSlugChanged);

    return () => {
      removeEventListener('schoolSlugChanged', handleSchoolSlugChanged);
    };

  }, [schoolSlug]);

  var AuthButtons = "";
  if (!localStorage.getItem("auth_token")) {
    AuthButtons = (
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink className={location.pathname.includes('login/student') ? 'nav-link active' : 'nav-link' } to={`${schoolSlug}/login/student`}>
            会員ログイン
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={location.pathname.includes('login/coach') ? 'nav-link active' : 'nav-link' } to={`${schoolSlug}/login/coach`}>
            コーチログイン
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={location.pathname.includes('login/admin') ? 'nav-link active' : 'nav-link' } to={`${schoolSlug}/login/admin`}>
            管理者ログイン
          </NavLink>
        </li>
      </ul>
    );
  } else {
    AuthButtons = (
      <li className="nav-item">
        <button
          type="button"
          onClick={logoutSubmit}
          className="nav-link btn btn-danger btn-sm text-white"
        >
          Logout
        </button>
      </li>
    );
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary shadow sticky-top">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          スイミングスクール
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" exact to="/">
                ホーム
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">
                案内
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">
                お問い合わせ
              </NavLink>
            </li>
            { getSchool() ? AuthButtons : null}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
