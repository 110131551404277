import React, { useState, useEffect } from "react";
import { Button, Badge } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import { Link, } from "react-router-dom";
import privateApi from "../../../services/privateApi";
import "../student/Student.css";
import "../../../assets/common/css/modal.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackButton from "../../common/Button/BackButton";
import ShortTermAdvanceSearch from "./ShortTermAdvanceSearch";
import "./ShortTermClass.css";
import { Table, Modal as AntModal, Row, Checkbox, Button as AntButton, Popconfirm, Spin } from "antd";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { ConfigProvider } from "antd";
import useModal from "../../../hooks/useModal";
import { checkboxes } from "../student/checkboxes";
import moment from "moment";
import { katakanaBtns, shortTermTableColumns } from "./katakanas";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8",
    },
  },
};

let isFetching = false;

const ShortTermStudentList = () => {
  const history = useHistory();
  const [students, setStudents] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const memberPerPage = 100;
  const [totalStudents, setTotalStudents] = useState(0);
  const { isOpen, close, open } = useModal();
  const { isOpen: isLoading, close: stopLoading, open: startLoading } = useModal();
  const [checkedColumnList, setCheckedColumnList] = useState([]);
  const { open: openFilterModal, isOpen: filterModal, close: closeFilterModal } = useModal();
  const [advanceSearch, setAdvanceSearch] = useState({
    member_code_start: "",
    member_code_end: "",
    furigana: "",
    gender: "",
    birthday_start: "",
    birthday_end: "",
    telePhone_1: "",
    telePhone_2: "",
    bus_user: false,
  });

  const getStudents = async (params, isScrolling) => {
    setLoader(true);
    const queryString = Object.entries({ page: pageNumber, per_page: memberPerPage, ...advanceSearch, ...params })
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    try {
      const response = await privateApi.post(
        `admin/short-terms-members?member_type=Short Term&${queryString}`
      );

      if (response.status === 200) {
        // Assuming response.data.data is an array of student data
        setPageNumber(p => response?.data?.meta?.current_page);
        if (isScrolling) {
          setStudents((prevStudents) => [...prevStudents, ...response.data.data]);
        } else {
          setStudents(() => response.data.data);
        }
        setTotalStudents(response?.data?.total_members);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      isFetching = false;
      setLoader(false);
    }
  };

  const searchStudentByKatakana_name = async (
    katakanasearch,
  ) => {
    if (advanceSearch.furigana === katakanasearch) {
      setAdvanceSearch(p => ({ ...p, furigana: "" }));
      getStudents({ furigana: "" });
    } else {
      setAdvanceSearch(p => ({ ...p, furigana: katakanasearch }));
      getStudents({ furigana: katakanasearch, page: 1 });
    }
  };

  const getstudents_csv_export = async (type) => {
    try {
      let queryString;
      const searchParams = {
        page: pageNumber,
        per_page: memberPerPage,
        // active_member: isActiveMembers ? 2 : 1,
        // order_column: orderColumn,
        // order_type: orderType ? "asc" : "desc",
      };
      if (type === "all") {
        queryString = Object.entries({ ...advanceSearch, ...searchParams })
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");
      } else {
        queryString =
          Object.entries({ ...advanceSearch, ...searchParams })
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&") +
          `&columns=${checkedColumnList.join(",")}` +
          `&headerColumns=${checkedColumnList.map(c => checkboxes.find(b => b.value === c).label).join(",")}`;
      }
      const response = await privateApi.get(
        "admin/short-terms-members-csv?" + queryString
      );
      if (response.status === 200) {
        return response.data; // Return the data
      } else {
        console.log("Server Error");
        return []; // Return an empty array in case of an error
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      return []; // Return an empty array in case of an error
    }
  };

  async function download_table_as_csv(
    table_id,
    students_csv_export,
  ) {
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";

    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(students_csv_export)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async (table_id, type) => {
    startLoading();
    try {
      const students_csv_export = await getstudents_csv_export(type); // Fetch the data
      await download_table_as_csv(table_id, students_csv_export); // Pass the data to the download function
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
    stopLoading();
  };

  const deleteStudent = async id => {
    const response = await privateApi.delete(`admin/members/${id}`);
    if (response.status === 200) {
      swal("削除しました！", "success");
      setStudents(students => students.filter(s => s.id !== id));
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const updatePageHandler = id => {
    history.push(`/admin/stc/student/details/${id}?action=view&key=tab1`);
  };
  const handleAdvanceSearch = async () => {
    closeFilterModal();
    setPageNumber(1);
    getStudents({ page: 1, });
  };
  const handleScroll = e => {
    const target = e.target;
    const distanceFromBottom =
      target.scrollHeight - target.scrollTop - target.clientHeight;
    if (distanceFromBottom <= 300 && !loader && !isFetching) {
      isFetching = true;
      getStudents({ page: pageNumber + 1 }, true);
    }
  };

  const [columns, setColumns] = useState([...shortTermTableColumns, {
    title: '操作',
    key: 'action',
    width: 150,
    className: 'text-center',
    fixed: 'right',
    render: (text, record) => (
      <div className="text-center">
        <Link
          className="btn btn-sm btn-sm-custom btn-info text-light mx-1"
          to={`/admin/stc/student/view/${record.id}`}
        >
          <FontAwesomeIcon icon="eye" />
        </Link>
        <Link
          className="btn btn-sm btn-sm-custom btn-primary mx-1"
          to={`/admin/stc/student/update/${record.id}`}
        >
          <FontAwesomeIcon icon="edit" />
        </Link>
        <Popconfirm description="消去してもよろしいですか？" okText={"消去"} cancelText="キャンセル" title={"削除の確認"} onConfirm={() => deleteStudent(record.id)}>
          <button
            className="btn btn-sm btn-sm-custom btn-danger mx-1"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </Popconfirm>
      </div>
    ),
  },]);

  const handleResize =
    index =>
      (e, { size }) => {
        setColumns(columns => {
          const nextColumns = [...columns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          return nextColumns;
        });
      };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = record => {
    const key = record.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );
  };

  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  useEffect(() => {
    getStudents({ page: 1 });
  }, []);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === "F9") {
        history.push("/admin/stc/student/create");
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [history]);

  useEffect(() => {
    const container = document.querySelector(".ant-table-body");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [pageNumber, loader]);

  const listContent = (
    <div style={{ position: "relative" }}>
      <div className="d-flex justify-content-end align-items-center upper-container">
        <div className="d-flex flex-row">
          <div className="d-flex align-items-center"></div>
        </div>
      </div>

      <div className="py-1">
        <div className="border px-2">
          <div className="row member-options">
            <div className="py-2 part-1 col-md-12 pr-0">
              <input
                className="form-control"
                name="student_id"
                placeholder="会員番号／氏名／フリガナで検索"
                onChange={e => {
                  setAdvanceSearch((s) => ({ ...s, search: e.target.value }));
                  getStudents({ search: e.target.value });
                }}
                style={{ padding: "3px" }}
              />
            </div>

            <div
              className="py-2 part-2 col-md-12"
              style={{ paddingLeft: "5px" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="custom-btn-container">
                  <button
                    type="button"
                    className="w-100 h-100 btn btn-sm btn-primary"
                    onClick={() => getStudents()}
                  >
                    検索
                  </button>
                </div>

                <div className="custom-btn-container">
                  <button
                    onClick={openFilterModal}
                    type="button"
                    className="w-100 h-100 btn btn-sm btn-primary"
                  >
                    検索条件
                  </button>
                </div>

                <div className="custom-btn-container">
                  <Link
                    className="btn btn-sm btn-primary px-3 ml-auto"
                    variant="primary"
                    to="/admin/stc/student/create"
                  >
                    新規 [F9]
                  </Link>
                </div>

                <AntModal
                  width={"800px"}
                  open={isOpen}
                  onCancel={close}
                  footer={null}
                  title={"CSVでダウンロード"}
                >
                  {isLoading ? <div style={{ width: "100%", minHeight: "400px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><Spin /></div> : <><Row>
                    <Checkbox.Group
                      value={checkedColumnList}
                      onChange={list => {
                        setCheckedColumnList(list);
                      }}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr ",
                      }}
                      options={
                        students[0]
                          ? checkboxes.map(v => ({
                            value: v.value,
                            label: v.label,
                          }))
                          : []
                      }
                    />
                  </Row>
                    <AntButton
                      onClick={() => handleDownloadCSV("会員一覧", "all")}
                      className="mt-2"
                      type="primary"
                    >
                      全てダウンロード
                    </AntButton>
                    <AntButton
                      onClick={() => handleDownloadCSV("会員一覧", "columns")}
                      className="ml-5"
                    >
                      選択した項目をダウンロード
                    </AntButton></>}

                </AntModal>
                <div className="custom-btn-container">
                  <button
                    type="button"
                    onClick={() => {
                      open();
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    <i className="fas fa-download fa-fw"></i>
                    CSV
                  </button>
                </div>
              </div>
            </div>

            <div className="row part-3 col-md-12 py-2">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="custom-btn-container">
                  <button type="button" className="btn btn-sm btn-primary">
                    {" "}
                    合計
                    <Badge bg="secondary">{totalStudents}</Badge>
                  </button>
                </div>
                <div
                  className="custom-btn-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {katakanaBtns.map(katakana => <button
                    key={katakana.letter}
                    onClick={() =>
                      searchStudentByKatakana_name(katakana.values)
                    }
                    className={`btn mb-1 p-1 mx-1 ${advanceSearch.furigana?.includes(katakana.letter)
                      ? "btn-selected"
                      : "btn-primary"
                      }`}
                  >
                    {katakana.letter}
                  </button>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="card-body">
          <div className="">
            <div
              className=""
            >
              <ConfigProvider theme={customTheme}>
                <Table className="table-striped-rows"
                  bordered
                  dataSource={students}
                  scroll={{ x: 1500, y: "65vh" }}
                  columns={resizableColumns}
                  components={components}
                  loading={loader}
                  rowKey="id"
                  pagination={false}
                  rowClassName={rowClassName}
                  onRow={record => ({
                    onClick: () => handleRowClick(record),
                    onDoubleClick: () => updatePageHandler(record.id),
                  })}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>

      {students && (
        <div className="paginations d-flex mt-3">
          <div className="d-flex mx-auto neomp">

          </div>
        </div>
      )}
      <BackButton redirectTo="/admin/stc/dashboard" />
    </div>
  );
  return (
    <>
      {listContent}
      {/* advance search modal with advance search button */}
      <Modal size="xl" show={filterModal} onHide={closeFilterModal}>
        <Modal.Header closeButton>
          <Modal.Title>検索条件</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShortTermAdvanceSearch
            advanceSearch={advanceSearch}
            setAdvanceSearch={setAdvanceSearch}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeFilterModal}>
            キャンセル
          </Button>
          <Button onClick={() => handleAdvanceSearch()} variant="success">
            検索
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShortTermStudentList;
