import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import privateApi from "../../services/privateApi";
import Pagination from "react-js-pagination";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import BackButton from "../common/Button/BackButton";
import ConvertTo from "../utilis/ConvertKataka";
import { Skeleton } from "antd";

const Coach = () => {
  const position = {
    bottom: "3%",
  };

  const [modalNewCoach, setModalNewCoach] = useState(false);
  const [modalEditCoach, setModalEditCoach] = useState(false);

  const [coachs, setcoachs] = useState([]);
  const [selectedCoach, setselectedCoach] = useState();
  const [errorList, setErrorList] = useState({});
  const [loading, setLoading] = useState("");
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [selectedCoachIDForDelete, setselectedCoachIDForDelete] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const [newCoach, setnewCoach] = useState({
    first_name: "",
    last_name: "",
    furigana: "",
    name: "",
    phone: "",
    email: "",
    username: "",
    password: "",
    password_confirmation: "",
    remarks: "",
  });

  useEffect(() => {
    getAllCoach();
  }, []);

  const handleInput = e => {
    const { name, value } = e.target;
    setnewCoach(prevCoach => ({
      ...prevCoach,
      [name]: value,
    }));
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const getAllCoach = async pageNumber => {
    setLoading(true);
    const response = await privateApi.get(
      `admin/coaches?page=${pageNumber}&per_page=10`
    );
    if (response.status === 200) {
      setcoachs(response.data.data);
      setPaginationBtns(response.data?.meta);
    } else {
      console.log("Server Error");
    }
    setLoading(false);
  };

  const [validation, setValidation] = useState({
    first_name: false,
    last_name: false,
    furigana: false,
    phone: false,
    email: false,
    username: false,
    password: false,
    password_confirmation: false,
  });

  const deleteCoach = async id => {
    const response = await privateApi.delete(`admin/coaches/${id}`);

    if (response.status === 200) {
      swal("削除しました！", "コーチを削除しました！", "error");
      setShowModal(false);
      getAllCoach();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const handleDeleteClick = id => {
    setselectedCoachIDForDelete(id);
    // Show the delete confirmation modal
    setShowModal(true);
  };

  const handleCloseModal = () => {
    // Close the modal without performing the delete operation
    setShowModal(false);
  };

  const addCoach = async () => {
    setShowPassword1(false);
    setShowPassword2(false);
    setnewCoach([]);
    setModalNewCoach(true);
  };

  const addnewCoach = async () => {
    setLoading(true);

    const response = await privateApi.post("admin/coaches", newCoach);

    if (response.status === 200) {
      // Successful coach creation
      swal("作成しました！", "コーチを作成しました。！", "success");
      setModalNewCoach(false);
      getAllCoach();
      setnewCoach({});
      setShowValidation(false);
      setLoading(false);
      setErrorList({}); // Clear error list on success
    } else {
      setLoading(false);
      if (response.status === 422) {
        setShowValidation(true);
        setErrorList(response.data.errors);
      } else {
        swal(response.data.message, "", "error");
      }
    }
  };

  const updateModalShow = async id => {
    setShowPassword1(false);
    setShowPassword2(false);
    setselectedCoach(id);
    setModalEditCoach(true);

    const response = await privateApi.get("admin/coaches/" + id);

    if (response.status === 200) {
      setnewCoach({
        ...newCoach,
        first_name: response.data.data.first_name,
        last_name: response.data.data.last_name,
        furigana: response.data.data.furigana,
        phone: response.data.data.phone,
        email: response.data.data.email,
        username: response.data.data.username,
        password: response.data.data.plain_password,
        password_confirmation: response.data.data.plain_password,
        remarks: response.data.data.remarks || "",
      });
    } else {
      console.log("Server Error");
    }
  };

  const updateFormSubmit = async id => {
    setLoading(true);

    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("first_name", newCoach.first_name);
    formData.append("last_name", newCoach.last_name);
    formData.append("furigana", newCoach.furigana);
    formData.append("phone", newCoach.phone);
    formData.append("username", newCoach.username);
    formData.append("email", newCoach.email);
    formData.append("password", newCoach.password);
    formData.append("password_confirmation", newCoach.password_confirmation);
    formData.append("remarks", newCoach.remarks);

    const response = await privateApi.formUpdate(
      `admin/coaches/${id}`,
      formData
    );

    if (response.status === 200) {
      swal("コーチ情報を更新しました", "", "success");
      setModalEditCoach(false);
      setnewCoach({});
      setLoading(false);
      getAllCoach();
    } else {
      swal("内容に問題があります", response.data.message, "error");
      setErrorList(response.data.errors);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      var full_name = "";
      if (!newCoach.last_name) {
        full_name = `${newCoach.first_name} `;
      } else {
        full_name = `${newCoach.first_name} ${newCoach.last_name}`;
      }
      if (newCoach.first_name || newCoach.last_name) {
        try {
          const convertedText = await ConvertTo(full_name, "katakana");
          if (convertedText) {
            setnewCoach({
              ...newCoach,
              furigana: convertedText,
            });
          } else {
            console.log("Something wrong");
          }
        } catch (error) {
          console.error("Error converting", error);
        }
      } else {
        setnewCoach({
          ...newCoach,
          furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newCoach.first_name, newCoach.last_name]);

  const formSection = (
    <div className="">
      <span
        className={`${showValidation ? "was-validated" : "needs-validation"}`}
      >
        <div className="row">
          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              姓 <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="姓を入力してください"
              name="first_name"
              value={newCoach.first_name}
              onChange={handleInput}
              required
              className={validation.first_name ? "is-invalid" : ""}
            />
            <Form.Control.Feedback type="invalid">
              {errorList?.first_name ? errorList?.first_name : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              名 <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="名を入力してください"
              name="last_name"
              value={newCoach.last_name}
              onChange={handleInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errorList?.last_name ? errorList?.last_name : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              フリガナ <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="フリガナを入力してください"
              name="furigana"
              value={newCoach.furigana}
              onChange={handleInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errorList?.furigana ? errorList?.furigana : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              電話番号<span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder="電話番号を入力"
              name="phone"
              value={newCoach.phone}
              onChange={handleInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errorList?.phone ? errorList?.phone : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              Eメール<span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="メールアドレスを入力"
              name="email"
              value={newCoach.email}
              onChange={handleInput}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errorList?.email ? errorList?.email : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-12 mb-3" controlId="formBasicPassword">
            <Form.Label>
              ユーザー名 <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="ユーザー名を入力してください"
              name="username"
              value={newCoach.username}
              required
              onChange={handleInput}
            />
            <Form.Control.Feedback type="invalid">
              {errorList?.username ? errorList?.username : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              パスワード <span className="required">*</span>
            </Form.Label>
            <div className="input-group mb-6 mr-sm-3">
              <Form.Control
                type={showPassword1 ? "text" : "password"}
                placeholder="パスワードを入力してください"
                name="password"
                value={newCoach.password}
                onChange={handleInput}
                className="form-control"
                required
              />
              <div
                className="input-group-prepend"
                onClick={togglePasswordVisibility1}
              >
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showPassword1 ? faEyeSlash : faEye}
                    className="py-1"
                  />
                </div>
              </div>
            </div>
            <Form.Control.Feedback type="invalid">
              {errorList?.password ? errorList?.password[0] : ""}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              パスワードを再入力してください<span className="required">*</span>
            </Form.Label>
            <div className="input-group mb-6 mr-sm-3">
              <Form.Control
                type={showPassword2 ? "text" : "password"}
                placeholder="パスワードを再入力してください"
                name="password_confirmation"
                value={newCoach.password_confirmation}
                onChange={handleInput}
                className="form-control"
                required
              />
              <div
                className="input-group-prepend"
                onClick={togglePasswordVisibility2}
              >
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showPassword2 ? faEyeSlash : faEye}
                    className="py-1"
                  />
                </div>
              </div>
              <Form.Control.Feedback type="invalid">
                {errorList?.plain_password ? errorList?.plain_password[0] : ""}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          <Form.Group className="col-md-12 mb-3" controlId="formBasicPassword">
            <Form.Label>備考欄</Form.Label>
            <Form.Control
              type="text"
              placeholder="備考欄を入力してください"
              name="remarks"
              value={newCoach.remarks}
              onChange={handleInput}
            />
          </Form.Group>
        </div>
      </span>
    </div>
  );

  return (
    <div
      className="card"
      style={{
        width: "100%",
      }}
    >
      <div className="card-header ">
        <div className="row">
          <div className="col-10">
            <p className="headline">コーチのリスト</p>
          </div>
          <div className="col-2 d-flex align-items-center">
            <button
              className="btn btn-sm btn-primary px-3 ml-auto"
              variant="primary"
              onClick={addCoach}
            >
              {" "}
              <i className="fas fa-plus fa-fw"></i>
              新しく追加する
            </button>
          </div>
        </div>
      </div>

      <div className="p-2">
        {loading && <Skeleton paragraph={{ row: 3 }} />}
        {coachs && !loading && coachs.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>名前</th>
                <th>フリガナ</th>
                <th>メール</th>
                <th>電話番号</th>
                <th>ユーザー名</th>
                <th>備考欄</th>

                <th className="text-center">操作</th>
              </tr>
            </thead>
            <tbody>
              {coachs.map((coach, index) => (
                <tr key={index}>
                  <td>{coach.name}</td>
                  <td>{coach.furigana}</td>
                  <td>{coach.email}</td>
                  <td>{coach.phone}</td>
                  <td>{coach.username}</td>
                  <td>{coach.remarks}</td>
                  <td className="text-center">
                    <button
                      className="btn btn-sm btn-primary mx-1"
                      onClick={() => updateModalShow(coach.id)}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </button>
                    <button
                      className="btn btn-sm btn-danger mx-1"
                      onClick={() => handleDeleteClick(coach.id)}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {/* modal for adding new Coach */}
        <Modal
          show={modalNewCoach}
          onHide={() => setModalNewCoach(false)}
          size="xl"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
          // onsubmit={() => addnewCoach()}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              新しいコーチを追加
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {formSection}

              <Button
                className="float-right px-5"
                variant="primary"
                // type="submit"
                onClick={() => addnewCoach()}
                style={{ float: "right" }}
              >
                保存
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal for editing current coach*/}
        <Modal
          show={modalEditCoach}
          onHide={() => setModalEditCoach(false)}
          size="xl"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              コーチ情報の更新
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {formSection}

              <Button
                className="float-right px-5"
                variant="primary"
                // type="submit"
                onClick={() => updateFormSubmit(selectedCoach)}
                style={{ float: "right" }}
              >
                保存
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {coachs && (
        <div className="paginations d-flex mt-3">
          <div className="d-flex mx-auto neomp">
            <Pagination
              activePage={
                paginationBtns.current_page ? paginationBtns.current_page : 0
              }
              itemsCountPerPage={paginationBtns.per_page}
              totalItemsCount={paginationBtns.total}
              onChange={pageNumber => {
                getAllCoach(pageNumber);
              }}
              pageRangeDisplayed={5}
              itemclassName="page-item"
              linkclassName="page-link"
              firstPageText="First"
              lastPageText="Last"
            />
          </div>
        </div>
      )}
      <div className="col-3">
        {" "}
        <BackButton redirectTo="/admin/user/dashboard" position={position} />
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>削除の確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>消去してもよろしいですか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            キャンセル
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteCoach(selectedCoachIDForDelete)}
          >
            消去
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Coach;
