import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import swal from "sweetalert";
import privateApi from "../../services/privateApi";
import TimePicker from "react-time-picker";
import "../../assets/global.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RouteLeavingGuard from "../Prompt/RouterLeavingGuard";

const CourseTime = ({ history }) => {
  const [rows, setRows] = useState(6);
  const [whenState, updateWhenState] = useState(false);

  const daysOfWeek = ["月", "火", "水", "木", "金", "土", "日"];

  const defaultClassSchedule = daysOfWeek.map(day => ({
    day,
    classes: Array.from({ length: 6 }, () => ({
      id: "",
      start_time: "",
      end_time: "",
      max_student: "",
      coach_id: "",
    })),
  }));

  const [classSchedule, setClassSchedule] = useState(defaultClassSchedule);
  const [selectedClass, setSelectedClass] = useState();
  const [classes, setClasses] = useState([]);
  const [levelLists, setLevelLists] = useState([]);
  const [coachLists, setCoachLists] = useState([]);

  const getClasses = async e => {
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const handleInputChange = (value, name, dayIndex, classIndex) => {
    updateWhenState(true);
    const updatedSchedule = classSchedule.map((schedule, i) => {
      if (i === dayIndex) {
        const updatedClasses = schedule.classes.map((classData, j) => {
          if (j === classIndex) {
            let newValue;
            let isValid = true;

            if (name === "start_time") {
              const endTime =
                classSchedule[dayIndex]?.classes[classIndex]?.end_time;
              if (endTime && endTime < value) {
                newValue = endTime;
                isValid = false;
              } else {
                newValue = value !== null && value !== "__:__" ? value : "";
              }
            } else if (name === "end_time") {
              const startTime =
                classSchedule[dayIndex]?.classes[classIndex]?.start_time;
              if (startTime && startTime > value) {
                newValue = null;
                isValid = false;
              } else {
                newValue = value !== null && value !== "__:__" ? value : "";
              }
            } else {
              newValue = value !== null && value !== "__:__" ? value : "";
            }

            return { ...classData, [name]: newValue, isValid };
          }

          return classData;
        });

        return { ...schedule, classes: updatedClasses };
      }

      return schedule;
    });

    setClassSchedule(updatedSchedule);
  };

  const getScheduleInfo = async id => {
    if (id === "") {
      setClassSchedule(defaultClassSchedule);
      return;
    }
    try {
      const response = await privateApi.get(`/admin/class-schedules/${id}`);

      if (response.status === 200) {
        if (response.data.data && response.data.data.classSchedule) {
          // Find the day with the maximum number of classes
          const maxClassesDay = response.data.data.classSchedule.reduce(
            (maxDay, currentDay) => {
              return currentDay.classes.length > maxDay.classes.length
                ? currentDay
                : maxDay;
            },
            response.data.data.classSchedule[0]
          );

          // Set the value of 'rows' state to the correct length of classes in the day with the maximum classes
          setRows(maxClassesDay.classes.length);

          const updatedData = response.data.data.classSchedule.map(day => ({
            day: day.day,
            classes: fillClasses(day.classes, maxClassesDay.classes.length),
          }));

          setClassSchedule(updatedData);

          setSelectedClass(response.data.data.regular_class_id);
        } else {
          setClassSchedule(defaultClassSchedule);
        }
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fillClasses = (classes, rnumber) => {
    const newRows = parseInt(rnumber, 10);

    const filledClasses = Array.from({ length: newRows }, (_, index) => {
      const existingClass = classes[index];
      if (existingClass) {
        return {
          id: existingClass.id,
          start_time: existingClass.start_time || "",
          end_time: existingClass.end_time || "",
          max_student: existingClass.max_student || 0,
          coach_id: existingClass.coach_id || null,
        };
      } else {
        return {
          id: null,
          start_time: "",
          end_time: "",
          max_student: 0,
          coach_id: null,
        };
      }
    });
    return filledClasses;
  };

  const getLevelsInfo = async class_id => {
    const response = await privateApi.get(
      `/admin/class-levels?regular_class_id=${class_id}`
    );
    if (response.status === 200) {
      console.log("level list", response.data.data);
      setLevelLists(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const getCoaches = async () => {
    const response = await privateApi.get(`/admin/coaches`);

    if (response.status === 200) {
      console.log("Coach list", response.data.data);
      setCoachLists(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const classChangeHandler = e => {
    setRows(0);
    setSelectedClass(e.target.value);
    getScheduleInfo(e.target.value);
    getLevelsInfo(e.target.value);
    getCoaches();
  };

  useEffect(() => {
    getClasses();
  }, []);

  const handleFormSubmit = async () => {
    const data = {
      regular_class_id: selectedClass,
      classSchedule: classSchedule,
    };

    const response = await privateApi.post("/admin/class-schedules", data);

    if (response.status === 200) {
      updateWhenState(false);
      swal("授業スケジュール情報を更新しました", "", "success");
    } else {
      console.log("Server Error");
    }
  };

  const addNewRows = () => {
    // const newRows = parseInt(rows, 10);

    setRows(rows + 1);

    const updatedSchedule = classSchedule.map(day => ({
      day: day.day,
      classes: [
        ...day.classes,
        ...Array.from({ length: 1 }, () => ({
          id: "",
          start_time: "",
          end_time: "",
          max_student: "",
          coach_id: "",
        })),
      ],
    }));
    setClassSchedule(updatedSchedule);
  };

  const removeRows = classIndex => {
    const updatedSchedule = [...classSchedule];
    updatedSchedule.forEach(day => {
      day.classes.splice(classIndex, 1); // Remove the class at classIndex for each day
    });

    setClassSchedule(updatedSchedule);
  };

  const handleRowDelete = (dayIndex, classIndex) => {
    const updatedSchedule = [...classSchedule];
    updatedSchedule[dayIndex].classes.splice(classIndex, 1);
    setClassSchedule(updatedSchedule);
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-8">
          <div className="card time-table">
            <div className="card-header">
              <div className="row">
                <label
                  className="col-form-label  col-md-1 text-md-end text-start"
                  style={{
                    paddingTop: "5px!important",
                    fontWeight: "600",
                    fontSize: "19px",
                  }}
                >
                  所属
                </label>

                <div className="col-md-6">
                  <select
                    name="class_id"
                    className="form-control shadow-sm"
                    onChange={classChangeHandler}
                    value={selectedClass}
                    style={{ width: "145px" }}
                  >
                    <option value="">クラスの選択</option>
                    {classes?.map((st_class, i) => (
                      <option key={i} value={st_class.id}>
                        {st_class.affiliation_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-5">
                  <div className="d-flex justify-content-end">
                    <div
                      style={{
                        padding: "10px 10px",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      行番号: {rows}
                    </div>

                    <Button
                      className="btn btn-success px-2 ml-2"
                      onClick={addNewRows}
                    >
                      + 行を追加
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <Table
                striped
                bordered
                hover
                className={`mt-2 text-left ${
                  selectedClass ? "" : "disabled-div disabled-overlay"
                }`}
              >
                <thead>
                  <tr>
                    {classSchedule.map((schedule, i) => (
                      <th className="text-center" key={i}>
                        {schedule.day}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {classSchedule[0].classes.map((_, classIndex) => (
                    <tr key={classIndex}>
                      {classSchedule.map((day, dayIndex) => (
                        <React.Fragment key={dayIndex}>
                          <td className="text-center">
                            <div>
                              <TimePicker
                                className="bg-white"
                                id={`${day.day}-${classIndex}-start-time`}
                                name="start_time"
                                value={
                                  classSchedule[dayIndex]?.classes[classIndex]
                                    ?.start_time || null
                                }
                                onChange={value =>
                                  handleInputChange(
                                    value,
                                    "start_time",
                                    dayIndex,
                                    classIndex
                                  )
                                }
                                clearIcon={null}
                                format="HH:mm"
                                disableClock
                              />

                              <div style={{ fontSize: "10px" }}>|</div>

                              <TimePicker
                                className="bg-white"
                                id={`${day.day}-${classIndex}-end-time`}
                                name="end_time"
                                value={
                                  classSchedule[dayIndex]?.classes[classIndex]
                                    ?.end_time || null
                                }
                                onChange={value =>
                                  handleInputChange(
                                    value,
                                    "end_time",
                                    dayIndex,
                                    classIndex
                                  )
                                }
                                clearIcon={null}
                                format="HH:mm"
                                disableClock
                                disabled={
                                  !classSchedule[dayIndex].classes[classIndex]
                                    .start_time
                                }
                              />
                            </div>
                            <br />

                            <div>
                              コースレベル
                              <select
                                className="form-control text-center"
                                style={{ padding: "2px" }}
                                value={
                                  classSchedule[dayIndex].classes[classIndex]
                                    .max_student !== ""
                                    ? classSchedule[dayIndex].classes[
                                        classIndex
                                      ].max_student
                                    : ""
                                }
                                onChange={event =>
                                  handleInputChange(
                                    event.target.value,
                                    "max_student",
                                    dayIndex,
                                    classIndex
                                  )
                                }
                              >
                                {classSchedule[dayIndex].classes[classIndex]
                                  .end_time ? (
                                  <>
                                    <option value="">選択</option>
                                    {levelLists.map((level, index) => (
                                      <option value={level.id}>
                                        {level.in_text}
                                      </option>
                                    ))}
                                  </>
                                ) : (
                                  <option
                                    value=""
                                    disabled
                                    className="text-center"
                                  >
                                    --
                                  </option>
                                )}
                              </select>
                            </div>

                            <div className="mt-1">
                              コーチ
                              <select
                                className="form-control text-center"
                                style={{ padding: "2px" }}
                                value={
                                  classSchedule[dayIndex].classes[classIndex]
                                    .coach_id !== ""
                                    ? classSchedule[dayIndex].classes[
                                        classIndex
                                      ].coach_id
                                    : ""
                                }
                                onChange={event =>
                                  handleInputChange(
                                    event.target.value,
                                    "coach_id",
                                    dayIndex,
                                    classIndex
                                  )
                                }
                              >
                                {classSchedule[dayIndex].classes[classIndex]
                                  .max_student ? (
                                  <>
                                    <option value="">選択</option>
                                    {coachLists.map((coach, index) => (
                                      <option key={index} value={coach.id}>
                                        {coach.name}
                                      </option>
                                    ))}
                                  </>
                                ) : (
                                  <option
                                    value=""
                                    disabled
                                    className="text-center"
                                  >
                                    --
                                  </option>
                                )}
                              </select>
                            </div>
                          </td>
                        </React.Fragment>
                      ))}
                      <td>
                        <button
                          className="btn btn-sm btn-sm-custom btn-danger"
                          style={{ marginLeft: "8px" }}
                          onClick={() => removeRows(classIndex)}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="card-footer">
              <div className="text-end">
                <Link to="/admin/master-maintenance/dashboard">
                  <span className="btn btn-primary mr-5">戻る</span>
                </Link>

                <Button
                  className="btn btn-primary mx-2"
                  type="submit"
                  onClick={handleFormSubmit}
                >
                  保存する
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RouteLeavingGuard
        when={whenState}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={location => {
          if (whenState) {
            return true;
          }
          return false;
        }}
        yes="yes"
        no="no"
      />
    </>
  );
};

export default CourseTime;
