import React from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  //assigning location variable
  const location = useLocation();
  const { pathname } = location;
  const url = pathname.split("/super-admin/");

  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav">
          <div className="sb-sidenav-menu-heading"></div>
          <Link
            className={url[1] === "dashboard" ? "nav-link active2" : "nav-link"}
            to="/super-admin/dashboard"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-tachometer-alt"></i>
            </div>
            機能一覧{" "}
          </Link>

          <Link
            className={url[1] === "school" ? "nav-link active2" : "nav-link"}
            to="/super-admin/school"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-school"></i>
            </div>
            学校
          </Link>

          {/* <Link
            className={url[1] === "payments" ? "nav-link active2" : "nav-link"}
            to="/super-admin/payments"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-tachometer-alt"></i>
            </div>
            支払い
          </Link> */}

          <Link
            className={
              url[1] === "super-admin-list" ? "nav-link active2" : "nav-link"
            }
            to="/super-admin/super-admin-list"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-user-shield"></i>
            </div>
            スーパー管理者を追加
          </Link>

          <Link
            className={
              url[1] === "modules-and-permissions"
                ? "nav-link active2"
                : "nav-link"
            }
            to="/super-admin/modules-and-permissions"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-wrench"></i>
            </div>
            モジュールと権限
          </Link>
          <Link
            className={url[1] === "ip" ? "nav-link active2" : "nav-link"}
            to="/super-admin/ip"
          >
            <div className="sb-nav-link-icon">
              <i className="fas fa-cogs"></i>
            </div>
            IPアドレス
          </Link>
          <Link
            className={
              url[1] === "white-list" ? "nav-link active2" : "nav-link"
            }
            to="/super-admin/white-list"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-list-alt"></i>
            </div>
            ホワイトリスト
          </Link>
          <Link
            className={
              url[1] === "login-logs" ? "nav-link active2" : "nav-link"
            }
            to="/super-admin/login-logs"
          >
            <div className="sb-nav-link-icon">
              <i className="fa fa-history"></i>
            </div>
            ログインログ
          </Link>
        </div>
      </div>
      <div className="sb-sidenav-footer">
        <div className="small">としてログイン:</div>
        デモコーチ
      </div>
    </nav>
  );
};

export default Sidebar;
