import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import privateApi from "../../../services/privateApi";
import { Skeleton } from "antd";
import RouteLeavingGuard from "../../Prompt/RouterLeavingGuard";

const IpAddress = ({ history }) => {
  const [ips, setIps] = useState([]);
  const [errorList, setErrorList] = useState({});
  const [loading, setLoading] = useState("");
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [selectedIpIDForDelete, setselectedCoachIpForDelete] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [ipAddressError, setIpAddressError] = useState("");
  const [editingIpId, setEditingIpId] = useState(null);
  const [editedIpAddress, setEditedIpAddress] = useState("");
  const [whenState, updateWhenState] = useState(false);

  const getAllIps = async pageNumber => {
    setLoading(true);
    const response = await privateApi.get(`admin/ip-accesses`);
    if (response.status === 200) {
      setIps(response.data.data);
      setPaginationBtns(response.data?.meta);
    } else {
      console.log("Server Error");
    }
    setLoading(false);
  };

  const deleteIp = async id => {
    const response = await privateApi.delete(`admin/ip-accesses/${id}`);

    if (response.status === 200) {
      swal("削除しました！", "IPアドレスを削除しました。", "success");
      setShowModal(false);
      getAllIps();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const handleDeleteClick = id => {
    setselectedCoachIpForDelete(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const addIp = async () => {
    setLoading(true);
    const response = await privateApi.post("admin/ip-accesses", {
      ip_address: ipAddress,
      is_allowed: 1,
    });
    if (response?.status === 200) {
      updateWhenState(false);
      swal("作成しました！", "新しいIPアドレスが追加されました。", "success");
      setIpAddress("");
      getAllIps();
      setShowValidation(false);
      setLoading(false);
      setErrorList({});
    } else {
      setLoading(false);
      if (response.status === 422) {
        setShowValidation(true);
        setErrorList(response.data.errors);
      } else {
        swal(response.data.message, "", "error");
      }
    }
  };

  useEffect(() => {
    getAllIps();
  }, []);

  const validateIpAddress = ip => {
    const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    return ipRegex.test(ip);
  };

  const handleIpAddressChange = e => {
    updateWhenState(true);
    const value = e.target.value;
    setIpAddress(value);
    if (value.trim() === "" || validateIpAddress(value)) {
      setIpAddressError("");
    } else {
      setIpAddressError("無効なIPアドレス形式");
    }
  };
  const updatePermission = (id, allowed, ip) => {
    const isAllowed = allowed === 1 ? 0 : 1;
    privateApi
      .update(`admin/ip-accesses/${id}`, {
        ip_address: ip,
        is_allowed: isAllowed,
      })
      .then(res => {
        if (res.status === 200) {
          swal("更新しました！", "IPアドレスを更新しました。", "success");
          getAllIps();
        } else {
          swal(res.data.message, "", "error");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleUpdateClick = id => {
    setEditingIpId(id);
    const ipToEdit = ips.find(ip => ip.id === id);
    setEditedIpAddress(ipToEdit.ip_address); // Set the IP address for editing
  };

  const handleSaveEdit = async () => {
    await privateApi.update(`admin/ip-accesses/${editingIpId}`, {
      ip_address: editedIpAddress,
    });
    swal("更新しました！", "IPアドレスを更新しました。", "success");
    setEditingIpId(null);
    getAllIps();
  };

  const handleCancelEdit = () => {
    setEditingIpId(null);
    setEditedIpAddress("");
  };

  const handleKeyPress = e => {
    if (e.key === "Enter" && ipAddress && !ipAddressError) {
      addIp();
    }
  };

  return (
    <>
      <RouteLeavingGuard
        when={whenState}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={location => {
          if (whenState) {
            return true;
          }
          return false;
        }}
        yes="yes"
        no="no"
      />
      <div
        className="card"
        style={{
          width: "100%",
        }}
      >
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-md-4">
              <p className="headline">IPアドレスを追加する</p>
            </div>
            <div className="col-md-6 d-flex flex-column">
              <input
                className={`w-100 p-2 rounded shadow ${
                  ipAddressError ? "is-invalid" : ""
                }`}
                type="text"
                name="ip-address"
                placeholder="IPアドレスを挿入する"
                value={ipAddress}
                onChange={handleIpAddressChange}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="col-2 d-flex align-items-center">
              <button
                className="btn btn-sm btn-primary px-3 ml-auto p-2"
                variant="primary"
                onClick={() => addIp()}
                disabled={!!ipAddressError}
              >
                新しく追加する
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-4" style={{ margin: "0 auto" }}>
              {ipAddressError && (
                <div className="invalid-feedback ml-5">{ipAddressError}</div>
              )}
            </div>
          </div>
        </div>
        <div className="p-2">
          {loading && <Skeleton paragraph={{ rows: 8 }} />}
          {ips && !loading && ips.length > 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>IPアドレス</th>
                  <th>学校名</th>
                  <th className="text-center">許可</th>
                  <th className="text-center">操作</th>
                </tr>
              </thead>
              <tbody>
                {ips.map((ip, index) => (
                  <tr key={index}>
                    <td>
                      {editingIpId === ip.id ? (
                        <div className="col-md-12">
                          <input
                            type="text"
                            className="col-md-10"
                            value={editedIpAddress}
                            onChange={e => setEditedIpAddress(e.target.value)}
                          />
                          <button
                            className="btn btn-sm btn-danger mx-1 col-md-1"
                            onClick={handleCancelEdit}
                          >
                            <FontAwesomeIcon icon="ban" />
                          </button>
                        </div>
                      ) : (
                        ip.ip_address
                      )}
                    </td>
                    <td>{ip?.school?.school_name}</td>
                    <td>
                      <Form.Check
                        className="d-flex justify-content-center  mx-2"
                        type="switch"
                        id={`custom-switch-${index}`}
                        label=""
                        checked={ip?.is_allowed}
                        onChange={() => {
                          updatePermission(
                            ip?.id,
                            ip?.is_allowed,
                            ip?.ip_address
                          );
                        }}
                      />
                    </td>

                    <td className="text-center">
                      {editingIpId === ip.id ? (
                        <button
                          className="btn btn-sm btn-success mx-1"
                          onClick={handleSaveEdit}
                        >
                          <FontAwesomeIcon icon="save" />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-primary mx-1"
                          onClick={() => handleUpdateClick(ip.id)}
                        >
                          <FontAwesomeIcon icon="edit" />
                        </button>
                      )}

                      <button
                        className="btn btn-sm btn-danger mx-1"
                        onClick={() => handleDeleteClick(ip?.id)}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>削除の確認</Modal.Title>
          </Modal.Header>
          <Modal.Body>消去してもよろしいですか？</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              キャンセル
            </Button>
            <Button
              variant="danger"
              onClick={() => deleteIp(selectedIpIDForDelete)}
            >
              消去
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default IpAddress;
