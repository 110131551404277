import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ButtonLoader from "../../common/ButtonLoader/ButtonLoader";
import swal from "sweetalert";
import ReactSelect from "react-select";
import publicApi from "../../../services/publicApi";
import { saveSchool } from "../../../services/auth/token";

function Schools() {
  const history = useHistory();
  const [schoolOptions, setSchoolOptions] = useState();
  const [schoolId, setSchoolId] = useState();
  const [loading, setLoading] = useState(false);

  const loginSubmit = async () => {
    if (schoolId) {
      saveSchool(schoolId.value);
      history.push(`/${schoolId.value}`);
    } else {
      swal("任意の学校を選択してください", "", "warning");
    }
  };

  const getSchools = async () => {
    setLoading(true);
    const response = await publicApi.get("schools");
    if (response.status === 200) {
      setLoading(false);
      const newSchoolArray = [{ label: "学校を選択", value: "" }];
      response.data.data.forEach(function (item) {
        newSchoolArray.push({ label: item.school_name, value: item.slug });
      });
      setSchoolOptions(newSchoolArray);
    } else {
      setLoading(false);
      swal("Schools not loaded", "", "warning");
    }
  };

  useEffect(() => {
    //this is temporary
    // history.push("/linkstaff/login/admin");
    // return;
    getSchools();
  }, []);

  return (
    <div>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow rounded">
              <div className="card-header text-center">
                <h4>学校を選択してください</h4>
              </div>
              <div className="card-body ">
                <div>
                  <ReactSelect
                    className="shadow-sm"
                    options={schoolOptions}
                    onChange={setSchoolId}
                    value={schoolId}
                    placeholder="学校を選択"
                  />

                  <div className="form-group mb-3 text-center mt-3">
                    <button
                      onClick={loginSubmit}
                      className="btn w-100 btn-theme"
                    >
                      {loading ? <ButtonLoader /> : <span>ログインする</span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schools;
