import React, { useState, useEffect } from "react";
import { Button, Table, Badge, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import "react-datepicker/dist/react-datepicker.css";
import AdvanceSearch from "./AdvanceSearch";
import BackButton from "../../common/Button/BackButton";
import Nvh from "../../common/Nvh";
import "./Student.css";
import "../../../assets/common/css/modal.css";
import privateApi from "../../../services/privateApi";
import Page20 from "../../extrapage/Page20";
import DateForm from "../../common/DateForm";
import moment from "moment";
import { Skeleton } from "antd";

const StudentWithNote = () => {
  const history = useHistory();
  const [students, setStudents] = useState([]);
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [selectedStudentIDForDelete, setselectedStudentIDForDelete] =
    useState();
  const [showModal, setShowModal] = useState(false);
  const [searchButtonenable, setsearchButtonenable] = useState(false);
  const [showSearch, setSearchShow] = useState(false);
  const [search, setSearch] = useState(null);
  const [advancedSearchPage, setAdvancedSearchPage] = useState(false);
  const [page20, setPage20] = useState(false);
  const [selectedKatakana, setSelectedKatakana] = useState([]);
  const [selected, setSelected] = useState(true);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [advanceSearchHasValue, setAdvanceSearchHasValue] = useState(false);
  const [totalStudents, setTotalStudents] = useState(0);
  const [advancedSearchHasMembers, setAdvancdSearchHasMembers] =
    useState(false);
  const [isActiveMembers, setIsActiveMembers] = useState(false);
  const [isFilterToday, setIsFilterToday] = useState(true);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [loader, setLoader] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/admin/std-advanced-search") {
      setAdvancedSearchPage(true);
    }
  }, [location]);

  const getStudents = async pageNumber => {
    setLoader(true);
    const response = await privateApi.get(
      `admin/members?page=${pageNumber}&per_page=1000&notice=1&active_member=${
        isActiveMembers ? 0 : 1
      }&member_type=regular`
    );
    if (response.status === 200) {
      setLoader(false);
      await setStudents(response.data.data);
      // const today = getCurrentDayInJapanese();
      // filterStudentsByDay([response.data.data], today);

      // const today = getCurrentDayInJapanese();
      // const doFilteredStudents = !isFilterToday
      //   ? filterStudentsByDay([response.data.data], today)
      //   : students;
      // await setFilteredStudents(doFilteredStudents);
      // // if (!isFilterToday) {
      // //   setFilteredStudents(response.data.data);
      // // }
      setTotalStudents(response?.data?.total_members);
      setPaginationBtns(response.data?.meta);
    } else {
      console.log("Server Error");
    }
  };

  const searchStudentByID = async value => {
    setLoader(true);
    // e.preventDefault();
    const response = await privateApi.get(
      `admin/members?search=${value}&per_page=1000&notice=1&active_member=${
        isActiveMembers ? 0 : 1
      }&member_type=regular`
    );
    if (response.status === 200) {
      setLoader(false);
      setStudents(response.data.data);
      setTotalStudents(response?.data?.total_members);
      setPaginationBtns(response.data?.meta);
      setsearchButtonenable(true);
      setAdvancdSearchHasMembers(false);
    } else {
      console.log("Server Error");
      const studentArray = [];
      setStudents(studentArray);
      swal("生徒が見つかりません！", "検索された！", "error");
    }
  };
  const searchStudentByKatakana_name = async (
    katakanasearch,
    forActive = false,
    pageNumber
  ) => {
    if (selectedKatakana === katakanasearch && !forActive) {
      getStudents();
      setSelectedKatakana([]);
      setSelected(false);
    } else {
      setLoader(true);
      setSelectedKatakana(katakanasearch);
      const response = await privateApi.get(
        `admin/members?furigana=${katakanasearch}&page=${pageNumber}&per_page=1000&notice=1&active_member=${
          isActiveMembers ? 0 : 1
        }&member_type=regular`
      );
      if (response.status === 200) {
        setLoader(false);
        setStudents(response.data.data);
        // setFilteredStudents(response.data.data);
        setTotalStudents(response?.data?.total_members);
        setPaginationBtns(response.data?.meta);
        setsearchButtonenable(true);
        setAdvancdSearchHasMembers(false);
      } else {
        console.log("Server Error");
        const studentArray = [];
        setLoader(false);
        setStudents(studentArray);
        swal("生徒が見つかりません！", "検索された！", "error");
      }
    }
  };

  const getstudents_csv_export = async () => {
    try {
      const response = await privateApi.get("admin/members-csv");
      if (response.status === 200) {
        return response.data.data;
      } else {
        console.log("Server Error");
        return []; // Return an empty array in case of an error
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      return []; // Return an empty array in case of an error
    }
  };

  async function download_table_as_csv(
    table_id,
    students_csv_export,
    separator = ","
  ) {
    var rows = document.querySelectorAll("tr");
    // Construct csv
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) {
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ");
        data = data.replace(/"/g, '""');
        row.push('"' + data + '"');
      }
      csv.push(row.join(separator));
    }

    var csv_string = csv.join("\n");
    // Download it
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async table_id => {
    try {
      const students_csv_export = await getstudents_csv_export(); // Fetch the data
      await download_table_as_csv(table_id, students_csv_export); // Pass the data to the download function
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };

  const handleActiveBtn = async () => {
    setIsActiveMembers(!isActiveMembers);
  };

  const getCurrentDayInJapanese = () => {
    const daysInJapanese = ["日", "月", "火", "水", "木", "金", "土"];
    const today = new Date();
    return daysInJapanese[today.getDay()];
  };

  const filterStudentsByDay = (students, day) => {
    return students.filter(student => {
      const scheduleKeys = [
        "class_schedule_1",
        "class_schedule_2",
        "class_schedule_3",
      ];
      for (const key of scheduleKeys) {
        if (student[key] && student[key].startsWith(day)) {
          return true;
        }
      }
      return false;
    });
  };

  const handleDayFilter = () => {
    console.log("students inside: " + students.length);
    const today = getCurrentDayInJapanese();
    const doFilteredStudents = isFilterToday
      ? filterStudentsByDay(students, today)
      : students;
    setFilteredStudents(doFilteredStudents);
    console.log("filtered students: " + doFilteredStudents.length);
    // setIsFilterToday(!isFilterToday);
  };

  useEffect(() => {
    getStudents();
    // handleDayFilter();
  }, []);

  useEffect(() => {
    const handleKeyPress = event => {
      switch (event.key) {
        // case "F8":
        //   history.push("/admin/student");
        //   break;
        case "F9":
          history.push("/admin/student/create");
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [history]);

  useEffect(() => {
    if (students.length > 0) {
      handleDayFilter();
    }
    // handleDayFilter();
  }, [students, isActiveMembers, selectedKatakana, isFilterToday]);

  const handleDeleteClick = id => {
    setselectedStudentIDForDelete(id);
    setShowModal(true);
  };

  const deleteStudent = async id => {
    const response = await privateApi.delete(`admin/members/${id}`);

    if (response.status === 200) {
      swal("削除しました！", response.data.message, "success");
      setShowModal(false);
      getStudents();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const handleCloseModal = () => {
    setAdvanceSearchModal(false);
  };
  const handleCloseDeleteModal = () => {
    setShowModal(false);
  };

  const updatePageHandler = id => {
    history.push(`/admin/student/details/${id}?action=view&key=tab1`);
  };

  const [advanceSearchModal, setAdvanceSearchModal] = useState(false);
  const [advanceSearch, setAdvanceSearch] = useState({
    member_code_start: "",
    member_code_end: "",
    furigana: "",
    gender: "",
    birthday_start: "",
    birthday_end: "",
    joining_date_start: "",
    joining_date_end: "",
    district_code: "",
    telePhone_1: "",
    telePhone_2: "",
    family_member_id: "",
    representative: "",
    charge_type: "",
    remarks: "",
    payment_category: "",
    bank_code: "",
    member: "",
    affiliation: "",
    times: "",
    class: "",
    day: "",
    time: "",
    bus_user: "",
    bus_course: "",
  });

  const handleAdvanceSearchButton = () => {
    setAdvanceSearchModal(true);
  };

  const handleAdvanceSearch = async pageNumber => {
    setAdvanceSearchModal(false);
    let advanceResponse;

    setLoader(true);

    if (typeof pageNumber !== "number") {
      advanceResponse = await privateApi.post(
        "/admin/member-search",
        advanceSearch
      );
    } else {
      advanceResponse = await privateApi.post(
        `/admin/member-search?page=${pageNumber}&per_page=1000&active_member=${
          isActiveMembers ? 0 : 1
        }`,
        advanceSearch
      );
    }

    if (advanceResponse.status === 200) {
      setLoader(false);
      setStudents(advanceResponse?.data?.data);
      setTotalStudents(advanceResponse?.data?.total_members);
      setPaginationBtns(advanceResponse.data?.meta);
      if (advanceResponse?.data?.data.length > 0) {
        setAdvancdSearchHasMembers(true);
      }
    } else {
      console.log("Server Error");
      setAdvanceSearchModal(false);
      const studentArray = [];
      setLoader(false);
      setStudents(studentArray);
      swal("生徒が見つかりません！", "検索された！", "error");
    }
  };

  useEffect(() => {
    // if (advanceSearchHasValue) {
    //   handleAdvanceSearch();
    // } else if (selectedKatakana) {
    //   searchStudentByKatakana_name(selectedKatakana);
    // } else if (search) {
    //   searchStudentByID(search);
    // } else {
    //   getStudents();
    // }
    if (selectedKatakana.length === 0) {
      getStudents();
    }
    if (selectedKatakana.length > 0) {
      searchStudentByKatakana_name(selectedKatakana, true);
    }
  }, [isActiveMembers]);

  const listContent = (
    <div style={{ position: "relative" }}>
      <div className="d-flex justify-content-end align-items-center upper-container">
        <div className="d-flex flex-row">
          {advancedSearchPage && (
            <div
              className="d-flex align-items-center"
              style={{ marginRight: "12px" }}
            >
              <button
                className="btn btn-sm btn-primary px-3 ml-auto"
                variant="primary"
                onClick={() => setSearchShow(true)}
              >
                {" "}
                <i className="fas fa-search fa-fw"></i> 詳細検索
              </button>
            </div>
          )}

          <div className="d-flex align-items-center"></div>
        </div>
      </div>

      <div className="py-1">
        <div className="border px-2">
          <div className="row member-options">
            <div className="py-2 part-1 col-md-12 pr-0">
              <input
                className="form-control"
                name="student_id"
                placeholder="会員番号／氏名／フリガナで検索"
                onChange={e => {
                  setSearch(e.target.value);
                  searchStudentByID(e.target.value);
                }}
                style={{ padding: "3px" }}
              />
            </div>
            <div
              className="py-2 part-2 col-md-12"
              style={{
                paddingLeft: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="custom-btn-container">
                <button
                  type="submit"
                  onClick={() => searchStudentByID(search)}
                  className="btn btn-sm btn-primary"
                >
                  検索
                </button>
              </div>
              <div className="custom-btn-container">
                <button
                  type="button"
                  onClick={() => handleDownloadCSV("備考")}
                  className="btn btn-sm btn-primary"
                >
                  <i className="fas fa-download fa-fw"></i>
                  CSV
                </button>
              </div>
              <div className="custom-btn-container">
                <Form>
                  <Form.Check
                    type="checkbox"
                    id="custom-switch"
                    label="本日来校予定"
                    checked={isFilterToday}
                    onClick={() => {
                      setIsFilterToday(!isFilterToday);
                      handleDayFilter();
                    }}
                  />
                </Form>
              </div>
              <div className="custom-btn-container">
                <Form>
                  <Form.Check
                    type="checkbox"
                    id="custom-switch"
                    label="退校者を含む"
                    checked={isActiveMembers}
                    onClick={() => handleActiveBtn()}
                  />
                </Form>
              </div>
              <div className="custom-btn-container">
                <button type="button" className="btn btn-sm btn-primary">
                  {" "}
                  合計
                  <Badge bg="secondary">{totalStudents}</Badge>
                </button>
              </div>
              <div
                className="custom-btn-container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  onClick={() => searchStudentByKatakana_name("ア,イ,ウ,エ,オ")}
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("ア")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                >
                  ア
                </button>
                <button
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("カ")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                  onClick={() => searchStudentByKatakana_name("カ,キ,ク,ケ,コ")}
                >
                  カ
                </button>
                <button
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("サ")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                  onClick={() => searchStudentByKatakana_name("サ,シ,ス,セ,ソ")}
                >
                  サ
                </button>
                <button
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("タ")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                  onClick={() => searchStudentByKatakana_name("タ,チ,ツ,テ,ト")}
                >
                  タ
                </button>
                <button
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("ナ")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                  onClick={() => searchStudentByKatakana_name("ナ,ニ,ヌ,ネ,ノ")}
                >
                  ナ
                </button>
                <button
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("ハ")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                  onClick={() => searchStudentByKatakana_name("ハ,ヒ,フ,ヘ,ホ")}
                >
                  ハ
                </button>
                <button
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("マ")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                  onClick={() => searchStudentByKatakana_name("マ,ミ,ム,メ,モ")}
                >
                  マ
                </button>
                <button
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("ヤ")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                  onClick={() => searchStudentByKatakana_name("ヤ,ユ,ヨ")}
                >
                  ヤ
                </button>
                <button
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("ラ")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                  onClick={() => searchStudentByKatakana_name("ラ,リ,ル,レ,ロ")}
                >
                  ラ
                </button>
                <button
                  className={`btn p-1 char-btn ${
                    selectedKatakana.includes("ワ")
                      ? "btn-selected"
                      : "btn-primary"
                  }`}
                  onClick={() => searchStudentByKatakana_name("ワ")}
                >
                  ワ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="card-body member-list-card">
          <div className="">
            <div className="table-container2 member-table">
              <Table striped bordered hover responsive="lg" className="table2">
                <thead>
                  <tr>
                    <th style={{ minWidth: "90px" }}>会員番号</th>
                    <th style={{ minWidth: "150px" }}>氏名</th>
                    <th style={{ minWidth: "150px" }}>フリガナ</th>
                    <th style={{ minWidth: "45px" }}>性別</th>
                    <th style={{ minWidth: "300px" }}>申し送り事項</th>
                    {/* <th style={{ minWidth: "300px" }}>備考</th> */}
                    <th style={{ minWidth: "100px" }}>生年月日</th>
                    <th style={{ minWidth: "150px" }}>電話番号1</th>

                    <th style={{ minWidth: "100px" }}>1_所属名</th>
                    <th style={{ minWidth: "70px" }}>1_回数</th>
                    <th style={{ minWidth: "70px" }}>1_級</th>
                    <th style={{ minWidth: "150px" }}>1_曜日時間</th>
                    {/*
                    <th style={{ minWidth: "200px" }}>2_所属名</th>
                    <th style={{ minWidth: "100px" }}>2_回数</th>
                    <th style={{ minWidth: "100px" }}>2_級</th>
                    <th style={{ minWidth: "200px" }}>2_曜日時間</th>

                    <th style={{ minWidth: "200px" }}>3_所属名</th>
                    <th style={{ minWidth: "100px" }}>3_回数</th>
                    <th style={{ minWidth: "100px" }}>3_級</th>
                    <th style={{ minWidth: "200px" }}>3_曜日時間</th>

                    {/* <th style={{ minWidth: "100px" }}>地区コード</th>
                    <th style={{ minWidth: "100px" }}>郵便番号</th>
                    <th style={{ minWidth: "300px" }}>住所1</th>
                    <th style={{ minWidth: "300px" }}>住所2</th>
                    <th style={{ minWidth: "150px" }}>電話番号1</th>
                    <th style={{ minWidth: "150px" }}>電話備考1</th>
                    <th style={{ minWidth: "150px" }}>電話番号2</th>
                    <th style={{ minWidth: "150px" }}>電話備考2</th>
                     */}
                    {/* <th style={{ minWidth: "150px" }}>FAX番号</th> 
                    <th style={{ minWidth: "150px" }}>メールアドレス1</th>
                    <th style={{ minWidth: "120px" }}>メール区分1</th>
                    <th style={{ minWidth: "300px" }}>メール備考1</th>
                    <th style={{ minWidth: "150px" }}>メールアドレス2</th>
                    <th style={{ minWidth: "120px" }}>メール区分2</th>
                    <th style={{ minWidth: "150px" }}>メール備考2</th>
                    <th style={{ minWidth: "150px" }}>メールアドレス3</th>
                    <th style={{ minWidth: "150px" }}>メール区分3</th>
                    <th style={{ minWidth: "150px" }}>メール備考3</th>
                    <th style={{ minWidth: "120px" }}>家族会員番号</th>

                    <th style={{ minWidth: "120px" }}>緊急連絡氏名</th>
                    <th style={{ minWidth: "200px" }}>緊急連絡名：フリガナ</th>
                    <th style={{ minWidth: "150px" }}>緊急連絡電話番号</th>

                    <th style={{ minWidth: "150px" }}>バスコース番号</th>
                    <th style={{ minWidth: "100px" }}>バス往路</th>
                    <th style={{ minWidth: "100px" }}>バス復路</th>
                    <th style={{ minWidth: "150px" }}>料金種別コード</th>
                    <th style={{ minWidth: "150px" }}>納付区分コード</th>
                    <th style={{ minWidth: "100px" }}>銀行コード</th>
                    <th style={{ minWidth: "100px" }}>支店コード</th>
                    <th style={{ minWidth: "100px" }}>預金種目</th>
                    <th style={{ minWidth: "150px" }}>口座番号</th>
                    <th style={{ minWidth: "200px" }}>口座名義人</th>
                    <th style={{ minWidth: "100px" }}>入会日</th>
                    */}
                    <th style={{ minWidth: "100px" }}>退校日</th>

                    <th
                      className="action-column"
                      style={{ minWidth: "150px", backgroundColor: "#D6EAF8" }}
                    >
                      操作
                    </th>
                  </tr>
                </thead>

                {!loader ? (
                  <tbody>
                    {students.map((student, index) => (
                      <tr
                        key={index}
                        className={
                          selectedRowIndex === index ? "selected-row2" : ""
                        }
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => selectHandler(index)}
                        onDoubleClick={() => updatePageHandler(student?.id)}
                      >
                        <td style={{ width: "5%", cursor: "pointer" }}>
                          {student?.member_code}
                        </td>
                        <td>{Nvh({ value: student?.name })}</td>
                        <td>{Nvh({ value: student?.furigana })}</td>
                        <td>{Nvh({ value: student?.gender })}</td>
                        <td>{Nvh({ value: student?.notice })}</td>
                        {/* <td>{Nvh({ value: student?.note })}</td> */}
                        <td>{DateForm({ value: student?.birthday })}</td>
                        <td>{Nvh({ value: student?.phone_no_1 })}</td>

                        {/* Class Info Start */}
                        <td>{Nvh({ value: student?.class_name_1 })}</td>
                        <td>{Nvh({ value: student?.class_times_1 })}</td>
                        <td>
                          {Nvh({
                            value:
                              student?.class_grade_1 === "50"
                                ? "特習"
                                : student?.class_grade_1,
                          })}
                        </td>
                        <td>{Nvh({ value: student?.class_schedule_1 })}</td>
                        <td>{DateForm({ value: student?.leave_date })}</td>
                        <td
                          className="text-center border action-column "
                          style={{
                            minWidth: "150px",
                            backgroundColor: "#D6EAF8",
                          }}
                        >
                          <Link
                            className="btn btn-sm btn-info text-light mx-1"
                            to={`/admin/student/details/${student.id}?action=view&key=tab1`}
                          >
                            <FontAwesomeIcon icon="eye" />
                          </Link>
                          <Link
                            className="btn btn-sm btn-primary mx-1"
                            to={`/admin/student/details/${student.id}?action=update&key=tab1`}
                          >
                            <FontAwesomeIcon icon="edit" />
                          </Link>

                          <button
                            className="btn btn-sm btn-danger mx-1"
                            onClick={() => handleDeleteClick(student.id)}
                          >
                            <FontAwesomeIcon icon="trash" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <Skeleton paragraph={{ row: 12 }} />
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>

      {students && (
        <div className="paginations d-flex mt-3">
          <div className="d-flex mx-auto neomp">
            {/* <Pagination
              activePage={
                paginationBtns.current_page ? paginationBtns.current_page : 0
              }
              itemsCountPerPage={paginationBtns.per_page}
              totalItemsCount={paginationBtns.total}
              onChange={pageNumber => {
                const actualPageNumber = pageNumber;

                advancedSearchHasMembers
                  ? handleAdvanceSearch(actualPageNumber)
                  : selectedKatakana.length > 0
                    ? searchStudentByKatakana_name(
                        selectedKatakana,
                        true,
                        actualPageNumber
                      )
                    : getStudents(pageNumber);
              }}
              pageRangeDisplayed={5}
              itemclassName="page-item"
              linkclassName="page-link"
              firstPageText="First"
              lastPageText="Last"
            /> */}
          </div>
        </div>
      )}
      <BackButton redirectTo="/admin/dashboard" />
    </div>
  );

  const selectHandler = index => {
    if (selectedRowIndex == index) {
      setSelectedRowIndex("");
    } else {
      setSelectedRowIndex(index);
    }
  };

  return (
    <>
      <div>
        <span className="headline">会員一覧 (申し送り事項)</span>
      </div>

      {page20 ? <Page20 /> : listContent}
      <Modal show={showModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>削除の確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>消去してもよろしいですか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            キャンセル
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteStudent(selectedStudentIDForDelete)}
          >
            消去
          </Button>
        </Modal.Footer>
      </Modal>

      {/* advance search modal with advance search button */}
      <Modal size="xl" show={advanceSearchModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>検索条件</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AdvanceSearch
            advanceSearchData={advanceSearch}
            setAdvanceSearchData={setAdvanceSearch}
            advanceSearchStart={handleAdvanceSearch}
            advanceSearchValueChecker={setAdvanceSearchHasValue}
            advanceSearchHasValue={advanceSearchHasValue}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            キャンセル
          </Button>
          <Button onClick={handleAdvanceSearch} variant="success">
            検索
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StudentWithNote;
