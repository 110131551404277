import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ReactDatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import crossIcon from "../../assets/icons/sign-times-svgrepo-com (1).svg";
import BackButton from "../common/Button/BackButton";
import { bottom } from "@popperjs/core";
import ja from "date-fns/locale/ja";
import InputMask from "react-input-mask";

const StudentPayment = () => {
  const backButtonPosition = {
    bottom: "2.8%",
    right: "20%",
  };
  const [stuData, setStuData] = useState({
    id: "",
    name: "",
    course: "",
    fee: "",
  });

  const [startDate, setStartDate] = useState(new Date());
  const [courseName, setCourseName] = useState(null);

  const [show, setShow] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [search, setSearch] = useState(null);
  const [category, setCategory] = useState(null);

  const [students, setStudents] = useState([
    {
      s_id: 1,
      name: "Beetlejuice",
      id: "1988",
      course_name: "Course One",
      payment_status: 1,
    },
    {
      s_id: 2,
      name: "Halim",
      id: "1989",
      course_name: "Course Two",
      payment_status: 0,
    },
    {
      s_id: 3,
      name: "Kawsar",
      id: "1987",
      course_name: "Course Three",
      payment_status: 0,
    },
    {
      s_id: 4,
      name: "Tushar",
      id: "1977",
      course_name: "Course One",
      payment_status: 1,
    },
    {
      s_id: 5,
      name: "Sabbir",
      id: "2045",
      course_name: "Course Three",
      payment_status: 0,
    },
    {
      s_id: 6,
      name: "Rizwan",
      id: "1257",
      course_name: "Course Two",
      payment_status: 1,
    },
    {
      s_id: 7,
      name: "Rabbi",
      id: "1852",
      course_name: "Course Four",
      payment_status: 1,
    },
    {
      s_id: 8,
      name: "Tushar",
      id: "9875",
      course_name: "Course Three",
      payment_status: 0,
    },
    {
      s_id: 9,
      name: "Tashoki",
      id: "9987",
      course_name: "Course Three",
      payment_status: 0,
    },
    {
      s_id: 10,
      name: "Shizuka",
      id: "4523",
      course_name: "Course Three",
      payment_status: 0,
    },
  ]);

  const filteredList = (student, category, search) => {
    return student
      .filter(student => byCategory(student, category))
      .filter(student => bySearch(student, search));
  };

  // serach Functions
  const byCategory = (student, category) => {
    if (category) {
      return student.payment_status == category;
    } else return student;
  };
  const bySearch = (student, search) => {
    if (search) {
      return student.name.toLowerCase().includes(search.toLowerCase());
    } else return student;
  };

  const modalHandler = (id, name, course, fee) => {
    setStuData({
      id: id,
      name: name,
      course: course,
      fee: fee,
    });

    setShow(true);
  };

  const clickHandler = id => {
    setStudents(
      students.map(student => {
        if (student.id === id) {
          student.payment_status = 1;
        }
        return student;
      })
    );
    setShow(false);

    swal("成功", stuData.name + " さんの 受講料を支払いました", "success");
  };

  const paginateHandler = pageNumber => {
    setActivePage(pageNumber);
  };

  return (
    <div style={{ position: "relative" }}>
      {/* write code here for two dropdown and one submit button */}
      <p className="headline">会員の支払いリスト</p>

      <div className="container relative mx-auto row mb-4">
        <div className="col-md-6">
          <h6 className="mb-2">コース名</h6>
          <select
            className="w-100 shadow-sm form-control"
            onChange={e => {
              setCourseName(e.target.value);
            }}
          >
            <option value="">コースを選択</option>
            <option value="コース1">コース1</option>
            <option value="コース2">コース2</option>
            <option value="コース3">コース3</option>
          </select>
        </div>
        <div className="col-md-6">
          <h6 className="mb-2">月</h6>

          <ReactDatePicker
            className="form-control text-center"
            selected={startDate}
            onChange={date => {
              if (date) setStartDate(date);
            }}
            dateFormat="yyyy-MM"
            showMonthYearPicker
            showFullMonthYearPicker
            locale={ja}
          />
        </div>
      </div>

      <div className="card px-2 py-3">
        {/* <DataTable columns={columns} data={data} /> */}

        <div className="row mb-3">
          <div className="col-md-7">
            <h4>生徒一覧</h4>
          </div>

          <div className="col-md-5 row" style={{ paddingRight: "0px" }}>
            <div className="col-md-2 col-xs-12 d-flex align-items-center">
              <p>
                <FontAwesomeIcon icon="search" /> 探す:{" "}
              </p>
              {/* <i className="fa-solid fa-magnifying-glass"></i> */}
            </div>
            <div className="col-md-5 col-6">
              <select
                className="form-control"
                onChange={e => setCategory(e.target.value)}
              >
                <option value="">支払い方法を選択</option>
                <option value="1">支払った</option>
                <option value="0">未払い</option>
              </select>
            </div>
            <div className="col-md-5 col-6" style={{ paddingRight: "0px" }}>
              <input
                className="form-control"
                name="student_name"
                placeholder="名前で検索"
                onChange={e => setSearch(e.target.value)}
              ></input>
            </div>
          </div>
        </div>

        {courseName ? (
          <>
            <Table striped bordered hover responsive="md">
              <thead>
                <tr>
                  <th>#</th>
                  <th>名前</th>
                  <th style={{ width: "8%" }}>会員証</th>
                  <th className="text-center">コース名</th>
                  <th className="text-center">支払い状況</th>
                  <th className="text-center">アクション</th>
                </tr>
              </thead>
              <tbody>
                {filteredList(students, category, search).map(
                  (student, index) => (
                    <tr key={index}>
                      <td>{student.s_id}</td>
                      <td>{student.name}</td>
                      <td style={{ width: "8%" }}>{student.id}</td>
                      <td className="text-center">{courseName}</td>
                      <td className="text-center">
                        {student.payment_status == 1 ? (
                          <FontAwesomeIcon
                            className="text-success"
                            icon="fa-solid fa-check"
                          />
                        ) : (
                          <img src={crossIcon} height="20" width="20" />
                        )}
                      </td>
                      <td>
                        <div className="text-center">
                          {student.payment_status != 1 ? (
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() =>
                                modalHandler(
                                  student.id,
                                  student.name,
                                  courseName,
                                  "4500"
                                )
                              }
                            >
                              支払いを提出する
                            </button>
                          ) : (
                            <button className="btn btn-sm btn-success" disabled>
                              支払いを提出する
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>

            <div className="paginations d-flex">
              <div className="d-flex mx-auto neomp">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={450}
                  pageRangeDisplayed={8}
                  onChange={pageNumber => paginateHandler(pageNumber)}
                />
              </div>
            </div>
          </>
        ) : (
          <h5 className="text-center bg-light py-5">コースを選択</h5>
        )}
      </div>

      <BackButton redirectTo="/admin/dashboard" />

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        top
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/* <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Add New Course
            </Modal.Title>
          </Modal.Header> */}

        <Modal.Body className="text-center">
          <h3>お支払い状況を更新してもよろしいですか？</h3>

          <div className="my-2">
            <h6>
              <strong>名前： </strong> {stuData.name}
            </h6>
            <h6>
              <strong>コース名： </strong> {stuData.course}
            </h6>
            <h6>
              <strong>費用： </strong> {stuData.fee} ¥
            </h6>
          </div>

          <div className="mt-3">
            <button
              className="btn btn-danger mx-1"
              onClick={() => setShow(false)}
            >
              キャンセル
            </button>
            <button
              className="btn btn-success mx-1 px-5"
              onClick={() => clickHandler(stuData.id)}
            >
              確認
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StudentPayment;
