import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import privateApi from "../../services/privateApi";
import Calendar from "react-calendar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "react-calendar/dist/Calendar.css";
import "./transferAttendance.css";
import swal from "sweetalert";
import StudentList from "../coach/students/StudentList";
import AttendanceHistory from "./AttendanceHistory";
import ReactDatePicker from "react-datepicker";
import moment from "moment/moment";
import ja from "date-fns/locale/ja";
import PageLoader from "../common/PageLoader";

const TransferAttendance = () => {
  const [holidays, setHolidays] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedDateStr, setSelectedDateStr] = useState(null);
  const [disableHistory, setDisableHistory] = useState(true);
  const [callHistory, setCallHistory] = useState(false);
  const [info, setInfo] = useState();
  const [isLastMonth, setIsLastMonth] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);

  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [slots, setSlots] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [selectedClassName, setSelectedClassName] = useState("");
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [key2, setKey2] = useState("attendanceSubmit");

  const getHolidays = async () => {
    const response = await privateApi.get(`admin/holidays`);
    if (response.status === 200) {
      setHolidays(response.data.data);
    }
  };

  const fetchStudentAttendance = async (
    date,
    classId,
    courseId,
    startTime,
    endTime
  ) => {
    try {
      setMainLoading(true);
      if (key2 === "attendanceHistory") {
        setMainLoading(false);
        return;
      }
      const attendance_date = moment(date).format("YYYY-MM-DD");
      const response = await privateApi.post("/admin/showAttendance", {
        attendance_date: attendance_date,
        regular_class_id: classId,
        class_level_id: courseId,
        start_time: startTime,
        end_time: endTime,
      });
      if (response.status === 200) {
        setIsLastMonth(response.data?.data?.isLastMonth);
        const studentData = response.data?.data?.attendances || [];

        if (studentData.length) {
          // const studentsWithInitialAttendance = studentData.map(student => ({
          //   ...student,
          //   attendance: false,
          // }));
          // setStudents(studentsWithInitialAttendance);
          setStudents(studentData);

          setMainLoading(false);
        } else {
          setMainLoading(false);
          setStudents([]);
        }
      } else {
        setMainLoading(false);
        console.log("Server Error");
      }
    } catch (error) {
      setMainLoading(false);
      console.error("Error fetching student attendance data:", error);
    }
  };

  const selectDateHandler = date => {
    if (date) {
      setSelectedDate(date);
      refactorSelectedDate();
      setSelectedClass("");
      setSelectedCourse("");
      setSelectedSlot("");
      setSelectedEndTime("");
      setSelectedStartTime("");
      setClasses([]);
      setCourses([]);
      setSlots([]);
    }
  };

  const classChangeHandler = async e => {
    const selectedClassId = e.target.value;
    setSelectedClass(selectedClassId);
    setSelectedClassName(e.target.label);

    try {
      const response = await privateApi.get(
        `admin/class-levels?regular_class_id=${selectedClassId}`
      );
      if (response.status === 200) {
        const courseData = response.data.data;
        setCourses(courseData);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const handleSlotChange = event => {
    const selectedSlotValue = event.target.value;
    setSelectedSlot(selectedSlotValue);

    const [startTime, endTime] = selectedSlotValue.split(" - ");

    setSelectedStartTime(startTime);
    setSelectedEndTime(endTime);
  };

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleConfirmDate = () => {
    setShowConfirmModal(true);
  };

  const handleConfirm = () => {
    swal("振替設定が完了しました", "", "success");
    const updatedHolidays = holidays.map(holiday =>
      holiday.id === selectedHoliday?.id
        ? { ...holiday, date: selectedDateStr }
        : holiday
    );

    setHolidays(updatedHolidays);
    setShowCalendar(false);
    setShowConfirmModal(false);
    // setSelectedHoliday(null);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const refactorSelectedDate = () => {
    const selectedDateCopy = new Date(selectedDate);
    selectedDateCopy.setDate(selectedDateCopy.getDate() + 1);
    setSelectedDateStr(selectedDateCopy.toISOString().split("T")[0]);
  };

  const getClasses = async date => {
    try {
      // refactorSelectedDate();
      const dateForClass = moment(selectedDate).format("YYYY-MM-DD");
      const response = await privateApi.get(
        `admin/get-classes-by-date/${dateForClass}`
      );
      if (response.status === 200) {
        const classData = response.data.data;
        const allSchedules = response?.data?.data.flatMap(
          item => item?.schedules
        );
        setSchedules(allSchedules);
        setClasses(classData);
        // setClasstimes(classData);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const getSlots = async () => {
    try {
      const response = await privateApi.post("admin/class-times", {
        attendance_date: selectedDateStr,
        regular_class_id: selectedClass,
        class_level_id: selectedCourse,
      });
      if (response.status === 200) {
        const classData = response.data.data;
        setSlots(classData);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  useEffect(() => {
    const filtered = schedules
      .filter(
        schedule => schedule?.regular_class_id === parseInt(selectedClass, 10)
      )
      .map(({ start_time, end_time }) => ({ start_time, end_time }));

    // Convert filtered schedules to a Set to remove duplicates based on object content
    const uniqueFiltered = new Set(
      filtered.map(schedule => JSON.stringify(schedule))
    );

    const uniqueFilteredArray = Array.from(uniqueFiltered).map(JSON.parse);
    setSlots(uniqueFilteredArray);
    setFilteredSchedules(uniqueFilteredArray);
  }, [schedules, selectedClass]);

  useEffect(() => {
    getHolidays();
    refactorSelectedDate();
  }, []);

  useEffect(() => {
    refactorSelectedDate();
  }, [selectedDate]);

  useEffect(() => {
    setInfo({
      selectedDate: selectedDate,
      selectedClass: selectedClass,
      selectedCourse: selectedCourse,
      selectedSlot: selectedSlot,
    });
    setDisableHistory(!selectedDate || !selectedCourse || !selectedClass);
    if (selectedDate) {
      setTimeout(() => {
        refactorSelectedDate();
        if (selectedDateStr) {
          getClasses(selectedDateStr);
        }
      }, 100);
    }
    if (selectedClass && selectedCourse) {
      getSlots();
    }
    if (selectedClass && selectedDate) {
      fetchStudentAttendance(
        selectedDateStr,
        selectedClass,
        selectedCourse,
        selectedStartTime,
        selectedEndTime
      );
    }
  }, [selectedDate, selectedClass, selectedCourse, selectedSlot]);

  const UpperPart = () => {
    return (
      <>
        <div
          class="d-flex flex-wrap align-items-stretch p-3"
          style={{ gap: "4px" }}
        >
          <div>
            <ReactDatePicker
              className="w-100 shadow-sm custom_report_select"
              dateFormat="yyyy/MM/dd"
              selected={selectedDate}
              onChange={selectDateHandler}
              todayButton={"Today"}
              locale={ja}
              placeholderText="日付を選択"
            />
          </div>
          <div style={{ width: "100px" }}>
            <select
              name="class_id"
              className="w-100 shadow-sm custom_report_select"
              onChange={classChangeHandler}
              value={selectedClass}
              disabled={!selectedDate}
            >
              <option value="">授業</option>
              {classes?.map((st_class, i) => (
                <option key={i} value={st_class.id}>
                  {st_class.affiliation_name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "160px" }}>
            <select
              className="w-100 shadow-sm custom_report_select"
              onChange={handleSlotChange}
              value={selectedSlot}
              disabled={!selectedDate || !selectedClass}
            >
              <option value="">時間</option>
              {slots &&
                slots.map((slot, i) => (
                  <option key={i} value={slot.id}>
                    {slot?.start_time} - {slot?.end_time}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <select
              className="w-100 shadow-sm custom_report_select"
              onChange={e => setSelectedCourse(e.target.value)}
              value={selectedCourse}
              disabled={!selectedClass}
            >
              <option value="">コースを選択</option>
              {courses.map((course, i) => (
                <option key={i} value={course.id}>
                  {course?.regular_class?.affiliation_name} - {course.in_text}
                </option>
              ))}
            </select>
          </div>

          {/* <div className="" style={{ width: "120px" }}>
            <input
              className="form-control"
              type="text"
              placeholder="会員番号／氏名／フリガナで検索"
              value={search}
              onChange={e => {
                setSearch(e.target.value);
                debouncedSearch(e.target.value);
              }}
              style={{ padding: "3px" }}
            />
          </div> */}

          <div>
            <button
              onClick={() => {
                setSelectedDate(null);
                setSelectedClass("");
                setSelectedCourse("");
                setSelectedSlot("");
              }}
              className="btn btn-clear"
            >
              クリア
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="card col-md-10 col-xl-8 col-sm-12">
      {mainLoading && <PageLoader />}
      <Tabs
        id="controlled-tab-example"
        activeKey={key2}
        onSelect={k => setKey2(k)}
        className="my-1"
      >
        <Tab eventKey="attendanceSubmit" title="出席を提出する">
          <>
            <UpperPart />
            <StudentList
              setDisableHistory={setDisableHistory}
              noTittle={true}
              setInfo={setInfo}
              setKey2={setKey2}
              setCallHistory={setCallHistory}
              setStudents={setStudents}
              selectedClass={selectedClass}
              selectedCourse={selectedCourse}
              selectedDate={selectedDate}
              students={students}
              isLastMonth={isLastMonth}
            />
          </>
        </Tab>
        <Tab
          eventKey="attendanceHistory"
          title="出席歴"
          // disabled={disableHistory}
        >
          <>
            <UpperPart />
            <AttendanceHistory
              callHistory={callHistory}
              info={info}
              key2={key2}
              selectedStartTime={selectedStartTime}
              selectedEndTime={selectedEndTime}
              selectedDate={selectedDateStr}
            />
          </>
        </Tab>
      </Tabs>

      <Modal show={showCalendar} onHide={() => setShowCalendar(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>日付を選択してください</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Calendar onChange={handleDateChange} value={selectedDate} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCalendar(false)}>
            戻る
          </Button>
          <Button variant="primary" onClick={handleConfirmDate}>
            選択する
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>振替設定を行ないますよろしいですか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmModal}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TransferAttendance;
