import { Button } from "antd";
import styled from "styled-components";
import { useUser } from "../../../context/userContext";
import { displayData } from "./constants";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import privateApi from "../../../services/privateApi";
import useModal from "../../../hooks/useModal";
import ChangePassword from "./ChangePassword";
function getDaysAndTimes(input) {
   const result = [];
   for (let i = 1; i <= 5; i++) {
      const startTimeKey = `start_time_${i}`;
      const dayWeekKey = `day_week_${i}`;

      if (input[startTimeKey] !== null && input[dayWeekKey] !== null) {
         result.push({
            time: input[startTimeKey],
            day: input[dayWeekKey]
         });
      }
   }

   return result;
}

const Layout = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	grid-gap: 20px;
	margin: 15px 0;
	@media screen and (max-width: 896px) {
		grid-template-columns: 1fr;
	}
`;

export const Container = styled.div`
	display: flex;
   gap: 20px;
   padding:50px 20px 0 20px;
	width: 100%;
	justify-content: center;
	min-height: 100vh;
   height: 100%;
	@media screen and (max-width: 896px) {
		padding: 0 10px;
	}
`;

export const Mypage = () => {
   const { user } = useUser();
   const [classes, setClasses] = useState([]);
   const history = useHistory();
   const { open, isOpen, close } = useModal();
   const fetchClasses = async () => {
      const res = await privateApi.get("member/classes");
      setClasses(res.data.data.map(d => ({ class: d.regular_class.affiliation_name, rank: d.class ? d.class + "級" : "―", period: d.processed_date, weekdays: getDaysAndTimes(d) })));

   };
   useEffect(() => {
      fetchClasses();
   }, []);
   if (!user) {
      return <></>;
   }
   return (
      <Container>
         <div style={{ width: "100%", margin: "0 auto" }}>
            <Layout>
               <div style={{ background: "#f4f5f6", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "10px", padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                  <h1 style={{ fontSize: "3rem" }}>{user.name}</h1>
                  <h5>{user.mail_address_1}</h5>
                  <h5>{user.phone_no_1}</h5>
                  <h5>{user.address_1}</h5>
                  <CustomButton onClick={() => history.push("/student/re-schedule")} text={"出席狀況"} />
                  <CustomButton onClick={() => history.push("/student/payment")} text={"支払状況"} />
                  <CustomButton onClick={open} text={"パスワード変更"} style={{ background: "linear-gradient(to right, #2193b0, #007bff80", color: "#f4f5f6", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", border: "none" }} />
               </div>
               <div style={{ background: "#f4f5f6", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "10px", padding: "20px", display: "flex", flexDirection: "column" }}>
                  {displayData.map(dd => {
                     if (user[dd.key]) {
                        return <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr", borderBottom: "3px solid #80808012", padding: "8px 0" }}>
                           <div style={{ fontSize: "15px", fontWeight: "600" }}>{dd.label}</div>
                           <div style={{ fontSize: "15px", fontWeight: "400" }}>{user[dd.key]}</div>
                        </div>;
                     } else {
                        return <></>;
                     }
                  })}
               </div>
               <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  {
                     classes.map(c => <div style={{ background: "#f4f5f6", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "10px", padding: "20px", display: "flex", }}>
                        <div style={{ fontSize: "2.0rem", fontWeight: "600", paddingRight: "30px", paddingLeft: "10px", display: "flex", alignItems: "center" }}>
                           {c.rank}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "space-around" }}>
                           <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <span style={{ fontSize: "1.2rem", fontWeight: "400" }}>クラス: </span>
                              <span style={{ fontSize: "1.2rem", }}>{c.class}</span>
                           </div>
                           <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <span style={{ fontSize: "1.2rem", fontWeight: "400" }}>時期: </span>
                              <span style={{ fontSize: "1.2rem", }}>{c.period}</span>
                           </div>
                           <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <span style={{ fontSize: "1.2rem", fontWeight: "400" }}>曜日: </span>
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                 {c.weekdays.map(w => <span>{w.day}-{w.time}</span>)}
                              </div>
                           </div>
                        </div>
                     </div>)
                  }
               </div>
            </Layout>
         </div>
         {isOpen && <ChangePassword close={close} isOpen={isOpen} />}
      </Container>
   );
};

const CustomButton = ({ style, onClick, text }) => {
   return <Button
      size="large"
      onClick={() => {
         if (onClick) {
            onClick();
         }
      }}
      style={{ fontSize: "16px", width: "200px", fontWeight: "bold", marginBottom: "5px", ...style }}>
      {text}
   </Button>;
};
