import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import privateApi from "../../../services/privateApi";
import swal from "sweetalert";
import Pagination from "react-js-pagination";
import "./ShortTermClass.css";
import BackButton from "../../common/Button/BackButton";
import ActionButtonGroup from "../../common/Button/ActionButtonGroup";
import { useMediaQuery } from "react-responsive";
import { Resizable } from 'react-resizable';
import { Table } from 'antd';
import { ConfigProvider } from "antd";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8",
    },
  },
};
const ShortTermClass = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1366 });
  const position = {
    position: "static",
  };
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [selectedshortTermClass, setSelectedshortTermClass] = useState();
  const [Editmodereadonly, setEditmodereadonly] = useState(false);
  const [shortTermClasses, setshortTermClasses] = useState([]);
  const [AddBtnEnbl, SetAddBtnEnbl] = useState(true);
  const [UpdateBtnEnbl, SetUpdateBtnEnbl] = useState(false);
  const [DltBtnEnbl, SetDltBtnEnbl] = useState(false);
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const getClasses = async pageNumber => {
    setLoader(true);
    const response = await privateApi.get(
      `admin/short-term-classes?page=${pageNumber}&per_page=${100}`
    );
    if (response.status === 200) {
      setLoader(false);
      if (response.data.data.length === 0) {
        setHasMore(false);
      } else {
        setshortTermClasses((prevClass) => [
          ...prevClass,
          ...response.data.data,
        ]);
        setPageNumber(pageNumber + 1);
      }
    } else {
      console.log("サーバーの不具合");
    }
  };


  const blankHandler = () => {
    setData({
      id: "",
      opening_year: "",
      opening_season: "",
      class_code: "",
      class_name: "",
    });
    setEditmodereadonly(false);
    setSelectedshortTermClass(null);
    SetAddBtnEnbl(true);
    SetUpdateBtnEnbl(false);
    SetDltBtnEnbl(false);
  };

  useEffect(() => {
    getClasses(1, 10);
  }, []);

  const [dataInput, setData] = useState({
    id: "",
    opening_year: "",
    opening_season: "",
    class_code: "",
    class_name: "",
  });

  const handleInput = (e) => {
    if (Editmodereadonly === false) {
      e.persist();
      const { name, value } = e.target;
      setData({ ...dataInput, [name]: value });
    }
  };
  const handleInput_name = (e) => {
    e.persist();
    const { name, value } = e.target;
    setData({ ...dataInput, [name]: value });
  };

  const handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  const formSubmit = async e => {
    if (Editmodereadonly === false) {
      e.preventDefault();

      const response = await privateApi.post(
        "admin/short-term-classes",
        dataInput
      );

      if (response.status === 200) {
        swal("新しいクラスが追加されました", "", "success");
        setData({
          opening_year: "",
          opening_season: "",
          class_name: "",
        });
        getClasses();
      } else {
        swal("何かエラーが発生しました", "連絡してください", "error");
      }
    } else swal("", "連絡してください", "error");
  };

  const getshortTermClassInfo = async (id) => {
    const response = await privateApi.get("admin/short-term-classes/" + id);

    if (response.status === 200) {
      setData(response.data.data);
    } else {
      console.log("サーバーの不具合");
    }
  };

  const updateHandler = async () => {
    const updatedData = {
      opening_year: dataInput.opening_year,
      opening_season: dataInput.opening_season,
      class_name: dataInput.class_name,
    };

    if (selectedshortTermClass !== "") {
      const response = await privateApi.update(
        `admin/short-term-classes/${selectedshortTermClass}`,
        updatedData
      );

      if (response.status === 200) {
        swal("クラスが更新されました", "", "success");
        setData({
          opening_year: "",
          opening_season: "",
          class_name: "",
        });
        setEditmodereadonly(false);
        getshortTermClassInfo(selectedshortTermClass);
        getClasses();
      } else {
        swal("何か問題が発生しました", "ご連絡ください", "error");
        console.log("サーバーの不具合");
      }
    } else {
      swal("Select any class to update", "", "warning");
    }
  };


  const handleClick = (id) => {
    if (selectedshortTermClass === id) {
      blankHandler();
    } else {
      setSelectedshortTermClass(id);
      getshortTermClassInfo(id);
      setEditmodereadonly(true);
      SetAddBtnEnbl(false);
      SetUpdateBtnEnbl(true);
      SetDltBtnEnbl(true);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const response = await privateApi.delete(
      `admin/short-term-classes/${selectedshortTermClass}`
    );

    if (response.status === 200) {
      swal("クラスが削除されました", "", "success");
      setData({
        opening_year: "",
        opening_season: "",
        class_name: "",
      });
      setEditmodereadonly(false);
      getClasses();
    } else {
      swal("何かエラーが発生しました", "お問い合わせください", "error");
    }
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [columns, setColumns] = useState([
    {
      title: '開校年',
      dataIndex: 'opening_year',
      key: 'opening_year',
      width: 30,
      sorter: (a, b) => {
        const aValue = typeof a.opening_year === 'string' ? a.opening_year : String(a.opening_year);
        const bValue = typeof b.opening_year === 'string' ? b.opening_year : String(b.opening_year);
        return aValue.localeCompare(bValue);
      },
      render: (text, record) => <span>{record.opening_year}</span>,
    },
    {
      title: '時期',
      dataIndex: 'opening_season',
      key: 'opening_season',
      width: 30,
      sorter: (a, b) => a.opening_season.localeCompare(b.opening_season),
      render: (text, record) => (
        <span>
          {record.opening_season === '1' ? '春' : record.opening_season === '2' ? '夏' : record.opening_season === '3' ? '冬' : ''}
        </span>
      ),
    },
    {
      title: '短期クラス',
      dataIndex: 'class_name',
      key: 'class_name',
      width: 100,
      sorter: (a, b) => a.class_name.localeCompare(b.class_name),
      render: (text, record) => <span>{record.class_name}</span>,
    },
  ]);


  const handleScroll = e => {
    const target = e.target;
    const distanceFromBottom =
      target.scrollHeight - target.scrollTop - target.clientHeight;
    if (distanceFromBottom <= 300 && hasMore && !loader) {
      getClasses(pageNumber);
    }
  };

  useEffect(() => {
    const container = document.querySelector(".ant-table-body");

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [pageNumber, hasMore, loader]);


  const handleResize =
    index =>
      (e, { size }) => {
        setColumns(columns => {
          const nextColumns = [...columns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          return nextColumns;
        });
      };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = (record, index) => {
    const key = record.id;
    setSelectedRowKey((prevSelectedRowKey) =>
      prevSelectedRowKey === key ? null : key
    );

    handleClick(key, index);
  };


  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  const content = (
    <div className="card" style={{ width: isMobile ? "100%" : (isTablet || isLaptop) ? "43%" : "30%" }}>
      <div className="card-header">
        <div className="d-flex align-items-center">
          <p className="headline m-0">クラス</p>
          <div className="ml-auto">
            <ActionButtonGroup
              storeHandler={formSubmit}
              deleteClasseHandler={handleDeleteClick}
              updateClasseHandler={updateHandler}
              AddBtnEnbl={AddBtnEnbl}
              DltBtnEnbl={DltBtnEnbl}
              UpdateBtnEnbl={UpdateBtnEnbl}
            />
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="p-1 table-striped table-hover custom-tbl align-middle responsive">
          <div className="row mt-1">
            <div className="">
              <div className="col-14 row mb-2">
                <div className="col-md-4">
                  <p className="">開校年:</p>
                </div>
                <div className="col-md-5">
                  <input
                    type="number"
                    className={`form-control form-control-sm ${Editmodereadonly ? "readonly-text" : ""}`}
                    name="opening_year"
                    onChange={handleInput}
                    value={dataInput.opening_year}
                    placeholder="開校年を入力"
                  />
                </div>
              </div>
              <div className="col-14 row mb-2">
                <div className="col-md-4">時期:</div>
                <Form.Group
                  className="col-md-5"
                  controlId="opening_season"
                >
                  <Form.Select size="sm"
                    value={dataInput.opening_season}
                    style={{
                      backgroundColor: "#afbfff",
                    }}
                    onChange={handleInput}
                    name="opening_season"
                    className="shadow-sm"
                  >
                    <option value="">時期</option>
                    <option value="1">春</option>
                    <option value="2">夏</option>
                    <option value="3">冬</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-14 row">
                <div className="col-md-4">
                  <p className="">クラス:</p>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="class_name"
                    onChange={handleInput_name}
                    value={dataInput.class_name}
                    placeholder="短期クラスを入力"
                  />
                </div>
              </div>
            </div>
            <ConfigProvider theme={customTheme}>
              <Table bordered
                className="table-striped-rows"
                dataSource={shortTermClasses}
                rowKey="id"
                pagination={false}
                columns={resizableColumns}
                rowClassName={rowClassName}
                components={components}
                scroll={{ x: 400, y: "50vh" }}
                onRow={(record, index) => ({
                  onClick: () => handleRowClick(record, index),
                })}
              /></ConfigProvider>

          </div>
        </div>
        <div className="row">
          <div className="col-lg-9 d-flex justify-content-around">
            <div className="paginations">
              <div className="d-flex mx-auto neomp">

              </div>
            </div>
          </div>
          <div className="col-lg-3 d-flex justify-content-end">
            <BackButton redirectTo="/admin/stc/dashboard" position={position} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {content}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
      >
        {selectedshortTermClass ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>削除の確認</Modal.Title>
            </Modal.Header>
            <Modal.Body>削除してもよろしいですか？</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseModal}
              >
                キャンセル
              </Button>
              <Button
                variant="danger"
                onClick={handleConfirmDelete}
              >
                削除
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Modal.Body>
            <h4 className="text-center text-danger py-3">任意のクラスを選択</h4>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

export default ShortTermClass;
