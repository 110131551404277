import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import privateApi from "../../services/privateApi";
import DataTreeView from "./TreeView/TreeView";

const ModulesAndPermissions = () => {
  const [manageModuleModal, setManageModuleModal] = useState(false);
  const [manageTreeModuleModal, setTreeManageModuleModal] = useState(false);
  const [modalSchoolPermission, setmodalSchoolPermission] = useState(false);
  const [schoolPermissions, setSchoolPermissions] = useState([]);
  const [schools, setSchools] = useState([]);
  const [schoolId, setSchoolId] = useState(null);
  const [schoolUpdatedPermissions, setSchoolUpdatedPermissions] = useState([]);
  const [treeModule, setTreeModule] = useState(null);
  const [modules, setModules] = useState([]);
  const [module, setModule] = useState({
    name: "",
    slug: "",
    route: "",
    icon: "",
    order: "",
    parent_id: "",
  });

  useEffect(() => {
    getModules();
    getModuleTree();
  }, []);

  const handleModuleInput = e => {
    const { name, value } = e.target;
    setModule(prevModule => ({
      ...prevModule,
      [name]: value,
    }));
  };

  const getModules = async () => {
    const response = await privateApi.get("superadmin/modules");
    if (response.status === 200) {
      setModules(response.data.data);
    } else {
      //console.log("Server Error");
    }
  };

  const getSchoolPermissions = async id => {
    if (!id) {
      return;
    }
    const response = await privateApi.get(
      "superadmin/modules/permissions/" + id
    );
    if (response.status === 200) {
      setSchoolPermissions(response.data.data);
    }
  };

  const updateSchoolPermissions = async () => {
    swal({
      title: "本当に削除しますか？",
      text: "学校は選択されたモジュールについて許可を取得します。",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async process => {
      if (process) {
        const response = await privateApi.post(
          `superadmin/modules/permissions/${schoolId}`,
          {
            modules: schoolUpdatedPermissions,
          }
        );
        if (response.status === 200) {
          setmodalSchoolPermission(!modalSchoolPermission);
          swal("削除しました！", "プロセスが完了しました", "success");
        } else {
          swal(response.data.message, "", "error");
        }
      } else {
        swal("キャンセルしました！", "プロセスが開始されませんでした。");
      }
    });
  };

  const getModuleTree = async () => {
    const response = await privateApi.get("superadmin/tree/modules");
    if (response.status === 200) {
      setTreeModule(response.data.data);
    }
  };

  const getSchools = async () => {
    const response = await privateApi.get("superadmin/schools");
    if (response.status === 200) {
      setSchools(response.data.data);
    }
  };

  const updateStatus = async data => {
    const module = {
      id: data.id,
      name: data.name,
      slug: data.slug,
      status: data.status === "1" ? 0 : 1,
    };

    const response = await privateApi.update(
      `superadmin/modules/${data.id}`,
      module
    );
    if (response.status === 200) {
      swal("作成しました！", "モジュールが変更されました!", "success");
      getModules();
      getModuleTree();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const deleteModule = async id => {
    swal({
      title: "本当に削除しますか？",
      text: "一度削除すると、元に戻すことはできません。",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        const response = await privateApi.delete(`superadmin/modules/${id}`);
        if (response.status === 200) {
          swal("削除しました！", "スーパー管理者が削除されました！", "success");
          await getModules();
          await getModuleTree();
        } else {
          swal(response.data.message, "", "error");
        }
      } else {
        swal("キャンセルしました！", "モジュールは削除されていません。");
      }
    });
  };

  const addNewModule = async () => {
    const response = await privateApi.post("superadmin/modules", module);
    if (response.status === 200) {
      swal("作成しました！", "新しいモジュールが作成されました。", "success");
      setManageModuleModal(!manageModuleModal);
      getModules();
      setModule({});
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const updateModule = async id => {
    const response = await privateApi.update(
      `superadmin/modules/${id}`,
      module
    );
    if (response.status === 200) {
      swal("作成しました！", "モジュールが変更されました!", "success");
      setManageModuleModal(!manageModuleModal);
      getModules();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const createOrUpdateModule = async (id = null) => {
    if (id) {
      await updateModule(id);
    } else {
      await addNewModule();
    }
    await getModuleTree();
  };

  const handleNewModule = () => {
    setModule({});
    setManageModuleModal(!manageModuleModal);
  };

  const handlemodalSchoolPermission = () => {
    setSchoolPermissions([]);
    getSchools();
    setmodalSchoolPermission(!modalSchoolPermission);
  };

  const handleModuleEdit = data => {
    const module = {
      id: data.id,
      name: data.name,
      slug: data.slug,
      route: data.route,
      icon: data.icon,
      order: data.order,
      parent_id: data.parent?.id ? data.parent.id : null,
    };
    setModule(module);
    setManageModuleModal(!manageModuleModal);
  };

  const handleSchoolChange = e => {
    const { value } = e.target;
    setSchoolId(value);
    getSchoolPermissions(value);
  };

  const handleUpdatedPermissionData = updatedData => {
    const ids = updatedData.map(data => data.id);
    setSchoolUpdatedPermissions(ids);
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <p className="headline">モジュールと権限</p>
        </div>
        <div className="col-6 d-flex align-items-center">
          <button
            className="btn btn-sm btn-primary px-2 ml-auto"
            variant="primary"
            onClick={() => setTreeManageModuleModal(true)}
          >
            {" "}
            <i className="fas fa-bars fa-fw"></i>
            モジュールツリービュー
          </button>

          <button
            className="btn btn-sm btn-primary px-2 ml-auto"
            variant="primary"
            onClick={() => handlemodalSchoolPermission()}
          >
            {" "}
            <i className="fas fa-shield fa-fw"></i>
            学校モジュールの権限
          </button>

          <button
            className="btn btn-sm btn-primary px-3 ml-auto"
            variant="primary"
            onClick={() => handleNewModule()}
          >
            {" "}
            <i className="fas fa-plus fa-fw"></i>
            新しく追加する
          </button>
        </div>
      </div>

      <div>
        {modules && modules.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>名前</th>
                <th>ナメクジ</th>
                <th>ルート(url)</th>
                <th className="text-center">アイコン</th>
                <th className="text-center">親</th>
                <th className="text-center">状態</th>
                <th className="text-center">アクション</th>
              </tr>
            </thead>
            <tbody>
              {modules.map((module, index) => (
                <tr key={index}>
                  <td>{module.name}</td>
                  <td>{module.slug}</td>
                  <td>{module.route}</td>
                  <td>{module.icon}</td>
                  <td>{module.parent?.name}</td>

                  <td className="text-center">
                    {module.status === "1" ? (
                      <Form.Check
                        className="d-flex justify-content-center"
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={true}
                        onClick={() => updateStatus(module)}
                        onChange={e => {}}
                      />
                    ) : (
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={false}
                        onClick={() => updateStatus(module)}
                        className="d-flex justify-content-center"
                        onChange={e => {}}
                      />
                    )}
                  </td>

                  <td className="text-center">
                    <button
                      className="btn btn-sm btn-primary mx-1"
                      onClick={() => handleModuleEdit(module)}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </button>
                    <button
                      className="btn btn-sm btn-danger mx-1"
                      disabled={true}
                      onClick={() => deleteModule(module.id)}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        <Modal
          show={manageModuleModal}
          onHide={() => setManageModuleModal(false)}
          size="xl"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {module.id
                ? "モジュールを編集する"
                : "新しいモジュールを追加する"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    名前 <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="会員管理"
                    name="name"
                    value={module.name}
                    onChange={handleModuleInput}
                  />
                </Form.Group>

                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>
                    ナメクジ <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="membership_management"
                    name="slug"
                    value={module.slug}
                    onChange={handleModuleInput}
                  />
                </Form.Group>

                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>ルート(url)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="admin/student"
                    name="route"
                    value={module.route}
                    onChange={handleModuleInput}
                  />
                </Form.Group>

                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>アイコン</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="fas fa-user"
                    name="icon"
                    value={module.icon}
                    onChange={handleModuleInput}
                  />
                </Form.Group>

                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>親</Form.Label>
                  <Form.Select
                    name="parent_id"
                    value={module.parent_id}
                    onChange={handleModuleInput}
                  >
                    <option value="">親を選択してください</option>
                    {modules.map(module => (
                      <option key={module.id} value={module.id}>
                        {module.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="col-md-6 mb-3">
                  <Form.Label>注文</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="注文番号を入力してください"
                    name="order"
                    value={module?.order}
                    onChange={handleModuleInput}
                  />
                </Form.Group>
              </div>

              <Button
                className="float-right px-5"
                variant="primary"
                // type="submit"
                onClick={() =>
                  createOrUpdateModule(module.id ? module.id : null)
                }
                style={{ float: "right" }}
              >
                保存
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <Modal
        show={manageTreeModuleModal}
        onHide={() => setTreeManageModuleModal(false)}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>モジュールツリービュー</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-4 mr-5">
            <div className="row float-left">
              <Form.Group
                className="col-md-12 mb-3"
                style={{ height: "400px", overflowY: "auto" }}
              >
                {treeModule && (
                  <DataTreeView
                    key={treeModule ? treeModule.length : 0}
                    treeData={treeModule}
                    showCheckbox={false}
                  />
                )}
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalSchoolPermission}
        onHide={() => setmodalSchoolPermission(false)}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>学校のモジュール権限を設定する</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row float-left">
              <Form.Group className="col-md-12 mb-3">
                <Form.Label>
                  学校 <span className="required">*</span>
                </Form.Label>
                <Form.Select name="school_id" onChange={handleSchoolChange}>
                  <option value=""> 学校を選択してください</option>
                  {schools.map(school => (
                    <option key={school.id} value={school.id}>
                      {school.school_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group
                className="col-md-12 mb-3"
                style={{ height: "400px", overflowY: "auto" }}
              >
                {schoolPermissions && (
                  <DataTreeView
                    key={schoolPermissions ? schoolPermissions.length : 0}
                    treeData={schoolPermissions}
                    showCheckbox={true}
                    onUpdateData={handleUpdatedPermissionData}
                  />
                )}
              </Form.Group>
            </div>

            <Button
              className="float-right px-5"
              variant="primary"
              // type="submit"
              onClick={() => updateSchoolPermissions()}
              style={{ float: "right" }}
            >
              保存
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModulesAndPermissions;
