import { getData } from "../services/auth/token";

export const checkModulePermission = (module, permission = "") => {

    const checkPermissions = (module, permission = "") => {
        if (!module) {
            return false;
        }
        if (permission) {
            return module.permissions ? module.permissions[permission] : false;
        } else {
            const { create, read, update, delete: deletePermission } = module?.permissions || {};
            const isAnyPermissionSet = create || read || update || deletePermission;
            return isAnyPermissionSet;
        }
    };

    const hasPermissions = checkPermissions(module, permission);

    return hasPermissions;
};

export const checkModulePermissionBySlug = (slug, permission = "") => {
    const modules = convertModulePermissions(getData()?.modules);
    if (!modules) {
        return false;
    }

    const module = modules.find(module => module.slug === slug);
    if (!module) {
        return false;
    }

    const hasPermissions = checkModulePermission(module, permission);

    return hasPermissions;

};

const convertModulePermissions = (modules) => {

    const transformedPermissions = modules.reduce((acc, module) => { 
      acc.push(module);
  
      if (module.children && module.children.length > 0) {
        module.children.forEach((child) => {
          acc.push(child);
        });
      }
  
      return acc;
    }, []);

    return transformedPermissions;

  };