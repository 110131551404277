import React, { useState } from "react";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router-dom";
import AuthService from "../../../../services/auth/AuthService";
import ButtonLoader from "../../../common/ButtonLoader/ButtonLoader";
import useSchoolData from "../../../../hooks/useSchool";

function LoginNext() {
  const history = useHistory();
  const { schoolSlug } = useParams();

  const school = useSchoolData(schoolSlug);

  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
    error_list: [],
  });

  const [loading, setLoading] = useState(false);

  const inputBlank = () => {
    setLogin({ ...loginInput, password: "" });
    setLoading(false);
  };

  const handleInput = e => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = async e => {
    e.preventDefault();

    if (loginInput.email !== "" && loginInput.password !== "") {
      setLoading(true);
    }

    const data = {
      email: loginInput.email,
      password: loginInput.password,
      school_id: school.id,
    };

    const response = await AuthService.login("member", data);

    if (response.status === 200) {
      history.push("/student/dashboard");
    } else if (response.status === 422) {
      setLoading(false);
    } else if (response.status === 401) {
      inputBlank();
      swal(response.data.message, "", "warning");
    } else {
      inputBlank();
      swal(response.data.message, "", "warning");
    }
  };

  return (
    <div>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow rounded">
              <div className="card-header text-center">
                <h4>会員ログイン</h4>
              </div>
              <div className="card-body ">
                <form onSubmit={loginSubmit}>
                  <div className="form-group mb-3 mt-3">
                    <label>電子メールID</label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleInput}
                      value={loginInput.email}
                      className="form-control"
                    />
                    <span>{loginInput.error_list.email}</span>
                  </div>

                  <div className="form-group mb-3">
                    <label>パスワード</label>
                    <input
                      type="password"
                      name="password"
                      onChange={handleInput}
                      value={loginInput.password}
                      className="form-control"
                    />
                    <span>{loginInput.error_list.password}</span>
                  </div>

                  <div className={`form-group mb-3 text-center`}>
                    <button type="submit" className="btn w-100 btn-theme">
                      {loading ? <ButtonLoader /> : <span>ログインする</span>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginNext;
