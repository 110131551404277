import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";

import "react-calendar/dist/Calendar.css";
import swal from "sweetalert";
import Select from "react-select";

import ReactDatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";
import privateApi from "../../../services/privateApi";
import ReactSelect from "react-select";
import { useParams } from "react-router-dom";
import changeDateFormat from "../../utilis/ChangeDateFormat";
import { useMediaQuery } from "react-responsive";
import { getCurrentMonth } from "../../../services/auth/token";
import Nvh from "../../common/Nvh";
import PageLoader from "../../common/PageLoader";

const RankHistory = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [selectedDate, setSelectedDate] = useState(null);
  const [newSelectedDate, setNewSelectedDate] = useState(new Date());
  const [selectedDateStr, setSelectedDateStr] = useState(null);
  const [newSelectedDateStr, setNewSelectedDateStr] = useState(null);
  const [selectedStudentInfo, setSelectedStudentInfo] = useState(null);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedNewClass, setSelectedNewClass] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  const [selectedWeekday, setSelectedWeekday] = useState("");
  const [newSelectedRange, setNewSelectedRange] = useState("");
  const [newComment, setNewComment] = useState("");
  const [studentslist, setStudentslist] = useState([]);
  const [Student, setStudent] = useState({});
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpdateAllModal, setShowUpdateAllModal] = useState(false);
  const [selectedUpdateMember, setSelectedUpdateMember] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [classSchedule, setClassSchedule] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [currentClassRank, setCurrentClassRank] = useState("");
  const [processingMonth, setProcessingMonth] = useState("翌月");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [noDataText, setNoDataText] = useState("");
  const [selectedNewRank, setSelectedNewRank] = useState("");
  const [selectedCurrentRank, setSelectedCurrentRank] = useState("");
  const [selectedPrevRank, setSelectedPrevRank] = useState("");
  const [startTime, setStartTime] = useState(false);
  const prevRankRef = useRef(null);
  const currentRankRef = useRef(null);
  const newRankRef = useRef(null);

  const [tab, setTab] = useState(1);

  const { id } = useParams();

  const handleCheckboxChange = student => {
    const memberId = student.member_id;
    const existingIndex = selectedStudents.findIndex(
      selected => selected.memberId === memberId
    );

    if (existingIndex !== -1) {
      setSelectedStudents([
        ...selectedStudents.slice(0, existingIndex),
        ...selectedStudents.slice(existingIndex + 1),
      ]);
    } else {
      // Add the student if not selected
      setSelectedStudents([
        ...selectedStudents,
        {
          memberId,
          class: student.class,
          date: currentMonth,
          regular_class_id: selectedClass?.value,
        },
      ]);
    }
  };

  // Function to handle select/deselect all
  const selectAllStudents = () => {
    if (selectedStudents.length === students.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(
        students.map(student => ({
          memberId: student.member_id,
          class: student.class,
          date: currentMonth,
          regular_class_id: selectedClass?.value,
        }))
      );
    }
  };
  const rangeOptions = [...Array(25).keys()].map(i => i + 1);
  // rangeOptions.unshift("特習");
  const weekDays = ["すべて 日", "日", "月", "火", "水", "木", "金", "土"];

  const getClasses = async e => {
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const updateClass = async () => {
    setIsLoading(true);
    try {
      // Prepare the payload with all class information
      let payload = {
        member_id: selectedOption?.value,
        classes: {
          current_class: {
            member_class: Student?.current_class || {},
            rank: selectedCurrentRank,
            processed_date: formattedCurrentMon.replace("/", "-"),
          },
          previous_class: {
            member_class: Student?.previous_class || {},
            rank: selectedPrevRank,
            processed_date: formattedPrevMonth.replace("/", "-"),
          },
          next_class: {
            member_class: Student?.next_class || {},
            rank: selectedNewRank,
            processed_date: formattedNextMonth.replace("/", "-"),
          },
        },
      };

      // Make the API call
      const response = await privateApi.post(
        "admin/promotions-member",
        payload
      );

      if (response.status === 200) {
        swal("保存されました。", "級が更新されました。", "success");
        // Reset states and close modals
        setIsLoading(false);
        setSelectedNewRank("");
        setSelectedCurrentRank("");
        setSelectedPrevRank("");
        setShowUpdateModal(false);
        setShowUpdateAllModal(false);
        getStudentInfo(selectedOption?.value);
      } else {
        setShowUpdateModal(false);
        setSelectedNewRank("");
        setSelectedCurrentRank("");
        setSelectedPrevRank("");
        setShowUpdateAllModal(false);
        setIsLoading(false);
        swal(response.data.message, "", "error");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      swal("エラーが発生しました。", "級の更新に失敗しました。", "error");
    }
  };

  const updateSelected = async () => {
    setIsLoading(true);
    try {
      const response = await privateApi.post("admin/submit-bulk-rank", {
        selectedStudents: selectedStudents,
      });
      if (response.status === 200) {
        swal("保存されました。", "級が更新されました。", "success");
        setSelectedStudents([]);
        setIsLoading(false);
        setShowUpdateAllModal(false);
        fetchStudents();
      } else {
        setShowUpdateAllModal(false);
        setIsLoading(false);
        swal(response.data.message, "", "error");
      }
    } catch (error) {
      // setShowStudentModal(false);
    }
  };

  const fetchStudents = async () => {
    try {
      setIsLoading(true);
      const response = await privateApi.post("/admin/membersByRank", {
        date: selectedDateStr,
        day_week: selectedWeekday.value === 0 ? "" : selectedWeekday.label,
        regular_class_id: selectedClass?.value,
        class: selectedRange?.value,
        start_time: selectedSchedule?.start_time,
        end_time: selectedSchedule?.end_time,
      });
      if (response.status === 200) {
        const studentData = response.data?.data;
        setStudents(studentData);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching student attendance data:", error);
    }
  };

  const selectDateHandler = date => {
    if (date) {
      setSelectedDate(date);
      refactorSelectedDate(date);
    }
  };

  const refactorSelectedDate = date => {
    if (date) {
      const selectedDateCopy = new Date(date);
      selectedDateCopy.setDate(selectedDateCopy.getDate() + 1);
      setSelectedDateStr(
        selectedDateCopy?.toISOString().split("T")[0].slice(0, 7)
      );
    }
  };

  const refactorNewSelectedDate = () => {
    const selectedDateCopy = new Date(newSelectedDate);
    selectedDateCopy.setDate(selectedDateCopy.getDate() + 1);
    setNewSelectedDateStr(selectedDateCopy.toISOString().split("T")[0]);
  };

  const newSelectDateHandler = date => {
    if (date) {
      setNewSelectedDate(date);
      refactorNewSelectedDate();
    }
  };

  const fetchStudentInfo = async studentId => {
    try {
      const response = await privateApi.get(
        `admin/promotions-member?member_id=${studentId}`
      );
      if (response.status === 200) {
        setSelectedStudentInfo(response?.data);
        setNewSelectedRange({
          value: response.data?.data?.all_classes[0]?.latest_rank,
          label: `${response.data.data?.all_classes[0]?.latest_rank} ${
            response.data.data?.all_classes[0]?.latest_rank === "特習"
              ? ""
              : "級"
          }`,
        });
        setShowStudentModal(true);
        setSelectedNewClass("");
        setNewSelectedDate(new Date());
        setNewSelectedRange("");
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching student information:", error);
    }
  };

  const getScheduleInfo = async id => {
    const response = await privateApi.get(
      `/admin/class-schedules/${selectedClass?.value}`
    );

    if (response.status === 200) {
      if (response.data.data && response.data.data.classSchedule) {
        console.log("class schedule", response.data.data.classSchedule);
        setClassSchedule(response.data.data.classSchedule);
        // setSelectedClass(response.data.data.regular_class_id);
      } else {
        setClassSchedule([]);
      }
    } else {
      console.log("Server Error");
    }
  };

  useEffect(() => {
    const focusAndSelectInput = () => {
      if (processingMonth === "前月" && prevRankRef.current) {
        prevRankRef.current.focus();
        prevRankRef.current.select();
      } else if (processingMonth === "当月" && currentRankRef.current) {
        currentRankRef.current.focus();
        currentRankRef.current.select();
      } else if (processingMonth === "翌月" && newRankRef.current) {
        newRankRef.current.focus();
        newRankRef.current.select();
      }
    };

    focusAndSelectInput();
  }, [processingMonth]);

  useEffect(() => {
    setCurrentMonth(window?.localStorage.getItem("current_month"));
    getClasses();
  }, []);

  useEffect(() => {
    if (selectedNewClass) {
      Student?.all_classes
        ? setCurrentClassRank(
            Student.all_classes.find(
              cls => cls.regular_class_id === selectedNewClass?.value
            )?.latest_rank
          )
        : setCurrentClassRank(
            selectedStudentInfo?.all_classes.find(
              cls => cls.regular_class_id === selectedNewClass?.value
            )?.latest_rank
          );
    }
  }, [selectedNewClass]);

  useEffect(() => {
    refactorSelectedDate();
  }, [selectedDate]);

  useEffect(() => {
    if (selectedClass) {
      fetchStudents();
    }
  }, [
    selectedClass,
    selectedRange,
    selectedDate,
    selectedWeekday,
    selectedSchedule,
  ]);

  useEffect(() => {
    // getHolidays();
    refactorSelectedDate();
  }, []);

  useEffect(() => {
    getScheduleInfo();
  }, [selectedClass]);

  useEffect(() => {
    refactorSelectedDate(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    refactorNewSelectedDate();
    newSelectDateHandler();
  }, [newSelectedDate]);

  // single member

  const getStudentInfo = async id => {
    setStartTime(true);
    const response = await privateApi.get(
      `admin/promotions-member?member_id=${id}`
    );

    if (response.status === 200) {
      if (processingMonth === "翌月" && newRankRef.current) {
        newRankRef.current.focus();
        newRankRef.current.select();
      }
      if (response?.data?.data?.results.length === 0) {
        setNoDataText("クラスデータを挿入してください。");
      } else {
        setNoDataText("");
      }
      setSelectedNewRank(response?.data?.next_class?.class);
      setSelectedCurrentRank(response?.data?.current_class?.class);
      setSelectedPrevRank(response?.data?.previous_class?.class);
      setStudent(response?.data);
      setNewSelectedRange({
        value: response.data?.data?.all_classes[0]?.latest_rank,
        label: `${response.data.data?.all_classes[0]?.latest_rank} ${
          response.data.data?.all_classes[0]?.latest_rank === "特習" ? "" : "級"
        }`,
      });
      setStartTime(false);
    } else {
      setStudent({});
      console.log("Server Error");
      setStartTime(false);
    }
  };

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    getStudentInfo(selectedOption.value);
  };

  const getStudentslist = async () => {
    const response = await privateApi.get(
      `admin/get-members-list?type=Regular`
    );
    if (response.status === 200) {
      setStudentslist(response.data.data);
    } else {
      console.log("Server Error!");
    }
  };

  const updateRankHandler = async () => {
    try {
      const response = await privateApi.post(`admin/submitRankResult`, {
        date: newSelectedDateStr,
        member_id:
          selectedOption?.value ||
          selectedUpdateMember ||
          Student?.results[0]?.member?.id,
        regular_class_id: selectedNewClass?.value,
        class: newSelectedRange?.value,
        comment: newComment,
      });
      if (response.status === 200) {
        swal("保存されました。", "級が更新されました。", "success");
        // setShowStudentModal(false);
        getStudentInfo(selectedOption?.value || selectedStudentInfo.id);
        setShowUpdateModal(false);
        setNewComment("");
        setSelectedClass("");
        setSelectedNewClass("");
        setNewSelectedDate(new Date());
      } else {
        // setShowStudentModal(false);
        swal(response.data.message, "", "error");
        setShowUpdateModal(false);
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching student attendance data:", error);
      setShowUpdateModal(false);
    }
  };

  const students_Id_Name = studentslist.map(student => ({
    value: student.id,
    label: student.member_code + "-" + student.name + "-" + student.furigana,
  }));

  // Assuming getCurrentMonth() returns a Date object representing the current month
  const currentMon = getCurrentMonth();
  const nextMonth = new Date(
    currentMon.getFullYear(),
    currentMon.getMonth() + 1,
    1
  );
  const prevMonth = new Date(
    currentMon.getFullYear(),
    currentMon.getMonth() - 1,
    1
  );

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns month from 0 to 11
    return `${year}/${month}`;
  }

  const formattedCurrentMon = formatDate(currentMon);
  const formattedNextMonth = formatDate(nextMonth);
  const formattedPrevMonth = formatDate(prevMonth);

  useEffect(() => {
    getStudentslist();
  }, []);

  useEffect(() => {
    if (selectedOption) {
      getStudentInfo(selectedOption?.value);
    }
  }, [selectedOption]);

  useEffect(() => {
    setSelectedSchedule("");
  }, [selectedWeekday]);

  useEffect(() => {
    if (id && students_Id_Name) {
      const selectedFromParam = students_Id_Name.find(
        student => student.value.toString() === id
      );
      selectedFromParam && setSelectedOption(selectedFromParam);
    }
  }, [studentslist, id]);

  const allOption = opt => {
    return { label: `すべて ${opt}`, value: " " };
  };

  // Modify the options array for the first Select dropdown
  const modifiedScheduleOptions = [
    allOption("時間"),
    ...classSchedule
      .flatMap(option =>
        option.classes.flatMap(schedule => {
          if (
            option.day === selectedWeekday.label &&
            schedule.start_time !== "" &&
            schedule.end_time !== ""
          ) {
            return {
              start_time: schedule.start_time,
              end_time: schedule.end_time,
              value: `${schedule.start_time}-${schedule.end_time}`, // Use time slot as value
              label: `${schedule.start_time}-${schedule.end_time}`,
            };
          }
          return [];
        })
      )
      .filter((v, i, a) => a.findIndex(t => t.value === v.value) === i), // Filter out duplicates based on value
  ];

  // Modify the options array for the second Select dropdown
  const modifiedRangeOptions = rangeOptions.map(option => ({
    value: option === "特習" ? 0 : option,
    label: `${option} ${option === "特習" ? "" : "級"}`,
  }));

  const modifiedWeekdayOptions = [
    weekDays.map((option, index) => ({
      value: index,
      label: `${option}`,
    })),
  ];

  const bgActiveStyle = {
    backgroundColor: "#d4dfee",
  };

  const UpperPart = () => {
    return (
      <>
        <div
          class="d-flex flex-wrap align-items-stretch py-3"
          style={{ gap: "4px" }}
        >
          <div style={{ width: "100px" }}>
            <Select
              defaultValue={selectedClass}
              onChange={setSelectedClass}
              options={classes.map(option => ({
                value: option.id,
                label: `${option.affiliation_name}`,
              }))}
              placeholder="授業"
            />
          </div>

          <div style={{ width: "140px" }}>
            <Select
              value={selectedWeekday}
              onChange={setSelectedWeekday}
              options={modifiedWeekdayOptions[0]}
              placeholder="日"
              isDisabled={!selectedClass}
            />
          </div>

          <div style={{ width: "150px" }}>
            <Select
              defaultValue={selectedSchedule}
              onChange={setSelectedSchedule}
              options={modifiedScheduleOptions}
              placeholder="時間"
              isDisabled={!selectedClass || !selectedWeekday}
            />
          </div>
          <div style={{ width: "120px" }}>
            <Select
              defaultValue={selectedRange}
              onChange={setSelectedRange}
              options={[allOption("級"), ...modifiedRangeOptions]}
              placeholder="級"
              isDisabled={!selectedClass}
            />
          </div>
          <div>
            <ReactDatePicker
              className="form-control h-100"
              dateFormat="yyyy/MM"
              selected={selectedDate}
              onChange={selectDateHandler}
              todayButton={"Today"}
              locale={ja}
              placeholderText="日付を選択"
              readOnly={!selectedClass}
              showMonthYearPicker={true}
            />
          </div>
          <div>
            <button
              onClick={() => {
                setSelectedDate("");
                setSelectedRange("");
                setSelectedSchedule("");
                setSelectedWeekday("");
              }}
              className="btn btn-primary"
            >
              クリア
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {startTime && <PageLoader />}
      <div
        className="custom-scaling row"
        style={{
          fontSize: "medium",
        }}
      >
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="">
                  <span>
                    <button
                      onClick={() => setTab(1)}
                      className={`btn px-3 mr-4 ${
                        tab === 1 ? "btn-primary" : "btn-outline-primary"
                      }`}
                    >
                      生徒を選択
                    </button>
                  </span>
                  <span>
                    {/* <button
                    onClick={() => setTab(2)}
                    className={`btn px-3 mr-4 ${
                      tab === 2 ? "btn-primary" : "btn-outline-primary"
                    }`}
                  >
                    会員一覧
                  </button> */}
                  </span>
                </div>
              </div>
            </div>

            <div className="card-boady">
              {tab === 1 ? (
                <div>
                  <div className="">
                    <div className="p-2 col-md-12 d-flex">
                      <div className="d-flex align-items-center mb-2 col-md-4">
                        <span>処理区分：</span>
                        <div className="p-2 border">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="validationFormCheck1"
                              name="next-month"
                              value="前月"
                              onChange={e => setProcessingMonth(e.target.value)}
                              checked={processingMonth === "前月"}
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="validationFormCheck1"
                            >
                              前月
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="validationFormCheck2"
                              name="current-month"
                              value="当月"
                              onChange={e => setProcessingMonth(e.target.value)}
                              checked={processingMonth === "当月"}
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="validationFormCheck2"
                            >
                              当月
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="validationFormCheck3"
                              name="next-month"
                              value="翌月"
                              onChange={e => setProcessingMonth(e.target.value)}
                              checked={processingMonth === "翌月"}
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="validationFormCheck3"
                            >
                              翌月
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex col-md-12 align-items-center">
                        <div className="w-auto">
                          <span>会具番号：</span>
                        </div>
                        <div style={{ width: "345px" }}>
                          <ReactSelect
                            placeholder="生徒を選択"
                            options={students_Id_Name}
                            onChange={handleChange}
                            value={selectedOption}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12 d-flex">
                  <div className="col-md-4">
                    <div>
                      <span>ttt</span>
                      <input type="text" readOnly value="" />
                    </div>
                    <div>
                      <span>tt2</span>
                      <input type="text" readOnly value="" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <span>ttt</span>
                      <input type="text" readOnly value="" />
                    </div>
                    <div>
                      <span>tt2</span>
                      <input type="text" readOnly value="" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <span>ttt</span>
                      <input type="text" readOnly value="" />
                    </div>
                    <div>
                      <span>tt2</span>
                      <input type="text" readOnly value="" />
                    </div>
                  </div>
                  <div className="col-md3">
                    <div>
                      <span>ttt</span>
                      <input type="text" readOnly value="" />
                    </div>
                    <div>
                      <span>tt2</span>
                      <input type="text" readOnly value="" />
                    </div>
                  </div>
                </div> */}
                  <div className="row p-2 border m-2">
                    <div className="col-md-6">
                      <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label text-sm-end">
                          氏名:
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            // placeholder={!readOnly ? "電話番号1" : ""}
                            name=""
                            value={Student?.name}
                            disabled
                          />
                          <div className="invalid-feedback"></div>
                        </div>

                        <label className="col-sm-2 col-form-label text-sm-end">
                          性別:
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="text"
                            className="form-control"
                            // placeholder={!readOnly ? "電話備考1" : ""}
                            name="gender"
                            value={Student?.gender}
                            // onChange={handleInput}
                            // value={Nvh({ value: newStudents.phone_note_1 })}
                            // readOnly={readOnly}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-sm-3 col-form-label text-sm-end">
                          フリガナ:
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            // placeholder={!readOnly ? "電話番号1" : ""}
                            name="furigana"
                            // onChange={handleInput}
                            // value={Nvh({ value: newStudents.phone_no_1 })}
                            // className={`form-control ${
                            //   errorList?.phone_no_1 ? "is-invalid" : ""
                            // }`}
                            value={Student?.furigana}
                            // value={"test"}
                            disabled
                          />
                          <div className="invalid-feedback"></div>
                        </div>

                        <label className="col-sm-2 col-form-label text-sm-end">
                          年齢:
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="text"
                            className="form-control"
                            style={{ fontSize: "12px" }}
                            // placeholder={!readOnly ? "電話備考1" : ""}
                            name="phone_note_1"
                            value={Student?.birthday}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-2 row">
                        <label className="col-sm-2 col-form-label text-sm-end">
                          退校日:
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            name="dismissal day"
                            value={Student?.leave_date}
                            disabled
                          />
                          <div className="invalid-feedback"></div>
                        </div>

                        <label className="col-sm-3 col-form-label text-sm-end">
                          料金種別:
                        </label>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            value={Student?.charge_type_code}
                            // placeholder={!readOnly ? "電話備考1" : ""}
                            name="phone_note_1"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="form-group mb-2 row">
                        <label className="col-sm-2 col-form-label text-sm-end">
                          入会日:
                        </label>
                        <div className="col-sm-4">
                          <input
                            type="text"
                            // placeholder={!readOnly ? "電話番号1" : ""}
                            name="join date"
                            value={Student?.joining_date}
                            disabled
                          />
                          <div className="invalid-feedback"></div>
                        </div>

                        <label className="col-sm-3 col-form-label text-sm-end">
                          納付区分:
                        </label>
                        <div className="col-sm-3">
                          <input
                            type="text"
                            className="form-control"
                            // placeholder={!readOnly ? "電話備考1" : ""}
                            name="phone_note_1"
                            // onChange={handleInput}
                            // value={Nvh({ value: newStudents.phone_note_1 })}
                            // readOnly={readOnly}
                            value={Student?.payment_type_code}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 w-100 row">
                      <div className="form-group mb-2 row w-100">
                        <label
                          style={{ width: "13%" }}
                          className="col-sm-2 col-form-label text-sm-end"
                        >
                          備考:
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="w-100"
                            type="text"
                            // placeholder={!readOnly ? "電話番号1" : ""}
                            name="phone_no_1"
                            // onChange={handleInput}
                            // value={Nvh({ value: newStudents.phone_no_1 })}
                            // className={`form-control ${
                            //   errorList?.phone_no_1 ? "is-invalid" : ""
                            // }`}
                            value={Student.note}
                            required
                            disabled
                          />
                          <div className="invalid-feedback"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {Student ? (
                    <div className="px-2 pb-2">
                      {Student?.current_class && (
                        <>
                          <div className="promotion-submit-container col-md-12">
                            <div
                              className={`${processingMonth === "前月" ? "row m-2 shadow rounded p-2" : "row m-2 p-2"}`}
                              style={
                                processingMonth === "前月" ? bgActiveStyle : {}
                              }
                            >
                              <div className="col-md-2 d-flex align-items-start flex-column">
                                <p>前月:</p>
                                <p>{formattedPrevMonth}</p>
                              </div>
                              <div className="col-md-10">
                                <div
                                  style={{ maxWidth: "120px" }}
                                  className="current-rank"
                                >
                                  <input
                                    className="w-50 text-center"
                                    type="text"
                                    disabled
                                    value={""}
                                  />
                                </div>
                                <div className="right-bottom-container col-md-12 mt-2 border p-1">
                                  <div className="d-flex justify-content-start">
                                    <div className="col-md-6 p-2">
                                      <div className="form-group mb-2 row">
                                        <label className="col-sm-2 col-form-label text-sm-end">
                                          所属:
                                        </label>
                                        <div className="col-sm-4">
                                          <input
                                            type="text"
                                            name="phone_no_1"
                                            value={
                                              Student?.current_class
                                                ?.previous_class
                                                ?.affiliation_name
                                            }
                                            required
                                            disabled
                                          />
                                          <div className="invalid-feedback"></div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-2 row">
                                        <label className="col-sm-2 col-form-label text-sm-end">
                                          曜日時間:
                                        </label>
                                        <div className="col-sm-4">
                                          <input
                                            type="text"
                                            name="phone_no_1"
                                            value={
                                              (Student?.previous_class
                                                ?.day_week_1
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_1,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_1,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_1,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_2
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_2,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_2,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_2,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_3
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_3,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_3,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_3,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_4
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_4,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_4,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_4,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_5
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_5,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_5,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_5,
                                                  }) +
                                                  ")"
                                                : "")
                                            }
                                            disabled
                                          />
                                          <div className="invalid-feedback"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6 p-2">
                                      <span>回数：</span>
                                      <input
                                        type="text"
                                        className="w-25 mr-5"
                                        value={Student?.previous_class?.times}
                                        disabled
                                      />
                                      <span>級：</span>
                                      <input
                                        type="text"
                                        className="w-25"
                                        onChange={e =>
                                          setSelectedPrevRank(e.target.value)
                                        }
                                        value={selectedPrevRank}
                                        disabled={processingMonth !== "前月"}
                                        ref={prevRankRef}
                                      />
                                      <span>（50：特習）</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`${processingMonth === "当月" ? "row m-2 shadow rounded p-2" : "row m-2 p-2"}`}
                              style={
                                processingMonth === "当月" ? bgActiveStyle : {}
                              }
                            >
                              <div className="col-md-2 d-flex align-items-start flex-column">
                                <p>当月:</p>
                                <p>{formattedCurrentMon}</p>
                              </div>
                              <div className="col-md-10">
                                <div
                                  style={{ maxWidth: "120px" }}
                                  className="current-rank"
                                >
                                  <input
                                    className="w-50 text-center"
                                    type="text"
                                    disabled
                                    value={""}
                                  />
                                </div>
                                <div className="right-bottom-container col-md-12 mt-2 border p-1">
                                  <div className="d-flex justify-content-start">
                                    <div className="col-md-6 p-2">
                                      <div className="form-group mb-2 row">
                                        <label className="col-sm-2 col-form-label text-sm-end">
                                          所属:
                                        </label>
                                        <div className="col-sm-4">
                                          <input
                                            type="text"
                                            // placeholder={!readOnly ? "電話番号1" : ""}
                                            name="phone_no_1"
                                            value={
                                              Student?.current_class
                                                ?.regular_class
                                                ?.affiliation_name
                                            }
                                            disabled
                                          />
                                          <div className="invalid-feedback"></div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-2 row">
                                        <label className="col-sm-2 col-form-label text-sm-end">
                                          曜日時間:
                                        </label>
                                        <div className="col-sm-4">
                                          <input
                                            type="text"
                                            // placeholder={!readOnly ? "電話番号1" : ""}
                                            name="phone_no_1"
                                            value={
                                              (Student?.current_class
                                                ?.day_week_1
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_1,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_1,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_1,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_2
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_2,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_2,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_2,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_3
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_3,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_3,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_3,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_4
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_4,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_4,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_4,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_5
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_5,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_5,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_5,
                                                  }) +
                                                  ")"
                                                : "")
                                            }
                                            required
                                            disabled
                                          />
                                          <div className="invalid-feedback"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6 p-2">
                                      <span>回数：</span>
                                      <input
                                        type="text"
                                        className="w-25 mr-5"
                                        value={Student?.current_class?.times}
                                        disabled
                                      />
                                      <span>級：</span>
                                      <input
                                        type="text"
                                        className="w-25"
                                        onChange={e =>
                                          setSelectedCurrentRank(e.target.value)
                                        }
                                        value={selectedCurrentRank}
                                        disabled={processingMonth !== "当月"}
                                        ref={currentRankRef}
                                      />
                                      <span>（50：特習）</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`${processingMonth === "翌月" ? "row m-2 shadow rounded p-2" : "row m-2 p-2"}`}
                              style={
                                processingMonth === "翌月" ? bgActiveStyle : {}
                              }
                            >
                              <div className="col-md-2 d-flex align-items-start flex-column">
                                <p>翌月:</p>
                                <p>{formattedNextMonth}</p>
                              </div>
                              <div className="col-md-10">
                                <div
                                  style={{ maxWidth: "120px" }}
                                  className="current-rank"
                                >
                                  <input
                                    className="w-50 text-center"
                                    type="text"
                                    disabled
                                    value={""}
                                  />
                                </div>
                                <div className="right-bottom-container col-md-12 mt-2 border p-1">
                                  <div className="d-flex justify-content-start">
                                    <div className="col-md-6 p-2">
                                      <div className="form-group mb-2 row">
                                        <label className="col-sm-2 col-form-label text-sm-end">
                                          所属:
                                        </label>
                                        <div className="col-sm-4">
                                          <input
                                            type="text"
                                            name="phone_no_1"
                                            value={
                                              Student?.next_class?.regular_class
                                                ?.affiliation_name
                                            }
                                            disabled
                                          />
                                          <div className="invalid-feedback"></div>
                                        </div>
                                      </div>
                                      <div className="form-group mb-2 row">
                                        <label className="col-sm-2 col-form-label text-sm-end">
                                          曜日時間:
                                        </label>
                                        <div className="col-sm-4">
                                          <input
                                            type="text"
                                            name="phone_no_1"
                                            value={
                                              (Student?.next_class?.day_week_1
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_1,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_1,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_1,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_2
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_2,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_2,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_2,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_3
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_3,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_3,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_3,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_4
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_4,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_4,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_4,
                                                  }) +
                                                  ") "
                                                : "") +
                                              (Student?.current_class
                                                ?.day_week_5
                                                ? Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.day_week_5,
                                                  }) +
                                                  "(" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.start_time_5,
                                                  }) +
                                                  "-" +
                                                  Nvh({
                                                    value:
                                                      Student?.current_class
                                                        ?.end_time_5,
                                                  }) +
                                                  ")"
                                                : "")
                                            }
                                            disabled
                                          />
                                          <div className="invalid-feedback"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6 p-2">
                                      <span>回数：</span>
                                      <input
                                        type="text"
                                        className="w-25 mr-5"
                                        value={Student?.next_class?.times}
                                        disabled
                                      />
                                      <span>級：</span>
                                      <input
                                        type="text"
                                        className="w-25"
                                        value={selectedNewRank}
                                        onChange={e =>
                                          setSelectedNewRank(e.target.value)
                                        }
                                        disabled={processingMonth !== "翌月"}
                                        ref={newRankRef}
                                      />
                                      <span>（50：特習）</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex justify-content-end mt-2">
                            <Button
                              className="float-right col-md-2 mr-5"
                              variant="primary"
                              onClick={() => setShowUpdateModal(true)}
                              // type="submit"
                              style={{ float: "right" }}
                            >
                              保存
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      className="d-flex align-items-center justify-content-center bg-light p-3"
                      style={{ minHeight: "400px" }}
                    >
                      <h5 className="text-center">
                        {isLoading ? "読み込み中..." : noDataText}
                      </h5>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="col-12">
                    <UpperPart />
                  </div>
                  <div className="left">
                    {isLoading && (
                      <div
                        className="d-flex align-items-center justify-content-center bg-light p-3"
                        style={{ minHeight: "400px" }}
                      >
                        <h5 className="text-center">読み込み中...</h5>
                      </div>
                    )}

                    {!isLoading && students?.length > 0 && (
                      <div
                        style={{ maxHeight: "500px" }}
                        className="py-1 overflow-scroll"
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              {/* <th style={{ width: "2%" }}>No</th> */}
                              <th style={{ width: "5%" }}>会員番号</th>
                              <th style={{ width: "16%" }}>氏名</th>
                              <th style={{ width: "2%" }}>級</th>
                              <th style={{ width: "20%" }}>前回の注意点</th>
                              <th style={{ width: "20%" }}>今回の注意点</th>
                              <th
                                style={{ width: "6%" }}
                                className="text-center"
                              >
                                <label htmlFor="">進級</label> <br />
                                <input
                                  type="checkbox"
                                  onChange={selectAllStudents}
                                  checked={
                                    selectedStudents.length === students.length
                                  }
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody style={{ maxHeight: "500px" }} className="">
                            {students.map(
                              (student, index) =>
                                student.class && (
                                  <tr
                                    key={index}
                                    onDoubleClick={() => {
                                      setSelectedUpdateMember(
                                        student.member?.id
                                      );
                                      fetchStudentInfo(student.member.id);
                                    }}
                                  >
                                    {/* <td
                                    style={{ width: "2%" }}

                                    // onClick={() => toggleAttendance(student.id)}
                                  >
                                    {" "}
                                    {index + 1}
                                  </td> */}
                                    <td
                                      style={{ width: "10%" }}
                                      // onClick={() => toggleAttendance(student.id)}
                                    >
                                      {student?.member?.member_code}
                                    </td>
                                    <td
                                      style={{ width: "10%" }}
                                      // onClick={() => toggleAttendance(student.id)}
                                    >
                                      {student?.member?.name}
                                    </td>
                                    <td
                                      style={{ width: "2%" }}
                                      // onClick={() => toggleAttendance(student.id)}
                                    >
                                      {student?.class}
                                    </td>
                                    <td style={{ width: "20%" }}>
                                      {student?.result?.comment}
                                    </td>
                                    <td style={{ width: "20%" }}></td>
                                    <td
                                      style={{ width: "5%" }}
                                      className="text-center"
                                    >
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          handleCheckboxChange(student)
                                        }
                                        checked={selectedStudents.some(
                                          selected =>
                                            selected.memberId ===
                                            student.member_id
                                        )}
                                      />
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                    {selectedStudents.length > 0 && (
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={() => setShowUpdateAllModal(true)}
                          className="btn btn-primary"
                        >
                          提出する
                        </button>
                      </div>
                    )}

                    {!isLoading && selectedClass && students?.length < 1 && (
                      <div
                        className="d-flex align-items-center justify-content-center bg-light p-3"
                        style={{ minHeight: "400px" }}
                      >
                        <h5 className="text-center">
                          対象のデータはありません。
                        </h5>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>

            {/* modal for selected rank update  */}
            <Modal
              size="sm"
              show={showUpdateModal}
              onHide={() => setShowUpdateModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>更新してもよろしいですか?</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => updateClass()}>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="sm"
              show={showUpdateAllModal}
              onHide={() => setShowUpdateAllModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>更新してもよろしいですか? </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => updateSelected()}>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
            {/* modal for select student  */}
            <Modal
              size="lg"
              show={showStudentModal}
              onHide={() => {
                setSelectedNewClass("");
                setNewSelectedDate(new Date());
                setNewSelectedRange("");
                setShowStudentModal(false);
              }}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>進級履歴</Modal.Title>
              </Modal.Header>
              <Modal.Body className="w-100">
                {selectedStudentInfo?.results.length > 0 ? (
                  <div className="card px-2 py-3">
                    <Card
                      bg="primary"
                      style={{ width: "80%" }}
                      className="mb-2 col-md-12 col-sm-12 text-white"
                    >
                      <Card.Header></Card.Header>
                      <Card.Body>
                        <div className="d-flex row">
                          <Card.Title className="mr-2 d-flex">
                            <div className="col-md-4 d-flex">
                              <div className="">
                                {
                                  selectedStudentInfo?.results[0]?.member
                                    ?.member_code
                                }
                              </div>
                              <div>
                                {selectedStudentInfo?.results[0]?.member?.name}
                              </div>
                            </div>
                            <div>
                              {/* <Badge className="ml-18" bg="info">
                              {currentClassRank || newSelectedRange.label}級
                            </Badge> */}
                            </div>
                          </Card.Title>
                        </div>
                        <div className="row mb-2 upper-container">
                          <div className="col-md-2">
                            <ReactDatePicker
                              className="shadow-sm custom_report_select"
                              dateFormat="yyyy/MM/dd"
                              selected={newSelectedDate}
                              onChange={newSelectDateHandler}
                              todayButton={"Today"}
                              locale={ja}
                              placeholderText="処理日を選択してください"
                            />
                          </div>
                          <div
                            className=""
                            style={{ width: "130px", marginTop: "-4px" }}
                          >
                            <Select
                              defaultValue={selectedNewClass}
                              onChange={setSelectedNewClass}
                              options={selectedStudentInfo?.all_classes.map(
                                option => ({
                                  value: option.regular_class_id,
                                  label: `${option.class_name}`,
                                })
                              )}
                              placeholder="授業"
                              className="text-black form-select-sm"
                            />
                          </div>
                          <div className="" style={{ width: "130px" }}>
                            <Select
                              defaultValue={newSelectedRange}
                              onChange={setNewSelectedRange}
                              className="text-black"
                              options={rangeOptions.map(option => ({
                                value: option,
                                label: `${option} ${
                                  option === "特習" ? "" : "級"
                                }`,
                              }))}
                              placeholder="新しい級"
                            />
                          </div>
                          <div className="col-md-4">
                            <input
                              className="w-100"
                              type="text"
                              placeholder="コメント"
                              style={{ minHeight: "38px" }}
                              onChange={e => setNewComment(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-2 upper-container  justify-content-end mt-2">
                          <div className="col-md-3">
                            <button
                              onClick={() => setShowUpdateModal(true)}
                              type="submit"
                              className="btn btn-success"
                            >
                              登録する
                            </button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>

                    <div
                      style={{
                        height: isMobile ? "150px" : "350px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                      className="table-responsive col-md-10"
                    >
                      <Table striped bordered hover className="mt-4 text-left">
                        <thead>
                          <tr>
                            <th>日付</th>
                            <th style={{ width: "15%" }}>クラス</th>
                            <th>現在の級</th>
                            <th>前の級</th>
                            <th>コメント</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedStudentInfo?.results?.map((std, i) => (
                            <tr key={i}>
                              <td>{changeDateFormat(std?.date)}</td>
                              <td>{std?.regular_class?.affiliation_name}</td>
                              <td>{std?.current_rank}</td>
                              <td>{std?.previous_rank}</td>
                              <td style={{ width: "50%" }}>{std?.comment}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ) : (
                  <h5 className="text-center bg-light py-5">
                    結果が見つかりません
                  </h5>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setSelectedNewClass("");
                    setNewSelectedDate(new Date());
                    setNewSelectedRange("");
                    setShowStudentModal(false);
                  }}
                >
                  戻る
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default RankHistory;
