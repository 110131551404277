import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import ActionButtonGroup from "../../common/Button/ActionButtonGroup";
import { Resizable } from "react-resizable";
import { Table } from "antd";
import { ConfigProvider, Button as AntButton } from "antd";
import Select from "react-select";
import "react-resizable/css/styles.css";
import privateApi from "../../../services/privateApi";
import Nvh from "../../common/Nvh";
import ReactDatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";
import moment from "moment";
import { getCurrentMonth } from "../../../services/auth/token";
import { formatDate } from "../../../utils/formatdate";
import RidingListDetails from "./RidingListDetails";

function filterObjectsByProperties(array, filter) {
  return array.filter(obj =>
    Object.keys(filter).every(
      key =>
        filter[key] === undefined ||
        (obj.hasOwnProperty(key) && obj[key] === filter[key].value)
    )
  );
}

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8",
    },
  },
};
const RidingList = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [classes, setClasses] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [selectedClass, setSelectedClass] = useState("");
  const [selectedWeekday, setSelectedWeekday] = useState("");

  const [AddBtnEnbl, SetAddBtnEnbl] = useState(true);
  const [UpdateBtnEnbl, SetUpdateBtnEnbl] = useState(false);
  const [DltBtnEnbl, SetDltBtnEnbl] = useState(false);

  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1366 });
  const [isCopying, setIsCopying] = useState(false);

  const [startDate, setStartDate] = useState(getCurrentMonth());
  const [time, setTime] = useState();
  const [busName, setBusName] = useState();
  const [filter, setFilter] = useState();
  const [ridingList, setRidingList] = useState([]);
  const [filteredRidingList, setFilteredRidingList] = useState([]);
  const [selectedRidingId, setSelectedRidingId] = useState();
  const initialEndTo = getCurrentMonth();
  const [endTo, setEndTo] = useState(initialEndTo);
  const weekDays = ["すべて", "日", "月", "火", "水", "木", "金", "土"];
  const getClasses = async () => {
    setLoading(true);
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
    setLoading(false);
  };

  const deleteClasseHandler = async () => {
    if (selectedRidingId) {
      swal({
        title: "本当に削除しますか？",
        text: "一度削除すると、このデータを復元することはできません。",
        icon: "warning",
        buttons: {
          cancel: "キャンセル",
          confirm: "消去",
        },
        dangerMode: true,
      }).then(async willDelete => {
        if (willDelete) {
          const response = await privateApi.delete(
            `admin/bus-times/${selectedRidingId}`
          );
          if (response.status === 200) {
            swal(response.data.message, "", "success");
            blankHandler();
            getRidingList(startDate);
          } else {
            console.log("Server Error");
          }
        } else {
          swal({
            text: "あなたのデータは安全です!",
            buttons: {
              ok: "わかりました",
            },
          });
        }
      });
    } else {
      swal("Select any row to remove", "", "warning");
    }
  };

  const updateClasseHandler = async () => {
    const data = {
      processed_date: startDate && moment(startDate).format("YYYY-MM"),
      class_name: selectedClass.label,
      day_week: selectedWeekday.label,
      time: time,
      bus_name: busName,
    };

    const response = await privateApi.update(
      `/admin/bus-times/${selectedRidingId}`,
      data
    );

    if (response.status === 200) {
      swal(response.data.message, "", "success");
      blankHandler();
      getRidingList(startDate);
    } else {
      swal(response.data.message, "", "error");
    }
  };

  useEffect(() => {
    blankHandler();
    getRidingList(startDate);
    getClasses();
  }, [startDate]);

  const blankHandler = () => {
    setSelectedRowIndex("");
    setSelectedRowIndex(null);
    setSelectedClass();
    setSelectedWeekday();
    setTime("--:--");
    setBusName("");
    SetAddBtnEnbl(true);
    SetUpdateBtnEnbl(false);
    SetDltBtnEnbl(false);
  };

  const [columns, setColumns] = useState([
    {
      title: "no",
      dataIndex: "id",
      key: "id",
      width: 20,
      // sorter: (a, b) => a.id.localeCompare(b.id),
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "バスコース",
      dataIndex: "bus_name",
      key: "bus_name",
      width: 40,
      sorter: (a, b) => a.bus_name.localeCompare(b.bus_name),
      render: (text, record) => <span>{Nvh({ value: text })}</span>,
    },
    {
      title: "クラス",
      dataIndex: "class_name",
      key: "class_name",
      width: 40,
      sorter: (a, b) => a.class_name.localeCompare(b.class_name),
      render: (text, record) => <span>{Nvh({ value: text })}</span>,
    },
    {
      title: "曜日",
      dataIndex: "day_week",
      key: "day_week",
      width: 40,
      sorter: (a, b) => a.day_week.localeCompare(b.day_week),
      render: (text, record) => <span>{Nvh({ value: text })}</span>,
    },
    {
      title: "時間",
      dataIndex: "time",
      key: "time",
      width: 50,
      sorter: (a, b) => a.time.localeCompare(b.time),
      render: (text, record) => <span>{Nvh({ value: text })}</span>,
    },

    {
      title: "更新時間",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 50,
      sorter: (a, b) => {
        const dateA = +new Date(a.updated_at);
        const dateB = +new Date(b.updated_at);
        return dateA - dateB;
      },
      render: text => <span>{formatDate(new Date(Nvh({ value: text })))}</span>,
    },
  ]);

  const handleResize =
    index =>
    (e, { size }) => {
      setColumns(columns => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const submitHandler = async () => {
    const data = {
      processed_date: startDate && moment(startDate).format("YYYY-MM"),
      class_name: selectedClass.label,
      day_week: selectedWeekday.label,
      time: time,
      bus_name: busName,
    };
    const response = await privateApi.post("/admin/bus-times", data);
    if (response.status === 200) {
      swal(response.data.message, "", "success");
      blankHandler();
      getRidingList(startDate);
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const getRidingList = async date => {
    const date_params = date && moment(date).format("YYYY-MM");

    const response = await privateApi.get(
      `/admin/bus-times?processed_date=${date_params}&per_page=1000`
    );

    if (response.status === 200) {
      setRidingList(
        response.data.data.sort((a, b) => a.bus_name.localeCompare(b.bus_name))
      );
      setFilteredRidingList(
        response.data.data.sort((a, b) => a.bus_name.localeCompare(b.bus_name))
      );
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const selectHandler = (record, index) => {
    if (selectedRowIndex === index) {
      blankHandler();
    } else {
      SetAddBtnEnbl(false);
      SetUpdateBtnEnbl(true);
      SetDltBtnEnbl(true);
      setSelectedRowIndex(index);

      setSelectedRidingId(record.id);
      setBusName(record.bus_name);
      setTime({
        value: record.time,
        label: record.time,
      });
      setSelectedClass({
        value: record.class_name,
        label: record.class_name,
      });
      setSelectedWeekday({
        value: record.day_week,
        label: record.day_week,
      });
    }
  };

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = (record, index) => {
    const key = record.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );
  };

  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  const weekDayOption = weekDays.map((option, index) => ({
    value: option,
    label: option,
  }));

  const classOption = classes.map((option, index) => ({
    value: option.affiliation_name,
    label: option.affiliation_name,
  }));
  const timeoption = new Array(24)
    .fill(0)
    .map((_, index) => `${index.toString().padStart(2, "0")}:40`)
    .map(h => ({ value: h, label: h }));
  const handleConfirmCopy = () => {
    setShowConfirmationModal(true);
  };

  const handleCopy = async () => {
    setIsCopying(true);
    try {
      const fromMonth =
        startDate.getFullYear() +
        "-" +
        ("0" + (startDate.getMonth() + 1)).slice(-2);
      const toMonth =
        endTo.getFullYear() + "-" + ("0" + (endTo.getMonth() + 1)).slice(-2);

      const response = await privateApi.post("admin/bus-monthly-generate", {
        from_month: fromMonth,
        to_month: toMonth,
      });

      if (response.status === 200) {
        setIsCopying(false);
        setShowConfirmationModal(false);
        swal("新しいバスが追加されました", "", "success");
      } else {
        swal("何かエラーが発生しました", "", "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsCopying(false);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelCopy = () => {
    setShowConfirmationModal(false);
  };

  const content = (
    <div className="card">
      <div className="card-header d-flex align-items-center">
        <div className="form-group col-md-3 row align-items-center">
          <label htmlFor="staticEmail" className="col-md-4 col-form-label">
            年月:
          </label>
          <div className="col-md-7 mx-0" style={{ zIndex: "999" }}>
            <ReactDatePicker
              className="form-control form-control-sm"
              dateFormat="yyyy/MM"
              selected={startDate}
              onChange={date => {
                if (date) {
                  setStartDate(date);
                }
              }}
              todayButton={"Today"}
              locale={ja}
              placeholderText="日付を選択"
              showMonthYearPicker={true}
            />
          </div>
        </div>

        <div className="form-group col-md-3 row align-items-center">
          <div className="col-md-12 mx-0 d-flex align-items-center">
            <div className="btn-group">
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  setStartDate(prevDate => {
                    const newDate = new Date(prevDate);
                    newDate.setMonth(newDate.getMonth() - 1);
                    return newDate;
                  });
                }}
              >
                <i className="fas fa-arrow-left fa-fw"></i>
              </button>

              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  setStartDate(prevDate => {
                    const newDate = new Date(prevDate);
                    newDate.setMonth(newDate.getMonth() + 1);
                    return newDate;
                  });
                }}
              >
                <i className="fas fa-arrow-right fa-fw"></i>
              </button>
            </div>

            <div className="col-md-6 ms-3" style={{ zIndex: "999" }}>
              <ReactDatePicker
                className="form-control form-control-sm"
                selected={endTo}
                onChange={date => {
                  if (date) {
                    setEndTo(date);
                  }
                }}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                locale={ja}
                style={{ fontSize: "0.8rem", width: "50px" }}
              />
            </div>
          </div>
        </div>

        <div className="col-md-1 ms-1">
          {" "}
          <button
            className="btn btn-sm btn-success mx-1"
            onClick={() => {
              setShowConfirmationModal(true);
            }}
          >
            {" "}
            複製
          </button>
        </div>

        <span></span>

        <div className="ml-auto">
          <ActionButtonGroup
            storeHandler={submitHandler}
            deleteClasseHandler={deleteClasseHandler}
            updateClasseHandler={updateClasseHandler}
            AddBtnEnbl={AddBtnEnbl}
            DltBtnEnbl={DltBtnEnbl}
            UpdateBtnEnbl={UpdateBtnEnbl}
          />
        </div>
      </div>

      <div className="card-body d-flex">
        <div
          class="d-flex flex-wrap align-items-stretch pt-3"
          style={{ gap: "5px" }}
        >
          <div className="highIndex" style={{ width: "100px" }}>
            <Select
              value={selectedClass}
              onChange={setSelectedClass}
              options={classOption}
              placeholder="授業"
            />
          </div>

          <div className="highIndex" style={{ width: "100px" }}>
            <Select
              value={selectedWeekday}
              onChange={setSelectedWeekday}
              options={weekDayOption}
              placeholder="曜日"
            />
          </div>

          <div style={{ width: "140px", zIndex: 10 }}>
            <Select
              value={time}
              onChange={e => setTime(e)}
              options={timeoption}
              placeholder="時間"
            />
            {/* <input
              className="form-control"
              type="time"
              value={time}
              onChange={e => setTime(e.target.value)}
            /> */}
          </div>

          <div style={{ width: "130px" }}>
            <input
              name="bus_coach"
              className="form-control"
              placeholder="バスコース"
              value={busName}
              onChange={e => {
                setBusName(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="p-3">
        <AntButton
          onClick={() => {
            setFilter(p => (p ? undefined : {}));
            setFilteredRidingList(ridingList);
          }}
          style={{ width: "150px" }}
        >
          絞り込み
        </AntButton>
        {filter && (
          <div
            className="d-flex flex-wrap align-items-stretch pt-3 pb-1"
            style={{ gap: "2px" }}
          >
            <div style={{ width: "125px", zIndex: 2, marginLeft: "45px" }}>
              <Select
                value={filter.bus_name}
                onChange={e => {
                  setFilteredRidingList(
                    filterObjectsByProperties(ridingList, {
                      ...filter,
                      bus_name: e,
                    })
                  );
                  setFilter(p => ({ ...p, bus_name: e }));
                }}
                options={Array.from(
                  new Set(ridingList.map(rl => rl.bus_name))
                ).map(bn => ({ label: bn, value: bn }))}
                placeholder="コース"
              />
            </div>
            <div style={{ width: "125px", zIndex: 2 }}>
              <Select
                value={filter.class_name}
                onChange={e => {
                  setFilter(p => ({ ...p, class_name: e }));
                  setFilteredRidingList(
                    filterObjectsByProperties(ridingList, {
                      ...filter,
                      class_name: e,
                    })
                  );
                }}
                options={classOption}
                placeholder="クラス"
              />
            </div>

            <div style={{ width: "125px", zIndex: 2 }}>
              <Select
                value={filter.day_week}
                onChange={e => {
                  setFilteredRidingList(
                    filterObjectsByProperties(ridingList, {
                      ...filter,
                      day_week: e,
                    })
                  );
                  setFilter(p => ({ ...p, day_week: e }));
                }}
                options={weekDayOption}
                placeholder="曜日"
              />
            </div>
            <div style={{ width: "145px", zIndex: 2 }}>
              <Select
                value={filter.time}
                onChange={e => {
                  setFilteredRidingList(
                    filterObjectsByProperties(ridingList, {
                      ...filter,
                      time: e,
                    })
                  );
                  setFilter(p => ({ ...p, time: e }));
                }}
                options={timeoption}
                placeholder="時間"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {!showDetails ? (
        <div
          style={{
            width: isMobile ? "100%" : isTablet || isLaptop ? "80%" : "50%",
          }}
        >
          <div className="card-body">
            <div style={{ position: "relative" }}>
              <div className="body">{content}</div>
              <div className="mt-1">
                <ConfigProvider theme={customTheme}>
                  <Table
                    // key={tableKey}
                    className="table-striped-rows"
                    bordered
                    dataSource={filteredRidingList}
                    columns={resizableColumns}
                    scroll={{ y: "65vh" }}
                    components={components}
                    loading={loading}
                    rowKey="id"
                    pagination={false}
                    rowClassName={rowClassName}
                    onRow={record => ({
                      onClick: () => {
                        const index = ridingList.findIndex(
                          level => level.id === record.id
                        );
                        handleRowClick(record, index);
                        selectHandler(record, index);
                      },
                      onDoubleClick: () => {
                        // history.push(`/admin/bus/riding-list/${record.id}`);
                        setSelectedId(record.id);
                        setShowDetails(true);
                      },
                    })}
                    locale={{ emptyText: "この条件でデーターがありません。" }}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
          <Modal show={showConfirmationModal} onHide={handleCancelCopy}>
            <Modal.Header closeButton>
              <Modal.Title>複製の確認</Modal.Title>
            </Modal.Header>
            <Modal.Body>複製してもよろしいですか？</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCancelCopy}>
                キャンセル
              </Button>
              <Button
                variant="success"
                onClick={handleCopy}
                disabled={isCopying}
              >
                複製
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <RidingListDetails id={selectedId} setShowDetails={setShowDetails} />
      )}
    </>
  );
};

export default RidingList;
