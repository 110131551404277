import React from "react";
import { Link } from "react-router-dom";

import "../masterMaintenance/extraPageStyles.css";

const busUserDashboardButtons = [
  {
    name: "バス停一覧",
    path: "/admin/bus/bus-list",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "バスの時刻表",
    path: "/admin/bus/output-bus",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "乗車名簿",
    path: "/admin/bus/riding-list",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "時刻表",
    path: "/admin/bus/schedule",
    icon: "fas fa-tachometer-alt",
  },
];

function BusDashboard() {
  return (
    <div className="p-3">
      <p className="headline">バス管理機能一覧</p>
      <div className="btn-container col-md-4 col-sm-12">
        {busUserDashboardButtons.map(button => (
          <Link to={button.path}>
            <button className="dash-btn">{button.name}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BusDashboard;
