export const displayData = [
   {
      key: "member_code",
      label: "会員番号"
   },
   {
      key: "name",
      label: "名前"
   },
   {
      key: "furigana",
      label: "フリガナ"
   },
   {
      key: "gender",
      label: "性別"
   },
   {
      key: "guardian_name",
      label: "保護者名"
   },
   {
      key: "guardian_phone",
      label: "保護者電話番号"
   },
   {
      key: "birthday",
      label: "生年月日"
   },
   {
      key: "joining_date",
      label: "入会日"
   },
   {
      key: "payment_type_code",
      label: "納付区分"
   },
];
