import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledButton = styled.button`
  display: flex;
  line-height: 1em;
  background: white ;
  border-width: 2px;
  border-image-source: linear-gradient(144deg,  #6484ff, #00DDEB);
  border-image-slice: 1;
  border-radius: 8px;
  transition: all 1s ease-in-out;
  &:active,
  &:hover {
    background-image: linear-gradient(144deg,  #6484ff, #00DDEB); /* Adjusted gradient colors */
  }
  span {
    padding: 10px 24px;
    width: 100%;
  }
  &:hover span {
   color: white;
   background: none;
  }
  min-width: 250px;
  @media (max-width: 1025px) {
    min-width: 150px;
    font-size: 24px;
  }
  @media (max-width: 768px) {
    min-width: 250px;
  }
`;

const Button = ({ to, children }) => {
  return (
    <Link to={to} className="text-decoration-none">
      <StyledButton>
        <span>{children}</span>
      </StyledButton>
    </Link>
  );
};

export default Button;
