import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";

import "./coachDashboard.css";
import privateApi from "../../services/privateApi";

function Profile() {
  const [showData, setShowData] = useState(false);
  const [userID, setUserID] = useState("");
  const [studentName, setStudentName] = useState("");
  const [selectedClass, setSelectedClass] = useState();
  const [selectedCourse, setSelectedCourse] = useState();
  const [classes, setClasses] = useState([]);
  const [batch, setBatch] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [students, setStudents] = useState([
    {
      id: 50,
      member_code: "10034",
      school_id: 1,
      name: "dsdsa",
      furigana: "sadsadsa",
      gender: "\u5973",
      birthday: "2000-01-01",
      district_code: "Default",
      zip_code: "5200",
      address_1: "dsfdsfdsf",
      address_2: null,
      phone_no_1: "+1 (733) 872-6815",
      phone_note_1: null,
      phone_no_2: null,
      phone_note_2: null,
      fax_number: null,
      mail_address_1: "tytugur1y@mailinator.com",
      mail_category_1: "5200",
      mail_note_1: "dsfdsfdsf",
      mail_address_2: "sakif1407023@gmail.com",
      mail_category_2: null,
      mail_note_2: null,
      mail_address_3: null,
      mail_category_3: null,
      mail_note_3: null,
      family_member_id: "undefined",
      bus_course_number: null,
      outbound_bus: null,
      return_bus: null,
      charge_type_code: "\u7279\u5225\uff11",
      payment_type_code: "\u73fe\u91d1",
      bank_code: "2",
      branch_code: "765",
      deposit_item: "\u7d0d\u7a0e\u57fa\u6e96",
      account_no: null,
      account_holder: null,
      joining_date: "2023-08-18",
      leave_date: null,
      created_at: "2023-08-18T05:48:01.000000Z",
      updated_at: "2023-08-18T05:48:01.000000Z",
    },
    {
      id: 49,
      member_code: "10033",
      school_id: 1,
      name: "example 2",
      furigana: "\u306f\u306a\u3079\u304d\u3080\u3089",
      gender: "\u5973",
      birthday: "2011-09-07",
      district_code: "Default",
      zip_code: "23423",
      address_1: "qqweq",
      address_2: "wrwerwr",
      phone_no_1: "12341",
      phone_note_1: "1241",
      phone_no_2: "13414",
      phone_note_2: "12341",
      fax_number: "null",
      mail_address_1: "skfjskh@gmail.com",
      mail_category_1: "23423",
      mail_note_1: "qqweq",
      mail_address_2: "2122@rwe.vf",
      mail_category_2: "null",
      mail_note_2: "2",
      mail_address_3: "null",
      mail_category_3: "null",
      mail_note_3: "null",
      family_member_id: "4",
      bus_course_number: "3",
      outbound_bus: "123",
      return_bus: "23",
      charge_type_code: "\u7279\u5225\uff12",
      payment_type_code: "\u73fe\u91d1",
      bank_code: "13",
      branch_code: "222",
      deposit_item: "\u5f53\u5ea7",
      account_no: "232",
      account_holder: "131",
      joining_date: "1970-02-04",
      leave_date: "1969-11-30",
      created_at: "2023-08-01T14:18:49.000000Z",
      updated_at: "2023-08-09T02:58:29.000000Z",
    },
    {
      id: 48,
      member_code: "10032",
      school_id: 1,
      name: "\u30ad",
      furigana: "\u30ad",
      gender: "\u7537",
      birthday: "1974-09-05",
      district_code: "Default",
      zip_code: "5200",
      address_1: "A",
      address_2: "A",
      phone_no_1: "01750054343",
      phone_note_1: "null",
      phone_no_2: "null",
      phone_note_2: "null",
      fax_number: "null",
      mail_address_1: "sakif1407023@gmail.com",
      mail_category_1: "5200",
      mail_note_1: "A",
      mail_address_2: "sakif1407023@gmail.com",
      mail_category_2: "null",
      mail_note_2: "null",
      mail_address_3: "null",
      mail_category_3: "null",
      mail_note_3: "null",
      family_member_id: "2",
      bus_course_number: "1",
      outbound_bus: "null",
      return_bus: "null",
      charge_type_code: "\u7279\u5225\uff12",
      payment_type_code: "null",
      bank_code: "null",
      branch_code: "null",
      deposit_item: "null",
      account_no: "null",
      account_holder: "null",
      joining_date: "2023-01-23",
      leave_date: "2023-04-05",
      created_at: "2023-07-20T16:11:16.000000Z",
      updated_at: "2023-07-31T20:03:52.000000Z",
    },
    {
      id: 47,
      member_code: "10031",
      school_id: 1,
      name: "\u30ad",
      furigana: "\u30ab",
      gender: "\u5973",
      birthday: "2000-01-21",
      district_code: "Default",
      zip_code: "55832",
      address_1: "92 Clarendon Court",
      address_2: "Doloremque ut rerum",
      phone_no_1: "+1 (733) 872-6815",
      phone_note_1: "1",
      phone_no_2: "1",
      phone_note_2: "null",
      fax_number: "null",
      mail_address_1: "tytugury414@mailinator.com",
      mail_category_1: "55832",
      mail_note_1: "92 Clarendon Court",
      mail_address_2: "1",
      mail_category_2: "null",
      mail_note_2: "1",
      mail_address_3: "null",
      mail_category_3: "null",
      mail_note_3: "null",
      family_member_id: "2",
      bus_course_number: "1",
      outbound_bus: "null",
      return_bus: "null",
      charge_type_code: "\u7279\u5225\uff12",
      payment_type_code: "\u73fe\u91d1",
      bank_code: "null",
      branch_code: "null",
      deposit_item: "null",
      account_no: "null",
      account_holder: "null",
      joining_date: "2023-07-07",
      leave_date: "1970-01-15",
      created_at: "2023-07-20T15:29:08.000000Z",
      updated_at: "2023-07-21T13:52:38.000000Z",
    },
    {
      id: 46,
      member_code: "10030",
      school_id: 3,
      name: "\u30b5",
      furigana: "\u30ad\u30a2",
      gender: "\u7537",
      birthday: "2000-01-20",
      district_code: "Default",
      zip_code: "55832",
      address_1: "92 Clarendon Court",
      address_2: "null",
      phone_no_1: "+1 (733) 872-6815",
      phone_note_1: "null",
      phone_no_2: "null",
      phone_note_2: "null",
      fax_number: "null",
      mail_address_1: "tytug1ury@mailinator.com",
      mail_category_1: "55832",
      mail_note_1: "92 Clarendon Court",
      mail_address_2: "null",
      mail_category_2: "null",
      mail_note_2: "null",
      mail_address_3: "null",
      mail_category_3: "null",
      mail_note_3: "null",
      family_member_id: "2",
      bus_course_number: "null",
      outbound_bus: "null",
      return_bus: "null",
      charge_type_code: "\u7279\u5225\uff12",
      payment_type_code: "\u73fe\u91d1",
      bank_code: "null",
      branch_code: "null",
      deposit_item: "null",
      account_no: "null",
      account_holder: "null",
      joining_date: "2023-07-28",
      leave_date: "1970-01-30",
      created_at: "2023-07-20T14:48:04.000000Z",
      updated_at: "2023-07-21T13:54:19.000000Z",
    },
    {
      id: 45,
      member_code: "10029",
      school_id: 3,
      name: "\u30bf",
      furigana: "\u30ad\u30a4",
      gender: "\u5973",
      birthday: "2000-01-14",
      district_code: "Default",
      zip_code: "5200",
      address_1: "92 Clarendon Court",
      address_2: "null",
      phone_no_1: "+1 (926) 704-8446",
      phone_note_1: "null",
      phone_no_2: "null",
      phone_note_2: "null",
      fax_number: "null",
      mail_address_1: "hosuzexs@mailinator.com",
      mail_category_1: "5200",
      mail_note_1: "92 Clarendon Court",
      mail_address_2: "null",
      mail_category_2: "null",
      mail_note_2: "null",
      mail_address_3: "null",
      mail_category_3: "null",
      mail_note_3: "null",
      family_member_id: "1",
      bus_course_number: "null",
      outbound_bus: "null",
      return_bus: "null",
      charge_type_code: "\u7279\u5225\uff12",
      payment_type_code: "null",
      bank_code: "null",
      branch_code: "null",
      deposit_item: "null",
      account_no: "null",
      account_holder: "null",
      joining_date: "2023-07-22",
      leave_date: "1970-01-24",
      created_at: "2023-07-20T14:08:55.000000Z",
      updated_at: "2023-07-21T13:55:17.000000Z",
    },
    {
      id: 44,
      member_code: "10028",
      school_id: 3,
      name: "dsfdsf",
      furigana: "\u30a2",
      gender: "\u5973",
      birthday: "2000-01-05",
      district_code: "Default",
      zip_code: "5200",
      address_1: "18 Hague Road",
      address_2: "null",
      phone_no_1: "+1 (438) 807-7356",
      phone_note_1: "null",
      phone_no_2: "null",
      phone_note_2: "null",
      fax_number: "null",
      mail_address_1: "hosuzeux@mailinator.com",
      mail_category_1: "5200",
      mail_note_1: "18 Hague Road",
      mail_address_2: "null",
      mail_category_2: "null",
      mail_note_2: "null",
      mail_address_3: "null",
      mail_category_3: "null",
      mail_note_3: "null",
      family_member_id: "2",
      bus_course_number: "1",
      outbound_bus: "null",
      return_bus: "null",
      charge_type_code: "\u7279\u5225\uff12",
      payment_type_code: "null",
      bank_code: "null",
      branch_code: "null",
      deposit_item: "null",
      account_no: "null",
      account_holder: "null",
      joining_date: "2023-07-21",
      leave_date: "1970-01-16",
      created_at: "2023-07-20T14:06:00.000000Z",
      updated_at: "2023-07-26T15:45:22.000000Z",
    },
    {
      id: 42,
      member_code: "10027",
      school_id: 3,
      name: "Arsenio Thompson",
      furigana: "\u30a4",
      gender: "\u5973",
      birthday: "2000-01-08",
      district_code: "Default",
      zip_code: "54333",
      address_1: "667 North First Extension",
      address_2: "Iusto excepteur enim",
      phone_no_1: "+1 (756) 549-3589",
      phone_note_1: "+1 (731) 379-8755",
      phone_no_2: "+1 (884) 356-1251",
      phone_note_2: "+1 (365) 227-4655",
      fax_number: "+1 (795) 284-8736",
      mail_address_1: "qyfejibod@mailinator.com",
      mail_category_1: "54333",
      mail_note_1: "667 North First Extension",
      mail_address_2: "todo@mailinator.com",
      mail_category_2: "null",
      mail_note_2: "Quisquam anim ad ape",
      mail_address_3: "null",
      mail_category_3: "null",
      mail_note_3: "null",
      family_member_id: "1",
      bus_course_number: "1",
      outbound_bus: "27",
      return_bus: "65",
      charge_type_code: "\u7279\u5225\uff12",
      payment_type_code: "\u73fe\u91d1",
      bank_code: "18",
      branch_code: "12245",
      deposit_item: "\u7d0d\u7a0e\u57fa\u6e96",
      account_no: "Deserunt sint moles",
      account_holder: "Culpa labore Nam di",
      joining_date: "2023-07-01",
      leave_date: "1970-01-08",
      created_at: "2023-07-20T13:34:45.000000Z",
      updated_at: "2023-07-26T15:46:10.000000Z",
    },
    {
      id: 41,
      member_code: "10026",
      school_id: 1,
      name: "New Student One",
      furigana: "\u30db",
      gender: "\u5973",
      birthday: "2000-01-13",
      district_code: "Default",
      zip_code: "55832",
      address_1: "92 Clarendon Court",
      address_2: "null",
      phone_no_1: "+1 (733) 872-6815",
      phone_note_1: "null",
      phone_no_2: "null",
      phone_note_2: "null",
      fax_number: "null",
      mail_address_1: "tytug25ury@mailinator.com",
      mail_category_1: "55832",
      mail_note_1: "92 Clarendon Court",
      mail_address_2: "null",
      mail_category_2: "null",
      mail_note_2: "null",
      mail_address_3: "null",
      mail_category_3: "null",
      mail_note_3: "null",
      family_member_id: "2",
      bus_course_number: "null",
      outbound_bus: "null",
      return_bus: "null",
      charge_type_code: "\u7279\u5225\uff12",
      payment_type_code: "null",
      bank_code: "null",
      branch_code: "null",
      deposit_item: "null",
      account_no: "null",
      account_holder: "null",
      joining_date: "2023-07-28",
      leave_date: "1970-01-23",
      created_at: "2023-07-20T13:32:36.000000Z",
      updated_at: "2023-08-01T15:07:04.000000Z",
    },
    {
      id: 40,
      member_code: "10025",
      school_id: 1,
      name: "Josiah Garza",
      furigana: "\u30ed",
      gender: "\u5973",
      birthday: "2000-01-07",
      district_code: "Default",
      zip_code: "55832",
      address_1: "BOROGURGOLA MOSQUE ROAD, BOROGURGOLA",
      address_2: "Doloremque ut rerum",
      phone_no_1: "+1 (733) 872-6815",
      phone_note_1: "null",
      phone_no_2: "null",
      phone_note_2: "null",
      fax_number: "null",
      mail_address_1: "tytugu1ry@mailinator.com",
      mail_category_1: "55832",
      mail_note_1: "BOROGURGOLA MOSQUE ROAD, BOROGURGOLA",
      mail_address_2: "null",
      mail_category_2: "null",
      mail_note_2: "null",
      mail_address_3: "null",
      mail_category_3: "null",
      mail_note_3: "null",
      family_member_id: "2",
      bus_course_number: "1",
      outbound_bus: "null",
      return_bus: "null",
      charge_type_code: "\u7279\u5225\uff12",
      payment_type_code: "null",
      bank_code: "null",
      branch_code: "null",
      deposit_item: "null",
      account_no: "null",
      account_holder: "null",
      joining_date: "2023-07-07",
      leave_date: "1970-01-14",
      created_at: "2023-07-20T13:29:45.000000Z",
      updated_at: "2023-08-01T15:10:52.000000Z",
    },
  ]);

  const demoStudent = {
    name: "John Doe",
    userID: "123456",
    email: "abc@test.com",
    schoolID: "4456",
    attendances: [
      { date: "2023-01-01", status: "present", comment: "Good" },
      { date: "2023-01-02", status: "absent", comment: "Bad" },
      { date: "2023-01-03", status: "present", comment: "Good" },
      { date: "2023-01-04", status: "absent", comment: "Bad" },
      { date: "2023-01-05", status: "present", comment: "Very Good" },
      { date: "2023-01-06", status: "absent", comment: "Very Bad" },
      { date: "2023-01-07", status: "present", comment: "Good" },
      { date: "2023-01-08", status: "absent", comment: "Bad" },
      { date: "2023-01-09", status: "present", comment: "Very Good" },
      { date: "2023-01-10", status: "absent", comment: "Very Bad" },
    ],
  };

  const getClasses = async e => {
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const classChangeHandler = e => {
    setSelectedClass(e.target.value);
    setBatch(e.target.value);
    // getScheduleInfo(e.target.value);
  };

  const courseChangeHandler = e => {
    setSelectedCourse(e.target.value);
    setBatch(e.target.value);
    // getScheduleInfo(e.target.value);
  };

  useEffect(() => {
    getClasses();
  }, []);

  return (
    <>
      <p className="headline">会員のプロフィールを検索</p>
      <div className="row mb-4 upper-container">
        <div className="col-md-6">
          <select
            name="class_id"
            className=" w-100 shadow-sm custom_select"
            onChange={classChangeHandler}
            value={selectedClass}
          >
            <option value="">クラスの選択</option>
            {classes?.map((st_class, i) => (
              <option key={i} value={st_class.id}>
                {st_class.affiliation_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <select
            className="w-100 shadow-sm custom_select"
            onChange={courseChangeHandler}
            value={selectedCourse}
          >
            <option value="">コースを選択</option>
            <option value="Course-1">コース1 Rank (1-5)</option>
            <option value="Course-2">コース2 Rank (7-14)</option>
            <option value="Course-3">コース Rank(11-18)</option>
          </select>
        </div>
      </div>
      {/* <form>
        <div className="row w-75 search-bar">
          <div className="form-group col-md-9 search-bar-container">
            <select
              className="form-control"
              value={studentName}
              onChange={(e) => setStudentName(e.target.value)}
            >
              <option value="">生徒を選択</option>
              <option value="会員-1">会員-1</option>
              <option value="会員-2">会員-2</option>
              <option value="会員-3">会員-3</option>
            </select>
            <span>また</span>
            <input
              type="text"
              value={userID}
              onChange={(e) => setUserID(e.target.value)}
              placeholder="ユーザーIDを入力してください"
              className="form-control form-control-underlined"
            />
          </div>
          <div className="form-group col-md-3">
            <button
              onClick={() => setShowData(true)}
              type="button"
              className="btn btn-primary rounded-pill btn-block shadow-sm"
            >
              探す
            </button>
          </div>
        </div>
      </form> */}
      {/* create student details dummy data card with name, id, school and course */}

      {(selectedClass || selectedCourse) && (
        <>
          <div className="row mb-3">
            <div className="card-body">
              <div className="container">
                <div className="table-responsive">
                  <Table
                    striped
                    bordered
                    hover
                    responsive="lg"
                    className="text-left"
                  >
                    <thead>
                      <tr>
                        <th
                          className="text-center"
                          style={{ minWidth: "90px" }}
                        >
                          会員番号
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          氏名
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          フリガナ
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "60px" }}
                        >
                          性別
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          生年月日
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          地区コード
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          郵便番号
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "300px" }}
                        >
                          住所1
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "300px" }}
                        >
                          住所2
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          電話番号1
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          電話備考1
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          電話番号2
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          電話備考2
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          FAX番号
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          メールアドレス1
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          メール区分1
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "300px" }}
                        >
                          メール備考1
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          メールアドレス2
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          メール区分2
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          メール備考2
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          メールアドレス3
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          メール区分3
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          メール備考3
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          家族会員番号
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          バスコース番号
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          バス往路
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          バス復路
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          料金種別コード
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          納付区分コード
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          銀行コード
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          支店コード
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          預金種目
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "150px" }}
                        >
                          口座番号
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "200px" }}
                        >
                          口座名義人
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          入会日
                        </th>
                        <th
                          className="text-center"
                          style={{ minWidth: "100px" }}
                        >
                          退校日
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {students.map((student, index) => (
                        <tr
                          key={index}
                          style={{
                            cursor: "pointer",
                          }}
                          onDoubleClick={() => setShowInfo(true)}
                        >
                          <td
                            className="text-center"
                            style={{ width: "5%", cursor: "pointer" }}
                          >
                            {student?.member_code}
                          </td>
                          <td className="text-center">{student?.name}</td>
                          <td className="text-center">{student?.furigana}</td>
                          <td className="text-center">{student?.gender}</td>
                          <td className="text-center">{student?.birthday}</td>
                          <td className="text-center">
                            {student?.district_code}
                          </td>
                          <td className="text-center">{student?.zip_code}</td>
                          <td className="text-center">{student?.address_1}</td>
                          <td className="text-center">{student?.address_2}</td>
                          <td className="text-center">{student?.phone_no_1}</td>
                          <td className="text-center">
                            {student?.phone_note_1}
                          </td>
                          <td className="text-center">{student?.phone_no_2}</td>
                          <td className="text-center">
                            {student?.phone_note_2}
                          </td>
                          <td className="text-center">{student?.fax_number}</td>
                          <td className="text-center">
                            {student?.mail_address_1}
                          </td>
                          <td className="text-center">
                            {student?.mail_category_1}
                          </td>
                          <td className="text-center">
                            {student?.mail_note_1}
                          </td>
                          <td className="text-center">
                            {student?.mail_address_2}
                          </td>
                          <td className="text-center">
                            {student?.mail_category_2}
                          </td>
                          <td className="text-center">
                            {student?.mail_note_2}
                          </td>
                          <td className="text-center">
                            {student?.mail_address_3}
                          </td>
                          <td className="text-center">
                            {student?.mail_category_3}
                          </td>
                          <td className="text-center">
                            {student?.mail_note_3}
                          </td>
                          <td className="text-center">
                            {student?.family_member_id}
                          </td>
                          <td className="text-center">
                            {student?.bus_course_number}
                          </td>
                          <td className="text-center">
                            {student?.outbound_bus}
                          </td>
                          <td className="text-center">{student?.return_bus}</td>
                          <td className="text-center">
                            {student?.charge_type_code}
                          </td>
                          <td className="text-center">
                            {student?.payment_type_code}
                          </td>
                          <td className="text-center">{student?.bank_code}</td>
                          <td className="text-center">
                            {student?.branch_code}
                          </td>
                          <td className="text-center">
                            {student?.deposit_item}
                          </td>
                          <td className="text-center">{student?.account_no}</td>
                          <td className="text-center">
                            {student?.account_holder}
                          </td>
                          <td className="text-center">
                            {student?.joining_date}
                          </td>
                          <td className="text-center">{student?.leave_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          {
            <Modal size="lg" show={showInfo} onHide={() => setShowInfo(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Modal Title</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  <div className="print-button-container">
                    <button className="print-button">印刷する</button>
                  </div>

                  <div className="card align-items-center bg-light mb-3">
                    <div className="card-body w-50">
                      <div className="row mx-auto text-md-center text-lg-start">
                        <div className="col-md-6">
                          <p>
                            <span className="fw-bold">名前:</span> 田田中さん
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <span className="fw-bold">ID:</span> {userID}
                          </p>
                        </div>

                        <div className="col-md-6">
                          <p>
                            <span className="fw-bold">学校:</span> 千葉県学校{" "}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <span className="fw-bold">コース:</span> コース１
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>NO.</th>
                        <th>日にち</th>
                        <th className="text-center">出席</th>
                        <th>コメント</th>
                      </tr>
                    </thead>
                    <tbody>
                      {demoStudent?.attendances.map((attendance, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{attendance?.date}</td>
                          <td className="text-center">
                            {attendance?.status === "absent" ? "✅" : "❌"}
                          </td>
                          <td>{attendance.comment}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowInfo(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          }
        </>
      )}
    </>
  );
}

export default Profile;
