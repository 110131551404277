import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";

import axios from "axios";
import CoachPrivateRoute from "./routes/PrivateRoutes/CoachPrivateRoute";
import StudentPrivateRoute from "./routes/PrivateRoutes/StudentPrivateRoute";
import AdminPrivateRoute from "./routes/PrivateRoutes/AdminPrivateRoute";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "./assets/global.css";
import "flatpickr/dist/flatpickr.min.css";

import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "./components/common/Loader/Loader";
import SuperAdminPrivateRoute from "./routes/PrivateRoutes/SuperAdminPrivateRoute";
import { UserProvider } from "./context/userContext";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.baseURL = "http://localhost:8000/api/";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("api_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  return (
    <Suspense fallback={<Loader message="Loading.." />}>
      <div className="App">
        <UserProvider>
          <Router>
            <Switch>
              <CoachPrivateRoute
                path="/coach"
                name="Coach"
              />
              <StudentPrivateRoute
                path="/student"
                name="Student"
              />
              <AdminPrivateRoute
                path="/admin"
                name="Admin"
              />
              <SuperAdminPrivateRoute
                path="/super-admin"
                name="SuperAdmin"
              />

              <PublicRoute
                path="/"
                name="Home"
              />

              {/* <Route path="/login">
              {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Login />}
            </Route>
            <Route path="/register">
              {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Register />}
            </Route> */}

              {/* <Route path="/admin" name="Admin" render={(props) => <MasterLayout {...props} />} /> */}
            </Switch>
          </Router>
        </UserProvider>
      </div>
    </Suspense>
  );
}

export default App;
