import React, { useState, useEffect } from "react";
import privateApi from "../../services/privateApi";
import swal from "sweetalert";
import { useMediaQuery } from "react-responsive";
import BackButton from "../common/Button/BackButton";
import ActionButtonGroup from "../common/Button/ActionButtonGroup";
import { Resizable } from "react-resizable";
import { Skeleton, Table } from "antd";
import { ConfigProvider } from "antd";
import "react-resizable/css/styles.css";
import RouteLeavingGuard from "../Prompt/RouterLeavingGuard";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8",
    },
  },
};
const CourseLevel = ({ history }) => {
  const [levelLists, setLevelLists] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClassName, setSelectedClassName] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [maxMember, setMaxMember] = useState();
  const [AddBtnEnbl, SetAddBtnEnbl] = useState(true);
  const [UpdateBtnEnbl, SetUpdateBtnEnbl] = useState(false);
  const [DltBtnEnbl, SetDltBtnEnbl] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1366 });
  const [whenState, updateWhenState] = useState(false);

  const getClasses = async () => {
    setLoading(true);
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
    setLoading(false);
  };

  const levelSelectHandler = (
    index,
    id,
    regular_class_id,
    start,
    end,
    max_stu
  ) => {
    if (selectedRowIndex === index) {
      blankHandler();
    } else {
      SetAddBtnEnbl(false);
      SetUpdateBtnEnbl(true);
      SetDltBtnEnbl(true);
      setSelectedRowIndex(index);
      setSelectedLevel(id);
      setFrom(start);
      setTo(end);
      setMaxMember(max_stu);
      setSelectedClass(regular_class_id);
    }
  };

  const deleteClasseHandler = async () => {
    if (selectedClass !== "") {
      const response = await privateApi.delete(
        `admin/class-levels/${selectedLevel}`
      );
      if (response.status === 200) {
        swal(response.data.message, "", "success");
        blankHandler();
        getLevelsInfo(selectedClass);
      } else {
        console.log("Server Error");
      }
    } else {
      swal("Select any row to remove", "", "warning");
    }
  };

  const updateClasseHandler = async () => {
    const data = {
      regular_class_id: selectedClass,
      from_level: from,
      to_level: to,
      max_students: maxMember,
      in_text: `${from}-${to} 級`,
    };

    if (selectedClass !== "") {
      const response = await privateApi.update(
        `admin/class-levels/${selectedLevel}`,
        data
      );
      if (response.status === 200) {
        updateWhenState(false);
        swal(response.data.message, "", "success");
        getLevelsInfo(selectedClass);
      } else {
        swal(response.data.message, "", "warning");
      }
    } else {
      swal("Select any row to update", "", "warning");
    }
  };

  useEffect(() => {
    getClasses();
    getLevelsInfo(selectedClass);
  }, []);

  const classChangeHandler = e => {
    setLevelLists([]);
    setSelectedClass(e.target.value);
    const selectedOption = e.target.selectedOptions[0];
    const selectedAffiliation = selectedOption.getAttribute("data-affiliation");
    setSelectedClassName(selectedAffiliation);
    getLevelsInfo(e.target.value);
  };

  const blankHandler = () => {
    setFrom("");
    setTo("");
    setMaxMember("");
    setSelectedRowIndex("");
    setSelectedRowIndex(null);
    SetAddBtnEnbl(true);
    SetUpdateBtnEnbl(false);
    SetDltBtnEnbl(false);
  };

  const storeHandler = async () => {
    if (selectedClass !== "") {
      const data = {
        regular_class_id: selectedClass,
        from_level: from,
        to_level: to,
        max_students: maxMember,
        in_text: `${from}-${to} 級`,
      };

      const response = await privateApi.post("/admin/class-levels", data);

      if (response.status === 200) {
        updateWhenState(false);
        swal(response.data.message, "", "success");
        blankHandler();
        getLevelsInfo(selectedClass);
      } else {
        swal(response.data.message, "", "error");
      }
    } else {
      swal("Select any class", "", "warning");
    }
  };

  const getLevelsInfo = async (class_id, pageNumber) => {
    const response = await privateApi.get(
      `/admin/class-levels?regular_class_id=${class_id}&per_page=100&page=${pageNumber}`
    );

    if (response.status === 200) {
      setLevelLists(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const [columns, setColumns] = useState([
    {
      title: "所属",
      dataIndex: "regular_class.affiliation_name",
      key: "affiliation_name",
      width: 90,
      sorter: (a, b) =>
        a.regular_class.affiliation_name.localeCompare(
          b.regular_class.affiliation_name
        ),
      render: (text, record) => (
        <span>{record.regular_class.affiliation_name}</span>
      ),
    },
    {
      title: "から",
      dataIndex: "from_level",
      key: "from_level",
      width: 90,
      sorter: (a, b) => a.from_level - b.from_level,
      render: (text, record) => <span>{record.from_level}</span>,
    },
    {
      title: "まで",
      dataIndex: "to_level",
      key: "to_level",
      width: 90,
      sorter: (a, b) => a.to_level - b.to_level,
      render: (text, record) => <span>{record.to_level}</span>,
    },
    {
      title: "級",
      dataIndex: "in_text",
      key: "in_text",
      width: 90,
      sorter: (a, b) => a.in_text.localeCompare(b.in_text),
      render: (text, record) => <span>{record.in_text}</span>,
    },
    {
      title: "人数",
      dataIndex: "max_students",
      key: "max_students",
      width: 90,
      sorter: (a, b) => a.max_students - b.max_students,
      render: (text, record) => <span>{record.max_students}</span>,
    },
  ]);

  const handleResize =
    index =>
    (e, { size }) => {
      setColumns(columns => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = (record, index) => {
    const key = record.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );
  };

  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  const position = {
    bottom: "-8%",
  };

  const content = (
    <div className="card">
      <div className="card-header">
        <div className="d-flex  d-flex justify-content-center align-items-center">
          <div className="form-group col-md-5 row">
            <label htmlFor="staticEmail" className="col-md-4 col-form-label">
              所属
            </label>
            <div className="col-md-8 mx-0">
              <select
                name="class_id"
                className="form-control form-control-sm shadow-sm"
                onChange={classChangeHandler}
                value={selectedClass}
                style={{ width: "130px" }}
              >
                <option value="">クラスの選択</option>
                {classes?.map((st_class, i) => (
                  <option
                    key={i}
                    value={st_class.id}
                    data-affiliation={st_class.affiliation_name}
                  >
                    {st_class.affiliation_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <span></span>
          <div className="ml-auto">
            <ActionButtonGroup
              storeHandler={storeHandler}
              deleteClasseHandler={deleteClasseHandler}
              updateClasseHandler={updateClasseHandler}
              AddBtnEnbl={AddBtnEnbl}
              DltBtnEnbl={DltBtnEnbl}
              UpdateBtnEnbl={UpdateBtnEnbl}
            />
          </div>
        </div>
      </div>
      <div className="card-body d-flex justify-content-center">
        <div className="row m-0 p-0">
          {/* Input Range: From */}
          <div className="col-sm">
            <div className="form-group">
              <div className="d-flex">
                <input
                  className="form-control form-control-sm text-center float-left"
                  type="number"
                  value={from}
                  onChange={e => {
                    updateWhenState(true);
                    setFrom(e.target.value);
                  }}
                  style={{ width: "100px" }}
                />
                <label htmlFor="from" className="col-form-label ml-2">
                  から
                </label>
              </div>
            </div>
          </div>

          {/* Input Range: To */}
          <div className="col-sm">
            <div className="form-group">
              <div className="d-flex">
                <input
                  className="form-control form-control-sm text-center float-left"
                  type="number"
                  value={to}
                  onChange={e => {
                    updateWhenState(true);
                    setTo(e.target.value);
                  }}
                  style={{ width: "100px" }}
                />
                <label htmlFor="to" className="col-form-label ml-2">
                  まで
                </label>
              </div>
            </div>
          </div>

          <div className="col-sm-5">
            <div className="form-group form-control-sm ">
              <div className="d-flex">
                <label
                  htmlFor="maxMember"
                  className="text-end col-6 mr-5 col-form-label"
                >
                  人数の入力欄
                </label>
                <div className="">
                  <input
                    className="form-control form-control-sm text-center"
                    type="number"
                    value={maxMember}
                    onChange={e => {
                      updateWhenState(true);
                      setMaxMember(e.target.value);
                    }}
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        width: isMobile ? "100%" : isTablet || isLaptop ? "70%" : "40%",
      }}
    >
      <div className="card-body">
        <div style={{ position: "relative" }}>
          <div className="body">{content}</div>
          <div className="mt-1">
            <div className="">
              <h4>
                {selectedClassName && `レベルリスト - ${selectedClassName}`}
              </h4>
              {loading ? (
                <Skeleton paragraph={{ rows: 4 }} />
              ) : (
                <ConfigProvider theme={customTheme}>
                  <Table
                    bordered
                    className="table-striped-rows"
                    columns={resizableColumns}
                    dataSource={levelLists}
                    rowKey="id"
                    components={components}
                    rowClassName={rowClassName}
                    pagination={false}
                    onRow={record => ({
                      onClick: () => {
                        const index = levelLists.findIndex(
                          level => level.id === record.id
                        );
                        levelSelectHandler(
                          index,
                          record.id,
                          record.regular_class_id,
                          record.from_level,
                          record.to_level,
                          record.max_students
                        );
                        handleRowClick(record, index);
                      },
                    })}
                  />
                </ConfigProvider>
              )}
            </div>
            <div className="w-75 ">
              <div className="paginations d-flex mt-3">
                <div className="d-flex mx-auto neomp">
                  <BackButton
                    position={position}
                    redirectTo="/admin/master-maintenance/dashboard"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RouteLeavingGuard
        when={whenState}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={location => {
          if (whenState) {
            return true;
          }
          return false;
        }}
        yes="yes"
        no="no"
      />
    </div>
  );
};

export default CourseLevel;
