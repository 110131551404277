import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../assets/common/css/modal.css";
import Pagination from "react-js-pagination";

const ShortTermStuManagement = () => {
  const [search, setSearch] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const paginateHandler = pageNumber => {
    setActivePage(pageNumber);
  };

  const [students, setStudents] = useState([
    {
      name: "タズワー ",
      dob: "1999 年  1月 1 日",
      gender: "男",
      payment: false,
      course: "course 1",
      phone: "03-3813-6817",
    },
    {
      name: "サキブ",
      dob: "2004 年  1月 1 日",
      gender: "男",
      payment: true,
      course: "course 1",
      phone: "03-3813-6817",
    },
    {
      name: "ソネット",
      dob: "2003 年  1月 1 日",
      gender: "女",
      payment: true,
      course: "course 2",
      phone: "03-3813-6817",
    },
    {
      name: "テスト",
      dob: "1998 年  1月 1 日",
      gender: "男",
      payment: false,
      course: "course 1",
      phone: "03-3813-6817",
    },
    {
      name: "陽菜",
      dob: "2000 年  1月 1 日",
      gender: "女",
      payment: true,
      course: "course 3",
      phone: "03-3813-6817",
    },
    {
      name: "タズワー",
      dob: "2004 年  1月 1 日",
      gender: "男",
      payment: false,
      course: "course 1",
      phone: "03-3813-6817",
    },
    {
      name: "咲良",
      dob: "2003 年  1月 1 日",
      gender: "女",
      payment: true,
      course: "course 2",
      phone: "03-3813-6817",
    },
    {
      name: "ムラド",
      dob: "2002 年  1月 1 日",
      gender: "男",
      payment: false,
      course: "course 4",
      phone: "03-3813-6817",
    },
  ]);

  const [show, setShow] = useState(false);

  return (
    <>
      <div className="row mb-4 upper-container">
        <div className="col-10">
          <p className="headline">短期生管理</p>
        </div>
        <div className="col-2 d-flex align-items-center">
          <button
            className="btn btn-sm btn-primary px-3 ml-auto"
            variant="primary"
            onClick={() => setShow(true)}
          >
            {" "}
            <i className="fas fa-plus fa-fw"></i> 新しく追加する
          </button>
        </div>
      </div>

      <div className="card px-2 py-3">
        <div className="row mb-3">
          <div className="col-md-9">
            <h4>生徒一覧</h4>
          </div>

          <div className="col-md-3 row" style={{ paddingRight: "0px" }}>
            <div className="col-md-4 col-xs-12 d-flex align-items-center">
              <p>
                <FontAwesomeIcon icon="search" /> 探す:{" "}
              </p>
              {/* <i className="fa-solid fa-magnifying-glass"></i> */}
            </div>

            <div className="col-md-8" style={{ paddingRight: "0px" }}>
              <input
                className="form-control"
                name="student_name"
                placeholder="名前で検索"
                onChange={e => setSearch(e.target.value)}
              ></input>
            </div>
          </div>
        </div>

        <Table striped bordered hover responsive="md">
          <thead>
            <tr>
              <th className="text-center" style={{ width: "5%" }}>
                NO.
              </th>
              <th className="text-center">名前</th>
              <th className="text-center">生年月日</th>
              <th className="text-center">性別</th>
              <th className="text-center">電話番号</th>
              <th className="text-center">操作</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={index}>
                <td className="text-center" style={{ width: "5%" }}>
                  {index + 1}
                </td>
                <td className="text-center">{student.name}</td>
                <td className="text-center">{student.dob}</td>
                <td className="text-center">{student.gender}</td>
                <td className="text-center">{student.phone}</td>
                <td className="text-center">
                  <button className="btn btn-sm btn-primary mx-1">
                    <FontAwesomeIcon icon="edit" />
                  </button>
                  <button className="btn btn-sm btn-danger mx-1">
                    <FontAwesomeIcon icon="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="paginations d-flex">
          <div className="d-flex mx-auto neomp">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={450}
              pageRangeDisplayed={8}
              onChange={pageNumber => paginateHandler(pageNumber)}
            />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="xl"
        centered
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            新しい生徒を追加
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <h5>会員情報:</h5>

              <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                <Form.Label>名前</Form.Label>
                <Form.Control type="text" placeholder="Name" />
              </Form.Group>

              <div className="row">
                <Form.Group
                  className="mb-3 col-6"
                  controlId="formBasicPassword"
                >
                  <Form.Label>メール</Form.Label>
                  <Form.Control type="text" placeholder="Mail" />
                </Form.Group>

                <Form.Group
                  className="mb-3 col-6"
                  controlId="formBasicPassword"
                >
                  <Form.Label>電話番号</Form.Label>
                  <Form.Control type="text" placeholder="Phone Number" />
                </Form.Group>
              </div>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>住所</Form.Label>
                <Form.Control type="text" placeholder="Address" />
              </Form.Group>

              <div className="row">
                <Form.Group
                  className="mb-3 col-6"
                  controlId="formBasicPassword"
                >
                  <Form.Label>生年月日</Form.Label>
                  <Form.Control type="date" placeholder="Date of Birth" />
                </Form.Group>

                <Form.Group className="mb-3 col-6" controlId="formFileSm">
                  <Form.Label>性別</Form.Label>
                  <Form.Select className="py-1" size="xs">
                    <option>Select Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </Form.Select>{" "}
                </Form.Group>
              </div>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>会員写真</Form.Label>
                <Form.Control type="file" placeholder="Picture" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>パスワード</Form.Label>
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>
              <Row className="justify-content-md-center">
                <Col sm={2}>
                  <Button
                    variant="primary"
                    className="mx-auto btn-block mt-4"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>

              {/* <Form.Group as={Row} className="mb-3">
                <Col sm={{ span: 10, offset: 5 }}>
                  <Button type="submit">Submit</Button>
                </Col>
              </Form.Group> */}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ShortTermStuManagement;
