import React from "react";
import { Link } from "react-router-dom";

import "../masterMaintenance/extraPageStyles.css";

const BankDashboardButtons = [
  {
    name: "請求処理",
    path: "/admin/bank/billing-process",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "入金処理",
    path: "/admin/bank/payment-process",
    icon: "fas fa-tachometer-alt",
  },
];

function BankDashboard() {
  return (
    <div className="p-3">
      <p className="headline">月次処理</p>

      <div className="btn-container col-md-4 col-sm-12">
        {BankDashboardButtons.map(button => (
          <Link to={button.path}>
            <button className="dash-btn">{button.name}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BankDashboard;
