import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ConfirmBox = ({
  yes,
  no,
  visible,
  onCancel,
  onConfirm,
  className,
  children,
}) => {
  const confirm = () => {
    onCancel();
    onConfirm();
  };

  return (
    <>
      {visible && (
        <>
          <Modal
            className="shadow"
            show={true}
            onHide={onCancel}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>保存されていない変更があります!! </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {children?.props?.children || "本当に離れますか？"}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => onCancel()}>
                戻る
              </Button>
              <Button variant="primary" onClick={confirm}>
                移動する
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default ConfirmBox;
