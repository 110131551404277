import React from "react";
import { Link } from "react-router-dom";

import "../masterMaintenance/extraPageStyles.css";

const rankManagementDashboardButtons = [
  {
    name: "進級",
    path: "/admin/rank/rank-management",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "進級管理",
    path: "/admin/rank/report",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "学年ラベル",
    path: "/admin/rank/grade",
    icon: "fas fa-tachometer-alt",
  },
];

function rankManagementDashboard() {
  return (
    <div className="p-3">
      <p className="headline">進級処理 機能一覧</p>
      <div className="btn-container col-md-4 col-sm-12">
        {rankManagementDashboardButtons.map(button => (
          <Link to={button.path}>
            <button className="dash-btn">{button.name}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default rankManagementDashboard;
