import React, { useState } from "react";
import "./adminDashboard.css";
import exportIcon from "../../assets/icons/export.png";
import importIcon from "../../assets/icons/import.png";
import { Modal, ProgressBar, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import privateApi from "../../services/privateApi";
import swal from "sweetalert";

const Backup = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [downloadType, setDownloadType] = useState("sql");
  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileInputSelected, setFileInputSelected] = useState(false);
  const [selectedFileName, setSelectedFileName] =
    useState("ファイルを選択してください");
  const [key, setKey] = useState("import");

  const handleFileChange = e => {
    const file = e.target.files[0];
    // Check if the selected file has a valid extension
    if (file && !file.name.toLowerCase().endsWith(".sql")) {
      // Show alert and reset the file input
      swal("エラー", "ファイルはSQL形式でなければなりません", "error");
      setSelectedFile(null);
      setFileInputSelected(false);
      setSelectedFileName("ファイルを選択してください");
      return;
    }
    setSelectedFile(file);
    setFileInputSelected(true);
    setSelectedFileName(file ? file.name : "ファイルを選択してください");
  };

  const handleDownloadBtn = async () => {
    try {
      setLoading(true);
      let progress = 0;
      const interval = setInterval(() => {
        if (progress < 100) {
          setDownloadProgress(progress);
          progress += Math.random() * (7 - 3) + 3;
        } else {
          clearInterval(interval);
        }
      }, 500);

      const today = new Date();
      const formattedDate = today.toISOString().split("T")[0];

      const response = await privateApi.post(
        `admin/backup/${downloadType}`,
        [],
        {
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        setDownloadProgress(100);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        let filename = `バックアップ_${formattedDate}.${
          downloadType === "excel" ? "xlsx" : downloadType
        }`;

        if (response.headers["content-disposition"]) {
          const match =
            response.headers["content-disposition"].match(/filename="(.+)"/);
          if (match && match[1]) {
            filename = match[1];
          }
        }
        const anchor = document.createElement("a");
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        setShow(false);
      } else {
        swal(
          "エラー",
          "バックアップファイルのダウンロードに失敗しました",
          "error"
        );
        setShow(false);
      }
    } catch (error) {
      swal(
        "エラー",
        "バックアップファイルのダウンロードに失敗しました",
        "error"
      );
    } finally {
      setLoading(false);
      setDownloadProgress(0);
      setShow(false);
    }
  };

  const handleUploadBtn = async () => {
    try {
      setUploadLoading(true);
      let progress = 0;
      const interval = setInterval(() => {
        if (progress < 100) {
          setUploadProgress(progress);
          progress += Math.random() * (7 - 3) + 3;
        } else {
          clearInterval(interval);
        }
      }, 500);

      const formData = new FormData();
      formData.append("file", selectedFile);

      const response = await privateApi.post("admin/data-import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setUploadProgress(100);
        setSelectedFile(null);
        swal("アップロードされた!", "アップロードに成功しました", "success");
        setUploadLoading(false);
        setShow2(false);
      } else {
        swal("エラー", "アップロードに失敗しました", "error");
      }
    } catch (error) {
      swal("エラー", "アップロードに失敗しました", "error");
    } finally {
      setUploadLoading(false);
      setUploadProgress(0);
      setShow2(false);
    }
  };

  return (
    <div col-md-6>
      <div className="p-3">
        <p className="headline">バックアップ</p>
      </div>

      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={k => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="import" title="データベース エクスポート">
          <div className="col-md-6">
            <div className="download-section">
              <div className="d-flex flex-column align-items-center mb-12">
                <img
                  alt="icon"
                  className="text-center"
                  src={importIcon}
                  height="64"
                  width="64"
                />
                <p>
                  ファイルタイプを選択し、最新のデータベースバックアップファイルをダウンロードしてください
                </p>
              </div>

              <div className="d-flex justify-content-around align-items-center">
                <select
                  id="dataFormat"
                  value={downloadType}
                  onChange={e => setDownloadType(e.target.value)}
                >
                  <option value="">ファイルの種類</option>
                  <option value="sql">SQL</option>
                  <option value="csv">CSV</option>
                  <option value="excel">Excel</option>
                </select>
                <button
                  className="btn btn-primary"
                  onClick={() => setShow(true)}
                >
                  ダウンロードファイル
                </button>
              </div>

              <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="my-modal"
                size="md"
                centered
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Body>
                  <div className="text-center px-5">
                    <h3 className="pb-3">
                      {!loading
                        ? "バックアップファイルをエクスポートしてもよろしいですか?"
                        : "インポート中、お待ちください..."}
                    </h3>
                    {loading ? (
                      <ProgressBar
                        striped
                        variant="success"
                        animated
                        now={downloadProgress}
                        label={`${Math.round(downloadProgress)}％`}
                      />
                    ) : (
                      <>
                        <button
                          className="btn btn-sm btn-danger px-3 mx-1"
                          onClick={() => setShow(false)}
                        >
                          <FontAwesomeIcon icon="times" /> キャンセル
                        </button>
                        <button
                          onClick={() => handleDownloadBtn()}
                          className="btn btn-sm btn-success px-5 mx-1"
                        >
                          <FontAwesomeIcon icon="check" /> 確認
                        </button>
                      </>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </Tab>
        {/* <Tab eventKey="export" title="データベース インポート">
          <div className="col-md-6">
            <div className="download-section">
              <div className="d-flex flex-column align-items-center mb-12">
                <img
                  alt="icon"
                  className="text-center"
                  src={exportIcon}
                  height="64"
                  width="64"
                />
                <p>
                  データベースエクスポートファイルを選択し、アップロードしてください
                </p>
              </div>

              <div className="d-flex justify-content-around">
                <div className="custom-file-upload">
                  <label htmlFor="fileInput">
                    <input
                      type="file"
                      id="fileInput"
                      accept=".sql"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <div className="d-flex text-center">
                      <FontAwesomeIcon
                        icon={`fa-solid fa-folder-plus`}
                        style={{ color: "#0d6efd" }}
                        className="mr-4"
                        size="2x"
                      />
                      <p
                        style={{
                          margin: "auto 2px",
                        }}
                      >
                        {selectedFileName}
                      </p>
                    </div>
                  </label>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => setShow2(true)}
                  disabled={
                    !selectedFile ||
                    !selectedFile.name.toLowerCase().endsWith(".sql")
                  }
                >
                  アップロードファイル
                </button>
              </div>

              <Modal
                show={show2}
                onHide={() => setShow2(false)}
                dialogClassName="my-modal"
                size="md"
                centered
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Body>
                  <div className="text-center px-5">
                    <h3 className="pb-3">
                      {!uploadLoading
                        ? "インポートを開始してもよろしいですか?"
                        : "インポート中、お待ちください..."}
                    </h3>
                    {uploadLoading ? (
                      <ProgressBar
                        striped
                        variant="success"
                        animated
                        now={uploadProgress}
                        label={`${Math.round(uploadProgress)}％`}
                      />
                    ) : (
                      <>
                        <button
                          className="btn btn-sm btn-danger px-3 mx-1"
                          onClick={() => setShow2(false)}
                        >
                          <FontAwesomeIcon icon="times" /> キャンセル
                        </button>
                        <button
                          onClick={() => handleUploadBtn()}
                          className="btn btn-sm btn-success px-5 mx-1"
                        >
                          <FontAwesomeIcon icon="check" /> 確認
                        </button>
                      </>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </Tab> */}
      </Tabs>
    </div>
  );
};

export default Backup;
