import React, { useState, useEffect } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import MasterLayout from "../../layouts/coach/MasterLayout";
import privateApi from "../../services/privateApi";
import { getSchool, getToken } from "../../services/auth/token";

function CoachPrivateRoute({ ...rest }) {
  const history = useHistory();
  const [Authenticated, setAuthenticated] = useState(false);
  const [loading, setloading] = useState(true);

  const location = useLocation();

  const authChecker = async (e) => {

    const schoolSlug = getSchool() ? '/' + getSchool() : '';
    if (!getToken()) {
      history.push(schoolSlug +"/login/coach");
      return;
    }

    const response = await privateApi.get("coach/me");

    if (response.status === 200) {
      setloading(false);
      setAuthenticated(true);
    } else {
      history.push(schoolSlug +"/login/coach");
    }
  };

  useEffect(() => {
    localStorage.setItem("previousPathname", location.pathname);
  }, [location]);

  useEffect(() => {
    authChecker();
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={({ props, location }) => (Authenticated ? <MasterLayout {...props} /> : <></>)}
    />
  );
}

export default CoachPrivateRoute;
