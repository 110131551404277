import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import privateApi from "../../../services/privateApi";

import "./studentList.css";

function StudentList({
  noTittle = false,
  setDisableHistory = true,
  setInfo,
  setKey2,
  setCallHistory,
  setStudents,
  students,
  selectedDate,
  selectedClass,
  selectedCourse,
  isLastMonth,
  isCoach = false,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showComment, setShowComment] = useState({});
  const [rankCounts, setRankCounts] = useState({});

  useEffect(() => {
    const initialShowCommentState = {};
    students.forEach(student => {
      initialShowCommentState[student.id] = false;
    });
    setShowComment(initialShowCommentState);
    setRankCounts(calculateRankCounts(students));
  }, [students]);

  const calculateRankCounts = students => {
    const counts = {};
    students.forEach(student => {
      const rank = student?.member_class?.class;

      if (!counts[rank]) {
        counts[rank] = { total: 0, attended: 0 };
      }
      counts[rank].total += 1;
      if (student?.present) {
        counts[rank].attended += 1;
      }
    });
    return counts;
  };

  const toggleAttendance = id => {
    setStudents(prevStudents => {
      const updatedStudents = prevStudents.map(student => {
        if (student?.id === id) {
          return { ...student, present: !student.present };
        }
        return student;
      });
      setRankCounts(calculateRankCounts(updatedStudents));
      return updatedStudents;
    });
  };

  const commentHandler = (id, newComment) => {
    setStudents(
      students.map(student => {
        if (student.id === id) {
          student.comment = newComment;
        }
        return student;
      })
    );
  };

  const classCommentHandler = (id, newComment) => {
    setStudents(
      students.map(student => {
        if (student.id === id) {
          student.class_comment = newComment;
        }
        return student;
      })
    );
  };

  const submitAttendance = async () => {
    try {
      setCallHistory(false);
      let postData;
      if (isLastMonth) {
        postData = students.map(student => ({
          id: student.id,
          present: student.present ? 1 : 0,
          comment: student.comment || "",
          class_comment: student.class_comment || "",
          class: student.class,
        }));
      } else {
        postData = students.map(student => ({
          id: student.id,
          present: student.present ? 1 : 0,
          comment: student.comment || "",
        }));
      }

      const requestData = {
        attendances: postData,
      };

      const response = await privateApi.post(
        `/${isCoach ? "coach" : "admin"}/takeAttendance`,
        requestData
      );

      if (response.status === 200) {
        setShowModal(false);
        swal("出席提出完了", "", "success");
        setCallHistory(true);
        setKey2("attendanceHistory");
      } else {
        setShowModal(false);
        swal("出席提出失敗", "", "error");
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error submitting present:", error);
    }
  };

  const toggleShowComment = id => {
    setShowComment(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const sortedStudents = [...students].sort((a, b) => {
    const classA = a?.member_class?.class
      ? parseInt(a.member_class.class)
      : -Infinity;
    const classB = b?.member_class?.class
      ? parseInt(b.member_class.class)
      : -Infinity;

    return classB - classA;
  });

  const isLastOfRank = (student, index) => {
    const nextStudent = sortedStudents[index + 1];
    return (
      !nextStudent ||
      nextStudent?.member_class?.class !== student?.member_class?.class
    );
  };

  return (
    <>
      {!noTittle && <p className="headline">出席入力</p>}

      {selectedDate && selectedClass ? (
        <div>
          {sortedStudents.length > 0 ? (
            <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}>NO.</th>
                    <th style={{ width: "15%" }}>名前</th>
                    <th style={{ width: "15%" }}>フリガナ</th>
                    <th style={{ width: "4%" }}>会員番号</th>
                    <th style={{ width: "5%" }} className="text-center">
                      級
                    </th>
                    <th style={{ width: "5%" }} className="text-center">
                      小計
                    </th>
                    <th style={{ width: "10%" }} className="text-center">
                      出席
                    </th>
                    <th>申し送り事項</th>
                    {isLastMonth && (
                      <>
                        <th style={{ width: "8%", background: "aliceblue" }}>
                          級
                        </th>
                        <th style={{ width: "25%", background: "aliceblue" }}>
                          試験についてのコメント
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {sortedStudents.map((student, index) => (
                    <tr key={student.id}>
                      <td
                        style={{ width: "5%" }}
                        onClick={() => toggleAttendance(student.id)}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{ width: "10%" }}
                        onClick={() => toggleAttendance(student.id)}
                      >
                        {student?.member?.name}
                      </td>
                      <td
                        style={{ width: "10%" }}
                        onClick={() => toggleAttendance(student.id)}
                      >
                        {student?.member?.furigana}
                      </td>
                      <td
                        style={{ width: "10%" }}
                        onClick={() => toggleAttendance(student.id)}
                      >
                        {student?.member?.member_code}
                      </td>
                      <td
                        className="text-center"
                        style={{ width: "5%" }}
                        onClick={() => toggleAttendance(student.id)}
                      >
                        {student?.member_class?.class}
                      </td>
                      <td
                        className="text-center"
                        style={{ width: "7%" }}
                        onClick={() => toggleAttendance(student.id)}
                      >
                        {isLastOfRank(student, index)
                          ? rankCounts[student.member_class.class]?.attended ||
                            0
                          : ""}
                      </td>
                      <td style={{ width: "10%" }} className="text-center">
                        <Form.Check
                          className="d-flex justify-content-center"
                          type="switch"
                          id={`custom-switch-${student.id}`}
                          label=""
                          checked={student?.present}
                          onChange={() => toggleAttendance(student.id)}
                        />
                      </td>
                      <td className="d-flex">
                        <input
                          className="w-100"
                          type={showComment[student.id] ? "text" : "password"}
                          value={student?.member?.notice}
                          // onChange={e =>
                          //   commentHandler(student.id, e.target.value)
                          // }
                          // placeholder="コメントを入力"
                          disabled
                        />
                        <Button
                          variant="link"
                          className="float-end"
                          onClick={() => toggleShowComment(student.id)}
                        >
                          <FontAwesomeIcon
                            icon={showComment[student.id] ? faEye : faEyeSlash}
                          />
                        </Button>
                      </td>
                      {isLastMonth && (
                        <>
                          <td style={{ width: "10%" }}>
                            <select
                              className="form-select custom-form-select"
                              aria-label="Default select example"
                              onChange={e => {
                                const selectedRank = e.target.value;
                                setStudents(prevStudents =>
                                  prevStudents.map(std => {
                                    if (std.id === student.id) {
                                      return {
                                        ...std,
                                        class: selectedRank,
                                      };
                                    }
                                    return std;
                                  })
                                );
                              }}
                            >
                              {Array.from({ length: 50 }, (_, i) => (
                                <option
                                  key={i + 1}
                                  value={(i + 1).toString()}
                                  selected={
                                    student.class === (i + 1).toString()
                                  }
                                >
                                  {i + 1}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td style={{ width: "20%" }}>
                            <input
                              className="w-100"
                              type="text"
                              // value={student?.member?.note}
                              value="test"
                              // onChange={e =>
                              //   classCommentHandler(student.id, e.target.value)
                              // }
                              // placeholder="試験についてのコメント"
                              readOnly
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="col-md-2">
                <Button
                  type="button"
                  className="btn-primary  "
                  data-bs-toggle="modal"
                  onClick={() => setShowModal(true)}
                >
                  登録する
                </Button>
              </div>

              <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    登録する
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>出席を提出しますか？</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => submitAttendance()}>保存する</Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <p className="text-info text-center">
              会員が見つかりませんでした。/ 出席者はすでに提出されています
            </p>
          )}
        </div>
      ) : (
        <p className="text-info text-center">
          まずはクラスとコースを選択してください。
        </p>
      )}
    </>
  );
}

export default StudentList;
