import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { getData } from "../../services/auth/token";
import privateApi from "../../services/privateApi";
import BackButton from "../common/Button/BackButton";
import ConvertTo from "../utilis/ConvertKataka";
import { useMediaQuery } from "react-responsive";
import { checkModulePermissionBySlug } from "../../utils/CheckModulePermission";
import { Skeleton } from "antd";

const UserRole = () => {
  const position = {
    bottom: "5%",
  };
  const [modalNewAdmin, setModalNewAdmin] = useState(false);
  const [modalEditAdmin, setModalEditAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [adminIdToDelete, setAdminIdToDelete] = useState(null);

  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedadmin] = useState();
  const [errorList, setErrorList] = useState({});
  const [loading, setLoading] = useState("");
  const [paginationBtns, setPaginationBtns] = useState([]);
  const [selectedAdminIDForDelete, setselectedCoachIDForDelete] = useState();
  const [showModalDel, setShowModalDel] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [currentModuleData, setCurrentModuleData] = useState({});
  const [adminModulePermissions, setAdminModulePermissions] = useState({});
  const [updateAdminId, setUpdateAdminId] = useState(null);

  const [newAdmin, setNewAdmin] = useState({
    first_name: "",
    last_name: "",
    furigana: "",
    name: "",
    phone: "",
    email: "",
    username: "",
    password: "",
    password_confirmation: "",
  });

  const [validation, setValidation] = useState({
    first_name: false,
    last_name: false,
    furigana: false,
    name: false,
    phone: false,
    email: false,
    username: false,
    password: false,
    password_confirmation: false,
  });

  const getAdminModulesData = async id => {
    const response = await privateApi.get(`admin/module-permissions/${id}`);
    if (response.status === 200) {
      setCurrentModuleData(response.data.data);
      convertApiResponseToPermissions(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const updateAdminPermissions = async id => {
    const response = await privateApi.post(
      `admin/module-permissions/${updateAdminId}`,
      {
        modules: adminModulePermissions,
      }
    );
    if (response.status === 200) {
      swal("更新しました！", "権限を更新しました！", "success");
      setShowPermissionModal(false);
      getAllAdmins();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  useEffect(() => {
    getAllAdmins();
  }, []);

  const handleInput = e => {
    const { name, value } = e.target;
    e.persist();
    setNewAdmin({ ...newAdmin, [e.target.name]: e.target.value });
    const isValid = value.trim() !== ""; // You can add more validation rules

    setValidation({
      ...validation,
      [name]: !isValid,
    });
  };

  const convertApiResponseToPermissions = modules => {
    if (!modules) {
      return {};
    }

    const transformedPermissions = modules.reduce((acc, module) => {
      const modulePermissions = module.permissions || {
        create: 0,
        read: 0,
        update: 0,
        delete: 0,
      };

      acc.push({
        id: module.id,
        permissions: {
          create: modulePermissions.create || 0,
          read: modulePermissions.read || 0,
          update: modulePermissions.update || 0,
          delete: modulePermissions.delete || 0,
        },
      });

      if (module.children && module.children.length > 0) {
        module.children.forEach(child => {
          const childPermissions = child.permissions || {
            create: 0,
            read: 0,
            update: 0,
            delete: 0,
          };

          acc.push({
            id: child.id,
            parent_id: module.id,
            permissions: {
              create: childPermissions.create || 0,
              read: childPermissions.read || 0,
              update: childPermissions.update || 0,
              delete: childPermissions.delete || 0,
            },
          });
        });
      }

      return acc;
    }, []);

    setAdminModulePermissions(transformedPermissions);
  };

  const updateStatus = async id => {
    let updatedSchool = {};
    admins.map(school => {
      if (school.id === id) {
        updatedSchool = { ...school, status: school.status === true ? 0 : 1 };
      }
    });
    const response = await privateApi.update(
      `admin/admins/status/${id}`,
      updatedSchool
    );
    if (response.status === 200) {
      swal("更新しました！", "ステータスを更新しました！", "success");
      getAllAdmins();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const renderCheckBox = (id, permission) => {
    if (!adminModulePermissions || !Array.isArray(adminModulePermissions)) {
      return null;
    }

    const permissionData = adminModulePermissions.find(
      module => module.id === id
    );

    const permissionValue = permissionData?.permissions[permission];

    return (
      <Form.Check
        type="checkbox"
        id={`${id}-${permission}`}
        label=""
        checked={permissionValue === 1}
        onChange={e => handlePermissionChange(id, permission)}
      />
    );
  };

  const handlePermissionChange = (id, permission) => {
    if (!adminModulePermissions || !Array.isArray(adminModulePermissions)) {
      return null;
    }

    const permissionData = adminModulePermissions.find(
      module => module.id === id
    );

    const permissionValue = permissionData?.permissions[permission];
    const updatedPermissions = adminModulePermissions.map(module => {
      if (module.id === id) {
        return {
          ...module,
          permissions: {
            ...module.permissions,
            [permission]: permissionValue === 1 ? 0 : 1,
          },
        };
      }
      return module;
    });
    setAdminModulePermissions(updatedPermissions);

    if (permissionData.parent_id) {
      const adminModulePermissionsForParent = updatedPermissions.filter(
        module => module.parent_id === permissionData.parent_id
      );

      const isAnyPermissionSet = adminModulePermissionsForParent.some(module =>
        Object.values(module.permissions).some(value => value === 1)
      );

      const updatedPermissionsForParent = updatedPermissions.map(module => {
        if (
          permissionData.parent_id &&
          module.id === permissionData.parent_id
        ) {
          return {
            ...module,
            permissions: {
              ...module.permissions,
              read: isAnyPermissionSet ? 1 : 0,
            },
          };
        }
        return module;
      });
      setAdminModulePermissions(updatedPermissionsForParent);
    }
  };

  const renderSubmodules = submodules => {
    const indentation = Array(3).fill("\u00A0\u00A0");
    return submodules.map(
      submodule =>
        submodule.status === "1" &&
        submodule.checked === true && (
          <tr key={submodule.id}>
            <td>
              {indentation.join("")}
              {submodule.name}
            </td>
            <td className="text-center">
              {renderCheckBox(submodule.id, "create")}
            </td>
            <td className="text-center">
              {renderCheckBox(submodule.id, "read")}
            </td>
            <td className="text-center">
              {renderCheckBox(submodule.id, "update")}
            </td>
            <td className="text-center">
              {renderCheckBox(submodule.id, "delete")}
            </td>
          </tr>
        )
    );
  };

  const renderModules = modules => {
    return modules.map(
      module =>
        module.status === "1" &&
        module.checked === true && (
          <React.Fragment key={module.id}>
            <tr>
              <td>{module.name}</td>
              {module.children && module.children.length > 0 ? (
                <td colSpan={4}></td>
              ) : (
                <>
                  <td className="text-center">
                    {renderCheckBox(module.id, "create")}
                  </td>
                  <td className="text-center">
                    {renderCheckBox(module.id, "read")}
                  </td>
                  <td className="text-center">
                    {renderCheckBox(module.id, "update")}
                  </td>
                  <td className="text-center">
                    {renderCheckBox(module.id, "delete")}
                  </td>
                </>
              )}
            </tr>
            {module.children && renderSubmodules(module.children)}
          </React.Fragment>
        )
    );
  };

  const handlePermissionColumnChange = (permission, checked) => {
    const updatedPermissions = adminModulePermissions.map(module => ({
      ...module,
      permissions: {
        ...module.permissions,
        [permission]: checked ? 1 : 0,
      },
    }));
    setAdminModulePermissions(updatedPermissions);
  };

  const renderPermissionHeader = (permission, label) => (
    <th style={{ width: "15%" }}>
      <Form.Check
        type="checkbox"
        id={`header-${permission}`}
        label={label}
        onChange={e =>
          handlePermissionColumnChange(permission, e.target.checked)
        }
      />
    </th>
  );

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const getAllAdmins = async pageNumber => {
    setLoading(true);
    const response = await privateApi.get(
      `admin/admins?page=${pageNumber}&per_page=10`
    );
    if (response.status === 200) {
      setAdmins(response.data.data);
      setPaginationBtns(response.data?.meta);
    } else {
      console.log("Server Error");
    }
    setLoading(false);
  };

  const ConfirmationModal = ({ show, onCancel, onConfirm }) => {
    if (!show) return null;

    return (
      <div className="modal">
        <div className="modal-content">
          <h2>Confirm Delete</h2>
          <p>Are you sure you want to delete this admin?</p>
          <button onClick={onCancel}>Cancel</button>
          <button onClick={onConfirm}>Delete</button>
        </div>
      </div>
    );
  };

  const handleDeleteClick = id => {
    setAdminIdToDelete(id);
    setShowModal(true);
  };

  const handlePermissionClick = id => {
    setUpdateAdminId(id);
    setShowPermissionModal(true);
    getAdminModulesData(id);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await privateApi.delete(
        `admin/admins/${adminIdToDelete}`
      );

      if (response.status === 200) {
        swal("削除しました！", "ユーザを削除しました！", "success");
        setShowModalDel(false);
        setShowModal(false);
        getAllAdmins();
      } else {
        swal(response.data.message, "", "error");
      }
    } catch (error) {
      swal("Error", "", "error");
    }
  };

  const handleCancelDelete = () => {
    setShowModalDel(false);
    setAdminIdToDelete(null);
  };

  const handleCloseModal = () => {
    // Close the modal without performing the delete operation
    setShowModal(false);
  };

  const addCoach = async () => {
    setShowPassword1(false);
    setShowPassword2(false);
    setNewAdmin([]);
    setModalNewAdmin(true);
  };

  const addNewAdmin = async () => {
    const response = await privateApi.post("admin/admins", {
      ...newAdmin,
      school_id: getData()?.school_id,
    });
    if (response.status === 200) {
      swal("作成しました！", "ユーザを作成しました。！", "success");
      setModalNewAdmin(false);
      getAllAdmins();
      setNewAdmin({});
      setShowValidation(false);
      setLoading(false);
      setErrorList({});
    } else {
      setLoading(false);
      if (response.status === 422) {
        setShowValidation(true);
        setErrorList(response.data.errors);
      } else {
        swal(response.data.message, "", "error");
      }
    }
  };

  const updateModalShow = async id => {
    try {
      setShowPassword1(false);
      setShowPassword2(false);
      setSelectedadmin(id);
      setModalEditAdmin(true);

      const response = await privateApi.get(`admin/admins/${id}`);

      if (response.status === 200) {
        const adminData = response.data.data;

        setNewAdmin({
          ...newAdmin,
          first_name: adminData.first_name,
          last_name: adminData.last_name,
          furigana: adminData.furigana,
          phone: adminData.phone,
          email: adminData.email,
          username: adminData.username,
          password: adminData.plain_password,
          password_confirmation: adminData.plain_password,
        });

        setShowValidation(false);
        setLoading(false);
        setErrorList({});
      } else {
        setLoading(false);

        if (response.status === 422) {
          setShowValidation(true);
          setErrorList(response.data.errors);
        } else {
          swal(response.data.message, "", "error");
        }
      }
    } catch (error) {
      console.error("Error in updateModalShow:", error);
    }
  };

  const updateFormSubmit = async id => {
    setLoading(true);

    const updateSchema = { ...newAdmin, school_id: getData()?.school_id };

    const response = await privateApi.update(
      `admin/admins/${id}`,
      updateSchema
    );

    if (response.status === 200) {
      swal("ユーザ情報を更新しました", "", "success");
      setModalEditAdmin(false);
      setNewAdmin({});
      setLoading(false);
      getAllAdmins();
      setShowValidation(false);
      setLoading(false);
      setErrorList({});
    } else {
      setLoading(false);

      if (response.status === 422) {
        setShowValidation(true);
        setErrorList(response.data.errors);
      } else {
        swal(response.data.message, "", "error");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      var full_name = "";
      if (!newAdmin.last_name) {
        full_name = `${newAdmin.first_name} `;
      } else {
        full_name = `${newAdmin.first_name} ${newAdmin.last_name}`;
      }
      if (newAdmin.first_name || newAdmin.last_name) {
        try {
          const convertedText = await ConvertTo(full_name, "katakana");
          if (convertedText) {
            setNewAdmin({
              ...newAdmin,
              furigana: convertedText,
            });
          } else {
            console.log("Something wrong");
          }
        } catch (error) {
          console.error("Error converting", error);
        }
      } else {
        setNewAdmin({
          ...newAdmin,
          furigana: "",
        });
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [newAdmin.first_name, newAdmin.last_name]);

  const formSection = (
    <div className="">
      <span
        className={`${showValidation ? "was-validated" : "needs-validation"}`}
      >
        <div className="row">
          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              姓 <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="姓を入力してください"
              name="first_name"
              value={newAdmin.first_name}
              onChange={handleInput}
              className={`form-control ${
                newAdmin.first_name === "" ? "is-invalid" : ""
              }`}
              required
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin.first_name === ""
                ? "このフィールドは必須です。"
                : errorList?.first_name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              名 <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="名を入力してください"
              name="last_name"
              value={newAdmin.last_name}
              onChange={handleInput}
              className={`form-control ${
                newAdmin.last_name === "" ? "is-invalid" : ""
              }`}
              required
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin.last_name === ""
                ? "このフィールドは必須です。"
                : errorList?.last_name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              フリガナ <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="フリガナを入力してください"
              name="furigana"
              value={newAdmin.furigana}
              onChange={handleInput}
              className={`form-control ${
                newAdmin.furigana === "" ? "is-invalid" : ""
              }`}
              required
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin.furigana === ""
                ? "このフィールドは必須です。"
                : errorList?.furigana}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              電話番号<span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder="電話番号を入力"
              name="phone"
              value={newAdmin.phone}
              onChange={handleInput}
              className={`form-control ${
                newAdmin.phone === "" ? "is-invalid" : ""
              }`}
              required
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin.phone === ""
                ? "このフィールドは必須です。"
                : errorList?.phone}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              Eメール <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="メールアドレスを入力"
              name="email"
              value={newAdmin.email}
              onChange={handleInput}
              className={`form-control ${
                newAdmin.email === "" ? "is-invalid" : ""
              }`}
              required
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin.email === ""
                ? "このフィールドは必須です。"
                : errorList?.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-12 mb-3" controlId="formBasicPassword">
            <Form.Label>
              ユーザー名 <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="ユーザー名を入力してください"
              name="username"
              value={newAdmin.username}
              onChange={handleInput}
              className={`form-control ${
                newAdmin.username === "" ? "is-invalid" : ""
              }`}
              required
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin.username === ""
                ? "このフィールドは必須です。"
                : errorList?.username}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              パスワード <span className="required">*</span>
            </Form.Label>
            <div className="input-group mb-6 mr-sm-3">
              <Form.Control
                type={showPassword1 ? "text" : "password"}
                placeholder="パスワードを入力してください"
                name="password"
                value={newAdmin.password}
                onChange={handleInput}
                className={`form-control ${
                  newAdmin.password === "" ? "is-invalid" : ""
                }`}
                required
              />
              <div
                className="input-group-prepend"
                onClick={togglePasswordVisibility1}
              >
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showPassword1 ? faEyeSlash : faEye}
                    className="py-1"
                  />
                </div>
              </div>
            </div>
            <Form.Control.Feedback type="invalid">
              {newAdmin.password === ""
                ? "このフィールドは必須です。"
                : errorList?.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              パスワードを再入力してください<span className="required">*</span>
            </Form.Label>
            <div className="input-group mb-6 mr-sm-3">
              <Form.Control
                type={showPassword2 ? "text" : "password"}
                placeholder="パスワードを再入力してください"
                name="password_confirmation"
                value={newAdmin.password_confirmation}
                onChange={handleInput}
                className={`form-control ${
                  newAdmin.password_confirmation === "" ? "is-invalid" : ""
                }`}
                required
              />
              <div
                className="input-group-prepend"
                onClick={togglePasswordVisibility2}
              >
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showPassword2 ? faEyeSlash : faEye}
                    className="py-1"
                  />
                </div>
              </div>
            </div>
            <Form.Control.Feedback type="invalid">
              {newAdmin.password_confirmation === ""
                ? "このフィールドは必須です。"
                : errorList?.password}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </span>
    </div>
  );

  const formSectionEdit = (
    <div className="">
      <span
        className={`${showValidation ? "was-validated" : "needs-validation"}`}
      >
        <div className="row">
          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              姓 <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="姓を入力してください"
              name="first_name"
              value={newAdmin?.first_name}
              onChange={handleInput}
              className={`form-control ${
                newAdmin?.first_name === "" ? "is-invalid" : ""
              }`}
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin?.first_name === ""
                ? "このフィールドは必須です。"
                : errorList?.first_name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              名 <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="名を入力してください"
              name="last_name"
              value={newAdmin?.last_name}
              onChange={handleInput}
              className={`form-control ${
                newAdmin?.last_name === "" ? "is-invalid" : ""
              }`}
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin?.last_name === ""
                ? "このフィールドは必須です。"
                : errorList?.last_name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              フリガナ <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="フリガナを入力してください"
              name="furigana"
              value={newAdmin?.furigana}
              onChange={handleInput}
              className={`form-control ${
                newAdmin?.furigana === "" ? "is-invalid" : ""
              }`}
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin?.furigana === ""
                ? "このフィールドは必須です。"
                : errorList?.furigana}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              電話番号<span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder="電話番号を入力"
              name="phone"
              value={newAdmin?.phone}
              onChange={handleInput}
              className={`form-control ${
                newAdmin?.phone === "" ? "is-invalid" : ""
              }`}
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin?.phone === ""
                ? "このフィールドは必須です。"
                : errorList?.phone}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              Eメール<span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="メールアドレスを入力"
              name="email"
              value={newAdmin?.email}
              onChange={handleInput}
              className={`form-control ${
                newAdmin?.email === "" ? "is-invalid" : ""
              }`}
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin?.email === ""
                ? "このフィールドは必須です。"
                : errorList?.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-12 mb-3" controlId="formBasicPassword">
            <Form.Label>
              ユーザー名 <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="ユーザー名を入力してください"
              name="username"
              value={newAdmin?.username}
              onChange={handleInput}
              className={`form-control ${
                newAdmin.username === "" ? "is-invalid" : ""
              }`}
            />
            <Form.Control.Feedback type="invalid">
              {newAdmin.username === ""
                ? "このフィールドは必須です。"
                : errorList?.username}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              パスワード <span className="required">*</span>
            </Form.Label>
            <div className="input-group mb-6 mr-sm-3">
              <Form.Control
                type={showPassword1 ? "text" : "password"}
                placeholder="パスワードを入力してください"
                name="password"
                value={newAdmin?.password}
                onChange={handleInput}
                className={`form-control ${
                  newAdmin?.password === "" ? "is-invalid" : ""
                }`}
                required
              />
              <div
                className="input-group-prepend"
                onClick={togglePasswordVisibility1}
              >
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showPassword1 ? faEyeSlash : faEye}
                    className="py-1"
                  />
                </div>
              </div>
              <Form.Control.Feedback type="invalid">
                {newAdmin?.password === ""
                  ? "このフィールドは必須です。"
                  : errorList?.password}
              </Form.Control.Feedback>
            </div>
          </Form.Group>

          <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
            <Form.Label>
              パスワードを再入力してください<span className="required">*</span>
            </Form.Label>
            <div className="input-group mb-6 mr-sm-3">
              <Form.Control
                type={showPassword2 ? "text" : "password"}
                placeholder="パスワードを再入力してください"
                name="password_confirmation"
                value={newAdmin?.password_confirmation}
                onChange={handleInput}
                className={`form-control ${
                  newAdmin?.password_confirmation === "" ? "is-invalid" : ""
                }`}
                required
              />
              <div
                className="input-group-prepend"
                onClick={togglePasswordVisibility2}
              >
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showPassword2 ? faEyeSlash : faEye}
                    className="py-1"
                  />
                </div>
              </div>
              <Form.Control.Feedback type="invalid">
                {newAdmin?.password_confirmation === ""
                  ? "このフィールドは必須です。"
                  : errorList?.password}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
        </div>
      </span>
    </div>
  );

  return (
    <div
      className="card"
      style={{
        width: "100%",
      }}
    >
      <div className="row p-2">
        <div className="col-9">
          <p className="headline">システムユーザ</p>
        </div>
        <div className="col-3 d-flex align-items-center">
          {checkModulePermissionBySlug("user-user-management", "create") ===
            1 && (
            <button
              className="btn btn-sm btn-primary px-3 ml-auto"
              variant="primary"
              onClick={addCoach}
            >
              {" "}
              <i className="fas fa-plus fa-fw"></i>
              新しく追加する
            </button>
          )}
        </div>
      </div>

      <div className="p-2">
        {loading && <Skeleton paragraph={{ row: 3 }} />}
        {admins && !loading && admins.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>名前</th>
                <th>フリガナ</th>
                <th>メール</th>
                <th>電話番号</th>
                <th>ユーザー名</th>
                <th>スターテス</th>
                <th style={{ width: "15%" }} className="text-center">
                  操作
                </th>
              </tr>
            </thead>
            <tbody>
              {admins.map((admin, index) => (
                <tr key={index}>
                  <td>
                    {admin?.first_name} {admin?.last_name}
                  </td>
                  <td>{admin?.furigana}</td>
                  <td>{admin?.email}</td>
                  <td>{admin?.phone}</td>
                  <td>{admin?.username}</td>
                  <td className="text-center">
                    {admin?.status ? (
                      <Form.Check
                        className="d-flex justify-content-center"
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={true}
                        onClick={() => updateStatus(admin.id)}
                      />
                    ) : (
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={false}
                        onClick={() => updateStatus(admin.id)}
                        className="d-flex justify-content-center"
                      />
                    )}
                  </td>
                  <td className="text-center">
                    {checkModulePermissionBySlug(
                      "user-user-management",
                      "update"
                    ) === 1 && (
                      <button
                        className="btn btn-sm btn-primary mx-1"
                        onClick={() => updateModalShow(admin.id)}
                      >
                        <FontAwesomeIcon icon="edit" />
                      </button>
                    )}
                    {checkModulePermissionBySlug(
                      "user-user-management",
                      "delete"
                    ) === 1 && (
                      <button
                        className="btn btn-sm btn-danger mx-1"
                        onClick={() => handleDeleteClick(admin.id)}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    )}
                    {checkModulePermissionBySlug(
                      "user-user-management",
                      "update"
                    ) === 1 && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>モジュールの権限</Tooltip>
                        }
                      >
                        <Button
                          className="btn btn-sm btn-success mx-1"
                          onClick={() => handlePermissionClick(admin.id)}
                        >
                          <FontAwesomeIcon icon="user-shield" />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <div className="col-3">
          {" "}
          <BackButton redirectTo="/admin/user/dashboard" position={position} />
        </div>

        {/* Modal for confirmation */}
        <ConfirmationModal
          show={showModalDel}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />

        {/* modal for adding new Coach */}
        <Modal
          show={modalNewAdmin}
          onHide={() => setModalNewAdmin(false)}
          size="xl"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
          // onsubmit={() => addNewAdmin()}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              新しいユーザを追加
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {formSection}
              <Button
                className="float-right px-5"
                variant="primary"
                // type="submit"
                onClick={() => addNewAdmin()}
                style={{ float: "right" }}
              >
                登録
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal for editing current admin*/}
        <Modal
          show={modalEditAdmin}
          onHide={() => setModalEditAdmin(false)}
          size="xl"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              ユーザ情報の更新
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {formSectionEdit}
              <Button
                className="float-right px-5"
                variant="primary"
                // type="submit"
                onClick={() => updateFormSubmit(selectedAdmin)}
                style={{ float: "right" }}
              >
                更新
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {admins && (
        <div className="paginations d-flex mt-3">
          <div className="d-flex mx-auto neomp">
            <Pagination
              activePage={
                paginationBtns.current_page ? paginationBtns.current_page : 0
              }
              itemsCountPerPage={paginationBtns.per_page}
              totalItemsCount={paginationBtns.total}
              onChange={pageNumber => {
                getAllAdmins(pageNumber);
              }}
              pageRangeDisplayed={5}
              itemclassName="page-item"
              linkclassName="page-link"
              firstPageText="First"
              lastPageText="Last"
            />
          </div>
        </div>
      )}
      {/* modal for edit */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>削除の確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>削除してもよろしいですか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            キャンセル
          </Button>
          <Button
            variant="danger"
            onClick={() => handleConfirmDelete(selectedAdminIDForDelete)}
          >
            更新
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal for permission */}
      <Modal
        show={showPermissionModal}
        onHide={() => setShowPermissionModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>モジュールの権限</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "75%" }}>
          <Table striped bordered hover responsive="lg">
            <thead>
              <tr>
                <th style={{ width: "40%" }}>モジュール名</th>
                {renderPermissionHeader("create", "作成する")}
                {renderPermissionHeader("read", "読む")}
                {renderPermissionHeader("update", "アップデート")}
                {renderPermissionHeader("delete", "消去")}
              </tr>
            </thead>
            <tbody>
              {currentModuleData.length && renderModules(currentModuleData)}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowPermissionModal(false)}
          >
            キャンセル
          </Button>
          <Button variant="success" onClick={updateAdminPermissions}>
            更新
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserRole;
