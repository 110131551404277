import AdminDashboard from "../components/admin/Dashboard";
// import Course from "../components/admin/Course";
import Student from "../components/admin/student/Student";
import StudentPayment from "../components/admin/StudentPayment";
import Coach from "../components/admin/Coach";
import Guardian from "../components/admin/Guardian";
import StudentPaymentHistory from "../components/admin/StudentPaymentHistory";
import BillingProcess from "../components/admin/BillingProcess";
import PaymentProcessing from "../components/admin/PaymentProcessing";
import MonthlyUpdate from "../components/admin/MonthlyUpdate";
import CourseTime from "../components/admin/CourseTime";
import CourseLevel from "../components/admin/CourseLevel";
import PayerDelinquentList from "../components/admin/PayerDelinquentList";
import ShortTermStuManagement from "../components/admin/ShortTermStuManagement";
import Backup from "../components/admin/Backup";
import Dashboard from "../components/admin/masterMaintenance/Dashboard";
import MasterBelonging from "../components/admin/masterMaintenance/MasterBelonging";
import TuitionMaster from "../components/admin/masterMaintenance/TuitionMaster";
import { EnvironMentalMaster } from "../components/admin/masterMaintenance/EnvirontmentalMaster";
import MasterShortCourse from "../components/admin/masterMaintenance/MasterShortCourse";
import CreateStudent from "../components/admin/student/CreateStudent";
import UpdateStudent from "../components/admin/student/UpdateStudent";
import BusUserDetailsPreview from "../components/extrapage/BusUserDetailsPreview";
import BusMaster from "../components/admin/BusMaster";
import BankMaster from "../components/admin/BankMaster";
import UserRole from "../components/admin/UserRole";
import BusDashboard from "../components/admin/busManagement/Dashboard";
import STDashboard from "../components/admin/shortTerm/Dashboard";
import ShortTermClass from "../components/admin/shortTerm/ShortTermClass";
import BankDashboard from "../components/admin/bankManagement/Dashboard";
import RMDashboard from "../components/admin/roleManagement/Dashboard";
import CoachFunction from "./CoachFunction";
import TransferAttendance from "../components/admin/TransferAttendance";
import CourseInfo from "../components/admin/CourseInfo";
import CreateShortTermStudent from "../components/admin/shortTerm/CreateShortTermStudent";
import ShortTermStudentList from "../components/admin/shortTerm/ShortTermStudentList";
import ClassReschedule from "../components/admin/ClassRechedule";
import UpdateShortTermStudent from "../components/admin/shortTerm/UpdateShortTermStudent";
import CourseMaster from "../components/admin/courseMaster/Dashboard";
import CourseMaintenance from "../components/admin/courseMaster/CourseMaintenance";
import CoachMaintenance from "../components/admin/courseMaster/CoachMaintenance";
import OutputBus from "../components/admin/busManagement/OutputBus";
import RankManagement from "../components/admin/rankManagement/RankManagement";
import rankManagementDashboard from "../components/admin/rankManagement/Dashboard";
import RankHistory from "../components/admin/rankManagement/RankHistory";
import StudentWithNote from "../components/admin/student/StudentWithNote";
import NewAdvancedCertificate from "../components/admin/NewAdvancedCertificate";
import IpAddress from "../components/admin/ipAddress/IpAddress";
import LoginLogs from "../components/admin/LoginLogs";
import RidingList from "../components/admin/busManagement/RidingList";
import RidingListDetails from "../components/admin/busManagement/RidingListDetails";
import BusSchedule from "../components/admin/BusSchedule";
import BusReport from "../components/admin/BusReport";
import CSVDownload from "../components/admin/busManagement/CSVDownload";
import StickerPage from "../components/admin/student/StickerPage";
import GradeLabels from "../components/admin/rankManagement/GradeLabels";
import CourseMaintenanceNew from "../components/admin/courseMaster/CourseMaintenanceNew";
import CoachMaintenanceNew from "../components/admin/courseMaster/CoachMaintenanceNew";
import Furikae from "../components/admin/furikae/ClassReschedule";
import SummaryReport from "../components/admin/SummaryReport";
import Certificate from "../components/admin/Certificate";

const routes = [
  { path: "/admin", exact: true, name: "Admin" },
  {
    path: "/admin/dashboard",
    exact: true,
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  // { path: "/admin/course", exact: true, name: "Profile", component: Course },
  { path: "/admin/student", exact: true, name: "Student", component: Student },
  {
    path: "/admin/student/create",
    exact: true,
    name: "Student",
    component: CreateStudent,
  },
  {
    path: "/admin/stc/student/create",
    exact: true,
    name: "Student",
    component: CreateShortTermStudent,
  },
  {
    path: "/admin/student/details/:id",
    exact: true,
    name: "Student",
    component: UpdateStudent,
  },
  {
    path: "/admin/stc/student/details/:id",
    exact: true,
    name: "Student",
    component: UpdateShortTermStudent,
  },
  // {
  //   path: "/admin/std-advanced-search",
  //   exact: true,
  //   name: "Student Advance Search",
  //   component: Student,
  // },

  {
    path: "/admin/student/payment-entry",
    exact: true,
    name: "Profile",
    component: StudentPayment,
  },
  {
    path: "/admin/student/payment-history",
    exact: true,
    name: "Profile",
    component: StudentPaymentHistory,
  },
  {
    path: "/admin/student/sticker_download",
    exact: true,
    name: "Sticker",
    component: StickerPage,
  },
  {
    path: "/admin/rank/dashboard",
    exact: true,
    name: "Rank Dashboard",
    component: rankManagementDashboard,
  },
  {
    path: "/admin/rank/report",
    exact: true,
    name: "Rank Report",
    component: RankManagement,
  },
  {
    path: "/admin/rank/rank-management",
    exact: true,
    name: "Rank",
    component: RankHistory,
  },
  {
    path: "/admin/rank/grade",
    exact: true,
    name: "Grade Labels",
    component: GradeLabels,
  },
  {
    path: "/admin/rank/rank-management/:id",
    exact: true,
    name: "Rank",
    component: RankHistory,
  },
  {
    path: "/admin/coach",
    exact: true,
    name: "Coach",
    component: CoachFunction,
  },
  {
    path: "/admin/bank/dashboard",
    exact: true,
    name: "Profile",
    component: BankDashboard,
  },
  {
    path: "/admin/bank/billing-process",
    exact: true,
    name: "Billing-Process",
    component: BillingProcess,
  },
  { path: "/admin/coach", exact: true, name: "Profile", component: Coach },
  {
    path: "/admin/bank/payment-process",
    exact: true,
    name: "Profile",
    component: PaymentProcessing,
  },
  {
    path: "/admin/monthly-update",
    exact: true,
    name: "monthly-update",
    component: MonthlyUpdate,
  },
  {
    path: "/admin/transfer-attendance",
    exact: true,
    name: "transfer-attendance",
    component: TransferAttendance,
  },

  {
    path: "/admin/short-term-student-management",
    exact: true,
    name: "st_stu_management",
    component: ShortTermStuManagement,
  },
  { path: "/admin/backup", exact: true, name: "backup", component: Backup },
  {
    path: "/admin/advanced-certificate",
    exact: true,
    name: "Profile",
    component: Certificate,
  },
  {
    path: "/admin/master-maintenance/dashboard",
    exact: true,
    name: "master maintenance",
    component: Dashboard,
  },
  {
    path: "/admin/master-maintenance/master-belonging",
    exact: true,
    name: "master maintenance",
    component: MasterBelonging,
  },
  {
    path: "/admin/master-maintenance/tuition-master",
    exact: true,
    name: "master maintenance",
    component: TuitionMaster,
  },
  {
    path: "/admin/master-maintenance/environmental-master",
    exact: true,
    name: "master maintenance",
    component: EnvironMentalMaster,
  },
  {
    path: "/admin/stc/dashboard",
    exact: true,
    name: "short term",
    component: STDashboard,
  },
  {
    path: "/admin/stc/short-term-course",
    exact: true,
    name: "short term course",
    component: MasterShortCourse,
  },
  {
    path: "/admin/stc/short-term-class",
    exact: true,
    name: "short term class",
    component: ShortTermClass,
  },
  {
    name: "short term student list",
    path: "/admin/stc/short-term-student-list",
    exact: true,
    component: ShortTermStudentList,
  },

  {
    path: "/admin/reschedule-class",
    exact: true,
    name: "RescheduleClass",
    component: Furikae,
    // component: ClassReschedule,
  },
  {
    path: "/admin/master-maintenance/course-time",
    exact: true,
    name: "CourseTime",
    component: CourseTime,
  },
  {
    path: "/admin/master-maintenance/course-level",
    exact: true,
    name: "CourseLevel",
    component: CourseLevel,
  },
  // {
  //   path: "/admin/master-maintenance/course-info",
  //   exact: true,
  //   name: "CourseInfo",
  //   component: CourseInfo,
  // },
  {
    path: "/admin/user/dashboard",
    exact: true,
    name: "master maintenance",
    component: RMDashboard,
  },
  {
    path: "/admin/user/user-management",
    exact: true,
    name: "coach role management",
    component: UserRole,
  },
  {
    path: "/admin/user/coach-management",
    exact: true,
    name: "User role management",
    component: Coach,
  },
  {
    path: "/admin/bus/schedule",
    exact: true,
    name: "Bus-Schedule",
    component: BusSchedule,
  },
  {
    path: "/admin/bus/report",
    exact: true,
    name: "Bus-Report",
    component: BusReport,
  },
  {
    path: "/admin/bus/bus-list",
    exact: true,
    name: "BusMaster",
    component: BusMaster,
    // component: BusSchedule,
  },
  {
    path: "/admin/bus/output-bus",
    exact: true,
    name: "Profile",
    component: OutputBus,
  },
  {
    path: "/admin/master-maintenance/b-master",
    exact: true,
    name: "BankMaster",
    component: BankMaster,
  },

  {
    path: "/admin/check-student-history",
    exact: true,
    name: "Profile",
    component: StudentWithNote,
  },
  {
    path: "/admin/summary-report",
    exact: true,
    name: "summary report",
    component: SummaryReport,
  },
  {
    path: "/admin/payer-delinquent-list",
    exact: true,
    name: "Profile",
    component: PayerDelinquentList,
  },
  {
    path: "/admin/output-bus/details",
    exact: true,
    name: "bso_details",
    component: BusUserDetailsPreview,
  },
  {
    path: "/admin/guardian",
    exact: true,
    name: "Guardian",
    component: Guardian,
  },
  {
    path: "/admin/ip",
    exact: true,
    name: "IP-Address",
    component: IpAddress,
  },
  {
    path: "/admin/login-logs",
    exact: true,
    name: "Login Logs",
    component: LoginLogs,
  },
  {
    path: "/admin/bus/dashboard",
    exact: true,
    name: "bus user maintenance",
    component: BusDashboard,
  },
  {
    path: "/admin/bus/riding-list",
    exact: true,
    name: "bus riding list",
    component: RidingList,
  },
  {
    path: "/admin/bus/riding-list/:id",
    exact: true,
    name: "bus riding list Details",
    component: RidingListDetails,
  },
  {
    path: "/admin/course-master",
    exact: true,
    name: "course_coach_maintenance",
    component: CourseMaster,
  },
  {
    path: "/admin/course-master/course-maintenance",
    exact: true,
    name: "course_maintenance",
    component: CourseMaintenanceNew,
    // component: CourseMaintenance,
  },
  {
    path: "/admin/course-master/coach-maintenance",
    exact: true,
    name: "coach_maintenance",
    component: CoachMaintenanceNew,
    // component: CoachMaintenance,
  },
];

export default routes;
