import React, { useState, useEffect } from "react";
import "./extraPageStyles.css";
import { Table } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";

const Page20 = ({ setPage20 }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedBanks, setSelectedBanks] = useState("");
  const [bankOptions, setBankOptions] = useState("選択する");

  const bankNames = [
    "バンク 1",
    "バンク 2",
    "バンク 3",
    "バンク 4",
    "バンク 5",
  ];
  const newArray = [];

  const createCustomArray = (bankNames) => {
    bankNames.forEach(function (item) {
      newArray.push({ label: item, value: item });
    });
    setBankOptions(newArray);
  };

  // useEffect(() => {
  //   createCustomArray(bankNames);
  // }, [bankNames]);

  return (
    <>
      {/* <div className="p-3">
        <p className="headline">ページ20</p>
      </div> */}

      <div className="container">
        <div className="row  gx-1 mt-3">
          <div className="col-md-auto">
            <button
              className="buttons_style  button3 btn btn-primary mb-1"
              variant="primary"
            >
              参照モード
            </button>
          </div>

          <div className="col-md-auto">
            <button
              className="buttons_style  button3 btn btn-primary mb-1"
              variant="primary"
            >
              編集モード
            </button>
          </div>
        </div>

        <div className="row  gx-1 mt-3">
          <div className="col-md-auto">
            <button
              className="buttons_style  button3 btn btn-primary mb-1"
              variant="primary"
            >
              会員
            </button>
          </div>

          <div className="col-md-auto">
            <Link to={"/admin/course"}>
              <button
                className="buttons_style  button3 btn btn-primary mb-1"
                variant="primary"
              >
                ﾔﾏﾀﾞ　ﾊﾅｺ
              </button>
            </Link>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">:会員番号</div>
              <div className="col-md-2">
                <input
                  type="text"
                  placeholder="0000001"
                  className="form-control"
                />
              </div>
              <div className="col-md-auto ms-3 ">会</div>
            </div>

            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">:氏名</div>
              <div className="col-md-4">
                <input
                  type="text"
                  placeholder="山田 花子"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">ﾌﾘｶﾞﾅ</div>
              <div className="col-md-4">
                <input
                  type="text"
                  placeholder="Test"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">:性別</div>
              <div className="col-md-2">
                <input type="text" placeholder="女" className="form-control" />
              </div>
            </div>

            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">:生年月日</div>
              <div className="col-md-3">
                <ReactDatePicker
                  className="form-control text-center inputs_bg"
                  selected={startDate}
                  onChange={(date) => {
                    if(date)
                      setStartDate(date)
                  }}
                  dateFormat="yyyy/MM"
                />
              </div>
            </div>

            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">:郵便番号</div>
              <div className="col-md-2">
                <input
                  type="text"
                  placeholder="555-9999"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">:住所1</div>
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="大阪市住 Test"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">:住所2</div>
              <div className="col-md-6">
                <input type="text" placeholder="111" className="form-control" />
              </div>
            </div>

            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">:電話番号1</div>
              <div className="col-md-3">
                <input
                  type="text"
                  placeholder="06-111-1111"
                  className="form-control"
                />
              </div>

              <div className="col-md-auto ms-3">電話番号2:</div>
              <div className="col-md-3">
                <input
                  type="text"
                  placeholder="06-111-9999"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row  g-1 align-items-center mt-0  ">
              <div className="col-md-1 ms-3 rtl">保護者氏名:</div>
              <div className="col-md-4">
                <input
                  type="text"
                  placeholder="山田 一郎"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-12 mt-2">
              <div className="card">
                <div className="card-body">
                  <p className="mt-1 mb-0">道バス:</p>
                </div>
                <div className="row mb-2 gx-2 align-items-center">
                  <div className="col-md-1 rtl">:パスコース</div>
                  <div className="col-6">
                    <ReactSelect
                      className="shadow-sm "
                      placeholder="A01 桜井町かつら"
                      options={bankOptions}
                      onChange={setSelectedBanks}
                      value={selectedBanks}
                    />
                  </div>

                  <div className="col-md-auto">住话:</div>
                  <div className="col-md-1">
                    <input
                      type="text"
                      placeholder="1"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-auto">Y</div>
                  <div className="col-md-auto">則:</div>
                  <div className="col-md-1">
                    <input
                      type="text"
                      placeholder="1"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-auto">Y</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row rtl mt-3">
          <div className="col-md-auto">
            <button className="btn btn-primary mb-1" variant="primary">
              {" "}
              曜じる
            </button>
          </div>

          <div onClick={() => setPage20(false)} className="col-md-auto mb-2">
            <button className="btn btn-primary mb-1" variant="primary">
              {" "}
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page20;
