import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import swal from "sweetalert";
import adminLogo from "../../assets/super-admin/user-circle-svgrepo-com.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import privateApi from "../../services/privateApi";

const SuperAdmin = () => {
  const [modalNewAdmin, setModalNewAdmin] = useState(false);
  const [modalEditAdmin, setModalEditAdmin] = useState(false);
  const [supAdmins, setSupAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const [errorList, setErrorList] = useState({});
  const [newSuperAdminData, setNewSuperAdminData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    username: "",
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    getSuperAdmins();
  }, []);

  const handleInput = (e, setterFunction, currentState) => {
    e.persist();
    setErrorList({});
    setterFunction({ ...currentState, [e.target.name]: e.target.value });
  };

  const getSuperAdmins = async (e) => {
    const response = await privateApi.get("superadmins");
    if (response.status === 200) {
      setSupAdmins(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const updateStatus = async (id) => {
    let updatedSuperAdmin = {};
    supAdmins.map((supAdmin) => {
      if (supAdmin.id === id) {
        updatedSuperAdmin = { ...supAdmin, status: supAdmin.status === true ? 0 : 1 };
      }
    });
    const response = await privateApi.update(`superadmins/status/${id}`, updatedSuperAdmin);
    if (response.status === 200) {
      swal("更新しました！", "ステータスを更新しました！", "success");
      getSuperAdmins();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const deleteSuperAdmin = async (id) => {
    const response = await privateApi.delete(`superadmins/${id}`);

    if (response.status === 200) {
      swal("削除しました！", "スーパー管理者が削除されました！", "success");
      getSuperAdmins();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const addNewSuperAdmin = async () => {
    const response = await privateApi.post("superadmin/register", newSuperAdminData);
    if (response.status === 200) {
      swal("作成しました！", "新しいスーパー管理者が作成されました！", "success");
      setModalNewAdmin(!modalNewAdmin);
      getSuperAdmins();
      setNewSuperAdminData({});
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const updateSuperAdmin = async (id) => {
    const response = await privateApi.update(`superadmins/${id}`, selectedAdmin);
    if (response.status === 200) {
      swal("作成しました！", "新しいスーパー管理者が作成されました！", "success");
      setModalNewAdmin(!modalNewAdmin);
      getSuperAdmins();
    } else {
      swal(response.data.message, "", "error");
    }
  };

  const handleEditModal = (currentAdmin) => {
    setModalEditAdmin(true);
    setSelectedAdmin({
      ...currentAdmin,
      id: currentAdmin.id,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-10">
          <p className="headline">スーパー管理者のリスト</p>
        </div>
        <div className="col-2 d-flex align-items-center">
          <button
            className="btn btn-sm btn-primary px-3 ml-auto"
            variant="primary"
            onClick={() => setModalNewAdmin(true)}
          >
            {" "}
            <i className="fas fa-plus fa-fw"></i>
            新しく追加する
          </button>
        </div>
      </div>

      <div>
        {supAdmins && supAdmins.length > 0 && (
          <Table
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>名前</th>
                <th>メール</th>
                <th>電話番号</th>
                <th className="text-center">画像</th>
                <th className="text-center">スターテス</th>
                <th className="text-center">アクション</th>
              </tr>
            </thead>
            <tbody>
              {supAdmins.map((supAdmin, index) => (
                <tr key={index}>
                  <td>{supAdmin.name}</td>
                  <td>{supAdmin.email}</td>
                  <td>{supAdmin.phone}</td>

                  <td className="d-flex justify-content-center">
                    <img
                      src={adminLogo}
                      alt="logo"
                      height="50"
                      width="50"
                      className="d-flex align-items-center"
                    />
                  </td>

                  <td className="text-center">
                    {supAdmin.status ? (
                      <Form.Check
                        className="d-flex justify-content-center"
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={true}
                        onClick={() => updateStatus(supAdmin.id)}
                      />
                    ) : (
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label=""
                        checked={false}
                        onClick={() => updateStatus(supAdmin.id)}
                        className="d-flex justify-content-center"
                      />
                    )}
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-sm btn-primary mx-1"
                      onClick={() => handleEditModal(supAdmin)}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </button>
                    <button
                      className="btn btn-sm btn-danger mx-1"
                      onClick={() => deleteSuperAdmin(supAdmin.id)}
                    >
                      <FontAwesomeIcon icon="trash" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {/* modal for adding new super admin */}
        <Modal
          show={modalNewAdmin}
          onHide={() => setModalNewAdmin(false)}
          size="xl"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
          // onsubmit={() => addNewSuperAdmin()}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              新しいスーパー管理者を追加
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="">
                <div className="row">
                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      ファーストネーム <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="名前を入力してください"
                      name="first_name"
                      onChange={(e) => handleInput(e, setNewSuperAdminData, newSuperAdminData)}
                      value={newSuperAdminData.first_name}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      苗字 <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="姓を入力してください"
                      name="last_name"
                      onChange={(e) => handleInput(e, setNewSuperAdminData, newSuperAdminData)}
                      value={newSuperAdminData.last_name}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      電話 <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="電話番号を入力"
                      name="phone"
                      onChange={(e) => handleInput(e, setNewSuperAdminData, newSuperAdminData)}
                      value={newSuperAdminData.phone}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      ユーザー名 <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ユーザー名を入力してください"
                      name="username"
                      onChange={(e) => handleInput(e, setNewSuperAdminData, newSuperAdminData)}
                      value={newSuperAdminData.username}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-12 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      Eメール <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="メールアドレスを入力"
                      name="email"
                      onChange={(e) => handleInput(e, setNewSuperAdminData, newSuperAdminData)}
                      value={newSuperAdminData.email}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      パスワード <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="パスワードを入力してください"
                      name="password"
                      onChange={(e) => handleInput(e, setNewSuperAdminData, newSuperAdminData)}
                      value={newSuperAdminData.password}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      パスワードを再入力してください <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="パスワードを再入力してください"
                      name="password_confirmation"
                      onChange={(e) => handleInput(e, setNewSuperAdminData, newSuperAdminData)}
                      value={newSuperAdminData.password_confirmation}
                    />
                  </Form.Group>

                  {/* <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
                    <Form.Label>画像 (随意)</Form.Label>
                    <Form.Control type="File" placeholder="入力 画像" />
                  </Form.Group> */}
                </div>
              </div>

              <Button
                className="float-right px-5"
                variant="primary"
                // type="submit"
                onClick={() => addNewSuperAdmin()}
                style={{ float: "right" }}
              >
                保存
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal for editing current super admin*/}
        <Modal
          show={modalEditAdmin}
          onHide={() => setModalEditAdmin(false)}
          size="xl"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              スーパー管理者情報の更新
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="">
                <div className="row">
                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      ファーストネーム <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="名前を入力してください"
                      name="first_name"
                      onChange={(e) => handleInput(e, setSelectedAdmin, selectedAdmin)}
                      value={selectedAdmin.first_name}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      苗字 <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="姓を入力してください"
                      name="last_name"
                      onChange={(e) => handleInput(e, setSelectedAdmin, selectedAdmin)}
                      value={selectedAdmin.last_name}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      電話 <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="電話番号を入力"
                      name="phone"
                      onChange={(e) => handleInput(e, setSelectedAdmin, selectedAdmin)}
                      value={selectedAdmin.phone}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      ユーザー名 <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ユーザー名を入力してください"
                      name="username"
                      onChange={(e) => handleInput(e, setSelectedAdmin, selectedAdmin)}
                      value={selectedAdmin.username}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-12 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      Eメール <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="メールアドレスを入力"
                      name="email"
                      onChange={(e) => handleInput(e, setSelectedAdmin, selectedAdmin)}
                      value={selectedAdmin.email}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      パスワード <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="パスワードを入力してください"
                      name="password"
                      onChange={(e) => handleInput(e, setSelectedAdmin, selectedAdmin)}
                      value={selectedAdmin.password}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-md-6 mb-3"
                    controlId="formBasicPassword"
                  >
                    <Form.Label>
                      パスワードを再入力してください <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="パスワードを再入力してください"
                      name="password_confirmation"
                      onChange={(e) => handleInput(e, setSelectedAdmin, selectedAdmin)}
                      value={selectedAdmin.password_confirmation}
                    />
                  </Form.Group>

                  {/* <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
                    <Form.Label>画像 (随意)</Form.Label>
                    <Form.Control type="File" placeholder="入力 画像" />
                  </Form.Group> */}
                </div>
              </div>

              <Button
                className="float-right px-5"
                variant="primary"
                // type="submit"
                onClick={() => updateSuperAdmin(selectedAdmin.id)}
                style={{ float: "right" }}
              >
                保存
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default SuperAdmin;
