import React, { useEffect, useState } from "react";
import "../../assets/common/css/modal.css";
import { Button, Form } from "react-bootstrap";
import privateApi from "../../services/privateApi";
import BackButton from "../common/Button/BackButton";
import swal from "sweetalert";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PageLoader from "../common/PageLoader";

const SummaryReport = () => {
  const [mainLoading, setMainLoading] = useState(false);

  const [name, setName] = useState(false);
  const [startTime, setStartTime] = useState(false);
  const [numberOfLimit, setNumberOfLimit] = useState(false);
  const [level, setLevel] = useState(false);

  const position = { position: "static" };

  const [coachs, setcoachs] = useState([]);
  const getAllCoach = async pageNumber => {
    const response = await privateApi.get(
      `admin/coaches?page=${pageNumber}&per_page=10`
    );
    if (response.status === 200) {
      setcoachs(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  useEffect(() => {
    getAllCoach();
  }, []);

  const getTuitionMasters_csv_export = async () => {
    try {
      const response = await privateApi.get("admin/tution-fees/csv/export");
      if (response.status === 200) {
        return response.data.data; // Return the data
      } else {
        console.log("Server Error");
        return []; // Return an empty array in case of an error
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      return []; // Return an empty array in case of an error
    }
  };
  async function download_table_as_csv(
    table_id,
    TuitionMasters_csv_export,
    separator = ","
  ) {
    var csv = [];
    var header = [];
    header.push('"' + "所属コード" + '"');
    header.push('"' + "所属名" + '"');
    header.push('"' + "回数" + '"');
    header.push('"' + "授業料" + '"');
    header.push('"' + "特別料1" + '"');
    header.push('"' + "特別料2" + '"');
    header.push('"' + "休校料" + '"');
    csv.push(header.join(separator));
    TuitionMasters_csv_export &&
      TuitionMasters_csv_export.forEach(tuition_fees => {
        var row = [];
        row.push('"' + tuition_fees.affiliation_code + '"');
        row.push('"' + tuition_fees.affiliation_name + '"');
        row.push('"' + tuition_fees.times + '"');
        row.push('"' + tuition_fees.tution_fee + '"');
        row.push('"' + tuition_fees.special_fee_1 + '"');
        row.push('"' + tuition_fees.special_fee_2 + '"');
        row.push('"' + tuition_fees.closure_fee + '"');
        csv.push(row.join(separator));
      });
    var csv_string = csv.join("\n");
    var filename =
      "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async table_id => {
    try {
      const TuitionMasters_csv_export = await getTuitionMasters_csv_export(); // Fetch the data
      await download_table_as_csv(table_id, TuitionMasters_csv_export); // Pass the data to the download function
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };

  const handleDownloadTextFile = async e => {};

  const handleDownload = async e => {
    e.preventDefault();

    setMainLoading(true)

    const formData = {
      name: name,
      start_time: startTime,
      number_of_limit: numberOfLimit,
      level: level,
    };

    try {
      const response = await privateApi.post(
        "admin/exports/members-by-class-schedules",
        formData
      );

      if (response.status === 200) {
        swal("成功", "", "success");

        const job_id = response.data.data.job_id;

        console.log("object", job_id);

        const response2 = await privateApi.get(
          "admin/exports/job-status/" + job_id
        );

        if (response2.status === 200) {
          const res = response2.data.data;

          if (res.status == "Completed") {
            setMainLoading(false)
            downloadFile(res.file_path);
          }
        }
      } else {
        swal("エラー", "ファイルをアップロードできませんでした。", "error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadFile = url => {
    const link = document.createElement("a");
    link.href = url;

    const filename = level ? "_曜日別人数集計所属名開始時間回数級.csv" : "曜日別人数集計所属名開始時間回数.csv"

    link.setAttribute("download", filename);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    // Cleanup
    document.body.removeChild(link);
  };

  const memberClassDownload = async () => {
    try {
      const response = await privateApi.post("admin/exports/member-classes");

      if (response.status === 200) {
        swal("成功", "ファイルをアップロードしました。", "success");
        downloadFile2(response.data.data);
      } else {
        swal("エラー", "ファイルをアップロードできませんでした。", "error");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadFile2 = url => {
    console.log("url", url);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      moment(new Date()).format("YYYYMMDD") + "_進級者リスト.csv"
    );
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
  };

  return (
    <div style={{ position: "relative" }}>

{mainLoading && <PageLoader />}

      <div className="card col-sm-12 col-md-8 col-lg-6 col-xl-6 mt-1">
        <div className="card-header p-0 m-0">
          <h5 className="card-title m-1">人数集計/一覧表</h5>
        </div>
        <div className="card-body mt-1">
          <div className="container">
            <Form>
              <div className="row">
                <div className="col-md-6 d-flex align-items-center">
                  <Form className="mem-checkbox">
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="全ての年齢を表示する"
                      onClick={handleDownloadTextFile}
                    />
                  </Form>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    <Button
                      size="sm"
                      className=""
                      variant="primary"
                      style={{ width: "300px" }}
                      onClick={() => handleDownloadCSV("CLASS_TABLE")}
                    >
                      地区別人数集計
                    </Button>
                  </div>
                  <div className="row">
                    {" "}
                    <Button
                      size="sm"
                      className="my-2"
                      variant="primary"
                      type="submit"
                      style={{ width: "300px" }}
                    >
                      年齢別人数集計
                    </Button>
                  </div>
                  <div className="row">
                    <Button
                      size="sm"
                      className="mb-2"
                      variant="primary"
                      onClick={memberClassDownload}
                      style={{ width: "300px" }}
                    >
                      進級者リスト
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="card col-sm-12 col-md-8 col-lg-6 col-xl-6 mt-2">
        <div className="card-header p-0 m-0">
          <h5 className="card-title m-1">曜日別人数集計</h5>
        </div>
        <div className="card-body mt-1">
          <div className="container">
            <Form>
              <div className="row mb-2">
                <div className="col-md-6 mb-2">
                  <h7 className="card-title m-1">集計単位:</h7>
                  <Form className="mem-checkbox">
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="所属名"
                      onClick={() => setName(!name)}
                      checked={name}
                    />
                  </Form>
                  <Form className="mem-checkbox">
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="開始時間"
                      onClick={() => setStartTime(!startTime)}
                      checked={startTime}
                    />
                  </Form>
                  <Form className="mem-checkbox">
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="回数"
                      onClick={() => setNumberOfLimit(!numberOfLimit)}
                      checked={numberOfLimit}
                    />
                  </Form>
                  <Form className="mem-checkbox">
                    <Form.Check
                      type="checkbox"
                      id="custom-switch"
                      label="級"
                      onClick={() => setLevel(!level)}
                      checked={level}
                    />
                  </Form>
                </div>
                <div className="col-md-6 mb-2">
                  <div className="row">
                    <Button
                      size="sm"
                      className=""
                      variant="primary"
                      // type="submit"
                      style={{ width: "300px" }}
                      onClick={handleDownload}
                    >
                      曜日別人数集計
                    </Button>
                  </div>
                </div>

                {/* <hr />
                <div className="row mt-2">
                  <Table striped bordered hover >
                    <thead>
                      <tr>
                        <th>ファイル名</th>
                        <th>作成する時間</th>
                        <th>ダウンロードできます</th>
                        <th className="text-center">操作</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coachs.map((coach, index) => (
                        <tr key={index}>

                          <td>曜日別人数集計所属名開始時間回数級.csv</td>
                          <td>2024年05月23日  16:13</td>
                          <td>X</td>
                          <td className="text-center">
                            <button
                              className="btn btn-sm btn-primary mx-1"
                            >
                              <FontAwesomeIcon icon="download" />
                            </button>
                          </td>
                        
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div> */}
              </div>
            </Form>
          </div>
        </div>
      </div>
      <BackButton redirectTo="/admin/dashboard" position={position} />
    </div>
  );
};

export default SummaryReport;
