import React from "react";
import "./loader.css";

export default function Loader({ message }) {
  return (
    <div id="outer">
      <div id="middle">
        <div id="inner"></div>
      </div>
      {message && <p className="loading-message">{message}</p>}
    </div>
  );
}
