import React from "react";
import { Link } from "react-router-dom";

import "./extraPageStyles.css";

const masterMaintenanceDashboardButtons = [
  {
    name: "クラスマスタ",
    path: "/admin/master-maintenance/master-belonging",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "コースマスタ",
    path: "/admin/master-maintenance/course-time",
    icon: "fas fa-tachometer-alt",
  },
  // {
  //   name: "地区マスタ",
  //   path: "/admin/maintenance/",
  //   icon: "fas fa-tachometer-alt",
  // },
  // {
  //   name: "ログインID",
  //   path: "/admin/maintenance/",
  //   icon: "fas fa-tachometer-alt",
  // },
  {
    name: "銀行マスタ",
    path: "/admin/master-maintenance/b-master",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "授業料マスタ",
    path: "/admin/master-maintenance/tuition-master",
    icon: "fas fa-tachometer-alt",
  },

  {
    name: "環境マスタ",
    path: "/admin/master-maintenance/environmental-master",
    icon: "fas fa-tachometer-alt",
  },

  // {
  //   name: "短期クラスマスタ",
  //   path: "/admin/maintenance/dashboard",
  //   icon: "fas fa-tachometer-alt",
  // },
  // {
  //   name: "短期コースマスタ",
  //   path: "/admin/master-maintenance/short-term-course",
  //   icon: "fas fa-tachometer-alt",
  // },
  // {
  //   name: "パスコースマスタ",
  //   path: "/admin/maintenance/dashboard",
  //   icon: "fas fa-tachometer-alt",
  // },
];

function Dashboard() {
  return (
    <div className="p-3">
      <p className="headline">マスター メンテナンス 機能一覧</p>
      <div className="btn-container col-md-4 col-sm-12">
        {masterMaintenanceDashboardButtons.map(button => (
          <Link to={button.path}>
            <button className="dash-btn">{button.name}</button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
