import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import swal from "sweetalert";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";
import privateApi from "../../../services/privateApi";
import "../../../assets/coach/css/styles.css";
import "./promotion.css";

const RankManagement = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateStr, setSelectedDateStr] = useState(null);

  const [isLastMonth, setIsLastMonth] = useState(false);
  const [classes, setClasses] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  const [classSchedule, setClassSchedule] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedWeekday, setSelectedWeekday] = useState("");
  const [noDataMsg, setNoDataMsg] = useState("");
  const [levels, setLevels] = useState([]);
  const [showUpdateAllModal, setShowUpdateAllModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stoppagelevel, setStoppagelevel] = useState([
    {
      class: "",
      grades: [],
      data: [],
    },
  ]);

  const rangeOptions = [...Array(25).keys()].map(i => i + 1);
  const weekDays = ["すべて 日", "日", "月", "火", "水", "木", "金", "土"];

  const guideline =
    selectedRange &&
    stoppagelevel &&
    stoppagelevel.find(level => level.rank === selectedRange?.value)?.guideline;

  const labelInfo =
    selectedRange &&
    stoppagelevel &&
    stoppagelevel.find(level => level.rank === selectedRange?.value)?.level;
  let output = "";

  if (labelInfo && Object.keys(labelInfo).length > 1) {
    let isFirst = true;

    for (const key in labelInfo) {
      if (key !== "") {
        if (!isFirst) {
          output += ", ";
        }

        output += `${key}: ${labelInfo[key]}`;

        isFirst = false;
      }
    }
  }

  const labelDetails = `${output}`;

  const fetchStudents = async () => {
    try {
      const response = await privateApi.post("/admin/membersByRank", {
        date: selectedDateStr,
        day_week: selectedWeekday.value === 0 ? "" : selectedWeekday.label,
        regular_class_id: selectedClass?.value,
        class: selectedRange?.value,
        start_time: selectedSchedule?.start_time,
        end_time: selectedSchedule?.end_time,
      });
      if (response.status === 200) {
        setIsLastMonth(response.data?.data?.isLastMonth);
        const studentData = response.data?.data;
        const studentsWithManualTotal = studentData.map(student => {
          const attendancesWithManualTotal = student.attendances.map(att => ({
            ...att,
            manual_total: "",
          }));
          return { ...student, attendances: attendancesWithManualTotal };
        });
        setStudents(studentsWithManualTotal);
      } else {
        setNoDataMsg("対象のデータはありません。");
        console.log("Server Error");
      }
    } catch (error) {
      console.error("Error fetching student attendance data:", error);
    }
  };

  const selectDateHandler = date => {
    if (date) {
      setSelectedDate(date);
      refactorSelectedDate();
    }
  };

  const handlePrint = async () => {
    const printWindow = window.open("", "_blank");
    await printWindow.document.write(
      '<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">'
    );
    const printContent = document.querySelector(".print-this");

    if (printWindow && printContent) {
      printWindow.document.write("<html><head><title>進級管理</title>");

      printWindow.document.write(`
      <style>
        body {
          margin: 20px;
        }
        table {
          border-collapse: collapse;
          width: 100%;
        }
        table, th, td {
          border: 1px solid black; 
        }
        @page {
          size: landscape;
        }
      </style>
    `);

      printWindow.document.write("</head><body>");
      printWindow.document.write(printContent.innerHTML);
      printWindow.document.write("</body></html>");

      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("Print failed. Make sure there is content to print.");
    }
  };

  const handleCheckboxChange = student => {
    const memberId = student.member_id;
    const existingIndex = selectedStudents.findIndex(
      selected => selected.memberId === memberId
    );

    if (existingIndex !== -1) {
      setSelectedStudents([
        ...selectedStudents.slice(0, existingIndex),
        ...selectedStudents.slice(existingIndex + 1),
      ]);
    } else {
      setSelectedStudents([
        ...selectedStudents,
        {
          memberId,
          class: student.class,
          date: currentMonth,
          regular_class_id: selectedClass?.value,
          manual_attendance: student?.manual_attendance || [],
        },
      ]);
    }
  };

  const handleManualAttendanceChange = (e, student, month) => {
    const memberId = student.member_id;

    const existingIndex = selectedStudents.findIndex(
      selected => selected.memberId === memberId
    );

    if (existingIndex !== -1) {
      const newSelectedStudents = selectedStudents.map(selected => {
        if (selected.memberId === memberId) {
          const newManualAttendance = selected.manual_attendance.map(att => {
            if (att.month === month + 1) {
              return { month: month + 1, total: e.target.value };
            }
            return att;
          });

          return {
            ...selected,
            class: student?.class || "",
            manual_attendance: newManualAttendance,
          };
        }
        return selected;
      });

      setSelectedStudents(newSelectedStudents);
      setStudents(
        students.map(student => {
          if (student.member_id === memberId) {
            const newAttendances = student.attendances.map(att => {
              if (att.month === month + 1) {
                return {
                  ...att,
                  manual_total: e.target.value,
                };
              }
              return att;
            });

            return {
              ...student,
              attendances: newAttendances,
            };
          }
          return student;
        })
      );
    } else {
      setSelectedStudents([
        ...selectedStudents,
        {
          memberId,
          class: student.class,
          date: currentMonth,
          regular_class_id: selectedClass?.value,
          manual_attendance: [{ month: month + 1, total: e.target.value }],
          comments: student?.comments || [],
        },
      ]);

      setStudents(
        students.map(student => {
          if (student.member_id === memberId) {
            const newAttendances = student.attendances.map(att => {
              if (att.month === month + 1) {
                return {
                  ...att,
                  manual_total: e.target.value,
                };
              }
              return att;
            });

            return {
              ...student,
              attendances: newAttendances,
            };
          }
          return student;
        })
      );
    }
  };

  const handleLabelCheckboxChange = (student, grade) => {
    const memberId = student.member_id;
    const existingIndex = selectedStudents.findIndex(
      selected => selected.memberId === memberId
    );
    if (existingIndex !== -1) {
      const newSelectedStudents = selectedStudents.map(selected => {
        if (selected.memberId === memberId) {
          const gradeExists = selected.comments.some(
            comment => comment.grade === grade
          );
          if (gradeExists) {
            return selected;
          }
          return {
            ...selected,
            class: student.class,
            date: currentMonth,
            regular_class_id: selectedClass?.value,
            manual_attendance: selected?.manual_attendance,
            comments: [...selected.comments, { grade: grade }],
          };
        }
        return selected;
      });

      setSelectedStudents(newSelectedStudents);
    }
  };

  const refactorSelectedDate = () => {
    if (selectedDate) {
      const selectedDateCopy = new Date(selectedDate);
      selectedDateCopy.setDate(selectedDateCopy.getDate() + 1);
      setSelectedDateStr(
        selectedDateCopy?.toISOString().split("T")[0].slice(0, 7)
      );
    }
  };

  const getClasses = async e => {
    const response = await privateApi.get("admin/classes");
    if (response.status === 200) {
      setClasses(response.data.data);
    } else {
      console.log("Server Error");
    }
  };

  const getScheduleInfo = async id => {
    const response = await privateApi.get(
      `/admin/class-schedules/${selectedClass?.value}`
    );

    if (response.status === 200) {
      if (response.data.data && response.data.data.classSchedule) {
        setClassSchedule(response.data.data.classSchedule);
      } else {
        setClassSchedule([]);
      }
    } else {
      console.log("Server Error");
    }
  };

  const updateSelected = async () => {
    setIsLoading(true);
    try {
      const response = await privateApi.post("admin/submit-bulk-rank", {
        selectedStudents: selectedStudents,
      });
      if (response.status === 200) {
        swal("保存されました。", "級が更新されました。", "success");
        setSelectedStudents([]);
        setIsLoading(false);
        setShowUpdateAllModal(false);
        fetchStudents();
      } else {
        setShowUpdateAllModal(false);
        setIsLoading(false);
        swal(response.data.message, "", "error");
      }
    } catch (error) {
      // setShowStudentModal(false);
    }
  };

  const getPromotionsData = async id => {
    try {
      const response = await privateApi.get(
        `admin/promotions?class=${selectedClass?.value}`
      );
      if (response.status === 200) {
        const promData = response.data;
        setNoDataMsg("");
        setLevels(promData.grades);
        setStoppagelevel(promData?.data[0]?.data);
      } else {
        console.log("Server Error");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    getScheduleInfo();
    getPromotionsData();
  }, [selectedClass]);

  useEffect(() => {
    refactorSelectedDate();
  }, [selectedDate]);

  useEffect(() => {
    setCurrentMonth(window?.localStorage.getItem("current_month"));
    getClasses();
  }, []);

  useEffect(() => {
    if (selectedClass) {
      fetchStudents();
    }
  }, [
    selectedDate,
    selectedClass,
    selectedRange,
    selectedSchedule,
    selectedWeekday,
  ]);

  useEffect(() => {
    setSelectedSchedule("");
  }, [selectedWeekday]);

  const modifiedWeekdayOptions = [
    weekDays.map((option, index) => ({
      value: index,
      label: `${option}`,
    })),
  ];
  const allOption = opt => {
    return { label: `すべて ${opt}`, value: "" };
  };

  const options = [
    allOption("時間"),
    ...classSchedule
      .flatMap(option =>
        option.classes.map(schedule => ({
          option,
          schedule,
        }))
      )
      .filter(({ option, schedule }) => {
        return (
          option.day === selectedWeekday.label &&
          schedule.start_time !== "" &&
          schedule.end_time !== ""
        );
      })
      .map(({ schedule }) => ({
        start_time: schedule.start_time,
        end_time: schedule.end_time,
        value: `${schedule.start_time}-${schedule.end_time}`,
        label: `${schedule.start_time}-${schedule.end_time}`,
      }))
      .filter((option, index, array) => {
        // Filter out duplicates based on the 'value' property
        return (
          index ===
          array.findIndex(
            item => item.value === option.value && item.label === option.label
          )
        );
      }),
  ];

  const UpperPart = () => {
    return (
      <>
        <div
          class="d-flex flex-wrap align-items-stretch py-3"
          style={{ gap: "4px" }}
        >
          <div style={{ width: "100px" }}>
            <Select
              defaultValue={selectedClass}
              onChange={setSelectedClass}
              options={classes.map(option => ({
                value: option.id,
                label: `${option.affiliation_name}`,
              }))}
              placeholder="授業"
            />
          </div>

          <div>
            <Select
              value={selectedWeekday}
              onChange={setSelectedWeekday}
              options={modifiedWeekdayOptions[0]}
              placeholder="曜日"
              isDisabled={!selectedClass}
            />
          </div>

          <div style={{ width: "160px" }}>
            <Select
              defaultValue={selectedSchedule}
              onChange={setSelectedSchedule}
              options={options}
              placeholder="時間"
              isDisabled={!selectedClass || !selectedWeekday}
            />
          </div>

          <div style={{ width: "110px" }}>
            <Select
              defaultValue={selectedRange}
              onChange={setSelectedRange}
              options={[
                allOption("級"),
                ...rangeOptions.map(option => ({
                  value: option === "特習" ? 0 : option,
                  label: `${option} ${option === "特習" ? "" : "級"}`,
                })),
              ]}
              placeholder="級"
              isDisabled={!selectedClass}
            />
          </div>
          <div>
            <ReactDatePicker
              className="shadow-sm custom_report_select"
              dateFormat="yyyy/MM"
              selected={selectedDate}
              onChange={selectDateHandler}
              todayButton={"Today"}
              locale={ja}
              placeholderText="日付を選択"
              readOnly={!selectedClass}
              showMonthYearPicker={true}
            />
          </div>
          <div>
            <button
              onClick={() => {
                setSelectedDate("");
                setSelectedRange("");
                setSelectedSchedule("");
                setSelectedWeekday("");
              }}
              className="btn btn-clear "
            >
              クリア
            </button>
          </div>
          <div>
            {selectedStudents && (
              <Button
                onClick={() => setShowUpdateAllModal(true)}
                variant="primary"
              >
                保存
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className="p-1 col-md-12 col-xl-12"
      eventKey="student-list"
      title="会員一覧"
    >
      <>
        <UpperPart />
        <div className="py-2">
          {students.length > 0 ? (
            <div className="print-this">
              <div className="list-header-one">
                <div
                  style={{ display: "flex", width: "50%", textAlign: "center" }}
                  className="w-50 border d-flex text-center"
                >
                  <div
                    style={{ width: "35%", border: "1px solid gray" }}
                    className="col-md-4 border"
                  >
                    <p>{selectedRange.label}</p>
                  </div>
                  <div
                    style={{ width: "35%", border: "1px solid gray" }}
                    className="border"
                  >
                    <p>{selectedDateStr}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "35%",
                      border: "1px solid gray",
                      flexDirection: "column",
                    }}
                    className="d-flex flex-column border"
                  >
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="border-bottom"
                    >
                      監督
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div
                style={{ display: "flex" }}
                className="list-header-two d-flex"
              >
                <div
                  style={{ width: "15%", border: "1px solid gray" }}
                  className="col-md-2 border"
                >
                  テスト項目
                </div>
                <div
                  style={{
                    width: "85%",
                    border: "1px solid gray",
                  }}
                  className="col-md-10 border"
                >
                  フローティング
                </div>
              </div>
              <div
                style={{ display: "flex" }}
                className="list-header-three d-flex"
              >
                <div
                  style={{ width: "15%", border: "1px solid gray" }}
                  className="col-md-2 border"
                >
                  目安
                </div>
                <div
                  style={{
                    width: "85%",
                    border: "1px solid gray",
                  }}
                  className="col-md-10 border"
                >
                  <p>{guideline}</p>
                  <p>{labelDetails}</p>
                </div>
              </div>
              <Table bordered hover size="sm">
                <thead>
                  <tr>
                    {[...Array(12).keys()].map(month => (
                      <th key={month} className="text-center">
                        {month + 1}
                      </th>
                    ))}
                    <th style={{ minWidth: "7%" }}>会員番号</th>
                    <th style={{ minWidth: "10%" }}>氏名</th>
                    <th className="text-center" style={{ minWidth: "5%" }}>
                      合格
                    </th>
                    <th style={{ minWidth: "30%" }}>今回の注意点</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, index) => (
                    <tr key={index}>
                      {[...Array(12).keys()].map(month => (
                        <td key={month} style={{ minWidth: "78px" }}>
                          <div className="col-md-12 att-value">
                            {/* First input for user-provided value */}
                            <input
                              className="col-md-4"
                              type="text"
                              value={
                                student.attendances.find(
                                  att => att.month === month + 1
                                )?.manual_total || ""
                              }
                              onChange={e =>
                                handleManualAttendanceChange(e, student, month)
                              }
                              disabled={
                                !student.attendances.find(
                                  att => att.month === month + 1
                                )?.total
                              }
                            />
                            {/* Second input for API data */}
                            <input
                              className="col-md-4"
                              type="text"
                              value={
                                student.attendances.find(
                                  att => att.month === month + 1
                                )?.total || ""
                              }
                              disabled
                            />
                            {/* Third input for sum */}
                            <input
                              className="col-md-4"
                              type="text"
                              value={
                                // sum first and second input
                                parseInt(
                                  student.attendances.find(
                                    att => att.month === month + 1
                                  )?.manual_total || 0
                                ) +
                                parseInt(
                                  student.attendances.find(
                                    att => att.month === month + 1
                                  )?.total || 0
                                ) +
                                ""
                              }
                              disabled
                            />
                          </div>
                        </td>
                      ))}
                      <td style={{ minWidth: "68px" }}>
                        {student?.member?.member_code}
                      </td>
                      <td style={{ minWidth: "100px" }}>
                        {student?.member?.name}
                      </td>
                      <td className="text-center" style={{ width: "5%" }}>
                        <Form.Check
                          className="d-flex justify-content-center"
                          type="switch"
                          id={`custom-switch`}
                          label=""
                          onChange={() => handleCheckboxChange(student)}
                          checked={selectedStudents.some(
                            selected => selected.memberId === student.member_id
                          )}
                        />
                      </td>
                      <td
                        class="d-flex w-100 gap-2"
                        style={{ minWidth: "130px" }}
                      >
                        {levels.length > 0 &&
                          levels.map((grade, index) => (
                            <div key={index}>
                              <input
                                data-toggle="tooltip"
                                data-placement="top"
                                title={grade}
                                type="checkbox"
                                id={index}
                                name={index}
                                value={grade}
                                className="mr-2"
                                onChange={() =>
                                  handleLabelCheckboxChange(student, grade)
                                }
                              />
                              <label htmlFor={index}>{grade}</label>
                            </div>
                          ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p className="text-info text-center">{noDataMsg}</p>
          )}
        </div>
        {students?.length > 0 && (
          <div className="col-md-12 d-flex justify-content-end">
            <Button className="mr-5" variant="primary" onClick={handlePrint}>
              印刷する
            </Button>
          </div>
        )}
      </>
      {/* modal for promotion */}
      <Modal
        size="sm"
        show={showUpdateAllModal}
        onHide={() => setShowUpdateAllModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>更新してもよろしいですか? </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => updateSelected()}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RankManagement;
