import React from "react";
// import "../../assets/common/css/dashboarButton.css";
import Button from "./Button";
import styled from "styled-components";

const studentDashboardButtons = [
  {
    name: "出席狀況",
    path: "/student/attendance",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "振替処理",
    path: "/student/re-schedule",
    icon: "fas fa-tachometer-alt",
  },
  {
    name: "支払状況",
    path: "/student/payment",
    icon: "fas fa-tachometer-alt",
  },
];
const Buttons = styled.div`
display: flex;
gap: 10px;
@media only screen and (max-width: 768px) {
   flex-direction: column;
   align-items: center;
}
`;
function Dashboard() {
  return (
    <div className="container mt-5">
      <h2 className="mb-4">これは会員機能一覧です</h2>
      <Buttons>
        {studentDashboardButtons.map((button, index) => (
          <div key={index} className=" mb-3">
            <Button to={button.path}>{button.name}</Button>
          </div>
        ))}
      </Buttons>
    </div>
  );
}

export default Dashboard;
