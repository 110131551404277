import React, { useState } from "react";
import privateApi from "../../services/privateApi";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Resizable } from "react-resizable";
import { Table } from "antd";
import { ConfigProvider } from "antd";
import ReactDatePicker from "react-datepicker";
import ja from "date-fns/locale/ja";
import swal from "sweetalert";
import BackButton from "../common/Button/BackButton";
import ActionButtonGroup from "../common/Button/ActionButtonGroup";
import "react-resizable/css/styles.css";
import moment from "moment";
import { getCurrentMonth } from "../../services/auth/token";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8",
    },
  },
};

const BusMaster = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  const isLaptop = useMediaQuery({ minWidth: 1024, maxWidth: 1366 });
  const [selectedBus, setSelectedBus] = useState();
  const [AddBtnEnbl, SetAddBtnEnbl] = useState(true);
  const [UpdateBtnEnbl, SetUpdateBtnEnbl] = useState(false);
  const [DltBtnEnbl, SetDltBtnEnbl] = useState(false);
  const [buses, setBuses] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [startDate, setStartDate] = useState(getCurrentMonth());
  const [loading, setLoading] = useState(false);
  const initialEndTo = getCurrentMonth();
  const [endTo, setEndTo] = useState(initialEndTo);
  const [isCopying, setIsCopying] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const getBuses = async () => {
    setLoading(true);

    const date = startDate && moment(startDate).format("YYYY-MM");

    const response = await privateApi.get(
      `admin/buses?page=${pageNumber}&per_page=${100}&processed_date=${date}`
    );
    if (response.status === 200) {
      setLoading(false);
      setBuses(response.data.data);
    } else {
      console.log("サーバーの不具合");
    }
  };

  const blankHandler = () => {
    setData({
      bus_course_number: "",
      stop_name: "",
      id: "",
    });
    setSelectedBus(null);
    SetAddBtnEnbl(true);
    SetUpdateBtnEnbl(false);
    SetDltBtnEnbl(false);
  };

  useEffect(() => {
    getBuses();
  }, [startDate]);

  const [dataInput, setData] = useState({
    bus_course_number: "",
    stop_name: "",
    id: "",
    start_time: "",
    reach_time: "",
  });

  const handleInput = e => {
    e.persist();
    const { name, value } = e.target;
    setData({ ...dataInput, [name]: value });
  };

  const formSubmit = async e => {
    e.preventDefault();

    const processedDate = startDate && moment(startDate).format("YYYY-MM");

    const requestData = {
      ...dataInput,
      processed_date: processedDate,
    };

    try {
      const response = await privateApi.post("admin/buses", requestData);

      if (response.status === 200) {
        swal("新しいバスが追加されました", "", "success");
        setData({
          bus_course_number: "",
          stop_name: "",
          id: "",
          start_time: "",
          reach_time: "",
        });
        getBuses();
      } else {
        swal(
          "何かエラーが発生しました",
          "",
          "error"
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      swal(
        "何かエラーが発生しました",
        "",
        "error"
      );
    }
  };


  const getBusInfo = async id => {
    const response = await privateApi.get("admin/buses/" + id);

    if (response.status === 200) {
      setData(response.data.data);
    } else {
      console.log("サーバーの不具合");
    }
  };

  const updateHandler = async () => {
    const idToUpdate = selectedBus;
    const updatedData = {
      bus_course_number: dataInput?.bus_course_number,
      stop_name: dataInput?.stop_name,
      start_time: dataInput?.start_time,
      reach_time: dataInput?.reach_time,
      processed_date: moment(startDate).format("YYYY-MM"),
    };

    if (!idToUpdate) {
      swal("更新するクラスを選択してください", "", "warning");
      return;
    }

    try {
      const response = await privateApi.update(
        `admin/buses/${idToUpdate}`,
        updatedData
      );

      if (response.status === 200) {
        swal("バスが更新されました", "", "success");
        getBusInfo(idToUpdate);
        getBuses();
      } else if (response.status === 422 && response.data?.errors?.processed_date) {
        swal(response.data.message, "", "error");
      } else {
        swal(
          "何かエラーが発生しました",
          "",
          "error"
        );
      }
    } catch (error) {
      console.error("Error updating bus:", error);
      swal(
        "何かエラーが発生しました",
        "",
        "error"
      );
    }
  };


  const handleClick = (id, index) => {
    if (selectedRowIndex === index) {
      blankHandler();
    } else {
      const selectedBus = buses.find(bus => bus.id === id);
      if (selectedBus) {
        setSelectedBus(id);
        setData({
          ...dataInput,
          bus_course_number: selectedBus.bus_course_number || "",
          stop_name: selectedBus.stop_name || "",
          id: selectedBus.id || "",
        });
        setSelectedRowIndex(index);
        SetAddBtnEnbl(false);
        SetUpdateBtnEnbl(true);
        SetDltBtnEnbl(true);
      }
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    const response = await privateApi.delete(`admin/buses/${selectedBus}`);

    if (response.status === 200) {
      swal("バスが削除されました", "", "success");
      setData({
        ...dataInput,
        bus_course_number: "",
        stop_name: "",
      });
      getBuses();
    } else {
      swal(
        "何かエラーが発生しました",
        "",
        "error"
      );
    }
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [columns, setColumns] = useState([
    {
      title: "バスコース番号",
      dataIndex: "bus_course_number",
      key: "bus_course_number",
      width: 20,
      sorter: (a, b) => a.bus_course_number.localeCompare(b.bus_course_number),
      render: (text, record) => <span>{record.bus_course_number}</span>,
    },
    {
      title: "停留所名",
      dataIndex: "stop_name",
      key: "stop_name",
      width: 40,
      sorter: (a, b) => a.stop_name.localeCompare(b.stop_name),
      render: (text, record) => <span>{record.stop_name}</span>,
    },
  ]);

  const handleConfirmCopy = () => {
    setShowConfirmationModal(true);
  };


  const handleCopy = async () => {
    setShowConfirmationModal(false);
    setIsCopying(true);
    try {

      const fromMonth = startDate.getFullYear() + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2);
      const toMonth = endTo.getFullYear() + '-' + ('0' + (endTo.getMonth() + 1)).slice(-2);

      const response = await privateApi.post('admin/bus-monthly-generate', {
        from_month: fromMonth,
        to_month: toMonth
      });

      if (response.status === 200) {
        swal("新しいバスが追加されました", "", "success");
      } else {
        swal(
          "何かエラーが発生しました",
          "",
          "error"
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setIsCopying(false);
    }
  };

  const handleCancelCopy = () => {
    setShowConfirmationModal(false);
  };

  const handleResize =
    index =>
      (e, { size }) => {
        setColumns(columns => {
          const nextColumns = [...columns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          return nextColumns;
        });
      };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = (record, index) => {
    const key = record.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );

    handleClick(key, index);
  };

  const rowClassName = record => {
    return selectedRowKey === record.id ? "table-selected-row" : "";
  };

  const position = {
    bottom: "-7%",
  };


  const handlePreviousMonth = () => {
    const newDate = new Date(startDate);
    newDate.setMonth(newDate.getMonth() - 1);
    console.log('Previous Month:', newDate);
    setStartDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(startDate);
    newDate.setMonth(newDate.getMonth() + 1);
    console.log('Next Month:', newDate);
    setStartDate(newDate);
  };

  return (
    <>
      <div
        className="card"
        style={{
          width: isMobile ? "100%" : isTablet || isLaptop ? "80%" : "50%",
        }}
      >
        <div className="card">
          <div className="card-header m-1 p-1">
            <div className="d-flex  d-flex justify-content-center align-items-center">

              <div className="form-group  col-md-3 row">
                <label htmlFor="staticEmail" className="col-4 d-flex justify-content-end col-form-label">
                  年月:
                </label>
                <div className="col-7" style={{ zIndex: "999" }}>
                  <ReactDatePicker
                    className="form-control form-control-sm"
                    dateFormat="yyyy/MM"
                    selected={startDate}
                    onChange={date => {
                      if (date) {
                        setStartDate(date);
                      }
                    }}
                    todayButton={"Today"}
                    locale={ja}
                    placeholderText="日付を選択"
                    showMonthYearPicker={true}
                    style={{ fontSize: "0.8rem", width: "50px" }}
                  />
                </div>
              </div>
              <div className="form-group col-md-3 row">
                <div className="col-md-12 mx-0 d-flex align-items-center">
                  <div className="btn-group">
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={handlePreviousMonth}
                    >
                      <i className="fas fa-arrow-left fa-fw"></i>
                    </button>

                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={handleNextMonth}
                    >
                      <i className="fas fa-arrow-right fa-fw"></i>
                    </button>

                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-6" style={{ zIndex: "999" }}>
                    <ReactDatePicker
                      className="form-control form-control-sm"
                      selected={endTo}
                      onChange={date => {
                        if (date) {
                          setEndTo(date);
                        }
                      }}
                      dateFormat="yyyy/MM"
                      showMonthYearPicker
                      locale={ja}
                      style={{ fontSize: "0.8rem", width: "50px" }}
                    />
                  </div>

                </div>
              </div>
              <div className="col-md-1 ms-1">  <button
                className="btn btn-sm btn-success mx-1"
                onClick={handleConfirmCopy}
                disabled={isCopying}
              >
                複製
              </button></div>

              <span></span>

              <div className="ml-auto">
                <ActionButtonGroup
                  storeHandler={formSubmit}
                  updateClasseHandler={updateHandler}
                  deleteClasseHandler={handleDeleteClick}
                  AddBtnEnbl={AddBtnEnbl}
                  UpdateBtnEnbl={UpdateBtnEnbl}
                  DltBtnEnbl={DltBtnEnbl}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="p-3 table-striped table-hover custom-tbl align-middle responsive">
            <div className="row mt-1">
              <div className="">
                <div className="row mb-1 col-12">
                  <div className="col-md-3">
                    <p>バスコース番号:</p>
                  </div>
                  <div className="col-md-5">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      name="bus_course_number"
                      onChange={handleInput}
                      value={dataInput.bus_course_number}
                      placeholder="番号を入力"
                      required
                    />
                  </div>
                </div>
                <div className="row col-12">
                  <div className="col-md-3">
                    <p>停留所名:</p>
                  </div>
                  <div className="col-md-5">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      onChange={handleInput}
                      value={dataInput.stop_name}
                      name="stop_name"
                      required
                    />
                  </div>
                </div>
              </div>

              <ConfigProvider theme={customTheme}>
                <Table
                  loading={loading}
                  bordered
                  className="table-striped-rows"
                  dataSource={buses}
                  columns={resizableColumns}
                  pagination={false}
                  components={components}
                  rowKey="id"
                  scroll={{ x: 400, y: "60vh" }}
                  rowClassName={rowClassName}
                  onRow={(record, index) => ({
                    onClick: () => handleRowClick(record, index),
                  })}
                />
              </ConfigProvider>
            </div>
          </div>

          <div className="d-flex">
            <div className="mx-auto neomp"></div>
          </div>

          
          <div className="row">
            <div className="col-md-10"></div>
            <div className="col-md-2">
              <BackButton
                position={position}
                redirectTo="/admin/bus/dashboard"
              />
            </div>
          </div>
        </div>
      </div>


      <Modal show={showModal} onHide={handleCloseModal}>
        {selectedBus ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>削除の確認</Modal.Title>
            </Modal.Header>
            <Modal.Body>消去してもよろしいですか？</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                キャンセル
              </Button>
              <Button variant="danger" onClick={handleConfirmDelete}>
                消去
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Modal.Body>
            <h4 className="text-center text-danger py-3">任意のクラスを選択</h4>
          </Modal.Body>
        )}
      </Modal>

      <Modal show={showConfirmationModal} onHide={handleCancelCopy}>
        <Modal.Header closeButton>
          <Modal.Title>複製の確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>複製してもよろしいですか？</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelCopy}>
            キャンセル
          </Button>
          <Button variant="success" onClick={handleCopy} disabled={isCopying}>
            複製
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BusMaster;
