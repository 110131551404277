const DateForm = ({ value, type = "" }) => {
  if (
    value === "null" ||
    value === null ||
    value === "undefined" ||
    value === undefined ||
    value === "Default"
  ) {
    return "";
  }

  const date = new Date(value);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  if (type === "short") {
    const formattedDate = `${year}/${month}`;
    return formattedDate;
  } else {
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  }
};

export default DateForm;
