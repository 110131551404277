export const isHalfWidthCharacter = char => {
   return /^[ -~]*$/.test(char);
};

export const convertToHalfWidthFunction = string => {
   let characters = getCharacters(string);
   let halfWidthString = "";
   characters.forEach(character => {
      halfWidthString += mapToHankaku(character);
   });
   return halfWidthString;
};

const getCharacters = string => {
   return string.split("");
};

const mapToHankaku = character => {
   let zenHanMap = getZenkakuToHankakuMap();
   return zenHanMap[character] || character;
};

const getZenkakuToHankakuMap = () => {
   let zenHanMap = {
      // Hiragana
      ぁ: "ｱ",
      あ: "ｱ",
      ぃ: "ｲ",
      い: "ｲ",
      ぅ: "ｳ",
      う: "ｳ",
      ぇ: "ｴ",
      え: "ｴ",
      ぉ: "ｵ",
      お: "ｵ",
      が: "ｶﾞ",
      き: "ｷ",
      ぎ: "ｷﾞ",
      く: "ｸ",
      ぐ: "ｸﾞ",
      け: "ｹ",
      げ: "ｹﾞ",
      こ: "ｺ",
      ご: "ｺﾞ",
      さ: "ｻ",
      ざ: "ｻﾞ",
      し: "ｼ",
      じ: "ｼﾞ",
      す: "ｽ",
      ず: "ｽﾞ",
      せ: "ｾ",
      ぜ: "ｾﾞ",
      そ: "ｿ",
      ぞ: "ｿﾞ",
      た: "ﾀ",
      だ: "ﾀﾞ",
      ち: "ﾁ",
      ぢ: "ﾁﾞ",
      っ: "ﾂ",
      つ: "ﾂ",
      づ: "ﾂﾞ",
      て: "ﾃ",
      で: "ﾃﾞ",
      と: "ﾄ",
      ど: "ﾄﾞ",
      な: "ﾅ",
      に: "ﾆ",
      ぬ: "ﾇ",
      ね: "ﾈ",
      の: "ﾉ",
      は: "ﾊ",
      ば: "ﾊﾞ",
      ぱ: "ﾊﾟ",
      ひ: "ﾋ",
      び: "ﾋﾞ",
      ぴ: "ﾋﾟ",
      ふ: "ﾌ",
      ぶ: "ﾌﾞ",
      ぷ: "ﾌﾟ",
      へ: "ﾍ",
      べ: "ﾍﾞ",
      ぺ: "ﾍﾟ",
      ほ: "ﾎ",
      ぼ: "ﾎﾞ",
      ぽ: "ﾎﾟ",
      ま: "ﾏ",
      み: "ﾐ",
      む: "ﾑ",
      め: "ﾒ",
      も: "ﾓ",
      ゃ: "ﾔ",
      や: "ﾔ",
      ゅ: "ﾕ",
      ゆ: "ﾕ",
      ょ: "ﾖ",
      よ: "ﾖ",
      ら: "ﾗ",
      り: "ﾘ",
      る: "ﾙ",
      れ: "ﾚ",
      ろ: "ﾛ",
      ゎ: "ﾜ",
      わ: "ﾜ",
      を: "ｵ",
      ん: "ﾝ",
      ゔ: "ｳﾞ",
      "・": "･",
      ー: "ｰ",
      "　": " ",
      // Katakana
      ア: "ｱ",
      イ: "ｲ",
      ウ: "ｳ",
      エ: "ｴ",
      オ: "ｵ",
      カ: "ｶ",
      ガ: "ｶﾞ",
      キ: "ｷ",
      ギ: "ｷﾞ",
      ク: "ｸ",
      グ: "ｸﾞ",
      ケ: "ｹ",
      ゲ: "ｹﾞ",
      コ: "ｺ",
      ゴ: "ｺﾞ",
      サ: "ｻ",
      ザ: "ｻﾞ",
      シ: "ｼ",
      ジ: "ｼﾞ",
      ス: "ｽ",
      ズ: "ｽﾞ",
      セ: "ｾ",
      ゼ: "ｾﾞ",
      ソ: "ｿ",
      ゾ: "ｿﾞ",
      タ: "ﾀ",
      ダ: "ﾀﾞ",
      チ: "ﾁ",
      ヂ: "ﾁﾞ",
      ッ: "ﾂ",
      ツ: "ﾂ",
      ヅ: "ﾂﾞ",
      テ: "ﾃ",
      デ: "ﾃﾞ",
      ト: "ﾄ",
      ド: "ﾄﾞ",
      ナ: "ﾅ",
      ニ: "ﾆ",
      ヌ: "ﾇ",
      ネ: "ﾈ",
      ノ: "ﾉ",
      ハ: "ﾊ",
      バ: "ﾊﾞ",
      パ: "ﾊﾟ",
      ヒ: "ﾋ",
      ビ: "ﾋﾞ",
      ピ: "ﾋﾟ",
      フ: "ﾌ",
      ブ: "ﾌﾞ",
      プ: "ﾌﾟ",
      ヘ: "ﾍ",
      ベ: "ﾍﾞ",
      ペ: "ﾍﾟ",
      ホ: "ﾎ",
      ボ: "ﾎﾞ",
      ポ: "ﾎﾟ",
      マ: "ﾏ",
      ミ: "ﾐ",
      ム: "ﾑ",
      メ: "ﾒ",
      モ: "ﾓ",
      ャ: "ｬ",
      ヤ: "ﾔ",
      ュ: "ｭ",
      ユ: "ﾕ",
      ョ: "ｮ",
      ヨ: "ﾖ",
      ラ: "ﾗ",
      リ: "ﾘ",
      ル: "ﾙ",
      レ: "ﾚ",
      ロ: "ﾛ",
      ワ: "ﾜ",
      ヲ: "ｵ",
      ン: "ﾝ",
      ヴ: "ｳﾞ",
   };

   return zenHanMap;
};


