import React, { useState, useEffect } from "react";
import privateApi from "../../services/privateApi";
import { Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { getData } from "../../services/auth/token";
import { Skeleton } from "antd";

export default function LoginLogs() {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState(false);
  const [paginationBtns, setPaginationBtns] = useState([]);

  const getLogs = async pageNumber => {
    setLoading(true);
    const response = await privateApi.get(
      `admin/search-login-log?page=${pageNumber}&per_page=10&school_id=${
        getData()?.school_id
      }`
    );
    if (response.status === 200) {
      setLogs(response.data.data);
      setPaginationBtns(response?.data);
    } else {
      console.log("Server Error");
    }
    setLoading(false);
  };

  useEffect(() => {
    getLogs();
  }, []);
  return (
    <div>
      <div className="p-2">
        {loading && <Skeleton paragraph={{ rows: 12 }} />}
        {logs && !loading && logs.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>IPアドレス</th>
                <th>メール</th>
                <th>ユーザーエージェント</th>
                <th>ログイン</th>
                <th>ログアウト</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>{log?.ip_address}</td>
                  <td>{log?.email}</td>
                  <td>{log?.user_agent}</td>
                  <td>{log?.login_at}</td>
                  <td>{log?.logout_at}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {logs.length > 0 && (
          <div className="paginations d-flex mt-3">
            <div className="d-flex mx-auto neomp">
              <Pagination
                activePage={
                  paginationBtns.current_page ? paginationBtns.current_page : 0
                }
                itemsCountPerPage={paginationBtns.per_page}
                totalItemsCount={paginationBtns.total}
                onChange={pageNumber => {
                  getLogs(pageNumber);
                }}
                pageRangeDisplayed={5}
                itemclassName="page-item"
                linkclassName="page-link"
                firstPageText="First"
                lastPageText="Last"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
