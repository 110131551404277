import React, { useState, useEffect, useMemo } from "react";
import swal from "sweetalert";
import privateApi from "../../services/privateApi";
import Page20 from "../extrapage/Page20";
import BackButton from "../common/Button/BackButton";
import ReactDatePicker from "react-datepicker";
import Nvh from "../common/Nvh";
import ja from "date-fns/locale/ja";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "react-resizable/css/styles.css";
import { Table } from "antd";
import { Resizable } from "react-resizable";
import { ConfigProvider } from "antd";

import "../../assets/common/css/modal.css";
import { getCurrentMonth } from "../../services/auth/token";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { HiChevronUpDown, HiChevronDown, HiChevronUp } from "react-icons/hi2";

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;
  if (width === undefined) {
    return <td {...restProps}></td>;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <td {...restProps}></td>
    </Resizable>
  );
};

const customTheme = {
  components: {
    Table: {
      rowHoverBg: "#D6EAF8",
    },
  },
};

let isFetching = false;

const Payer = () => {
  const buttonPosition = {
    position: "unset",
  };
  const [payers, setpayers] = useState([]);
  const [page20, setPage20] = useState(false);
  const [startDate, setStartDate] = useState(getCurrentMonth());
  const [currentStatus, setCurrentStatus] = useState("regular");
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loader, setLoader] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [tableKey, setTableKey] = useState(true);
  const payerPerpage = 100;
  const history = useHistory();

  const [orderType, setOrderType] = useState(false);
  const [orderColumn, setOrderColumn] = useState("");

  const updatePageHandler = id => {
    localStorage.setItem(
      "payerState",
      JSON.stringify({
        pageNumber: pageNumber - 1,
        selectedRowKey: id,
      })
    );
    history.push(`/admin/student/details/${id}?action=view&key=tab1`);
  };

  const getpayers = async (
    page = pageNumber,
    month = startDate,
    status = currentStatus,
    scroll = false,
    orderBy = orderColumn,
    order_type = orderType
  ) => {
    setLoader(true);

    const orderDirection = order_type ? "asc" : "desc";

    const response = await privateApi.get(
      `admin/invoices?status=${status}&processed_date=${moment(month).format("YYYY-MM")}&page=${page}&per_page=${payerPerpage}&orderBy=${orderBy}&orderDirection=${orderDirection}`
    );

    if (response.status === 200) {
      setLoader(false);
      isFetching = false;
      if (scroll) {
        if (response?.data?.data.length === 0) {
          setHasMore(false);
        } else {
          setpayers(prevPayers => [...prevPayers, ...response?.data?.data]);
        }

        setPageNumber(pageNumber + 1);
      } else {
        setTableKey(!tableKey);
        setHasMore(true);
        setpayers(response?.data?.data);
      }
    } else {
      console.log("Server Error");
    }
  };

  const handleSort = value => {
    setOrderType(prevOrderType => !prevOrderType);
    setOrderColumn(value);
  };

  useEffect(() => {
    getpayers(1, startDate, currentStatus, false, orderColumn, orderType);
  }, [orderType, orderColumn]);

  async function download_table_as_csv(table_id, separator = ",") {
    var rows = document.querySelectorAll("tr");
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) {
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ");
        data = data.replace(/"/g, '""');
        row.push('"' + data + '"');
      }
      csv.push(row.join(separator));
    }

    var csv_string = csv.join("\n");
    // Download it
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";

    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  // CSV
  const getstudents_class_csv_export = async (
    month = startDate,
    status = currentStatus
  ) => {
    try {
      const response = await privateApi.get(
        `admin/invoices?status=${status}&processed_date=${moment(month).format("YYYY-MM")}&page=1&per_page=9999999`
      );
      if (response.status === 200) {
        return response.data.data; // Return the data
      } else {
        console.log("Server Error");
        return []; // Return an empty array in case of an error
      }
    } catch (error) {
      console.error("Error fetching CSV data:", error);
      return []; // Return an empty array in case of an error
    }
  };

  async function download_table_as_csv(
    table_id,
    classDataCSVExport,
    separator = ","
  ) {
    var csv = [];
    var header = [];
    header.push('"' + "会員番号" + '"');
    header.push('"' + "氏名" + '"');
    header.push('"' + "電話番号" + '"');
    header.push('"' + "銀行名" + '"');
    header.push('"' + "処理年月" + '"');
    header.push('"' + "請求額" + '"');
    header.push('"' + "振替請求額" + '"');
    header.push('"' + "振替入金額" + '"');
    header.push('"' + "振替入金日" + '"');
    header.push('"' + "現金入金額1" + '"');
    header.push('"' + "現金入金日1" + '"');
    header.push('"' + "現金入金額2" + '"');
    header.push('"' + "現金入金日2" + '"');
    header.push('"' + "過不足額" + '"');
    header.push('"' + "過不足額累計" + '"');
    header.push('"' + "備考" + '"');

    csv.push(header.join(separator));

    classDataCSVExport &&
      classDataCSVExport.forEach(data => {
        var row = [];
        row.push('"' + Nvh({ value: data?.member?.member_code }) + '"');
        row?.push('"' + Nvh({ value: data?.member?.name }) + '"');
        row?.push('"' + Nvh({ value: data?.member?.phone_no_1 }) + '"');
        row?.push('"' + Nvh({ value: data?.member?.bank_name }) + '"');
        row?.push('"' + Nvh({ value: data?.processed_date }) + '"');
        row?.push('"' + Nvh({ value: data?.billing_amount }) + '"');
        row?.push('"' + Nvh({ value: data?.transfer_invoice_amount }) + '"');
        row?.push('"' + Nvh({ value: data?.transfer_amount }) + '"');
        row?.push('"' + Nvh({ value: data?.transfer_deposit_date }) + '"');
        row?.push('"' + Nvh({ value: data?.cash_deposit_amount }) + '"');
        row?.push('"' + Nvh({ value: data?.cash_deposit_date }) + '"');
        row?.push('"' + Nvh({ value: data?.cash_deposit_amount2 }) + '"');
        row?.push('"' + Nvh({ value: data?.cash_deposit_date2 }) + '"');
        row?.push('"' + Nvh({ value: data?.excess_deficiency }) + '"');
        row?.push(
          '"' + Nvh({ value: data?.cumulative_excess_deficiency }) + '"'
        );
        row?.push('"' + Nvh({ value: data?.note }) + '"');

        csv.push(row.join(separator));
      });
    var csv_string = csv.join("\n");
    // Download it
    var filename = table_id + "_" + moment().format("YYYYMMDD") + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    swal("CSV が正常に保存されました", "", "success");
  }

  const handleDownloadCSV = async table_id => {
    try {
      const classDataCSVExport = await getstudents_class_csv_export(
        startDate,
        currentStatus
      );
      await download_table_as_csv(table_id, classDataCSVExport); // Pass the data to the download function
    } catch (error) {
      console.error("Error handling CSV download:", error);
    }
  };

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem("payerState"));
    if (savedState) {
      setPageNumber(savedState.pageNumber);
      setSelectedRowKey(savedState.selectedRowKey);
      localStorage.removeItem("payerState");
    }
    getpayers(savedState?.pageNumber || 1, startDate, "regular");
  }, [startDate]);

  const handleScroll = e => {
    const target = e.target;
    const distanceFromBottom =
      target.scrollHeight - target.scrollTop - target.clientHeight;
    if (distanceFromBottom <= 300 && hasMore && !isFetching && !loader) {
      isFetching = true;
      getpayers(pageNumber, startDate, currentStatus, true);
    }
    setFirstTime(false);
  };

  useEffect(() => {
    const container = document.querySelector(".ant-table-body");

    container && container.addEventListener("scroll", handleScroll);

    return () => {
      container && container.removeEventListener("scroll", handleScroll);
    };
  }, [pageNumber, hasMore, loader, isFetching]);

  const [columns, setColumns] = useState([]);

  const updatedColumns = useMemo(() => [
    {
      title: (
        <div
          onClick={() => handleSort("member_code")}
          style={{ cursor: "pointer", display: "flex" }}
        >
          <div>会員番号</div>&nbsp;&nbsp;
          <div className="ml-auto">
            {orderColumn == "member_code" ? (
              orderType ? (
                <HiChevronUp style={{ fontSize: "22px" }} />
              ) : (
                <HiChevronDown style={{ fontSize: "22px" }} />
              )
            ) : (
              <HiChevronUpDown style={{ fontSize: "22px" }} />
            )}
          </div>
        </div>
      ),
      dataIndex: "member?.member_code",
      key: "member?.member_code",
      width: 110,
      fixed: "left",
      sorter: false,
      render: (text, record) => <span>{record?.member?.member_code}</span>,
    },
    {
      title: (
        <div
          onClick={() => handleSort("member_name")}
          style={{ cursor: "pointer", display: "flex" }}
        >
          <div>氏名</div>&nbsp;&nbsp;
          <div className="ml-auto">
            {orderColumn == "member_name" ? (
              orderType ? (
                <HiChevronUp style={{ fontSize: "22px" }} />
              ) : (
                <HiChevronDown style={{ fontSize: "22px" }} />
              )
            ) : (
              <HiChevronUpDown style={{ fontSize: "22px" }} />
            )}
          </div>
        </div>
      ),
      dataIndex: "member?.name",
      key: "member?.name",
      width: 120,
      fixed: "left",
      sorter: false,
      render: (text, record) => <span>{record?.member?.name}</span>,
    },
    {
      title: "電話番号",
      dataIndex: "member?.phone_no_1",
      key: "member?.phone_no_1",
      width: 130,
      sorter: (a, b) =>
        a?.member?.phone_no_1.localeCompare(b?.member?.phone_no_1),
      render: (text, record) => <span>{record?.member?.phone_no_1}</span>,
    },
    {
      title: (
        <div
          onClick={() => handleSort("bank_name")}
          style={{ cursor: "pointer", display: "flex" }}
        >
          <div>銀行名</div>&nbsp;&nbsp;
          <div className="ml-auto">
            {orderColumn == "bank_name" ? (
              orderType ? (
                <HiChevronUp style={{ fontSize: "22px" }} />
              ) : (
                <HiChevronDown style={{ fontSize: "22px" }} />
              )
            ) : (
              <HiChevronUpDown style={{ fontSize: "22px" }} />
            )}
          </div>
        </div>
      ),
      dataIndex: "member?.bank_name",
      key: "member?.bank_name",
      width: 120,
      render: (text, record) => <span>{record?.member?.bank_name}</span>,
      sorter: false,
    },
    {
      title: "処理年月",
      dataIndex: "processed_date",
      key: "processed_date",
      width: 90,
      sorter: (a, b) => {
        const aTimes = String(a?.processed_date);
        const bTimes = String(b?.processed_date);
        return aTimes?.localeCompare(bTimes);
      },
      render: (text, record) => <span>{record?.processed_date}</span>,
    },
    {
      title: "請求額",
      dataIndex: "billing_amount",
      key: "billing_amount",
      width: 80,
      render: (text, record) => (
        <span>{Nvh({ value: record?.billing_amount })}</span>
      ),
      sorter: (a, b) => {
        const aTimes = String(a?.billing_amount);
        const bTimes = String(b?.billing_amount);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "振替請求額",
      dataIndex: "transfer_invoice_amount",
      key: "transfer_invoice_amount",
      width: 110,

      render: (text, record) => <span>{record?.transfer_invoice_amount}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.transfer_invoice_amount);
        const bTimes = String(b?.transfer_invoice_amount);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "振替入金額",
      dataIndex: "transfer_amount",
      key: "transfer_amount",
      width: 110,
      render: (text, record) => (
        <span>{Nvh({ value: record?.transfer_amount })}</span>
      ),
      sorter: (a, b) => {
        const aTimes = String(a?.transfer_amount);
        const bTimes = String(b?.transfer_amount);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "振替入金日",
      dataIndex: "transfer_deposit_date",
      key: "transfer_deposit_date",
      width: 110,
      render: (text, record) => <span>{record?.transfer_deposit_date}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.transfer_deposit_date);
        const bTimes = String(b?.transfer_deposit_date);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "現金入金額1",
      dataIndex: "cash_deposit_amount",
      key: "cash_deposit_amount",
      width: 115,
      render: (text, record) => <span>{record?.cash_deposit_amount}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.cash_deposit_amount);
        const bTimes = String(b?.cash_deposit_amount);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "現金入金日1",
      dataIndex: "cash_deposit_date",
      key: "cash_deposit_date",
      width: 115,
      render: (text, record) => <span>{record?.cash_deposit_date}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.cash_deposit_date);
        const bTimes = String(b?.cash_deposit_date);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "現金入金額2",
      dataIndex: "cash_deposit_amount2",
      key: "cash_deposit_amount2",
      width: 130,
      render: (text, record) => <span>{record?.cash_deposit_amount2}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.cash_deposit_amount2);
        const bTimes = String(b?.cash_deposit_amount2);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "現金入金日2",
      dataIndex: "cash_deposit_date2",
      key: "cash_deposit_date2",
      width: 130,
      render: (text, record) => <span>{record?.cash_deposit_date2}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.cash_deposit_date2);
        const bTimes = String(b?.cash_deposit_date2);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "過不足額",
      dataIndex: "excess_deficiency",
      key: "excess_deficiency",
      width: 100,
      render: (text, record) => <span>{record?.excess_deficiency}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.excess_deficiency);
        const bTimes = String(b?.excess_deficiency);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "過不足額累計",
      dataIndex: "cumulative_excess_deficiency",
      key: "cumulative_excess_deficiency",
      width: 130,
      render: (text, record) => (
        <span>{record?.cumulative_excess_deficiency}</span>
      ),
      sorter: (a, b) => {
        const aTimes = String(a?.cumulative_excess_deficiency);
        const bTimes = String(b?.cumulative_excess_deficiency);
        return aTimes?.localeCompare(bTimes);
      },
    },
    {
      title: "備考",
      dataIndex: "note",
      key: "note",
      width: 150,
      render: (text, record) => <span>{Nvh({ value: record?.note })}</span>,
      sorter: (a, b) => {
        const aTimes = String(a?.note);
        const bTimes = String(b?.note);
        return aTimes?.localeCompare(bTimes);
      },
    },
  ]);

  useEffect(() => {
    setColumns(updatedColumns);
  }, [updatedColumns]);

  const handleResize =
    index =>
    (e, { size }) => {
      setColumns(columns => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: column => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const handleRowClick = record => {
    const key = record?.id;
    setSelectedRowKey(prevSelectedRowKey =>
      prevSelectedRowKey === key ? null : key
    );
  };

  const rowClassName = record => {
    return selectedRowKey === record?.id ? "table-selected-row" : "";
  };

  const listContent = (
    <div style={{ position: "inherit" }}>
      <div className="px-2 py-2">
        <div
          className="search-container d-flex justify-content-start border py-2 w-100 row"
          style={{ paddingRight: "0px" }}
        >
          <div
            style={{ gap: "4px" }}
            className="w-auto d-flex align-items-center col-md-12 justify-content-between"
          >
            <div className="col-md-2">
              <button
                type="button"
                id="B1"
                className={`btn ${
                  currentStatus === "paid" ? "btn-success" : "btn-primary"
                }`}
                onClick={() => {
                  setOrderType(false);
                  setOrderColumn("");

                  if (currentStatus === "paid") {
                    getpayers(1, startDate, "regular", false, "", false);
                    setCurrentStatus("regular");
                  } else {
                    getpayers(1, startDate, "paid", false, "", false);
                    setCurrentStatus("paid");
                  }
                }}
              >
                収納者
              </button>
            </div>
            <div className="col-md-2">
              <button
                type="button"
                className={`btn ${
                  currentStatus === "unpaid" ? "btn-success" : "btn-primary"
                }`}
                onClick={() => {
                  setOrderType(false);
                  setOrderColumn("");

                  if (currentStatus === "unpaid") {
                    getpayers(1, startDate, "regular", false, "", false);
                    setCurrentStatus("regular");
                  } else {
                    getpayers(1, startDate, "unpaid", false, "", false);
                    setCurrentStatus("unpaid");
                  }
                }}
              >
                未納者
              </button>
            </div>
            {/* <div className="col-md-1">年月:</div> */}
            <div className="col-md-4 w-auto justify-content-center mx-2 d-flex align-items-center highIndex">
              <span className="mr-3">年月:</span>
              <ReactDatePicker
                className="form-control w-100 text-center"
                selected={startDate}
                dateFormat="yyyy/MM"
                onChange={date => {
                  if (date) {
                    setStartDate(date);
                  }
                }}
                showMonthYearPicker
                locale={ja}
              />
            </div>
            <div className="col-md-2">
              <button
                type="button"
                className={`btn ${
                  currentStatus === "regular" ? "btn-primary" : "btn-primary"
                }`}
                onClick={() => getpayers(1, startDate, currentStatus)}
              >
                検索
              </button>
            </div>
            <div className="col-md-2">
              <button
                type="button"
                // onClick={() => {
                //   download_table_as_csv("収納者_未納者リスト");
                // }}
                onClick={() => {
                  handleDownloadCSV("収納者_未納者リスト");
                }}
                className="btn btn-primary"
              >
                <i className="fas fa-download fa-fw"></i>
                CSV
              </button>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3"></div>
        </div>
      </div>
      <div className="card-body" style={{ padding: "inherit" }}>
        <div>
          <div className="table-container2 member-table">
            <ConfigProvider theme={customTheme}>
              <Table
                key={tableKey}
                className="table-striped-rows"
                dataSource={payers}
                columns={resizableColumns}
                bordered
                scroll={{ y: "65vh" }}
                loading={loader}
                components={components}
                pagination={false}
                rowKey="id"
                rowClassName={rowClassName}
                onRow={record => ({
                  onClick: () => handleRowClick(record),
                  onDoubleClick: () => updatePageHandler(record?.member?.id),
                })}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>

      {payers && (
        <div className="paginations d-flex mt-3">
          <div className="d-flex mx-auto neomp"></div>
        </div>
      )}
      <div className="d-flex justify-content-end">
        <BackButton
          redirectTo="/admin/dashboard"
          className="btn-lg"
          position={buttonPosition}
        />
      </div>
    </div>
  );
  return (
    <>
      <div>
        <p className="headline">収納者／未納者リスト</p>
      </div>

      {page20 ? <Page20 /> : listContent}
    </>
  );
};

export default Payer;
