import ClassReschedule from "../components/coach/ClassReschedule";
import Dashboard from "../components/coach/Dashboard";
import Profile from "../components/coach/Profile";
import RankReport from "../components/coach/rank/RankReport";
import CoachPromotion from "../components/coach/rank/CoachPromotion";
import CoachAttendance from "../components/coach/attendance/CoachAttendance";
import CoachRankDash from "../components/coach/rank/CoachDashboard";
import CoachRankManagement from "../components/coach/rank/CoachRankManagement";
import CoachGrade from "../components/coach/rank/CoachGrade";

const routes = [
  { path: "/coach", exact: true, name: "Coach" },
  {
    path: "/coach/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  { path: "/coach/profile", exact: true, name: "Profile", component: Profile },

  // {
  //   path: "/coach/re-schedule/class",
  //   exact: true,
  //   name: "reschedule class",
  //   component: ClassReschedule,
  // },
  {
    path: "/coach/attendance",
    exact: true,
    name: "coach attendance",
    component: CoachAttendance,
  },
  // {
  //   path: "/coach/rank/rank-management",
  //   exact: true,
  //   name: "promotion",
  //   component: CoachPromotion,
  // },
  {
    path: "/coach/rank/report",
    exact: true,
    name: "rank report",
    component: RankReport,
  },
  //promotion
  {
    path: "/coach/promotion/dashboard",
    exact: true,
    name: "Rank Dashboard",
    component: CoachRankDash,
  },
  {
    path: "/coach/promotion/report",
    exact: true,
    name: "Rank Report",
    component: CoachRankManagement,
  },
  {
    path: "/coach/promotion/rank-management",
    exact: true,
    name: "Rank",
    component: CoachPromotion,
  },
  {
    path: "/coach/promotion/grade",
    exact: true,
    name: "Grade Labels",
    component: CoachGrade,
  },
];

export default routes;
