import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import FrontendLayout from "./layouts/frontend/FrontendLayout";
import privateApi from "./services/privateApi";
import adminRoutes from "./routes/adminRoutes";
import studentRoutes from "./routes/studentRoutes";
import coachRoutes from "./routes/coachRoutes";
import superAdminRoutes from "./routes/superAdminRoutes";
import { useLocation, useHistory } from "react-router-dom";
import { getSchool, getToken } from "./services/auth/token";
import { addEventListener, removeEventListener } from "./services/event/eventBus";

const routes = [...adminRoutes, ...studentRoutes, ...coachRoutes, ...superAdminRoutes];

function PublicRoute({ ...rest }) {
  const location = useLocation();
  const history = useHistory();
  const [schoolSlug, setSchoolSlug] = useState(getSchool() ? '/' + getSchool() : '');

  const isRouteInArray = (currentPath) => {
    return routes.some((route) => route.path === currentPath);
  };

  const isCurrentRouteInArray = isRouteInArray(location.pathname);

  const extractUserTypeFromPath = (pathname) => {
    const parts = pathname.split("/");
    if (parts.length > 1) {
      return parts[1];
    }
    return null;
  };

  const logoutController = async (userType) => {
    if (!getToken()) {
      return;
    }
    const response = await privateApi.get(`${userType}/me`);

    if (response.status === 200) {
      if(userType === "superadmin") {
        history.push("/super-admin/dashboard");
        return;
      } else if (userType === "member") {
        history.push("/student/dashboard");
        return;
      } else {
        history.push(`/${userType}/dashboard`);
        return;
      }
    }
  };

  useEffect(() => {

    if (!schoolSlug) {
      setSchoolSlug(getSchool() ? '/' + getSchool() : '');
    }

    const handleSchoolSlugChanged = (newSlug) => {
      setSchoolSlug(newSlug.startsWith('/') ? newSlug : '/' + newSlug);
    };

    addEventListener('schoolSlugChanged', handleSchoolSlugChanged);

    if (!isCurrentRouteInArray) {
      const previousPathname = localStorage.getItem("previousPathname");
      if (previousPathname) {
        const userType = extractUserTypeFromPath(previousPathname);
        if (userType) {
          if (userType === "super-admin") {
            logoutController("superadmin");
          }
          if (userType === "student") {
            logoutController("member");
          }
          logoutController(userType);
        }
      }
    }

    return () => {
      removeEventListener('schoolSlugChanged', handleSchoolSlugChanged);
    };
  }, [location.pathname, schoolSlug]);

  return (
    <Route
      {...rest}
      render={(props) => <FrontendLayout {...props} />}
    />
  );
}

export default PublicRoute;
